var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !!_vm.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between column-768",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-52 fw-600 white-color mb-4 main_text",
                            },
                            [_vm._v("Сведения о заказчике")]
                          ),
                          _c("div", { staticClass: "d-flex info_block" }, [
                            _vm.user &&
                            _vm.organization &&
                            _vm.user.organization.is_provider === true
                              ? _c("p", { staticClass: "mb-0 mr-4" }, [
                                  _vm.organization.is_favorite === true
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer white-color fs-15 align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFavorite(
                                                _vm.organization
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              alt: "empty_star",
                                              src: "/images/full_star.svg",
                                            },
                                          }),
                                          _vm._v(" УБРАТЬ ИЗ ИЗБРАННОГО "),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer white-color fs-15 align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addFavorite(
                                                _vm.organization
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              alt: "empty_star",
                                              src: "/images/empty_star.svg",
                                            },
                                          }),
                                          _vm._v(" В ИЗБРАННОЕ "),
                                        ]
                                      ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-14 white-color text-uppercase mb-4",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.organization.full_name) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "mr-2 fs-14 white-color fw-100" },
                            [_vm._v("ОГРН")]
                          ),
                          _c(
                            "span",
                            { staticClass: "m-0 fs-14 white-color fw-600" },
                            [_vm._v(_vm._s(_vm.organization.ogrn))]
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "mr-2 fs-14 white-color fw-100" },
                            [_vm._v("ИНН")]
                          ),
                          _c(
                            "span",
                            { staticClass: "m-0 fs-14 white-color fw-600" },
                            [_vm._v(_vm._s(_vm.organization.inn))]
                          ),
                        ]),
                        _vm.organization.kpp
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 fs-14 white-color fw-100",
                                },
                                [_vm._v("КПП")]
                              ),
                              _c(
                                "span",
                                { staticClass: "m-0 fs-14 white-color fw-600" },
                                [_vm._v(_vm._s(_vm.organization.kpp))]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        [
          !!_vm.organization
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-5" },
                    [
                      _c("text-header", [_vm._v("Сведения об организации")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.full_name,
                          label: "Полное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.short_name,
                          label: "Сокращенное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.post_address,
                          label: "Почтовый адрес",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.fact_address,
                          label: "Фактический адрес",
                        },
                      }),
                      _c("text-header", [_vm._v("Контактная информация")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.email,
                          label: "Адрес электронной почты",
                        },
                      }),
                      _c("text-row-phone", {
                        attrs: {
                          value: _vm.organization.phone,
                          label: "Телефон",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }