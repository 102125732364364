<template>
    <div>
        <text-header>Идентификационные данные пользователя</text-header>
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <form-row-edit-text label="Фамилия" v-model="form.user.last_name" disabled />
            <form-row-edit-text label="Имя" v-model="form.user.first_name" disabled />
            <form-row-edit-text label="Отчество" v-model="form.user.middle_name" disabled />
            <form-row-edit-email :email-confirmed="form.user.email_confirmed" :v="$v.form.user.email" :value="form.user.email" label="Email" with-validation is-edit @input="form.user.email_confirmed = false" @value="form.user.email = $event" @email-confirmed="form.user.email_confirmed = $event" />
            <form-row-edit-phone label="Телефон" :value="form.user.phone" @value="form.user.phone = $event" :v="$v.form.user.phone" />
            <b-form-group label="Должность" class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" :state="$v.form.user.post.$invalid ? !$v.form.user.post.$invalid : null">
                <b-form-select v-model="form.user.post" :state="!$v.form.user.post.$invalid" aria-describedby="formdata_post_liveFeedback" class="fs-14">
                    <option value="" disabled>Выберите должность</option>
                    <option>Руководитель организации</option>
                    <option>Администратор организации</option>
                    <option>Лицо, уполномоченное на размещение информации и документов</option>
                    <option>Лицо, имеющее право подписи документов от имени организации</option>
                </b-form-select>
                <b-form-invalid-feedback id="formdata_post_liveFeedback" v-if="$v.form.user.post.$invalid" :state="!$v.form.user.post.$invalid"> Поле обязательно для заполнения. </b-form-invalid-feedback>
            </b-form-group>
            <form-row-edit-text label="Логин" v-model="form.user.username" description="Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля" disabled />
            <form-row-edit-text label="Новый пароль" v-model="form.user.password" @value="form.user.password = $event" :v="form.user.password ? $v.form.user.password : null" type="password" />
            <form-row-edit-text label="Подтверждение пароля" v-model="form.user.password_confirmation" @value="form.user.password_confirmation = $event" :v="form.user.password ? $v.form.user.password_confirmation : null" type="password" />
        </b-overlay>
        <b-row class="pt-3 pl-3">
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <b-button class="mr-3" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
            </b-overlay>
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <b-button :disabled="$v.form.$invalid" variant="custom-green" class="text-uppercase" @click="sendData">Сохранить</b-button>
            </b-overlay>
        </b-row>
    </div>
</template>

<script>
import { helpers, required, email, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import CabinetUser from '@/services/api/cabinet/CabinetUser';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const allowable = helpers.withParams({ type: 'allowable' }, (value) => (value ? /^.*[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/.test(value) : true));
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserData',
    data() {
        return {
            isLoading: false,
            form: {
                user: {
                    last_name: '',
                    first_name: '',
                    middle_name: '',
                    email: '',
                    email_confirmed: true,
                    phone: '',
                    post: '',
                    username: '',
                    password: '',
                    password_confirmation: ''
                }
            }
        };
    },
    validations: {
        form: {
            user: {
                post: { required, minLength: minLength(3) },
                phone: { required, phone },
                email: { required, email },
                email_confirmed: { checked: (value) => value === true },
                password: { minLength: minLength(8), allowable, containsSymbols },
                password_confirmation: {
                    required: requiredIf((form) => form.password),
                    confirmPassword: sameAs((form) => (form.password ? form.password : form.password_confirmation))
                }
            }
        }
    },
    mounted() {
        this.isLoading = true;
        const userData = {
            last_name: this.getUser?.last_name,
            first_name: this.getUser?.first_name,
            middle_name: this.getUser?.middle_name,
            email: this.getUser?.email,
            email_confirmed: true,
            phone: this.getUser?.phone,
            post: this.getUser?.post,
            username: this.getUser?.username
        };
        this.form.user = JSON.parse(JSON.stringify(userData));
        this.isLoading = false;
    },
    methods: {
        onCancel() {
            this.$router.push('/cabinet').catch(() => {});
        },
        async sendData() {
            this.isLoading = true;
            if (await CabinetUser.sendChangeRequest(this.form)) {
                this.onCancel();
            }
            this.isLoading = false;
        }
    },
    computed: {
        ...mapGetters(['getUser'])
    }
};
</script>
