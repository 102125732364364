<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <p class="fs-32 grey-color mb-4" style="line-height: 1">Закупки</p>
                <ul class="tabs">
                    <li v-for="(tab, index) in tabs" :key="'key_' + index">
                        <router-link
                            :to="{
                                name: 'ProviderContractsCabinet',
                                params: { cat: tab.path }
                            }"
                            exact
                            >{{ tab.title }}
                        </router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <filter-multiple-select v-model="filter.status_id" :options="forFilters.statuses" label="Статус закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" />
            <filter-input-text v-model="filter.reg_number" label="Рег.номер закупки" label-class="grey-color" lg="6" md="6" placeholder="Введите рег.номер закупки" />
            <filter-multiple-select v-model="filter.purchase_categories" :options="forFilters.purchaseCategories" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />
            <filter-input-text v-model="filter.customer" label="Заказчик" label-class="grey-color" lg="6" md="6" placeholder="Введите название организации или ИНН" />

            <b-col class="mb-3 text-right" md="12">
                <b-btn class="text-uppercase ml-2" variant="custom-green" @click.prevent="applyFilters">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
            </b-col>
        </b-row>
        <template v-for="purchase in purchases">
            <out-system-contract v-if="purchase.is_external" :key="purchase.id" :purchase="purchase"></out-system-contract>
            <provider-contracts v-else :key="purchase.id" :purchase="purchase"></provider-contracts>
        </template>
        <empty-request v-if="!purchases.length && !loading"></empty-request>
        <b-pagination v-if="purchases && purchases.length" v-model="currentPage" :per-page="limit" :total-rows="count" align="center" @input="applyFilters"></b-pagination>
    </div>
</template>

<script>
import FilterMultipleSelect from '@/components/common/filter-components/filter-multiple-select';
import Directories from '@/services/api/Directories';

const ProviderContracts = () => import(/* webpackChunkName: "main" */ '@/components/purchases/PurchaseStatusCard/PurchaseCustomerCabinet.vue');
const OutSystemContract = () => import(/* webpackChunkName: "main" */ '@/components/purchases/outAtmo/PurchaseCardForCabinet.vue');
export default {
    name: 'Contracts',
    components: { FilterMultipleSelect, ProviderContracts, OutSystemContract },
    data() {
        return {
            tabs: [
                { title: 'Все закупки', path: 'all' },
                { title: 'Закупки в ИС', path: 'in_atmo' },
                { title: 'Внесистемные закупки', path: 'outside_atmo' }
            ],
            purchases: [],
            loading: false,
            limit: 10,
            count: 0,
            currentPage: 1,
            number: '',
            id: null,
            showDeleteModal: false,
            showPublishModal: false,
            showCancelModal: false,
            filter: {
                status_id: [],
                purchase_categories: [],
                reg_number: '',
                customer: ''
            },
            forFilters: {
                purchaseCategories: [],
                statuses: [
                    { name: 'Договор заключен', id: 3 },
                    { name: 'Договор исполнен', id: 4 },
                    { name: 'Договор не исполнен', id: 5 },
                    { name: 'Договор расторгнут', id: 6 }
                ]
            }
        };
    },
    async mounted() {
        await this.fillFilters();
        await this.applyFilters();
    },
    watch: {
        $route: 'applyFilters'
    },
    methods: {
        async fillFilters() {
            const responseData = await Promise.all([Directories.getPurchaseCategories()]);
            this.forFilters.purchaseCategories = responseData[0].data.data;
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getCustomers();
        },
        checkUpdate(response) {
            return Boolean(this.purchases.find((x) => x.id === response.data));
        },
        getCustomers() {
            // eslint-disable-next-line no-undef
            axios
                .post('/cabinet/provider/contracts', {
                    limit: this.limit,
                    currentPage: this.currentPage,
                    filters: this.filter,
                    param: this.$route.params.cat
                })
                .then((resp) => {
                    this.purchases = resp.data.purchases;
                    this.count = resp.data.count;
                    this.$scrollToTop();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cleanFilters() {
            this.filter.status_id.splice(0);
            this.filter.reg_number = '';
            this.filter.customer = '';
            this.filter.purchase_categories.splice(0);
            this.applyFilters();
        }
    },
    beforeDestroy() {
        // window.Echo.leave('purchase_change_status');
    }
};
</script>
<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.tabs li .router-link-active {
    color: var(--green-color) !important;
    border-bottom: 2px solid var(--green-color) !important;
}
</style>
