<template>
    <div>
        <b-container fluid class="greyBg pb-4" v-if="purchase">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="block_head-text">Информация о заказчике</p>
                    </b-col>
                </b-row>
                <form-row-show-text label="Наименование" v-model="purchase.organization.name" />
                <form-row-show-text label="ИНН" v-model="purchase.organization.inn" />
                <form-row-show-text label="КПП" v-model="purchase.organization.kpp" v-if="purchase.organization.kpp" />
                <form-row-show-text label="ОГРН" v-model="purchase.organization.ogrn" v-if="purchase.organization.ogrn" />
                <form-row-show-text label="Адрес" v-model="purchase.organization.post_address" />
                <form-row-show-text label="Дополнительная контактная информация" v-model="purchase.additional_contact_info" v-if="purchase.additional_contact_info" />
            </b-container>
        </b-container>
        <b-container fluid class="pb-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="block_head-text">Условия закупки</p>
                    </b-col>
                </b-row>
                <form-row-show-text label="Предмет закупки" v-model="purchase.purchase_category_name" />
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Предмет закупки: </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ purchase.purchase_category_name }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Вид оплаты: </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ purchase.payment_type }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Условия оплаты: </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ purchase.payment_term }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Начальная максимальная цена контракта, руб.: </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ $formatPrice(purchase.start_price) }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Дата и время размещения закупки </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ $formatDateTimeSeconds(purchase.purchase_start_datetime) }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Дата и время окончания подачи </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ $formatDateTimeSeconds(purchase.purchase_end_datetime) }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Планируемая дата заключения договора </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        {{ getDateFormat(purchase.planned_contract_date) }}
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Приложение заказчика к объявлению </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <ul>
                            <li v-for="document of purchase.documents" :key="document.id">
                                <b-button variant="link" size="xs" style="padding-top: 0; padding-bottom: 0; color: var(--green-color) !important" class="fs-14" @click="$commonDocumentDownload(document)">{{ document.name }}</b-button>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row class="py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color"> Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <!--                        {{  purchase.additional_contact_info }}-->
                        {{ purchase.additional_info }}
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <!--    <b-container fluid class="greyBg pb-4">-->
        <!--      <b-container>-->
        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <p class="block_head-text">Условия поставки</p>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <b-row class="border-bottom py-3" no-gutters v-if="purchase.delivery && purchase.delivery.length">-->
        <!--          <b-col md="3" class="fs-14 grey-color"> Доставка товаров или выполнение работ: </b-col>-->
        <!--          <b-col md="9" class="fs-14 grey-color">-->
        <!--            <p class="fs-12 m-0 fw-600" v-for="deliver in purchase.delivery" :key="deliver.id" v-bind="deliver">-->
        <!--              {{ deliver.address }}-->
        <!--            </p>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <b-row class="border-bottom py-3" no-gutters v-if="purchase.order_plan">-->
        <!--          <b-col md="3" class="fs-14 grey-color"> График поставки товаров (выполнения работ, оказания услуг): </b-col>-->
        <!--          <b-col md="9" class="fs-14 grey-color">-->
        <!--            {{ purchase.order_plan }}-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <b-row class="py-3" no-gutters>-->
        <!--          <b-col md="3" class="fs-14 grey-color"> Максимальный срок поставки товаров: </b-col>-->
        <!--          <b-col md="9" class="fs-14 grey-color">-->
        <!--            {{ getDateFormat(purchase.max_date) }}-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--      </b-container>-->
        <!--    </b-container>-->
        <!--    <b-container fluid class="pb-4">-->
        <!--      <b-container>-->
        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <p class="block_head-text">Спецификация</p>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <b-row class="py-3" no-gutters>-->
        <!--          <b-col>-->
        <!--            <table class="table">-->
        <!--              <thead>-->
        <!--              <tr>-->
        <!--                <th width="50px">№</th>-->
        <!--                <th width="150px">Рег.номер ТРУ</th>-->
        <!--                <th>Наименование товара (работ, услуг)</th>-->
        <!--                <th width="100px">Код ОКПД2</th>-->
        <!--                <th width="300px">Характеристики ТРУ</th>-->
        <!--                <th width="100px">Количество</th>-->
        <!--                <th width="100px">Ед.изм</th>-->
        <!--              </tr>-->
        <!--              </thead>-->
        <!--              <tbody>-->
        <!--              <tr v-for="(item, index) in purchase.items" :key="item.id" v-bind="item">-->
        <!--                <td>{{ index + 1 }}</td>-->
        <!--                <td>-->
        <!--                  <template v-if="!!item.ktru">-->
        <!--                    <span :id="'spec-ktru-popover-' + (index + 1)">{{ item.ktru.reg_number }}</span>-->
        <!--                    <b-popover :target="'spec-ktru-popover-' + (index + 1)" triggers="hover" placement="top">-->
        <!--                      <template v-slot:title><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</template>-->
        <!--                      <b>Группа ТРУ:</b> {{ item.ktru.ktru_group_name }}<br />-->
        <!--                      <b>Наименование:</b> {{ item.ktru.name }}<br />-->
        <!--                      <b>Тип:</b> {{ item.ktru.type }}<br />-->
        <!--                    </b-popover>-->
        <!--                  </template>-->
        <!--                  <template v-else>&mdash;</template>-->
        <!--                </td>-->
        <!--                <td>{{ item.name }}</td>-->
        <!--                <td class="align-text-top">{{ item.okpd ? item.okpd.code : '&mdash;' }}</td>-->
        <!--                <td class="align-text-top">-->
        <!--                  <template v-if="item.ktru">-->
        <!--                    <b-button v-b-toggle="'collapse-' + index" class="btn-xs btn-custom-green">{{ visible[index] ? 'Скрыть' : 'Показать' }} характеристики</b-button>-->
        <!--                    <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="mt-2">-->
        <!--                      <ul>-->
        <!--                        <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">-->
        <!--                          <b>{{ spec.Name }}</b> - {{ spec.Value }}-->
        <!--                        </li>-->
        <!--                      </ul>-->
        <!--                    </b-collapse>-->
        <!--                  </template>-->
        <!--                  <template v-else>&mdash;</template>-->
        <!--                </td>-->
        <!--                <td style="text-align: right">{{ item.count }}</td>-->
        <!--                <td style="text-align: right">-->
        <!--                  <template v-if="item.okei">{{ item.okei.local_symbol }}</template>-->
        <!--                  <template v-else>&mdash;</template>-->
        <!--                </td>-->
        <!--              </tr>-->
        <!--              </tbody>-->
        <!--            </table>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--      </b-container>-->
        <!--    </b-container>-->
        <!--    <b-container>-->
        <!--      <b-row class="mb-5">-->
        <!--        <b-col>-->
        <!--          <b-overlay :show="pdfLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">-->
        <!--            <b-btn variant="custom-outline-secondary" @click="downloadNoticePDF" class="mr-2">СКАЧАТЬ ПЕЧАТНУЮ ФОРМУ</b-btn>-->
        <!--          </b-overlay>-->
        <!--          <timer v-if="!!purchase.purchase_end" :data="purchase.purchase_end" @timeout="overTime = true" style="display: none"></timer>-->
        <!--          <b-btn-->
        <!--            variant="custom-green"-->
        <!--            class="text-uppercase mx-2"-->
        <!--            @click="checkProvider(purchase.id)"-->
        <!--            :disabled="checkingProvider"-->
        <!--            v-if="!overTime && $store.getters.isLoggedIn && $store.getters.isProvider && (purchase.status_id === PURCHASE_STATUS.status_start || purchase.is_hidden === true)"-->
        <!--          >-->
        <!--            {{ purchase.is_participation === true ? 'изменить предложение' : 'подать предложение' }}-->
        <!--          </b-btn>-->
        <!--          <router-link-->
        <!--            class="text-nowrap btn btn-custom-green text-uppercase mx-2"-->
        <!--            :to="{ name: 'ExternalPurchaseCreate', params: { id: purchase.id } }"-->
        <!--            v-if="$store.getters.isLoggedIn && isPurchaseCreator() && purchase.allow_external_purchase"-->
        <!--          >-->
        <!--            Закупить вне АТМО-->
        <!--          </router-link>-->
        <!--        </b-col>-->
        <!--      </b-row>-->
        <!--    </b-container>-->
        <!--    <b-modal hide-footer ref="attention-modal" title="Внимание!" size="lg" v-model="showDepositModal">-->
        <!--      <div class="d-block">-->
        <!--        <div class="w-100 fs-14 grey-color text-left">-->
        <!--          Блокировка денежных средств для обеспечения проведения торгов невозможна. Пожалуйста пополните лицевой счет. Необходимо для списания {{ needDep }} рублей.-->
        <!--        </div>-->
        <!--        <br />-->
        <!--        <div class="w-100 fs-14 fw-600 grey-color form-group">-->
        <!--          <b-input type="number" v-stop-number-mousewheel v-model="neededDeposit" :min="needDep" :state="checkDepositValid" step="any"></b-input>-->
        <!--        </div>-->
        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <b-input type="email" v-model="email" placeholder="Введите почту" :state="checkEmailValid"></b-input>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--        <br />-->
        <!--        <b-row>-->
        <!--          <b-col>-->
        <!--            <b-button variant="custom-green" class="text-uppercase" @click="sendInvoiceToEmail">ОТПРАВИТЬ НА E-MAIL </b-button>-->
        <!--            <b-button variant="custom-outline-secondary" class="text-uppercase ml-2" @click="downloadInvoice">СКАЧАТЬ PDF </b-button>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <!--      </div>-->
        <!--    </b-modal>-->
    </div>
</template>

<script>
import DownloadFilesApi from '@/services/api/DownloadFiles';
import Purchases from '@/services/api/Purchases';
import FileDownload from 'js-file-download';
import axios from 'axios';
import PurchasesApi from '@/services/api/Purchases';
import { __PURCHASE_STATUS } from '@/const';
import FormRowShowText from '@/components/common/form-rows/form-row-show-text';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    name: 'Fz223PurchaseOrderInfo',
    props: ['purchase'],
    components: {
        FormRowShowText
    },
    data() {
        return {
            pdfLoading: false,
            baseUrl: Config.get('api.config.legacy.url'),
            visible: [],
            PURCHASE_STATUS: {},
            overTime: false,
            checkingProvider: false,
            neededDeposit: 0,
            needDep: 0,
            email: this.$store.state.user && this.$store.state.user.email ? this.$store.state.user.email : '',
            showDepositModal: false
        };
    },
    created() {
        this.PURCHASE_STATUS = __PURCHASE_STATUS;
    },
    methods: {
        downloadInvoice() {
            if (this.isValid) {
                DownloadFilesApi.downloadInvoice('/account/invoice/download', 'Invoice.pdf', { amount: this.neededDeposit });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            }
        },
        sendInvoiceToEmail() {
            if (this.isValid) {
                let url = '/account/mail/sending';
                let data = { email: this.email, amount: this.neededDeposit };
                DownloadFilesApi.sendFileToEmail(url, data).then(() => {
                    this.pushToast({
                        text: 'Счет отправлен на Ваш email',
                        title: 'Успех',
                        variant: 'green'
                    });
                });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            }
        },
        downloadNoticePDF() {
            this.pdfLoading = true;
            Purchases.downloadNoticePDF(this.purchase).then((response) => {
                FileDownload(response.data, 'Извещение ' + this.purchase.notice_reg_number + '.pdf');
                this.pdfLoading = false;
            });
        },
        downloadPDF() {
            let url = '/purchases/download/order-info';
            let name = 'Извещение_№' + this.purchase.notice_reg_number + '.pdf';
            let data = { id: this.purchase.id };
            DownloadFilesApi.commonDownloadFileMethod(url, name, data);
        },
        async checkProvider(purchase_id) {
            let isPartisipation = this.purchase.is_participation || false;
            let checkAvailable = false;

            this.checkingProvider = true;
            if (!isPartisipation) {
                await axios
                    .post('/account/provider/check_deposit', { purchaseId: purchase_id })
                    .then((resp) => {
                        let dep = resp.data.needDep;
                        this.neededDeposit = dep;
                        this.needDep = dep;
                        checkAvailable = resp.data.isAvailable;
                    })
                    .catch(() => {
                        this.pushToast({
                            text: 'Сервер не отвечает, попробуйте еще раз.',
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    });
            }
            if (isPartisipation || checkAvailable) {
                PurchasesApi.checkProvider(purchase_id)
                    .then(() => {
                        this.$router.push({ name: 'PurchasesNewOffer', params: { id: purchase_id } }).catch(() => {});
                    })
                    .catch((error) => {
                        this.pushToast({
                            text: error.response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        this.checkingProvider = false;
                    });
            } else {
                this.checkingProvider = false;
                this.showDepositModal = true;
            }
        }
    },
    computed: {
        isValid() {
            return this.checkDepositValid && this.checkEmailValid;
        },
        checkDepositValid() {
            const re = /^\d{1,16}\.?\d{0,2}$/;
            return !isNaN(this.neededDeposit) && this.neededDeposit >= this.needDep && re.test(this.neededDeposit);
        },
        checkEmailValid() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        }
    }
};
</script>

<style scoped>
.link {
    color: var(--green-color) !important;
}
.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
.table {
    border-collapse: collapse;
}
.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}
.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
.btn-custom-green:hover {
    background-color: #fff !important;
    color: var(--green-color) !important;
    border-color: var(--green-color) !important;
}
</style>
