var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create" },
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { staticClass: "fs-52 fw-600 white-color mb-4 mt-2" },
                      [_vm._v("Объявление о закупочной сессии")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "fs-14 greyBg-color mb-1" }, [
                      _vm._v(
                        "«" + _vm._s(_vm.purchase.organization.short_name) + "»"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "fs-14 greyBg-color" }, [
                      _vm._v(
                        " Пользователь: " +
                          _vm._s(_vm.currentUser.full_name) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticStyle: { "background-color": "#f5f5f7" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "pb-5 position-relative purchase_session" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-tabs",
                        {
                          staticClass: "w-100",
                          attrs: { "content-class": "mt-3" },
                          model: {
                            value: _vm.tabIndex,
                            callback: function ($$v) {
                              _vm.tabIndex = $$v
                            },
                            expression: "tabIndex",
                          },
                        },
                        [
                          _c(
                            "b-tab",
                            { attrs: { active: "" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center head_register-list",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "fs-52 fw-600" },
                                      [_vm._v("01")]
                                    ),
                                    _c("span", { staticClass: "pl-2 fs-18" }, [
                                      _vm._v(" Информация о заказчике "),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-32 grey-color my-4" },
                                [_vm._v("Информация о заказчике")]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Полное наименование ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.purchase.organization.full_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Сокращенное наименование ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.purchase.organization.short_name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" ИНН ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.purchase.organization.inn
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" КПП ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.purchase.organization.kpp
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" ФИО ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentUser.full_name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Адрес электронной почты ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.currentUser.email) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Номер телефона ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parsePhone(
                                              _vm.currentUser.phone
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.purchase.additional_contact_info
                                ? _c(
                                    "b-row",
                                    { staticClass: "py-3" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "fs-14 grey-color d-flex align-items-center fw-700",
                                          attrs: { md: "3" },
                                        },
                                        [_vm._v(" Дополнительная информация ")]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "fs-14 grey-color",
                                          attrs: { md: "9" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.purchase
                                                  .additional_contact_info
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center head_register-list",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "fs-52 fw-600" },
                                      [_vm._v("02")]
                                    ),
                                    _c("span", { staticClass: "pl-2 fs-18" }, [
                                      _vm._v(" Условия закупки и поставки "),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-32 grey-color my-4" },
                                [_vm._v("Условия закупки")]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-14 grey-color fw-700",
                                        },
                                        [_vm._v("Предмет закупки")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.purchase_name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { state: true, md: "3" },
                                    },
                                    [_vm._v(" Тип закупки ")]
                                  ),
                                  _vm.purchase.order_type
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "fs-14 grey-color",
                                          attrs: { md: "9" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.purchase.order_type.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { state: true, md: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " Время продолжительности закупочной сессии, в часах "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [_vm._v(" 48 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { state: true, md: "3" },
                                    },
                                    [_vm._v(" Вид оплаты ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.payment_type) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-14 grey-color fw-700",
                                        },
                                        [
                                          _vm._v(
                                            "Планируемая дата заключения договора"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              _vm.purchase.planned_contract_date
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Условия оплаты ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.payment_term) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-14 grey-color fw-700",
                                        },
                                        [
                                          _vm._v(
                                            "Планируемая дата исполнения договора"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              _vm.purchase.planned_end_date
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-14 grey-color fw-700",
                                        },
                                        [
                                          _vm._v(
                                            "Начальная максимальная цена контракта (руб.)"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$formatPrice(
                                              _vm.purchase.start_price
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.additional_info) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "fs-32 grey-color my-4" },
                                [_vm._v("Требования к поставщикам")]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c("b-col", {
                                    staticClass:
                                      "fs-14 grey-color d-flex align-items-center",
                                    attrs: { md: "3" },
                                  }),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            checked:
                                              _vm.purchase
                                                .disallow_unfair_suppliers,
                                            disabled: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Отсутствие в реестре недобросовестных поставщиков "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c("b-col", {
                                    staticClass:
                                      "fs-14 grey-color d-flex align-items-center",
                                    attrs: { md: "3" },
                                  }),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            checked: _vm.purchase.only_smp,
                                            disabled: "",
                                          },
                                        },
                                        [_vm._v(" Только для СМП ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                { staticClass: "fs-32 grey-color my-4" },
                                [_vm._v("Условия поставки")]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v(" Адрес для доставки/самовывоза ")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row" },
                                        [
                                          _c("b-col", { attrs: { sm: "12" } }, [
                                            _c(
                                              "table",
                                              { staticClass: "table" },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", {
                                                      attrs: { width: "1" },
                                                    }),
                                                    _c("th", [_vm._v("Адрес")]),
                                                  ]),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  [
                                                    _vm._l(
                                                      _vm.purchase.delivery,
                                                      function (addr, index) {
                                                        return _c(
                                                          "tr",
                                                          { key: index },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  index + 1
                                                                ) + "."
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  addr.address
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm.purchase.delivery
                                                      .length === 0
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                colspan: "3",
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Не добавлено адресов"
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center fw-700",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _vm._v(
                                        " График поставки товаров (выполнения работ, оказания услуг) "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.order_plan) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                { staticClass: "py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "fs-14 grey-color fw-700",
                                        },
                                        [
                                          _vm._v(
                                            "Максимальный срок поставки товаров (выполнения работ, оказания услуг)"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              _vm.purchase.max_date
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center head_register-list",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "fs-52 fw-600" },
                                      [_vm._v("03")]
                                    ),
                                    _c("span", { staticClass: "pl-2 fs-18" }, [
                                      _vm._v(" Спецификация и документы "),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "fs-32 grey-color my-4",
                                        },
                                        [_vm._v("Спецификации")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "py-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "fs-14 grey-color" },
                                    [
                                      _c("table", { staticClass: "table" }, [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", { attrs: { width: "1" } }),
                                            _c("th", [
                                              _vm._v(
                                                "Наименование товара/работы/услуги"
                                              ),
                                            ]),
                                            _c("th", [_vm._v("Количество")]),
                                            _c(
                                              "th",
                                              { attrs: { nowrap: "" } },
                                              [_vm._v("Единица измерения")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.purchase.items,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.number)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.name)),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticStyle: {
                                                          "text-align": "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.count)
                                                        ),
                                                      ]
                                                    ),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.okei.local_symbol
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            !_vm.purchase.items
                                              ? _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: "5",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Не добавлено спецификаций"
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "fs-32 grey-color my-4",
                                        },
                                        [_vm._v("Проект ГК")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "py-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "fs-14 grey-color" },
                                    [
                                      _vm.purchase.documents
                                        ? _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                  _c("th", [
                                                    _vm._v("Наименование"),
                                                  ]),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.purchase.documents,
                                                  function (file, index) {
                                                    return _c(
                                                      "tr",
                                                      { key: index },
                                                      [
                                                        file.is_main === true
                                                          ? [
                                                              _c("td", [
                                                                _vm._v("1"),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "fs-14",
                                                                      staticStyle:
                                                                        {
                                                                          "padding-top":
                                                                            "0",
                                                                          "padding-bottom":
                                                                            "0",
                                                                          color:
                                                                            "var(--green-color) !important",
                                                                        },
                                                                      attrs: {
                                                                        variant:
                                                                          "link",
                                                                        size: "xs",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$commonDocumentDownload(
                                                                              file
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          file.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                  _c("th", [
                                                    _vm._v("Наименование"),
                                                  ]),
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                ]),
                                              ]),
                                              _c("tbody", [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: "3",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Не добавлено документов"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "fs-32 grey-color my-4",
                                        },
                                        [_vm._v("Дополнительные документы")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "py-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "fs-14 grey-color" },
                                    [
                                      _vm.purchase.documents
                                        ? _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                  _c("th", [
                                                    _vm._v("Наименование"),
                                                  ]),
                                                ]),
                                              ]),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.purchase.documents,
                                                  function (file, index) {
                                                    return _c(
                                                      "tr",
                                                      { key: index },
                                                      [
                                                        file.is_main === false
                                                          ? [
                                                              _c("td", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    index + 1
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "td",
                                                                [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "fs-14",
                                                                      staticStyle:
                                                                        {
                                                                          "padding-top":
                                                                            "0",
                                                                          "padding-bottom":
                                                                            "0",
                                                                          color:
                                                                            "var(--green-color) !important",
                                                                        },
                                                                      attrs: {
                                                                        variant:
                                                                          "link",
                                                                        size: "xs",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$commonDocumentDownload(
                                                                              file
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          file.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "table",
                                            { staticClass: "table" },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                  _c("th", [
                                                    _vm._v("Наименование"),
                                                  ]),
                                                  _c("th", {
                                                    attrs: { width: "1" },
                                                  }),
                                                ]),
                                              ]),
                                              _c("tbody", [
                                                _c("tr", [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: "3",
                                                        align: "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Не добавлено документов"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.isPurchaseCreator()
                        ? _c(
                            "b-row",
                            { staticClass: "my-3 mt-5" },
                            [
                              _c(
                                "b-col",
                                [
                                  _vm.tabIndex === 0
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-4 text-uppercase",
                                          attrs: {
                                            variant: "custom-outline-secondary",
                                          },
                                          on: { click: _vm.onCancel },
                                        },
                                        [_vm._v("Отменить ")]
                                      )
                                    : _vm._e(),
                                  _vm.tabIndex > 0
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-4 text-uppercase",
                                          attrs: {
                                            variant: "custom-outline-secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.tabIndex--
                                              _vm.$scrollToTop()
                                            },
                                          },
                                        },
                                        [_vm._v("Назад ")]
                                      )
                                    : _vm._e(),
                                  _vm.tabIndex < 2
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: { variant: "custom-green" },
                                          on: {
                                            click: function ($event) {
                                              _vm.tabIndex++
                                              _vm.$scrollToTop()
                                            },
                                          },
                                        },
                                        [_vm._v("Далее ")]
                                      )
                                    : _vm._e(),
                                  _vm.tabIndex === 2
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            disabled:
                                              !_vm.$store.getters.getCanSign,
                                            variant: "custom-green",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.prepareForm()
                                            },
                                          },
                                        },
                                        [_vm._v("Подписать и сохранить ")]
                                      )
                                    : _vm._e(),
                                  _vm.tabIndex === 2 &&
                                  !_vm.$store.getters.getCanSign
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "text-danger pt-1 pl-3",
                                        },
                                        [
                                          _vm._v(
                                            "Подписание возможно только при авторизации при помощи ЭЦП"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                title: "Подписать заявку",
                size: "lg",
                scrollable: "",
                centered: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { close: _vm.cancelSignModal },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3 px-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Заказчик"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Наименование заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$store.state.organization.full_name
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("ИНН Заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.organization.inn)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("КПП Заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.organization.kpp)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Адрес")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$store.state.organization.post_address
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("ФИО")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentUser.name) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Адрес электронной почты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentUser.email) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Номер контактного телефона")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentUser.phone) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Дополнительная информация")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.additional_contact_info
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Закупка"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Наименование закупочной сессии")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.purchase.tru_name) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Тип закупки")]
                              ),
                              _vm.purchase.order_type
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.purchase.order_type.name) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Вид оплаты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.payment_type) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Условия оплаты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.payment_term) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    "Время продолжительности закупочной сессии (в часах) "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [_vm._v(" 48 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    "Планируемая дата заключения договора "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateFormat(
                                          _vm.purchase.planned_contract_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          !!_vm.purchase.planned_end_date
                            ? _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _vm._v(
                                        "Планируемая дата исполнения договора "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              _vm.purchase.planned_end_date
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.additional_info) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Начальная максимальная цена контракта (руб.) "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatPrice(
                                          _vm.purchase.start_price
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Требования к поставщикам"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Отсутствие в реестре недобросовестных поставщиков "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.disallow_unfair_suppliers
                                          ? "ДА"
                                          : "НЕТ"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Только для СМП ")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.only_smp ? "ДА" : "НЕТ"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Условия поставки"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Адреса для доставки ")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                _vm._l(
                                  _vm.purchase.addresses,
                                  function (addr, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(" - "),
                                      _c("strong", [
                                        _vm._v(_vm._s(addr.address)),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " График поставки товаров (выполнения работ, оказания услуг) "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.purchase.order_plan) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Маскимальный срок поставки товаров (выполнения работ, оказания услуг) "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateFormat(_vm.purchase.max_date)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Спецификации ")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                _vm._l(
                                  _vm.purchase.items,
                                  function (sp, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(" - "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(sp.name) +
                                            " " +
                                            _vm._s(sp.count) +
                                            " " +
                                            _vm._s(sp.okei.local_symbol)
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Документы ")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.purchase.documents,
                                      function (file, index) {
                                        return _c(
                                          "li",
                                          { key: index },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "fs-14",
                                                staticStyle: {
                                                  "padding-top": "0",
                                                  "padding-bottom": "0",
                                                  color:
                                                    "var(--green-color) !important",
                                                },
                                                attrs: {
                                                  variant: "link",
                                                  size: "xs",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$commonDocumentDownload(
                                                      file
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(file.name))]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Xml представление")]
                            ),
                          ]),
                          _c("b-form-textarea", {
                            attrs: { disabled: "", rows: "20" },
                            model: {
                              value: _vm.formXml,
                              callback: function ($$v) {
                                _vm.formXml = $$v
                              },
                              expression: "formXml",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-5 px-3" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "3" },
                        },
                        [_vm._v(" Сертификат ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c("b-input", {
                            attrs: {
                              disabled: "",
                              value: _vm.currentCertSubject,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 px-3",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2 text-uppercase",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isSigningXml,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          !_vm.isSignedXml
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    variant: "custom-green",
                                    disabled: _vm.certIndex == null,
                                  },
                                  on: { click: _vm.signForm },
                                },
                                [_vm._v("Подписать ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _vm.isSignedXml
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    variant: "custom-green",
                                    disabled: _vm.certIndex == null,
                                  },
                                  on: { click: _vm.sendData },
                                },
                                [_vm._v("Отправить ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }