var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                { staticClass: "purchase_part-header" },
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_number" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "white-system_link-style fw-600",
                            attrs: {
                              to: {
                                name: "ImportSubstitutionRequestShow",
                                params: {
                                  id: _vm.importSubstitutionRequest.id,
                                },
                              },
                              exact: "",
                            },
                          },
                          [
                            _vm._v(
                              " Запрос импортозамещения № " +
                                _vm._s(
                                  _vm.importSubstitutionRequest.reg_number
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [
                        _vm._v("Прием предложений открыт "),
                        _c("img", {
                          staticClass: "ml-2",
                          attrs: {
                            alt: "secundomer",
                            src: "/images/secundomer.svg",
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-subheader" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { lg: "3", sm: "6", xs: "12" },
                    },
                    [
                      _vm.importSubstitutionRequest.replies_count
                        ? _c("span", { staticClass: "fs-11 green-color" }, [
                            _vm._v(" Предложений: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.importSubstitutionRequest.replies_count
                                )
                              ),
                            ]),
                          ])
                        : _c("span", { staticClass: "fs-11 green-color" }, [
                            _vm._v("Предложений нет"),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("До окончания подачи предложений:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-18 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              !!_vm.importSubstitutionRequest.deadline
                                ? _c("timer-new", {
                                    attrs: {
                                      data: _vm.importSubstitutionRequest
                                        .deadline,
                                    },
                                    on: {
                                      timeout: function ($event) {
                                        _vm.overTime = true
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2" },
                          [
                            !_vm.overTime &&
                            _vm.$store.getters.isLoggedIn &&
                            _vm.$store.getters.isProvider &&
                            _vm.importSubstitutionRequest.status_id === 1 &&
                            !_vm.tablet
                              ? _c(
                                  "b-btn",
                                  {
                                    staticClass: "text-uppercase",
                                    staticStyle: {
                                      "font-size": "12px !important",
                                    },
                                    attrs: {
                                      disabled: _vm.overTime,
                                      to: {
                                        name: "ImportSubstitutionReplyCreate",
                                        params: {
                                          id: _vm.importSubstitutionRequest.id,
                                        },
                                      },
                                      variant: "custom-green-small",
                                    },
                                  },
                                  [_vm._v(" Подать предложение ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет запроса:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.importSubstitutionRequest
                                    .purchase_category_name
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _vm.importSubstitutionRequest.organization.id
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                                _vm._v("Заказчик:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 green-color fw-600" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "green-link",
                                      attrs: {
                                        to: {
                                          name: "ProviderShow",
                                          params: {
                                            id: _vm.importSubstitutionRequest
                                              .organization.id,
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.importSubstitutionRequest
                                              .organization.name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col hidden-mobile",
                      attrs: { md: "4" },
                    },
                    [_c("div", [_c("div", { staticClass: "my-2" })])]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }