<template>
    <b-col md="6" class="my-3">
        <div class="purchase_part">
            <div class="atmo-override-color--customer purchase_part-header">
                <p class="purchase_part-header_number text-uppercase">Заказчик</p>
                <div class="d-flex align-items-center">
                    <div v-if="currentUser && client && currentUser.organization && currentUser.organization.is_provider" class="mb-0 mx-2 text-right" style="line-height: 1">
                        <div class="mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12" v-on:click="removeFavorite(client)" v-if="client.is_favorite === true">
                            <img class="mr-1" src="/images/full_star.svg" alt="full_star" />
                            <span class="max-content"
                                >ИЗ <br class="visible-768" />
                                ИЗБРАННОГО</span
                            >
                        </div>
                        <div class="mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12 mx-2" v-else v-on:click="addFavorite(client)">
                            <img class="mr-1" src="/images/empty_star.svg" alt="empty_star" />
                            <span>В ИЗБРАННОЕ</span>
                        </div>
                    </div>
                    <p class="d-flex align-items-center purchase_part-header_label mx-2" v-if="client.is_registered">
                        <img class="mr-1" src="/images/check.svg" alt="check_label" />
                        Зарегистрирован
                    </p>
                </div>
            </div>
            <b-row class="purchase_part-body">
                <div class="px-3 pt-3 w-100">
                    <p class="green-color fw-600 fs-12 text-uppercase">
                        {{ client.full_name || client.short_name }}
                    </p>
                </div>
                <div class="px-3 w-100 mb-4">
                    <p class="m-0 fs-12 grey-color">Адрес:</p>
                    <p class="m-0 fs-12 grey-color fw-600">{{ client.post_address }}</p>
                </div>
                <b-col class="purchase_part-body_col">
                    <div class="w-100 mb-4" v-if="client.okopf_name">
                        <p class="m-0 fs-12 grey-color">Уровень заказчика:</p>
                        <p class="m-0 fs-12 grey-color fw-600">{{ client.okopf_name }}</p>
                    </div>
                    <div class="w-100 mb-4" v-if="client.contact_name">
                        <p class="m-0 fs-12 grey-color">Контактное лицо:</p>
                        <p class="m-0 fs-12 grey-color fw-600">{{ client.contact_name }}</p>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col">
                    <div class="mb-3" v-if="client.ogrn">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            ОГРН: <span class="fw-600 grey-color"> {{ client.ogrn }} </span>
                        </p>
                    </div>
                    <div class="mb-3" v-if="client.inn">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            ИНН: <span class="fw-600 grey-color"> {{ client.inn }} </span>
                        </p>
                    </div>
                    <div class="mb-3" v-if="client.kpp">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            КПП: <span class="fw-600 grey-color"> {{ client.kpp }} </span>
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" class="mt-2">
                    <b-btn :to="{ name: 'CustomerShow', params: { id: client.id } }" variant="custom-green" size="sm" class="text-uppercase custom-green-btn" target="_blank">Сведения</b-btn>
                </b-col>
            </b-row>
        </div>
    </b-col>
</template>

<script>
import OrganizationsApi from '@/services/api/Organizations';
export default {
    name: 'CustomerCard',
    props: ['client'],
    methods: {
        addFavorite(client) {
            OrganizationsApi.addFavorite(client)
                .then(() => {
                    this.client.is_favorite = true;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        removeFavorite(client) {
            OrganizationsApi.removeFavorite(client)
                .then(() => {
                    this.client.is_favorite = false;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.user;
        }
    }
};
</script>

<style scoped>
.max-content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.visible-768 {
    display: none;
}
.purchase_part {
    box-shadow: 0px 2px 4px #0000000a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 8px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 40px;
}
.purchase_part-body {
    padding: 12px 24px;
}
.purchase_part-header_number {
    font-size: 12px;
    color: #fff;
    margin: 0;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.purchase_part-body_col + .purchase_part-body_col::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
.purchase_part-body span {
    text-transform: none;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .purchase_part-body_col::before {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .visible-768 {
        display: block;
    }
}
@media screen and (max-width: 568px) {
    .purchase_part-header {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .purchase_part-header_number {
        margin: 15px 0;
    }
}
</style>
