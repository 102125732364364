var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-5", attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              ref: "filter",
              staticClass: "w-100",
              attrs: { autocomplete: "off" },
              on: {
                focusin: function ($event) {
                  _vm.alert = false
                },
              },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile",
                                  },
                                  [_vm._v("Запросы КП")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "filter" },
                            [
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Наименование ТРУ",
                                  placeholder: "Укажите наименование ТРУ",
                                  md: "6",
                                  lg: "6",
                                },
                                model: {
                                  value: _vm.filter.conditionname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "conditionname", $$v)
                                  },
                                  expression: "filter.conditionname",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Заказчик",
                                  placeholder:
                                    "Укажите наименование/ИНН заказчика",
                                },
                                model: {
                                  value: _vm.filter.customer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "customer", $$v)
                                  },
                                  expression: "filter.customer",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Рег.номер запроса",
                                  placeholder: "Укажите рег.номер запроса",
                                },
                                model: {
                                  value: _vm.filter.regNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "regNumber", $$v)
                                  },
                                  expression: "filter.regNumber",
                                },
                              }),
                              _vm.showFilters
                                ? [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c("date-time-picker", {
                                          staticClass: "filter_datepicker",
                                          attrs: {
                                            id: "filter_from_purchase_start",
                                            label: "Укажите дату проведения с",
                                            "only-date": true,
                                            formatted: "DD.MM.YYYY",
                                            format: "YYYY-MM-DD",
                                            buttonNowTranslation: "Сегодня",
                                            "no-label":
                                              !!_vm.filter.orderDateStart,
                                            autoClose: "",
                                          },
                                          model: {
                                            value: _vm.filter.orderDateStart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "orderDateStart",
                                                $$v
                                              )
                                            },
                                            expression: "filter.orderDateStart",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-10 halfWhite-color",
                                          },
                                          [_vm._v("Дата проведения с...")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-3",
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c("date-time-picker", {
                                          staticClass: "filter_datepicker",
                                          attrs: {
                                            id: "filter_to_purchase_start",
                                            label: "Укажите дату проведения по",
                                            "only-date": true,
                                            formatted: "DD.MM.YYYY",
                                            format: "YYYY-MM-DD",
                                            buttonNowTranslation: "Сегодня",
                                            "no-label":
                                              !!_vm.filter.orderDateFinish,
                                            autoClose: "",
                                          },
                                          model: {
                                            value: _vm.filter.orderDateFinish,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "orderDateFinish",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filter.orderDateFinish",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-10 halfWhite-color",
                                          },
                                          [_vm._v("Дата проведения по...")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-row",
                            { staticStyle: { "margin-top": "27px" } },
                            [
                              _c(
                                "b-col",
                                { staticClass: "flex_col-mobile" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: { variant: "custom-white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getQuotationRequests(false)
                                        },
                                      },
                                    },
                                    [_vm._v("Найти ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.showOtherFilter },
                                    },
                                    [_vm._v("Все фильтры ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.clearFilter },
                                    },
                                    [_vm._v(" Сбросить ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.copyUrlWithFilters },
                                    },
                                    [_vm._v("Скопировать ссылку на фильтр")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    !_vm.mobileMenu
                      ? _c(
                          "ul",
                          { staticClass: "category_menu" },
                          _vm._l(
                            _vm.quotationRequestCategories,
                            function (category, cIndex) {
                              return _c(
                                "li",
                                { key: "category_" + cIndex },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "atmo-override-color",
                                      attrs: {
                                        to:
                                          "/quotation-requests/" +
                                          category.path,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(category.title) + " ")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.mobileMenu
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "purchase_category",
                                    expression: "'purchase_category'",
                                  },
                                ],
                                staticClass:
                                  "fs-14 fw-600 my-3 green-color d-flex justify-content-between",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.activeTab[0].title) + " "
                                ),
                                _c("i", {
                                  staticClass:
                                    "fa fs-14 fw-600 green-color d-flex align-items-center",
                                  class: {
                                    "fa-chevron-down": !_vm.mobileMenu_show,
                                    "fa-chevron-up": _vm.mobileMenu_show,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                attrs: { id: "purchase_category" },
                                model: {
                                  value: _vm.mobileMenu_show,
                                  callback: function ($$v) {
                                    _vm.mobileMenu_show = $$v
                                  },
                                  expression: "mobileMenu_show",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "mobile_category_menu" },
                                  _vm._l(
                                    _vm.quotationRequestCategories,
                                    function (category, cIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: "category_" + cIndex,
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeCategory(
                                                category.path
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(category.title) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "greyBg",
          staticStyle: { "border-top": "2px solid lightgray" },
          attrs: { fluid: "", id: "content-block" },
        },
        [
          _vm.quotationRequests.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getQuotationRequests()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._l(_vm.quotationRequests, function (quotationRequest) {
            return _c(
              "b-container",
              { key: quotationRequest.id },
              [
                _c(
                  "b-row",
                  [
                    quotationRequest.status_id === 1
                      ? _c("quotation-request-card-open", {
                          attrs: { quotationRequest: quotationRequest },
                          on: { reloadData: _vm.getQuotationRequests },
                        })
                      : _vm._e(),
                    quotationRequest.status_id === 2
                      ? _c("quotation-request-card-closed", {
                          attrs: { quotationRequest: quotationRequest },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.loading && !_vm.quotationRequests.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _vm.quotationRequests.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getQuotationRequests()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c("input", {
        staticClass: "hidden",
        staticStyle: { display: "none" },
        attrs: { type: "text", value: "", id: "myInput" },
      }),
      _c(
        "router-link",
        {
          staticClass: "copy_link hidden",
          staticStyle: { display: "none" },
          attrs: {
            id: "copy_link",
            to: {
              name: "QuotationRequestsList",
              params: { order: _vm.current_route },
              query: _vm.buildQueries(),
            },
            exact: "",
          },
        },
        [_vm._v(" Информация о закупке ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }