var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("deliverables-editable-list-controller", {
    attrs: {
      initiallist: _vm.$data.deliverables,
      max_price: _vm.$props.max_price,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (dsc) {
          return [
            _c("DeliverablesEditableList.view", {
              attrs: {
                controller: dsc,
                deliverables: dsc.list,
                islisteditable: _vm.$props.islisteditable,
                isquantityeditable: _vm.$props.isquantityeditable,
                max_price: _vm.$props.max_price,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "info",
                    fn: function () {
                      return [_vm._t("info")]
                    },
                    proxy: true,
                  },
                  {
                    key: "errors",
                    fn: function () {
                      return [
                        dsc.islistempty
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v("Заполните спецификацию"),
                            ])
                          : _vm._e(),
                        !dsc.islistempty &&
                        dsc.errors.is_kbk_code_empty &&
                        !_vm.$props.iskbklimitoptional
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v(
                                'У одной или нескольких позиций спецификации не заполнено поле "КБК".'
                              ),
                            ])
                          : _vm._e(),
                        !dsc.islistempty && dsc.errors.is_quantity_empty
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v(
                                'У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".'
                              ),
                            ])
                          : _vm._e(),
                        !dsc.islistempty && dsc.errors.is_price_per_unit_empty
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v(
                                'У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".'
                              ),
                            ])
                          : _vm._e(),
                        !dsc.islistempty &&
                        dsc.errors.is_specification_total_price_invalid
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v(
                                "Общая стоимость спецификации превышает " +
                                  _vm._s(
                                    _vm.$formatPrice(_vm.$props.max_price)
                                  ) +
                                  " руб."
                              ),
                            ])
                          : _vm._e(),
                        !dsc.islistempty &&
                        dsc.errors.has_kbk_limit_violations &&
                        !_vm.$props.iskbklimitoptional
                          ? _c("p", { staticClass: "atmo-error" }, [
                              _vm._v(
                                "Сумма стоимостей по одной или нескольким позициям спецификации превышает доступный лимит по КБК."
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }