var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4",
            },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color m-0",
                  staticStyle: { "line-height": "1" },
                },
                [_vm._v("Адресная книга")]
              ),
              _c(
                "b-btn",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-1",
                      modifiers: { "modal-1": true },
                    },
                  ],
                  attrs: { variant: "custom-unstyle-grey" },
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { src: "/images/plus.svg", alt: "ring" },
                  }),
                  _vm._v(" Добавить адрес "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.addresses.length && !_vm.loading
            ? _c(
                "b-col",
                { staticClass: "text-center mt-5", attrs: { cols: "12" } },
                [
                  _c("img", {
                    attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
                  }),
                  _c("p", { staticClass: "fs-14 grey-color my-4" }, [
                    _vm._v("В данный момент адресная книга пуста"),
                  ]),
                ]
              )
            : _vm._l(_vm.addresses, function (item, index) {
                return _c(
                  "b-col",
                  {
                    key: item.id,
                    staticClass: "mb-3",
                    attrs: { cols: "12", sm: "6", xl: "4" },
                  },
                  [
                    _c("div", { staticClass: "purchase_part h-100" }, [
                      _c(
                        "div",
                        {
                          staticClass: "purchase_part-header",
                          class: { main: index === 0 },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "purchase_part-header_number text-white fw-600",
                            },
                            [_vm._v(" " + _vm._s(item.short_address) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "purchase_part-body row" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "purchase_part-body_col" },
                            [
                              _c("div", [
                                _c("div", { staticClass: "row-mobile" }, [
                                  _c(
                                    "p",
                                    { staticClass: "fs-12 m-0 grey-color" },
                                    [_vm._v("Адрес:")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "fs-12 m-0 grey-color fw-600 mb-3 time_to_end",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.full_address) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "fs-12 m-0 grey-color mt-auto",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "fs-10 text-uppercase",
                                          staticStyle: {
                                            color:
                                              "var(--green-color) !important",
                                          },
                                          attrs: { variant: "unstyle" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteAddress(
                                                item.id,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Удалить ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
        ],
        2
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-modal",
                {
                  ref: "modal-1",
                  attrs: {
                    id: "modal-1",
                    title: "Добавить адрес",
                    "hide-footer": "",
                    centered: "",
                  },
                  on: { hidden: _vm.cleanForm },
                },
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { cols: "3" },
                            },
                            [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v("Короткая запись: "),
                                _c("span", { staticClass: "red-star" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-1",
                                  type: "text",
                                  required: "",
                                  placeholder: "Сокращенное название адреса",
                                },
                                model: {
                                  value: _vm.form.short_address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "short_address", $$v)
                                  },
                                  expression: "form.short_address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { cols: "3" },
                            },
                            [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v("Полный адрес из ФИАС: "),
                                _c("span", { staticClass: "red-star" }, [
                                  _vm._v("*"),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("VueSuggestions", {
                                staticClass: "form-control",
                                attrs: {
                                  model: _vm.city,
                                  coordinates: _vm.coordinates,
                                  placeholder: "Начните вводить",
                                  options: _vm.suggestionOptions,
                                },
                                on: {
                                  "update:model": function ($event) {
                                    _vm.city = $event
                                  },
                                  "update:coordinates": function ($event) {
                                    _vm.coordinates = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "3" } }, [_c("p")]),
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "9" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    type: "submit",
                                    variant: "custom-green",
                                  },
                                },
                                [_vm._v("Добавить")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }