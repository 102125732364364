<template>
    <div v-if="visible">
        <b-btn class="btn-card-action btn-card-action-green my-1" @click="copyPurchase">Копировать</b-btn>
    </div>
</template>

<script>
import { __PURCHASE_STATUS } from '@/const';

export default {
    name: 'CopyPurchaseButton',
    props: ['purchase'],
    methods: {
        copyPurchase() {
            this.$emit('click');
        }
    },
    computed: {
        visible() {
            return !this.purchase.is_external && this.purchase.status_id !== __PURCHASE_STATUS.status_draft && this.purchase.status_id !== __PURCHASE_STATUS.status_draft_save_signed && this.isCustomer() && this.isPurchaseCreator();
        }
    }
};
</script>
