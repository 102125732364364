<template>
    <b-container>
        <b-row>
            <b-col>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' } }">Извещения </router-link>
                </p>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' } }">Договоры </router-link>
                </p>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/quotation-requests'">Запросы КП </router-link>
                </p>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }">Импортозамещение</router-link>
                </p>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/clients'">ЗАКАЗЧИКИ И ПОСТАВЩИКИ </router-link>
                </p>
                <p class="mt-3 text-center" v-if="$links.marketplace_link">
                    <a :href="$links.marketplace_link" target="_blank" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14"> БАШМАРКЕТ </a>
                </p>
                <p class="mt-3 text-center">
                    <a :href="$links.info_materials" target="_blank" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <div class="d-flex flex-column align-items-center">
                    <b-btn variant="custom-danger" class="text-nowrap text-uppercase mb-2" v-if="!$store.state.token" @click="showModal">Регистрация</b-btn>
                    <router-link tag="button" exact class="text-uppercase text-nowrap btn btn-custom-green" v-if="!!$store.state.token" :to="{ name: 'Cabinet' }">Личный кабинет </router-link>
                    <router-link tag="button" class="text-uppercase text-nowrap btn btn-custom-green" v-if="!$store.state.token" to="/auth/login">Вход в ЛК </router-link>
                    <router-link tag="button" class="text-uppercase text-nowrap btn btn-custom-outline-secondary mt-2" v-if="!!$store.state.token" to="/auth/logout"> Выйти </router-link>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { __LINKS } from '@/const';

export default {
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        }
    },
    computed: {
        links() {
            return __LINKS;
        }
    }
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}
.router-link-active {
    color: var(--green-color) !important;
}
</style>
