<template>
    <div v-if="!loader">
        <b-container class="main_layer py-3" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-36 fw-600 white-color my-2 if-tablet">Внесистемная закупка № {{ purchase.reg_number }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container v-if="purchase" class="my-2" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row v-model="purchase.customer.name" label="Название" />
                <text-row v-model="purchase.customer.inn" label="ИНН" />
                <text-row v-if="purchase.customer.kpp" v-model="purchase.customer.kpp" label="КПП" />
                <text-row v-if="purchase.customer.ogrn" v-model="purchase.customer.ogrn" label="ОГРН" />
                <text-row v-if="purchase.customer.address" v-model="purchase.customer.address" label="Почтовый адрес" />

                <text-header>Информация о закупке</text-header>
                <text-row v-model="purchase.purchase_method" label="Способ закупки" />
                <text-row v-model="purchase.purchase_name" label="Предмет закупки" />
                <text-row v-if="purchase.national_project_name" v-model="purchase.national_project_name" label="Национальный проект" />
                <text-row v-model="purchase.order_type_name" label="Тип закупки" />
                <b-form-group v-if="purchase.failed_purchase_id" label="Номер несостоявшейся закупки:" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
                    <p class="fs-14 my-auto col-form-label">
                        <router-link v-if="purchase.failed_purchase_id" :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.failed_purchase_id } }" target="_blank">
                            {{ purchase.failed_purchase_reg_number }}
                        </router-link>
                    </p>
                </b-form-group>
                <text-row-price v-model="purchase.start_price" label="Общая стоимость" />
                <text-row-date v-model="purchase.planned_contract_date" label="Дата заключения договора" />
                <text-row-date v-model="purchase.planned_end_date" label="Дата исполнения договора" />

                <text-header>Спецификация</text-header>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Рег.номер ТРУ</th>
                                <th><abbr title="Характеристики ТРУ">Хар-ки ТРУ</abbr></th>
                                <th>Наименование товара (работ, услуг)</th>
                                <th>Код ОКПД2</th>
                                <th>Количество</th>
                                <th>Ед.изм</th>
                                <th>Цена за ед., руб.</th>
                                <th>Стоимость, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <fragment v-for="(item, index) in purchase.items" :key="item.id" v-bind="item">
                                <tr :class="visible[index] ? 'border-bottom-0' : ''">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <template v-if="!!item.ktru">
                                            <span :id="'spec-ktru-popover-' + (index + 1)">{{ item.ktru.reg_number }}</span>
                                            <b-popover :target="'spec-ktru-popover-' + (index + 1)" placement="top" triggers="hover">
                                                <template v-slot:title><b>Рег.номер ТРУ:</b> {{ item.ktru.reg_number }}</template>
                                                <b>Группа ТРУ:</b> {{ item.ktru.ktru_group_name }}<br />
                                                <b>Наименование:</b> {{ item.ktru.name }}<br />
                                                <b>Тип:</b> {{ item.ktru.type }}<br />
                                            </b-popover>
                                        </template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                    <td>
                                        <template v-if="item.ktru">
                                            <button v-b-toggle="'collapse-' + index" class="atmo-button-icon">
                                                <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                                <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                            </button>
                                            <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        </template>
                                    </td>

                                    <td>{{ item.name }}</td>
                                    <td>{{ item.okpd ? item.okpd.code : '&mdash;' }}</td>
                                    <td style="text-align: right">{{ item.count }}</td>
                                    <td style="text-align: right">
                                        <template v-if="item.okei">{{ item.okei.local_symbol }}</template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                    <td style="text-align: right">{{ $formatPrice(item.price) }}</td>
                                    <td style="text-align: right">{{ $formatPrice(item.count * item.price) }}</td>
                                </tr>
                                <tr v-show="visible[index]">
                                    <td class="align-text-top" colspan="9">
                                        <template v-if="item.ktru">
                                            <p v-if="item.ktru.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                            <p v-if="item.ktru.specs && item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru.specs && item.ktru.specs.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.Name }}</b> - {{ spec.Value }}
                                                </li>
                                            </ul>
                                        </template>
                                        <template v-else>&mdash;</template>
                                    </td>
                                </tr>
                            </fragment>
                        </tbody>
                    </table>
                </div>

                <div v-if="this.hasLimits" class="my-2 atmo-page-content">
                    <KBKLimitsList.view v-bind:limits="$data.limits" />
                </div>

                <text-header id="supplier-info">Поставщик</text-header>
                <template v-if="!purchase.is_private_provider">
                    <text-row v-model="purchase.supplier.full_name" label="Название" />
                    <text-row v-model="purchase.supplier.inn" label="ИНН" />
                    <text-row v-if="purchase.supplier.kpp" v-model="purchase.supplier.kpp" label="КПП" />
                    <text-row v-if="purchase.supplier.ogrn" v-model="purchase.supplier.ogrn" label="ОГРН" />
                    <text-row v-model="purchase.supplier.address" label="Фактический адрес" />
                </template>
                <template v-else>
                    <text-row label="Название" value="Физическое лицо" />
                </template>

                <text-header id="contract">Информация о договоре</text-header>
                <text-row :value="purchase.status" label="Статус договора" />
                <text-row v-model="purchase.customer_contract_number" label="Номер договора в системе учета заказчика" />
                <text-row-raw v-if="purchase.main_file && (!purchase.is_private_provider || (purchase.is_private_provider && isPurchaseCreator()))" label="Договор">
                    <a :href="$getAttachmentDownloadLink(purchase.main_file.id)" class="fs-14 col-form-label d-inline-block" target="_blank">{{ purchase.main_file.name }}</a>
                </text-row-raw>

                <template v-if="purchase.supplier_accounting">
                    <text-header>Банковские реквизиты поставщика</text-header>
                    <text-row :value="getBankTypeTitle(purchase.supplier_accounting.bank_type)" label="Вид обслуживающей организации" />
                    <text-row v-model="purchase.supplier_accounting.bik" label="БИК банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_name" label="Название банка" />
                    <text-row v-model="purchase.supplier_accounting.bank_city" label="Город банка" />
                    <text-row v-model="purchase.supplier_accounting.ks" label="Кор. счет" />
                    <text-row v-model="purchase.supplier_accounting.rs" label="Расчетный счет" />
                    <text-row v-if="purchase.supplier_accounting.bank_type !== 'bank'" v-model="purchase.supplier_accounting.ls" label="Лицевой счет" />
                </template>

                <text-header id="contract-fulfillment">Исполнение договора</text-header>
                <div v-if="isPurchaseCreator()" class="atmo-content-group">
                    <h5>Изменить статус закупки</h5>

                    <form-row-change-contract-status v-model="purchaseStatus" :disabled="purchaseStatus === purchase.status_id" @change-status="changeStatus" />
                </div>
                <div v-if="!purchase.is_private_provider || (purchase.is_private_provider && isPurchaseCreator())" class="atmo-content-group">
                    <h5>Документы об исполнении</h5>
                    <table class="atmo-content-table atmo-editable-attachments">
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Наименование</th>
                                <th v-if="isPurchaseCreator()">&nbsp;</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(file, index) in executionDocuments" v-bind:key="file.id">
                                <td v-text="index + 1"></td>
                                <td>
                                    <a :href="$getAttachmentDownloadLink(file.id)" class="fs-14 col-form-label d-inline-block" target="_blank">{{ file.name }}</a>
                                </td>
                                <td v-if="isPurchaseCreator()">
                                    <button :disabled="isWaiting" class="atmo-button-icon" title="Удалить файл из списка" @click="handleDeleteFile(file, index)">
                                        <i class="atmo-icon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr v-if="purchase.files.length === 0">
                                <td class="atmo-centered" colspan="3">Не добавлено документов</td>
                            </tr>
                        </tbody>
                    </table>

                    <ul v-if="isPurchaseCreator()" class="atmo-buttons">
                        <li>
                            <label :disabled="isWaiting" class="atmo-button">
                                <span>Добавить</span>
                                <input id="fileload" ref="fileload" :disabled="isWaiting" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx" type="file" @change="add" />
                            </label>
                        </li>
                    </ul>
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import { __PURCHASE_STATUS } from '@/const';
import FormRowChangeContractStatus from '@/components/common/form-rows/form-row-change-contract-status.vue';
import KBKLimitsListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/view/KBKLimitsList.view.vue';
import Config from '@core/js/ddriven/application/config/Config';
import KBKLimitsSpecificationItemsEditableCollection from '@core/js/ddriven/domain/model/purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import TextRowRaw from '@/components/common/form-rows/text-row-raw.vue';

export default {
    name: 'ExternalPurchaseShow',
    components: {
        TextRowRaw,
        FormRowChangeContractStatus,
        'KBKLimitsList.view': KBKLimitsListView
    },
    metaInfo() {
        return {
            title: 'Закупка вне ИС ' + this.getRegNumber
        };
    },
    data() {
        return {
            bankTypes: [
                { id: 'bank', name: 'Банк' },
                { id: 'finOrg', name: 'Финансовый орган' },
                { id: 'fedKazn', name: 'Орган ФК' }
            ],
            order: this.$route.params.purchase,
            purchase: null,
            loader: true,
            PURCHASE_STATUS: {},
            isWaiting: false,
            executionDocuments: [],
            purchaseStatus: null,
            baseUrl: Config.get('api.config.legacy.url'),
            visible: [],
            limits: []
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        getBankTypeTitle(typeId) {
            return this.bankTypes.find((bankType) => bankType.id === typeId)?.name;
        },
        refresh() {
            axios('/external_purchases/' + this.$route.params.purchase)
                .then((res) => {
                    this.purchase = res.data.data;
                    this.limits = new KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(this.purchase.limits);
                    this.loader = false;
                    this.executionDocuments = this.purchase.files;
                    this.purchaseStatus = this.purchase.status_id;

                    this.$nextTick(() => {
                        let el;
                        if (this.$route.hash && (el = document.querySelector(this.$route.hash))) {
                            const y = el.getBoundingClientRect().top + window.pageYOffset - 255;
                            window.scrollTo({ top: y });
                        }
                    });
                })
                .catch((error) => {
                    if (error && error.response && error.response.status === 404) {
                        this.$router.push({ name: '404' }).catch(() => {});
                    }
                });
            this.PURCHASE_STATUS = __PURCHASE_STATUS;
        },
        async handleDeleteFile(file, index) {
            try {
                this.isWaiting = true;
                const response = await this.$api.externalPurchases.deleteExecutionDocument(this.purchase.id, file.id);
                if (typeof response === 'object') {
                    this.executionDocuments.splice(index, 1);
                }
            } finally {
                this.isWaiting = false;
            }
        },
        async add(evt) {
            try {
                this.isWaiting = true;

                this.inputEl = evt.target; // Save to use later in remove method;
                const file = evt.target.files[0];
                const response = await this.upload(file);
                this.executionDocuments.push(response);
            } finally {
                this.$refs.fileload.value = '';
                this.isWaiting = false;
            }
        },
        async upload(file) {
            const formData = new FormData();
            formData.append('file', file);
            return await this.$api.externalPurchases.storeExecutionDocument(this.purchase.id, formData);
        },
        async changeStatus(value) {
            await this.$api.externalPurchases.changeStatus(this.purchase.id, value);
            this.refresh();
        }
    },
    computed: {
        hasLimits() {
            return this.purchase && this.purchase.limits && this.purchase.limits.length > 0;
        },
        getRegNumber() {
            return this.purchase?.reg_number ?? '';
        }
    }
};
</script>

<style scoped>
a {
    color: var(--green-color) !important;
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    color: var(--grey-color);
    border-top: 0;
}
.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}

.purchase_info-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
}

.purchase_info-list li {
    width: 100%;
}

.purchase_info-list li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    font-size: 18px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    padding: 20px 0;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}

.router-link-active {
    border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
}
</style>
