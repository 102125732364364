<template>
    <div>
        <b-container v-if="showForm" class="register_page_header_block" fluid>
            <b-container>
                <div class="fs-52 fw-600 white-color pt-3">Регистрация поставщика</div>
                <b-tabs v-model="tabIndex" class="w-100 register_provider py-3" content-class="invisible">
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.organization.$invalid" number="01"> Регистрационные и контактные данные организации</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.user.$invalid" number="02"> Идентификационные данные пользователя</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.check_rules.$invalid" number="03"> Завершение создания заявления</registration-tab-header>
                    </b-tab>
                </b-tabs>
            </b-container>
        </b-container>
        <b-container v-if="showForm" fluid>
            <b-container>
                <b-tabs v-model="tabIndex" class="w-100" nav-class="invisible">
                    <b-tab>
                        <text-header>Данные организации</text-header>
                        <form-row-edit-text v-model="form.organization.full_name" disabled label="Полное наименование" />
                        <form-row-edit-text v-model="form.organization.short_name" disabled label="Сокращенное наименование" />
                        <form-row-edit-text v-model="form.organization.manager" disabled label="Руководитель" />
                        <form-row-edit-text v-model="form.organization.inn" disabled label="ИНН" />
                        <form-row-edit-text v-model="form.organization.kpp" disabled label="КПП" />
                        <form-row-edit-text v-model="form.organization.ogrn" disabled label="ОГРН / ОГРНИП" />
                        <form-row-edit-checkbox v-model="form.organization.is_isp" label="Непосредственный производитель товаров, прямой исполнитель работ, услуг" />

                        <text-header>Контактная информация</text-header>
                        <form-row-edit-dadata-address v-model="form.organization.post_address" :v="$v.form.organization.post_address" label="Адрес почтовый" />
                        <form-row-edit-dadata-address v-model="form.organization.fact_address" :v="$v.form.organization.fact_address" label="Адрес фактический" />
                        <form-row-edit-email
                            :email-confirmed="form.organization.email_confirmed"
                            :v="$v.form.organization.email"
                            :value="form.organization.email"
                            label="Адрес электронной почты"
                            with-validation
                            @value="form.organization.email = $event"
                            @email-confirmed="form.organization.email_confirmed = $event" />
                        <form-row-edit-phone :v="$v.form.organization.phone" :value="form.organization.phone" label="Телефон" @value="form.organization.phone = $event" />
                        <form-row-edit-text v-model="form.organization.site" label="Адрес сайта" />
                        <form-row-edit-text v-model="form.organization.contact_name" :v="$v.form.organization.contact_name" label="ФИО контактного лица" />
                    </b-tab>
                    <b-tab>
                        <text-header>Идентификационные данные пользователя</text-header>
                        <form-row-edit-text v-model="form.user.last_name" :v="$v.form.user.last_name" label="Фамилия" @change="onLastNameChange" />
                        <form-row-edit-text v-model="form.user.first_name" :v="$v.form.user.first_name" label="Имя" />
                        <form-row-edit-text v-model="form.user.middle_name" label="Отчество" />
                        <form-row-edit-text label="Логин" v-model="form.user.username" description="Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля" disabled />
                        <form-row-edit-text label="Пароль" v-model="form.user.password" @value="form.user.password = $event" :v="$v.form.user.password" type="password" />
                        <form-row-edit-text label="Подтверждение пароля" v-model="form.user.password_confirmation" @value="form.user.password_confirmation = $event" :v="$v.form.user.password_confirmation" type="password" />
                        <form-row-edit-email :email-confirmed="form.user.email_confirmed" :v="$v.form.user.email" :value="form.user.email" label="Адрес электронной почты" with-validation @value="form.user.email = $event" @email-confirmed="form.user.email_confirmed = $event" />
                        <form-row-edit-phone v-if="$isPhoneConfirmationNeeded" :phone-confirmed="form.user.phone_confirmed" :v="$v.form.user.phone" :value="form.user.phone" label="Телефон" with-validation @value="form.user.phone = $event" @phone-confirmed="form.user.phone_confirmed = $event" />
                        <form-row-edit-phone v-else :v="$v.form.user.phone" :value="form.user.phone" label="Телефон" @value="form.user.phone = $event" />
                        <form-row-single-select v-model="form.user.post" :options="directories.posts" :v="$v.form.user.post" label="Должность" label-field="name" placeholder="Выберите должность" track-by="name" />
                    </b-tab>
                    <b-tab>
                        <text-header>Завершение создания заявления</text-header>
                        <registration-confirmation-text-block />
                        <b-form-group :state="!$v.form.check_rules">
                            <b-form-checkbox v-model="form.check_rules" :state="!$v.form.check_rules">
                                <span class="grey-color fw-600">Подтверждаю перечисленные выше условия</span>
                            </b-form-checkbox>
                        </b-form-group>
                        <div class="my-4">
                            <p class="fs-20 text-danger font-weight-bold">
                                Для возможности участия в закупочных сессиях Вам необходимо
                                <a :href="links.add_money" style="text-decoration: underline; cursor: pointer" target="_blank">пополнить лицевой счет</a> после прохождения процедуры регистрации.
                            </p>
                        </div>
                    </b-tab>
                </b-tabs>
                <div class="my-5">
                    <b-button v-if="tabIndex > 0" class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="previousTab">Назад</b-button>
                    <b-button v-if="tabIndex < 2" class="mx-2 text-uppercase" variant="custom-green" @click="nextTab">Далее</b-button>
                    <b-button v-if="tabIndex === 2" :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-green" @click="makeXml">Готово</b-button>
                    <span v-if="tabIndex === 2 && $v.form.$invalid" class="text-danger mx-2">Заполните все обязательные поля на каждой вкладке.</span>
                </div>
            </b-container>
        </b-container>
        <b-modal ref="select-organization" v-model="showOrgModal" centered no-close-on-backdrop no-close-on-esc title="Укажите ИНН организации">
            <div class="d-block text-left">
                <b-input-group class="mt-3" prepend="ИНН">
                    <b-form-input v-model="inn" trim></b-form-input>
                </b-input-group>
                <b-input-group v-if="foundOrganizationBranches || kpp" class="mt-3" prepend="КПП">
                    <b-form-input v-model="kpp" trim></b-form-input>
                </b-input-group>
                <b-row>
                    <b-col class="mt-3" md="12">
                        <b-button :disabled="isFind" class="text-uppercase float-right" variant="custom-green" @click="findOrg">
                            <b-spinner v-if="isFind" small></b-spinner>
                            Найти
                        </b-button>
                    </b-col>
                </b-row>
                <b-row v-if="!!tmpOrganization" class="py-3">
                    <b-col class="fs-16 grey-color"> Это ваша организация ?</b-col>
                    <b-col class="fs-16 grey-color fw-600">
                        {{ tmpOrganization.full_name }}
                    </b-col>
                </b-row>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelCertificate">Отмена</b-button>
                <b-button :disabled="tmpOrganization == null" class="text-uppercase" variant="custom-green" @click="showCreateForm">Подтвердить</b-button>
            </div>
        </b-modal>
        <b-modal ref="select-cert-modal" v-model="showModal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Выберите сертификат">
            <div class="d-block text-left">
                <multiselect
                    v-model="selectCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    label="text"
                    placeholder="Выберите сертификат"
                    track-by="value"
                    @input="onCertificateChange">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.text }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.valid_from) }} по {{ getDateFormat(props.option.valid_to) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.text }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.valid_from) }} по {{ getDateFormat(props.option.valid_to) }}
                    </template>
                </multiselect>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelCertificate">Отмена</b-button>
                <b-button :disabled="certIndex == null" class="text-uppercase" variant="custom-green" @click="checkCert"> Продолжить</b-button>
            </div>
        </b-modal>
        <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc scrollable size="lg" title="Подписать заявление" @close="cancelSignModal">
            <div class="d-block text-left">
                <div class="d-block text-left">
                    <b-tabs content-class="mt-3">
                        <b-tab active>
                            <template slot="title">
                                <span class="system_link-style fs-16">Форма</span>
                            </template>
                            <div class="px-3">
                                <text-header>Регистрационные данные организации</text-header>
                                <text-row v-model="form.organization.short_name" label="Сокращенное наименование" />
                                <text-row v-model="form.organization.manager" label="Руководитель" />
                                <text-row v-model="form.organization.inn" label="ИНН" />
                                <text-row v-if="form.organization.kpp" v-model="form.organization.kpp" label="КПП" />
                                <text-row v-model="form.organization.ogrn" label="ОГРН / ОГРНИП" />

                                <text-header>Контактные данные организации</text-header>
                                <text-row v-model="form.organization.post_address" label="Адрес почтовый" />
                                <text-row v-model="form.organization.fact_address" label="Адрес фактический" />
                                <text-row v-model="form.organization.email" label="Адрес электронной почты" />
                                <text-row-phone v-model="form.organization.phone" label="Телефон" />
                                <text-row v-model="form.organization.site" label="Адрес сайта" />
                                <text-row v-model="form.organization.fax" label="Факс" />
                                <text-row v-model="form.organization.contact_name" label="ФИО контактного лица" />

                                <text-header>Контактные данные организации</text-header>

                                <text-row v-model="form.user.last_name" label="Фамилия" />
                                <text-row v-model="form.user.first_name" label="Имя" />
                                <text-row v-model="form.user.middle_name" label="Отчество" />
                                <text-row v-model="form.user.email" label="Адрес электронной почты" />
                                <text-row-phone v-model="form.user.phone" label="Телефон" />
                                <text-row v-model="form.user.post" label="Должность" />
                            </div>
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                <span class="system_link-style fs-16">Xml версия</span>
                            </template>
                            <pre class="fs-10 grey-color p-3 greyBg m-0">{{ signedFormXml }}</pre>
                        </b-tab>
                    </b-tabs>
                    <div class="p-3 d-flex align-items-center">
                        <div class="w-50 fs-14 grey-color">Сертификат</div>
                        <div class="w-50 fs-14 fw-600 grey-color">
                            <b-input :value="currentCertSubject" disabled></b-input>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button v-if="!isSignedXml" :disabled="certIndex == null" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button v-if="isSignedXml" :disabled="certIndex == null" class="text-uppercase" variant="custom-green" @click="register">Зарегистрироваться</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Auth from '@/services/api/Auth';
import CadesMixins from '@/mixins/CadesMixins';
import { __LINKS } from '@/const';
import TextHeader from '@/components/common/form-rows/text-header';
import FormRowEditDadataAddress from '@/components/common/form-rows/form-row-edit-dadata-address';
import FormRowEditPhone from '@/components/common/form-rows/form-row-edit-phone';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPhone from '@/components/common/form-rows/text-row-phone';
import RegistrationTabHeader from '@/components/auth/parts/registration-tab-header';
import { email, helpers, minLength, required, sameAs } from 'vuelidate/lib/validators';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import RegistrationConfirmationTextBlock from '@/components/auth/parts/registration-confirmation-text-block';
import FormRowEditCheckbox from '@/components/common/form-rows/form-row-edit-checkbox';
import store from '@/store';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const allowable = helpers.regex('allowable', /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/);
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'RegisterProvider',
    mixins: [CadesMixins],
    components: {
        FormRowEditCheckbox,
        RegistrationConfirmationTextBlock,
        FormRowEditEmail,
        RegistrationTabHeader,
        TextRowPhone,
        TextRow,
        FormRowEditPhone,
        FormRowEditDadataAddress,
        TextHeader
    },
    data() {
        return {
            selectCertificate: null,
            sucessConfirm: false,
            tabIndex: 0,
            certificates: [{ value: null, text: 'Не выбран' }], // сертификаты для селекта
            certIndex: null, // индекс выбранного сертификата
            showModal: false, // modal выбора сертификатов
            showSignModal: false, // modal подписи формы
            showOrgModal: false, // modal для получения информации об организации по ИНН
            showForm: false, // отображение регистрационной формы
            formXml: '', // заготовка под xml для подписи
            signedFormXml: '', // заготовка под xml для подписи
            isSignedXml: false, // подписана ли форма
            isSigningXml: false,
            isDataSending: false,
            currentCertSubject: '', // наименование сертификата для отображения в форме подписи
            inn: '', // ИНН организации для поиска
            kpp: '',
            foundOrganizationBranches: false,
            isFind: false, // найдена ли организация (для прелоадера)
            tmpOrganization: null, // временное хранилище для найденной организации
            form: {
                organization: {
                    full_name: '',
                    short_name: '',
                    start_date: '',
                    end_date: '',
                    inn: '',
                    kpp: '',
                    fns_reg_date: '',
                    ogrn: '',
                    post_address: '',
                    fact_address: '',
                    email: '',
                    email_confirmed: false,
                    phone: '',
                    site: '',
                    contact_name: '',
                    manager: '',
                    is_agent: false,
                    is_isp: false,
                    municipality_id: null,
                    budgetary_manager_id: null
                },
                user: {
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    username: '',
                    password: '',
                    password_confirmation: '',
                    email: '',
                    phone: '',
                    post: null,
                    signed_xml: null,
                    email_confirmed: false,
                    phone_confirmed: false
                },
                check_rules: false
            },
            directories: {
                posts: [{ name: 'Руководитель организации' }, { name: 'Администратор организации' }, { name: 'Лицо, уполномоченное на размещение информации и документов' }, { name: 'Лицо, имеющее право подписи документов от имени организации' }]
            }
        };
    },
    validations() {
        return {
            form: {
                organization: {
                    post_address: { required },
                    fact_address: { required },
                    email: { required, email },
                    email_confirmed: { checked: (value) => value === true },
                    phone: { required, phone },
                    contact_name: { required }
                },
                user: {
                    last_name: { required, minLength: minLength(2) },
                    first_name: { required, minLength: minLength(2) },
                    password: { required, minLength: minLength(8), allowable, containsSymbols },
                    password_confirmation: {
                        required,
                        confirmPassword: sameAs((form) => form.password)
                    },
                    email: { required, email },
                    email_confirmed: { checked: (value) => value === true },
                    phone: { required, phone },
                    phone_confirmed: { checked: (value) => value === true || !this.$isPhoneConfirmationNeeded },
                    post: { required }
                },
                check_rules: { checked: (value) => value === true }
            }
        };
    },
    async mounted() {
        if (this.hasCertificates() === false) {
            this.loadCertificates();
        } else {
            this.showModal = true;
        }
        this.$on('certificates_loaded', () => {
            this.showModal = true;
        });
    },
    methods: {
        async onLastNameChange() {
            this.form.user.username = await Auth.getUsername(this.form.user.last_name);
        },
        nextTab() {
            this.tabIndex++;
            this.$scrollToTop();
        },
        previousTab() {
            this.tabIndex--;
            this.$scrollToTop();
        },
        onCertificateChange(value) {
            if (value) {
                this.certIndex = value.value;
            }
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        // проверяем возможность использовать выбранный сертификат
        checkCert() {
            // получаем сертификаты из хранилища
            let parsedCerts = this.$store.state.parsedCertificates;
            let currentParsedCert = parsedCerts[this.certIndex];
            // получаем данные из выбранного сертификата
            let thumbprint = currentParsedCert.thumbprint;
            axios
                .post('/auth/check-certificate', {
                    thumbprint: thumbprint
                })
                .then(() => {
                    this.showModal = false;
                    this.showOrgModal = true;
                    this.currentCertSubject = currentParsedCert.subject;
                    this.$store.commit('set_current_certificate', {
                        index: this.certIndex,
                        thumbprint: thumbprint
                    });
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        // поиск организации по ИНН в дадата
        findOrg() {
            this.isFind = true;
            axios
                .post('/auth/load-organization', { inn: this.inn, kpp: this.kpp, is_provider: true })
                .then((response) => {
                    this.foundOrganizationBranches = false;
                    this.tmpOrganization = response.data;
                })
                .catch((error) => {
                    if (error.response.status === 406) this.foundOrganizationBranches = true;
                    this.tmpOrganization = null;
                    this.pushToast({
                        text: error.response.data.message ? error.response.data.message : 'Организация не найдена',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                })
                .finally(() => {
                    this.isFind = false;
                });
        },
        // после выбора сертификата показываем форму организации и заполняем данными с сервера от дадаты
        showCreateForm() {
            this.showForm = true;
            this.showOrgModal = false;
            this.form.organization = { ...this.form.organization, ...this.tmpOrganization };
            this.form.organization.is_isp = false;
            delete this.form.organization.post;
        },
        // если нажата "Отмена" при выборе сертификата
        cancelCertificate() {
            this.$refs['select-cert-modal'].hide();
            this.showForm = false;
            this.showOrgModal = false;
            this.$bvToast.toast('Работа на сайте возможна только при наличии ЭЦП', {
                title: 'Ошибка',
                variant: 'danger'
            });
            this.$router.push('/auth/error').catch(() => {});
        },
        // формируем xml из json формы и показываем modal с данными для подписания
        makeXml() {
            let data = this.form;
            this.formXml = this.json2xml(data);
            this.formXml = '<?xml version="1.0" encoding="UTF-8"?>\n<application>' + this.formXml + '\n</application>';
            this.signedFormXml = this.formXml;
            this.showSignModal = true;
        },
        // подписание формы выбранным сертификатом
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await this.SignXMLForm(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        // регистрация поставщика
        register() {
            // если форма подписана
            if (this.isSignedXml) {
                this.isDataSending = true;
                // регистрируем пользователя, создаем организацию и сохраняем в БД сертификат пользователя
                axios
                    .post('/auth/register-provider', {
                        user: this.form.user,
                        organization: this.form.organization,
                        thumbprint: store.state.currentThumbprint,
                        certificate: store.getters.currentParsedCertificate,
                        xml: this.signedFormXml
                    })
                    .then((response) => {
                        // логиним пользователя и редиректим на главную
                        this.showSignModal = false;
                        this.$store
                            .dispatch('login', {
                                token: response.data.access_token,
                                user: response.data.user,
                                organization: response.data.organization
                            })
                            .then(() => {
                                window.location.assign('/cabinet');
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$bvToast.toast(error, {
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    })
                    .finally(() => {
                        this.isDataSending = false;
                    });
            }
        }
    },
    // computed для валидации формы
    computed: {
        links() {
            return __LINKS;
        }
    }
};
</script>

<style scoped>
.register_page_header_block {
    background-image: url('/images/providers_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style>
.register_provider ul.nav-tabs {
    width: 100%;
    background: transparent;
}

.register_provider ul.nav-tabs li {
    width: 33.33%;
}

.register_provider ul.nav-tabs li a {
    border: none;
}

.register_provider .nav-link.active {
    border-bottom: 2px solid #fff;
    background: transparent;
}

.register_provider .nav-link.active .head_register-list {
    color: #fff;
}

.invisible {
    display: none;
}
</style>
