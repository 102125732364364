import axios from 'axios';
import store from '@/store';

export default {
    async getUsername(last_name) {
        return await axios
            .post('/auth/get_username', { last_name })
            .then((response) => {
                return response.data.username;
            })
            .catch((error) => {
                store.dispatch('showError', error);
                return false;
            });
    }
};
