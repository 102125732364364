<template>
    <b-container>
        <b-row class="my-3 mt-5 mb-5">
            <b-col>
                <slot></slot>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'FormActionButtonsGroup'
};
</script>
