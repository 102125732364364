import axios from 'axios';

export default {
    cancelPurchasePublishing(purchase) {
        return axios.post('/cabinet/customer/purchases/' + purchase.id + '/cancelPublishing');
    },
    cancelHoldTime(purchase) {
        return axios.post('/cabinet/customer/purchases/' + purchase.id + '/cancelHoldTime');
    },
    prolong(purchase) {
        return axios.post('/cabinet/customer/purchases/' + purchase.id + '/prolong');
    },
    getContractsXlsExport(exportRequest) {
        return axios.post('/cabinet/customer/purchases/download/xls', exportRequest, { responseType: 'blob' });
    },
    getPurchasesList(filters) {
        return axios.get('/cabinet/customer/purchases', {
            params: filters
        });
    },
    getContractsList(filters) {
        return axios.get('/cabinet/customer/contracts', {
            params: filters
        });
    }
};
