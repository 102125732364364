var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#f5f5f7" } },
    [
      !!_vm.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between column-768",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-52 fw-600 white-color mb-4 main_text",
                                },
                                [_vm._v("Сведения о поставщике")]
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "d-flex info_block mb-4" }, [
                            _vm.user &&
                            _vm.organization &&
                            _vm.user.organization.is_provider === false
                              ? _c("p", { staticClass: "mb-0 mr-4" }, [
                                  _vm.organization.is_favorite === true
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer white-color fs-15 d-flex align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFavorite(
                                                _vm.organization
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              alt: "empty_star",
                                              src: "/images/full_star.svg",
                                            },
                                          }),
                                          _vm._v(" УБРАТЬ ИЗ ИЗБРАННОГО "),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer white-color fs-15 d-flex align-items-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addFavorite(
                                                _vm.organization
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              alt: "empty_star",
                                              src: "/images/empty_star.svg",
                                            },
                                          }),
                                          _vm._v(" В ИЗБРАННОЕ "),
                                        ]
                                      ),
                                ])
                              : _vm._e(),
                            _vm.organization.is_isp
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value: {
                                          variant: "custom-green",
                                          show: true,
                                        },
                                        expression:
                                          "{ variant: 'custom-green', show: true }",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "lable",
                                    attrs: {
                                      title:
                                        "Данный поставщик является прямым производителем товаров, исполнителем услуг или подрядчиком работ",
                                    },
                                  },
                                  [_vm._v("ИСП")]
                                )
                              : _vm._e(),
                            _vm.organization.is_smp
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value: { variant: "custom-green" },
                                        expression:
                                          "{ variant: 'custom-green' }",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "lable",
                                    attrs: {
                                      title:
                                        "Является субъектом малого предпринимательства",
                                    },
                                  },
                                  [_vm._v("СМП")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-14 white-color text-uppercase mb-4",
                            },
                            [_vm._v(_vm._s(_vm.organization.full_name))]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "mr-2 fs-14 white-color fw-100" },
                            [_vm._v("ОГРН")]
                          ),
                          _c(
                            "span",
                            { staticClass: "m-0 fs-14 white-color fw-600" },
                            [_vm._v(_vm._s(_vm.organization.ogrn))]
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "mr-2 fs-14 white-color fw-100" },
                            [_vm._v("ИНН")]
                          ),
                          _c(
                            "span",
                            { staticClass: "m-0 fs-14 white-color fw-600" },
                            [_vm._v(_vm._s(_vm.organization.inn))]
                          ),
                        ]),
                        _vm.organization.kpp
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2 fs-14 white-color fw-100",
                                },
                                [_vm._v("КПП")]
                              ),
                              _c(
                                "span",
                                { staticClass: "m-0 fs-14 white-color fw-600" },
                                [_vm._v(_vm._s(_vm.organization.kpp))]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        [
          !!_vm.organization
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-5" },
                    [
                      _c("text-header", [_vm._v("Сведения об организации")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.full_name,
                          label: "Полное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.short_name,
                          label: "Сокращенное наименование",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.post_address,
                          label: "Почтовый адрес",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.fact_address,
                          label: "Фактический адрес",
                        },
                      }),
                      _c("text-header", [_vm._v("Контактная информация")]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.organization.email,
                          label: "Адрес электронной почты",
                        },
                      }),
                      _c("text-row-phone", {
                        attrs: {
                          value: _vm.organization.phone,
                          label: "Телефон",
                        },
                      }),
                      _c("text-header", [_vm._v("Статистика")]),
                      _c(
                        "b-row",
                        { staticClass: "py-3 statistic_block" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "px-4 py-3", attrs: { md: "4" } },
                            [
                              _vm.organization.rating &&
                              _vm.organization.rating !== null
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "h-100 d-flex flex-column justify-content-between",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-uppercase grey-color fs-14 mb-4",
                                        },
                                        [_vm._v("РЕЙТИНГ ПОСТАВЩИКА")]
                                      ),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fs-32 fw-600",
                                            style: `color: ${_vm.ratingColor()}`,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$formatPrice(
                                                  _vm.organization.rating
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "fs-14 grey-color text-lowercase ml-2",
                                          },
                                          [_vm._v(_vm._s(_vm.ratingText()))]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "px-4 py-3", attrs: { md: "4" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column justify-content-between align-items-start",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "text-uppercase grey-color fs-14 mb-4 cursor-pointer",
                                      attrs: {
                                        tag: "a",
                                        to: {
                                          name: "contracts.grouped",
                                          params: { group: "fl44" },
                                          query: _vm.getQueries,
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " КОЛИЧЕСТВО ЗАКЛЮЧЕННЫХ ДОГОВОРОВ "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fs-32 green-color fw-600",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.organization.contracts_count ||
                                              0
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "fs-14 grey-color text-lowercase ml-2",
                                      },
                                      [_vm._v("договоров")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "px-4 py-3", attrs: { md: "4" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "h-100 d-flex flex-column justify-content-between align-items-start",
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "text-uppercase grey-color fs-14 mb-4 cursor-pointer",
                                      attrs: {
                                        tag: "a",
                                        to: {
                                          name: "contracts.grouped",
                                          params: { group: "fl44" },
                                          query: _vm.getQueries,
                                        },
                                      },
                                    },
                                    [_vm._v(" СУММА ЗАКЛЮЧЕННЫХ ДОГОВОРОВ ")]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fs-32 green-color fw-600",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatPrice(
                                              _vm.organization.contracts_sum
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "fs-14 grey-color text-lowercase ml-2",
                                      },
                                      [_vm._v("руб.")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }