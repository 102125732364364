import axios from 'axios';
import store from '@/store';

export default {
    getPurchases(filters) {
        return axios
            .get('/purchases', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    },
    get(id) {
        return axios.get(`/purchases/${id}`);
    },
    checkProvider(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/check_provider`);
    },
    getOffers(filters) {
        return axios.post('/cabinet/provider/offers', { filters: filters });
    },
    cancel(id, payload) {
        return axios.post(`/purchases/${id}/cancel`, payload);
    },
    changeLength(id) {
        return axios.get(`/purchases/${id}/change_length`);
    },
    getFailedPurchases() {
        return axios.get('/failed_purchases');
    },
    setRating(purchaseId, rating) {
        return axios.post(`/purchases/${purchaseId}/set_rating`, { rating });
    },
    updateComment(purchase_id, comment) {
        return axios.put(`/purchases/${purchase_id}/comment`, { purchase_comment: comment });
    },
    downloadContractPDF_dep(purchase) {
        // deprecated
        return axios.post('/purchases/download/contract', { purchase: purchase }, { responseType: 'blob' });
    },
    downloadActPDF_dep(purchase) {
        // deprecated
        return axios.post('/purchases/download/act', { purchase: purchase }, { responseType: 'blob' });
    },
    downloadProtocolPDF(purchase) {
        // deprecated
        return axios.post('/purchases/download/final-protocol', { purchase: purchase }, { responseType: 'blob' });
    },
    downloadNoticePDF(purchase) {
        // deprecated
        return axios.post('/purchases/download/order-info', { id: purchase.id }, { responseType: 'blob' });
    },
    downloadOrderInfoPDF(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/order_info_pdf`, { responseType: 'blob' });
    },
    downloadFinalProtocolPDF(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/final_protocol_pdf`, { responseType: 'blob' });
    },
    downloadContractPDF(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/contract_pdf`, { responseType: 'blob' });
    },
    downloadActPDF(purchaseId) {
        return axios.get(`/purchases/${purchaseId}/act_pdf`, { responseType: 'blob' });
    },
    copyPurchase(purchaseId) {
        return axios.post(`/purchases/${purchaseId}/copy`);
    },
    async storeSignedPurchase(id, form, xml) {
        const url = `/purchases`;
        try {
            const response = await axios.post(url, {
                id: id,
                form: form,
                xml: xml,
                thumbprint: store.state.currentThumbprint,
                certificate: store.getters.currentParsedCertificate
            });
            await store.dispatch('showSuccessToast', 'Закупочная сессия сформирована.');
            return response.data;
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    },
    getPurchaseSignFormXml(id) {
        return axios.get(`/purchases/${id}/purchase_xml`);
    },
    getPurchaseOfferCreateData(purchaseId) {
        return axios.get(`front/v1/fz44/purchases/${purchaseId}/offers/create`);
    }
};
