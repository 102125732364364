var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "5" } },
        [
          _c("multiselect", {
            attrs: {
              "allow-empty": false,
              "clear-on-select": true,
              "close-on-select": true,
              label: _vm.labelField,
              multiple: false,
              options: _vm.purchaseStatuses,
              "preserve-search": false,
              "show-labels": false,
              "track-by": _vm.trackBy,
              placeholder: "Выберите статус",
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
        ],
        1
      ),
      _c("b-col", [
        _c("ul", { staticClass: "atmo-buttons" }, [
          _c(
            "li",
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-green", disabled: _vm.disabled },
                  on: { click: _vm.handleClick },
                },
                [_vm._v("Изменить статус")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }