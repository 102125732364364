var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-col", { staticClass: "my-3", attrs: { md: "6" } }, [
    _c(
      "div",
      { staticClass: "purchase_part" },
      [
        _c(
          "div",
          { staticClass: "atmo-override-color--supplier purchase_part-header" },
          [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "p",
                { staticClass: "purchase_part-header_number text-uppercase" },
                [_vm._v("Поставщик")]
              ),
              _vm.client.is_isp
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { variant: "custom-green", show: true },
                          expression: "{ variant: 'custom-green', show: true }",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "lable",
                      attrs: {
                        title:
                          "Данный поставщик является прямым производителем товаров, исполнителем услуг или подрядчиком работ",
                      },
                    },
                    [_vm._v("ИСП")]
                  )
                : _vm._e(),
              _vm.client.is_smp
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          value: { variant: "custom-green" },
                          expression: "{ variant: 'custom-green' }",
                          modifiers: { hover: true },
                        },
                      ],
                      staticClass: "lable",
                      attrs: {
                        title: "Является субъектом малого предпринимательства",
                      },
                    },
                    [_vm._v("СМП")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _vm.currentUser &&
              _vm.client &&
              _vm.currentUser.organization.is_provider === false
                ? _c(
                    "div",
                    {
                      staticClass: "mb-0 mx-2 text-right",
                      staticStyle: { "line-height": "1" },
                    },
                    [
                      _vm.client.is_favorite === true
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFavorite(_vm.client)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/full_star.svg",
                                  alt: "full_star",
                                },
                              }),
                              _c("span", { staticClass: "max-content" }, [
                                _vm._v("ИЗ "),
                                _c("br", { staticClass: "visible-768" }),
                                _vm._v("ИЗБРАННОГО"),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12",
                              on: {
                                click: function ($event) {
                                  return _vm.addFavorite(_vm.client)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/empty_star.svg",
                                  alt: "empty_star",
                                },
                              }),
                              _c("span", [_vm._v("В ИЗБРАННОЕ")]),
                            ]
                          ),
                    ]
                  )
                : _vm._e(),
              _vm.client.is_registered
                ? _c("p", { staticClass: "purchase_part-header_label mx-2" }, [
                    _c("img", {
                      staticClass: "mr-1",
                      attrs: { src: "/images/check.svg", alt: "check_label" },
                    }),
                    _vm._v(" Зарегистрирован "),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _c(
          "b-row",
          { staticClass: "purchase_part-body" },
          [
            _c("div", { staticClass: "px-3 pt-3 w-100" }, [
              _c(
                "p",
                { staticClass: "green-color fw-600 fs-12 text-uppercase" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.client.full_name || _vm.client.short_name) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "px-3 w-100 mb-4" }, [
              _c("p", { staticClass: "m-0 fs-12 grey-color" }, [
                _vm._v("Адрес:"),
              ]),
              _c("p", { staticClass: "m-0 fs-12 grey-color fw-600" }, [
                _vm._v(_vm._s(_vm.client.post_address)),
              ]),
            ]),
            _c("b-col", { staticClass: "purchase_part-body_col" }, [
              _vm.client.contact_name
                ? _c("div", { staticClass: "w-100 mb-4" }, [
                    _c("p", { staticClass: "m-0 fs-12 grey-color" }, [
                      _vm._v("Контактное лицо:"),
                    ]),
                    _c("p", { staticClass: "m-0 fs-12 grey-color fw-600" }, [
                      _vm._v(_vm._s(_vm.client.contact_name)),
                    ]),
                  ])
                : _vm._e(),
              _vm.client.rating
                ? _c("div", { staticClass: "w-100 mb-4" }, [
                    _c("p", { staticClass: "fs-12 fw-400 m-0 grey-color" }, [
                      _vm._v(" Рейтинг: "),
                      _c("span", { staticClass: "fw-600 grey-color" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$formatPrice(_vm.client.rating)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "rating_scale" },
                      [
                        _vm._l(_vm.clientRatingWhole, function (el, index) {
                          return _c(
                            "div",
                            {
                              key: "index_" + index,
                              staticClass: "rating_scale-part_wrapper",
                              style: `border-color: ${_vm.ratingColor}`,
                            },
                            [
                              _c("div", {
                                staticClass: "rating_scale-part",
                                style: `background-color: ${_vm.ratingColor}`,
                              }),
                            ]
                          )
                        }),
                        _vm._l(
                          Math.ceil(_vm.clientRatingRest),
                          function (el, index) {
                            return _c(
                              "div",
                              {
                                key: "indexx_" + index,
                                staticClass: "rating_scale-part_wrapper",
                              },
                              [
                                _c("div", {
                                  staticClass: "rating_scale-part",
                                  style: `background: linear-gradient(to right, ${_vm.ratingColor} 0%, ${_vm.ratingColor} ${_vm.transitionPercent}%, #fff ${_vm.transitionPercent}%, #fff 100%)`,
                                }),
                              ]
                            )
                          }
                        ),
                        _vm._l(_vm.rest, function (el, index) {
                          return _c(
                            "div",
                            {
                              key: "indexxx_" + index,
                              staticClass:
                                "rating_scale-part_wrapper rest_part",
                            },
                            [_c("div", { staticClass: "rating_scale-part" })]
                          )
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("b-col", { staticClass: "purchase_part-body_col" }, [
              _vm.client.ogrn && _vm.client.okopf_code != 50102
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ОГРН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.ogrn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.client.ogrn && _vm.client.okopf_code == 50102
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ОГРНИП: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.ogrn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.client.inn
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ИНН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.inn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.client.kpp && _vm.client.okopf_code !== 50102
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" КПП: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.kpp) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "b-col",
              { staticClass: "mt-2", attrs: { cols: "12" } },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "text-uppercase custom-orange-btn",
                    attrs: {
                      to: {
                        name: "ProviderShow",
                        params: { id: _vm.client.id },
                      },
                      variant: "custom-orange",
                      size: "sm",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Сведения")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }