<template>
    <div v-if="!loading">
        <b-container fluid class="purchase-offer-header-block">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mt-3 mb-3">№ {{ purchase.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14">Форма предоставления наилучшего предложения на объявление о закупочной сессии</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container>
            <div class="text-right">
                <b-btn variant="danger" class="fs-14 my-1 my-3" style="color: white !important" size="lg" href="https://app.hinted.me/simulations/669e4103-056e-40fd-9586-f0e0c63a2bba/view" target="_blank">
                    <img class="mx-1" style="height: 16px" src="/images/youtube.svg" alt="documentation" />
                    Инструкция по формированию и подаче предложения</b-btn
                >
            </div>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link label="Наименование" v-model="purchase.customer.name" :router-link="{ name: 'CustomerShow', params: { id: purchase.customer.id } }" />
                <text-row label="ИНН" v-model="purchase.customer.inn" />
                <text-row label="КПП" v-model="purchase.customer.kpp" v-if="purchase.customer.kpp" />
                <text-row label="ОГРН" v-model="purchase.customer.ogrn" v-if="purchase.customer.ogrn" />
                <text-row label="Дополнительная контактная информация" v-model="purchase.additional_contact_info" v-if="purchase.additional_contact_info" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Условия закупки</text-header>
                <text-row-datetime label="Дата и время размещения закупки" v-model="purchase.purchase_start_datetime" />
                <text-row-datetime label="Дата и время окончания приема предложений" v-model="purchase.purchase_end_datetime" />
                <text-row label="Предмет закупки" v-model="purchase.terms_of_purchase.purchase_category_name" />
                <text-row label="Объект закупки" v-model="purchase.terms_of_purchase.purchase_object" />
                <text-row label="Национальный проект" v-model="purchase.terms_of_purchase.national_project_name" v-if="purchase.terms_of_purchase.national_project_name" />
                <text-row label="Тип закупки" v-model="purchase.terms_of_purchase.order_type_name" />
                <text-row label="Продолжительность закупочной сессии" v-model="purchase.terms_of_purchase.duration_type_title" />
                <text-row v-if="purchase.terms_of_purchase.with_advance" label="Размер аванса, %" v-model="purchase.terms_of_purchase.advance_percentage" />
                <text-row-price label="Начальная максимальная цена контракта" v-model="purchase.start_price" />
                <text-row-date label="Планируемая дата заключения договора" v-model="purchase.terms_of_purchase.planned_contract_date" />
                <text-row-documents label="Проект договора" v-model="purchase.contract_project_document" />
                <text-row-documents label="Дополнительные документы" v-model="purchase.additional_documents" v-if="purchase.additional_documents.length > 0" />
                <text-row label="Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ" v-model="purchase.terms_of_purchase.additional_info" v-if="purchase.terms_of_purchase.additional_info" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2" v-if="(purchase.terms_of_delivery.addresses && purchase.terms_of_delivery.addresses.length > 0) || purchase.terms_of_delivery.delivery_information">
            <b-container>
                <text-header>Условия поставки</text-header>
                <text-row-addresses label="Адреса для доставки товаров/выполнения работ/оказания услуг" v-model="purchase.terms_of_delivery.addresses" v-if="purchase.terms_of_delivery.addresses && purchase.terms_of_delivery.addresses.length > 0" />
                <text-row label="График поставки товаров (выполнения работ, оказания услуг)" v-model="purchase.terms_of_delivery.delivery_information" v-if="purchase.terms_of_delivery.delivery_information" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Требования к участникам закупки</text-header>
                <text-row :label-cols-sm="11" :label-cols-lg="11" value-class="text-right mr-2" label="Участником закупки могут быть только субъекты малого предпринимательства (СМП)" :value="purchase.supplier_requirements.only_msp ? 'Да' : 'Нет'" />
                <text-row
                    :label-cols-sm="11"
                    :label-cols-lg="11"
                    value-class="text-right mr-2"
                    label="Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла"
                    :value="purchase.supplier_requirements.supplier_reqs_rating ? 'Да' : 'Нет'" />

                <b-form-group
                    label-cols-sm="11"
                    label-cols-lg="11"
                    :state="!$v.form.supplier_requirements.supplier_reqs_a.$invalid"
                    label="Подтверждаю, что соответствую требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    v-if="purchase.supplier_requirements.supplier_reqs_a">
                    <b-form-checkbox :state="!$v.form.supplier_requirements.supplier_reqs_a.$invalid" class="fs-14 grey-color mt-2 text-right" v-model="form.supplier_requirements.supplier_reqs_a"></b-form-checkbox>
                </b-form-group>
                <div style="word-break: break-all" class="form-row" v-if="purchase.supplier_requirements.supplier_reqs_a">
                    <div class="col">
                        <p class="fs-14 grey-color mt-2"><strong>Участник закупки должен соответствовать следующим требованиям</strong> - {{ purchase.supplier_requirements.supplier_reqs_a_text }}</p>
                    </div>
                </div>
                <div class="form-row" v-if="purchase.supplier_requirements.supplier_reqs_a">
                    <div class="col">
                        <p class="fs-14 grey-color mt-2"><strong>Участник закупки должен подтвердить соответствие указанным требованиям, приложив следующие документы:</strong></p>
                    </div>
                </div>
                <form-row-single-line-document-upload
                    v-for="(item, index) in purchase.supplier_requirements.supplier_required_documents"
                    :key="index"
                    :title="item.name"
                    v-model="form.supplier_requirements.supplier_required_documents[index].storedDocument"
                    :v="$v.form.supplier_requirements.supplier_required_documents.$each[index].storedDocument" />

                <b-form-group
                    label-cols-sm="11"
                    label-cols-lg="11"
                    :state="!$v.form.supplier_requirements.supplier_reqs_b.$invalid"
                    label="Подтверждаю, что соответствую требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    v-if="purchase.supplier_requirements.supplier_reqs_b">
                    <b-form-checkbox :state="!$v.form.supplier_requirements.supplier_reqs_b.$invalid" class="fs-14 grey-color mt-2 text-right" v-model="form.supplier_requirements.supplier_reqs_b"></b-form-checkbox>
                </b-form-group>
                <b-form-group
                    label-cols-sm="11"
                    label-cols-lg="11"
                    :state="!$v.form.supplier_requirements.supplier_reqs_c.$invalid"
                    label="Подтверждаю, что соответствую требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    v-if="purchase.supplier_requirements.supplier_reqs_c">
                    <b-form-checkbox :state="!$v.form.supplier_requirements.supplier_reqs_c.$invalid" class="fs-14 grey-color mt-2 text-right" v-model="form.supplier_requirements.supplier_reqs_c"></b-form-checkbox>
                </b-form-group>
                <b-form-group
                    label-cols-sm="11"
                    label-cols-lg="11"
                    :state="!$v.form.supplier_requirements.supplier_reqs_sonko.$invalid"
                    label="Подтверждаю, оргчнизация является социально ориентированной некоммерческой организацией (СОНКО)"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    v-if="purchase.supplier_requirements.supplier_reqs_sonko">
                    <b-form-checkbox :state="!$v.form.supplier_requirements.supplier_reqs_sonko.$invalid" class="fs-14 grey-color mt-2 text-right" v-model="form.supplier_requirements.supplier_reqs_sonko"></b-form-checkbox>
                </b-form-group>

                <!-- Additional Requirements -->
                <div class="form-row" v-if="purchase.supplier_requirements.additional.state[0]">
                    <div class="col">
                        <p class="fs-14 grey-color mt-2"><strong>Участник должен подтвердить соответствие следующим дополнительным требованиям, приложив документы:</strong></p>
                    </div>
                </div>

                <form-row-single-line-document-upload
                    v-for="(requirement, index) in purchase.supplier_requirements.additional.requirements"
                    :key="`req-additional-${index}`"
                    :title="`${index + 1}. ${requirement.description} (причина: ${requirement.reason})`"
                    v-model="form.supplier_requirements.additional.requirements[index].attachment"
                    :v="$v.form.supplier_requirements.additional.requirements.$each[index].attachment" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Контакты</text-header>
                <form-row-edit-text label="Контактное лицо поставщика" v-model="form.contact_info" :v="$v.form.contact_info" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Спецификация</text-header>
                <b-row class="my-2" no-gutters>
                    <b-col class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="fw-600">№</th>
                                    <th rowspan="2" class="fw-600">Рег.номер ТРУ</th>
                                    <th rowspan="2" class="fw-600"><abbr title="Характеристики ТРУ">Хар-ки ТРУ</abbr></th>
                                    <th rowspan="2" class="fw-600">Наименование товара (работ, услуг)</th>
                                    <th rowspan="2" class="fw-600">Код ОКПД2</th>
                                    <th colspan="4" class="fw-600" style="text-align: center; border-right: 2px solid #dee2e6">Извещение</th>
                                    <th colspan="3" class="fw-600" style="text-align: center">Предложение</th>
                                </tr>
                                <tr>
                                    <th class="fw-600">Количество</th>
                                    <th class="fw-600">Единица</th>
                                    <th class="fw-600">Цена за ед., руб.</th>
                                    <th class="fw-600">Стоимость, руб.</th>
                                    <th class="fw-600" style="min-width: 150px">Цена за ед., руб.</th>
                                    <th class="fw-600">Стоимость, руб.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <fragment v-for="(item, index) in purchase.specifications" :key="item.id">
                                    <tr :class="visible[index] ? 'border-bottom-0 border-top-0' : ''">
                                        <td class="align-text-top">{{ index + 1 }}</td>
                                        <td class="align-text-top">
                                            <span :id="'spec-ktru-popover-' + (index + 1)">{{ item.ktru_reg_number }}</span>
                                            <b-popover :target="'spec-ktru-popover-' + (index + 1)" triggers="hover" placement="top">
                                                <template v-slot:title><b>Рег.номер ТРУ:</b> {{ item.ktru_reg_number }}</template>
                                                <b>Группа ТРУ:</b> {{ item.ktru_group_name }}<br />
                                                <b>Наименование:</b> {{ item.ktru_name }}<br />
                                                <b>Тип:</b> {{ item.ktru_type_name }}<br />
                                            </b-popover>
                                        </td>
                                        <td class="align-text-top">
                                            <button v-b-toggle="'collapse-' + index" class="atmo-button-icon">
                                                <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                                <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                            </button>
                                            <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                                        </td>
                                        <td class="align-text-top">{{ item.name }}</td>
                                        <td class="align-text-top">{{ item.okpd_name }}</td>
                                        <td class="align-text-top">{{ item.count }}</td>
                                        <td class="text-lowercase align-text-top">{{ item.okei_name }}</td>
                                        <td class="align-text-top text-nowrap">{{ item.price > 0 ? $formatPrice(item.price) : '&mdash;' }}</td>
                                        <td class="align-text-top text-nowrap">{{ item.price > 0 ? $formatPrice(purchaseSpecificationItemPriceTotal(item.price, item.count)) : '&mdash;' }}</td>
                                        <td class="align-text-top">
                                            <div class="d-flex align-items-center">
                                                <currency-input
                                                    v-stop-number-mousewheel
                                                    :allow-negative="false"
                                                    v-model="form.items[item.id].price"
                                                    class="form-control fs-14"
                                                    currency="RUB"
                                                    locale="ru"
                                                    :class="$v.form.items.$each[item.id].price.$invalid ? 'is-invalid' : ''"
                                                    @input="recalculateTotalSum" />
                                            </div>
                                        </td>
                                        <td class="align-text-top text-nowrap">{{ $formatPrice(sumTotalItem(item, item.id)) }}</td>
                                    </tr>
                                    <tr v-show="visible[index]">
                                        <td colspan="11">
                                            <p v-if="item.ktru_description"><b>Описание:</b> {{ item.ktru_description }}</p>
                                            <p v-if="item.ktru_specifications && item.ktru_specifications.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru_specifications && item.ktru_specifications.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru_specifications" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.name }}</b> - {{ spec.value }}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </fragment>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <text-row-price label="Итоговая стоимость предложения" v-model="totalSum" />
                <template v-if="$v.form.$invalid">
                    <p v-if="$v.form.items.$invalid" class="text-danger">Одна или нескольких позиций спецификации содержит недопустимое значение поля "Цена за ед., руб.". Значение должно быть больше 0, и не должно превышать цену позиции, указанную в извещении.</p>
                    <p v-if="$v.form.total_cost.$invalid && !$v.form.total_cost.maxValue" class="text-danger">Итоговая стоимость предложения не должна превышать стартовую цену извещения или стоимость ранее поданного вами предложения.</p>
                </template>

                <b-row>
                    <b-col class="d-flex justify-content-between align-items-center">
                        <p class="block_head-text">Документы</p>
                        <file-uploader @set_file="addFile">Добавить документ</file-uploader>
                    </b-col>
                </b-row>
                <b-row class="pb-4">
                    <b-col>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width: 50px"></th>
                                    <th>Наименование</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="form.documents.length">
                                <tr v-for="(document, index) in form.documents" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <b-button variant="link" size="xs" style="padding-top: 0; padding-bottom: 0; color: var(--green-color) !important" class="fs-14" @click="$documentDownload(document)">{{ document.name }}</b-button>
                                    </td>
                                    <td width="1">
                                        <b-button @click="removeFile(index)" variant="light" size="sm" style="background: transparent">
                                            <img src="/images/trash_icon.svg" alt="trash_icon" />
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" align="center">Документы не добавлены.</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row class="mb-4">
                    <b-col>
                        <b-button variant="custom-outline-secondary" class="mr-2" @click="cancelOffer">ОТМЕНИТЬ</b-button>
                        <b-button variant="custom-green" class="mx-2 text-uppercase" @click="checkResources" :disabled="$v.form.$invalid || !$store.getters.getCanSign">ПОДАТЬ ПРЕДЛОЖЕНИЕ</b-button>
                        <span v-if="!$store.getters.getCanSign" class="text-danger pt-1">Подача предложения возможна только при авторизации при помощи ЭЦП</span>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-modal ref="attention-modal" title="Внимание!" size="lg" v-model="showAttentionModal">
            <div class="d-block">
                <div class="py-3 d-flex">
                    <div class="fs-14 grey-color text-center">С вашего лицевого счета будут заблокированы денежные средства для обеспечения проведения торгов, согласно оферты ИС {{ application_name_short }}</div>
                </div>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showAttentionModal = false">ОТМЕНИТЬ</b-button>
                <b-button variant="custom-green" class="text-uppercase" @click="acceptConfirmation">ПРОДОЛЖИТЬ</b-button>
            </div>
        </b-modal>
        <!--DEPOSIT MODAL WINDOW -->
        <b-modal hide-footer ref="attention-modal" title="Внимание!" size="lg" v-model="showDepositModal">
            <div class="d-block">
                <div class="w-100 fs-14 grey-color text-left">Для подачи предложения в закупочной сессии на лицевом счете Вашей организации не хватает {{ needDep }} рублей.</div>
                <br />
                <div class="w-100 fs-14 fw-600 grey-color form-group">
                    <b-input type="number" v-stop-number-mousewheel step="any" v-model="neededDeposit" :min="needDep" :state="checkDepositValid"></b-input>
                </div>
                <b-row>
                    <b-col>
                        <b-input type="email" v-model="email" placeholder="Введите почту" :state="checkEmailValid"></b-input>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col>
                        <b-button variant="custom-green" class="text-uppercase" @click="sendInvoiceToEmail">ОТПРАВИТЬ НА E-MAIL</b-button>
                        <b-button variant="custom-outline-secondary" class="text-uppercase ml-2" @click="downloadInvoice">СКАЧАТЬ PDF</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!--SIGN OFFER MODAL WINDOW -->
        <b-modal ref="sign-form-modal" title="Подписать предложение" size="lg" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3">
                    <b-tab active>
                        <template slot="title">
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <text-row label="Закупочная сессия" v-model="purchase.reg_number" />
                        <text-row-price label="Итоговая стоимость предложения" v-model="form.total_cost" />
                    </b-tab>
                    <b-tab>
                        <template slot="title">
                            <span class="system_link-style fs-16">XML</span>
                        </template>
                        <b-form-textarea v-if="!isSignedXml" v-model="formXml" disabled rows="20" no-resize no-auto-shrink wrap="off" class="text-monospace fs-12"></b-form-textarea>
                        <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" disabled rows="20" class="text-monospace fs-12"></b-form-textarea>
                    </b-tab>
                </b-tabs>
            </div>
            <div slot="modal-footer" class="w-100">
                <text-row label="Сертификат" :value="certificate?.subjectName" />
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelSignModal" :disabled="isDataSending">ОТМЕНИТЬ</b-button>
                <b-overlay :show="isSigningXml" v-if="!isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="!certificate || !summLimit" @click="signForm">ПОДПИСАТЬ</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" v-if="isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="!certificate || !summLimit" @click="sendData">ОТПРАВИТЬ</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
// import CadesMixins from '@/mixins/CadesMixins';
import FileUploader from '@/components/common/FileUploader';
import DownloadFilesApi from '@/services/api/DownloadFiles';
import TextHeader from '@/components/common/form-rows/text-header';
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPrice from '@/components/common/form-rows/text-row-price';
import TextRowDatetime from '@/components/common/form-rows/text-row-datetime';
import TextRowDate from '@/components/common/form-rows/text-row-date';
import TextRowDocuments from '@/components/common/form-rows/text-row-documents';
import TextRowAddresses from '@/components/common/form-rows/text-row-addresses';
import { maxValue, minValue, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import FormRowSingleLineDocumentUpload from '@/components/common/form-rows/form-row-single-line-document-upload';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';
import roundToTwoDecimals from '@/rearchitected/lib/js/src/misc/roundToTwoDecimals';
import { getCertificate } from 'crypto-pro';

export default {
    name: 'PurchaseOfferCreate',
    components: { FormRowSingleLineDocumentUpload, TextRowAddresses, TextRowDocuments, TextRowDate, TextRowDatetime, TextRowPrice, TextRow, TextRowLink, TextHeader, FileUploader },
    // mixins: [CadesMixins],
    data() {
        return {
            isSigningXml: false,
            isDataSending: false,
            formXml: '',
            isSignedXml: false,
            loading: true,
            showSignModal: false,
            showAttentionModal: false,
            showDepositModal: false,
            attentionModalShowCounter: 0,
            havingDeposit: false,
            neededDeposit: 0,
            needDep: 0,
            accounting: null,
            email: this.$store.state.user.email,
            visible: [],
            isProviderChecked: false,

            purchase: {
                id: null,
                reg_number: '',
                additional_contact_info: '',
                customer: {
                    id: null,
                    name: '',
                    inn: '',
                    kpp: '',
                    ogrn: ''
                },
                terms_of_purchase: {
                    purchase_category_name: '',
                    national_project_name: '',
                    order_type_name: ''
                },
                supplier_requirements: {
                    only_msp: false,
                    supplier_reqs_a: false,
                    supplier_reqs_b: false,
                    supplier_reqs_c: false,
                    supplier_required_documents: []
                },
                terms_of_delivery: {
                    addresses: [],
                    delivery_information: '',
                    max_delivery_date: null
                },
                specifications: [],
                contract_project_document: null,
                additional_documents: [],
                max_total_cost: 0
            },
            form: {
                contact_info: '',
                total_cost: 0,
                items: [],
                documents: [],
                supplier_requirements: {
                    supplier_reqs_sonko: false,
                    supplier_reqs_a: false,
                    supplier_reqs_b: false,
                    supplier_reqs_c: false,
                    supplier_required_documents: [],
                    additional: {
                        requirements: []
                    }
                }
            },
            signedFormXml: null,
            certificate: null
        };
    },
    validations() {
        return {
            form: {
                contact_info: {},
                total_cost: {
                    required,
                    minValue: minValue(0.01),
                    maxValue: maxValue(this.purchase.max_total_cost)
                },
                supplier_requirements: {
                    supplier_reqs_sonko: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.supplier_reqs_sonko)
                    },
                    supplier_reqs_a: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.supplier_reqs_a)
                    },
                    supplier_required_documents: {
                        required: requiredIf(() => this.purchase.supplier_requirements.supplier_reqs_a),
                        $each: {
                            storedDocument: { required }
                        }
                    },
                    supplier_reqs_b: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.supplier_reqs_b)
                    },
                    supplier_reqs_c: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.supplier_reqs_c)
                    },
                    additional: {
                        requirements: {
                            required: requiredIf(() => this.purchase.supplier_requirements.additional.state[0]),
                            $each: {
                                attachment: { required }
                            }
                        }
                    }
                },
                items: {
                    required,
                    $each: {
                        required,
                        price: {
                            required,
                            minValue: minValue(0.01),
                            maxValue: (val, item) => {
                                const purchaseSpecificationItemPrice = this.purchase.specifications.find((i) => i.id === item.purchase_item_id).price;

                                return purchaseSpecificationItemPrice > 0 ? val <= purchaseSpecificationItemPrice : true;
                            }
                        }
                    }
                }
            }
        };
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        recalculateTotalSum() {
            this.form.total_cost = this.totalSum;
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        addFile(file) {
            this.form.documents.push(file);
        },
        removeFile(index) {
            this.form.documents.splice(index, 1);
        },
        async fillData() {
            const purchaseData = await this.$api.purchases.getPurchaseOfferCreateData(this.$route.params.id);
            this.form = purchaseData.offer;
            this.purchase = purchaseData.purchase;

            this.form.supplier_requirements.additional.requirements = this.form.supplier_requirements.additional.requirements.map((requirement) => {
                return { ...requirement, attachment: requirement.attachment ? { ...requirement.attachment, id: requirement.attachment.file_id } : null };
            });

            this.recalculateTotalSum();
        },
        async prepareForm() {
            this.certificate = await getCertificate(this.$store.getters.getCurrentThumbprint);
            if (!this.certificate) {
                return;
            }
            if (await this.$api.purchases.checkProvider(this.$route.params.id)) {
                await this.showSignForm();
            }
        },
        async showSignForm() {
            this.formXml = await this.$api.purchases.getPurchaseOfferXml(this.$route.params.id, this.transformRequestPayload());
            if (!this.formXml) {
                return;
            }
            this.showSignModal = true;
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await this.$api.purchases.newSignXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        async sendData() {
            this.isDataSending = true;
            const preparedCertificate = await this.prepareCertificate(this.certificate);
            const responseData = await this.$api.purchases.storePurchaseOffer(this.$route.params.id, this.transformRequestPayload(), this.signedFormXml, preparedCertificate);
            this.isDataSending = false;
            if (responseData) {
                this.onCancel();
            }
        },
        sumTotalItem(item, index) {
            if (this.form.items[index] && Object.prototype.hasOwnProperty.call(this.form.items[index], 'price')) {
                return roundToTwoDecimals(this.form.items[index].price * item.count);
            }
            return 0;
        },
        onCancel() {
            this.$router.push({ name: 'ProviderOffersCabinet' }).catch(() => {});
        },
        cancelOffer() {
            this.onCancel();
        },
        // проверка наличия средств на счету + предупреждение
        checkResources() {
            axios
                .post('/account/provider/check_deposit', {
                    purchaseId: this.purchase.id
                })
                .then((resp) => {
                    // получаем ответ достаточно ли средств на ЛС
                    //если достаточно то выводим модальку с предупреждением о комиссии
                    this.accounting = resp.data.accounting;
                    if (resp.data.isAvailable) {
                        this.openAttentionModal();
                    } else {
                        //если не достаточно, то выводим модальку с пополнением
                        let dep = resp.data.needDep;
                        this.neededDeposit = dep;
                        this.needDep = dep;
                        this.openDepositModal();
                    }
                })
                .catch(() => {
                    this.pushToast({
                        text: 'Сервер не отвечает, попробуйте еще раз.',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        downloadInvoice() {
            if (this.isValid) {
                DownloadFilesApi.downloadInvoice('/account/invoice/download', 'Invoice.pdf', { amount: this.neededDeposit });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            }
        },
        sendInvoiceToEmail() {
            if (this.isValid) {
                let url = '/account/mail/sending';
                let data = { email: this.email, amount: this.neededDeposit };
                DownloadFilesApi.sendFileToEmail(url, data).then(() => {
                    this.pushToast({
                        text: 'Счет отправлен на Ваш email',
                        title: 'Успех',
                        variant: 'green'
                    });
                });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            }
        },

        // Если уже было предложение, либо предупреждение уже было показано, тогда перейти сразу к подписи.
        // Иначе показать предупреждение
        openAttentionModal() {
            if (!!this.accounting || !!this.attentionModalShowCounter) {
                this.acceptConfirmation();
            } else {
                this.showAttentionModal = true;
                this.attentionModalShowCounter++;
            }
        },
        openDepositModal() {
            this.showDepositModal = true;
        },
        //переход непосредственно к подписи
        acceptConfirmation: function () {
            this.showAttentionModal = false;
            this.prepareForm();
        },

        purchaseSpecificationItemPriceTotal(quantity, pricePerUnit) {
            return roundToTwoDecimals(quantity * pricePerUnit);
        },

        transformRequestPayload() {
            const transformed = JSON.parse(JSON.stringify(this.form));

            transformed.supplier_requirements.additional.requirements = transformed.supplier_requirements.additional.requirements.map((item) => {
                return { id: item.id, attachment: { file_id: item.attachment.id, name: item.attachment.name } };
            });

            return transformed;
        }
    },
    computed: {
        application_name_short() {
            return Config.get('theme.variables.view.application_name_short');
        },
        isValid() {
            return this.checkDepositValid && this.checkEmailValid;
        },
        checkDepositValid() {
            let re = /^\d{1,16}\.{0,1}\d{0,2}$/;
            return !isNaN(this.neededDeposit) && this.neededDeposit >= this.needDep && re.test(this.neededDeposit);
        },
        checkEmailValid() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        totalSum() {
            let sum = 0;
            this.purchase.specifications.forEach(function (item) {
                sum += roundToTwoDecimals(this?.form?.items[parseInt(item.id)]?.price * item.count);
            }, this);
            if (sum) {
                return roundToTwoDecimals(sum);
            }
            return 0;
        },
        summLimit() {
            return this.totalSum <= parseFloat(this.purchase.max_total_cost);
        },

        notNullSpecsPrice() {
            return this.form.items && this.form.items.length > 0 ? this.form?.items.every((item) => item.price > 0) : false;
        }
    }
};
</script>

<style scoped>
.purchase-offer-header-block {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style scoped>
a {
    color: var(--green-color) !important;
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    min-height: 250px;
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
    vertical-align: top;
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    color: var(--grey-color);
    border-top: 0;
}

.table tbody tr {
    border-bottom: 1px solid #dee2e6;
}
</style>

<style>
.new_offer .is-valid {
    border-color: rgb(206, 212, 218) !important;
    background: #fff !important;
}
</style>
