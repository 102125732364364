<template>
    <b-container fluid class="greyBg pb-5">
        <b-container>
            <b-row>
                <b-col>
                    <p class="fs-32 grey-color my-4" style="line-height: 1">{{ section.title }}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <span class="fs-14 grey-color">{{ section.desc }}</span>
                </b-col>
            </b-row>
            <system-preloader v-if="loading"></system-preloader>
            <b-row v-if="!loading && !!section.files.length" class="mt-4">
                <b-col>
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1">#</th>
                                <th>Название</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(file, index) in section.files" :key="file.id">
                                <td>{{ index + 1 }}.</td>
                                <td>{{ file.name }}</td>
                                <td>
                                    <b-btn variant="custom-green" @click.prevent="downloadFile(file.id, file.original_name, file.name, file.extention)">Скачать</b-btn>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <b-row v-if="!loading && !section.files.length">
                <b-col class="text-center">
                    <empty-request></empty-request>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<script>
import Information from '@/services/api/Information';

export default {
    name: 'InformationSection',
    data() {
        return {
            loading: true,
            section: {
                title: '',
                desc: '',
                files: []
            }
        };
    },
    watch: {
        // при изменениях маршрута запрашиваем данные снова
        $route: 'fetchData'
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            Information.getSection(this.$route.params.id)
                .then((resp) => {
                    this.section = resp;
                })
                .catch(() => this.$router.push('/').catch(() => {}))
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadFile(id, originalName, name, extention) {
            Information.downloadFile(id, originalName, name, extention);
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
