var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-col", { staticClass: "my-3", attrs: { md: "6" } }, [
    _c(
      "div",
      { staticClass: "purchase_part" },
      [
        _c(
          "div",
          { staticClass: "atmo-override-color--customer purchase_part-header" },
          [
            _c(
              "p",
              { staticClass: "purchase_part-header_number text-uppercase" },
              [_vm._v("Заказчик")]
            ),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _vm.currentUser &&
              _vm.client &&
              _vm.currentUser.organization &&
              _vm.currentUser.organization.is_provider
                ? _c(
                    "div",
                    {
                      staticClass: "mb-0 mx-2 text-right",
                      staticStyle: { "line-height": "1" },
                    },
                    [
                      _vm.client.is_favorite === true
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFavorite(_vm.client)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/full_star.svg",
                                  alt: "full_star",
                                },
                              }),
                              _c("span", { staticClass: "max-content" }, [
                                _vm._v("ИЗ "),
                                _c("br", { staticClass: "visible-768" }),
                                _vm._v(" ИЗБРАННОГО"),
                              ]),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "mb-0 d-flex justify-content-end align-items-center cursor-pointer white-color fs-12 mx-2",
                              on: {
                                click: function ($event) {
                                  return _vm.addFavorite(_vm.client)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/empty_star.svg",
                                  alt: "empty_star",
                                },
                              }),
                              _c("span", [_vm._v("В ИЗБРАННОЕ")]),
                            ]
                          ),
                    ]
                  )
                : _vm._e(),
              _vm.client.is_registered
                ? _c(
                    "p",
                    {
                      staticClass:
                        "d-flex align-items-center purchase_part-header_label mx-2",
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/check.svg", alt: "check_label" },
                      }),
                      _vm._v(" Зарегистрирован "),
                    ]
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c(
          "b-row",
          { staticClass: "purchase_part-body" },
          [
            _c("div", { staticClass: "px-3 pt-3 w-100" }, [
              _c(
                "p",
                { staticClass: "green-color fw-600 fs-12 text-uppercase" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.client.full_name || _vm.client.short_name) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "px-3 w-100 mb-4" }, [
              _c("p", { staticClass: "m-0 fs-12 grey-color" }, [
                _vm._v("Адрес:"),
              ]),
              _c("p", { staticClass: "m-0 fs-12 grey-color fw-600" }, [
                _vm._v(_vm._s(_vm.client.post_address)),
              ]),
            ]),
            _c("b-col", { staticClass: "purchase_part-body_col" }, [
              _vm.client.okopf_name
                ? _c("div", { staticClass: "w-100 mb-4" }, [
                    _c("p", { staticClass: "m-0 fs-12 grey-color" }, [
                      _vm._v("Уровень заказчика:"),
                    ]),
                    _c("p", { staticClass: "m-0 fs-12 grey-color fw-600" }, [
                      _vm._v(_vm._s(_vm.client.okopf_name)),
                    ]),
                  ])
                : _vm._e(),
              _vm.client.contact_name
                ? _c("div", { staticClass: "w-100 mb-4" }, [
                    _c("p", { staticClass: "m-0 fs-12 grey-color" }, [
                      _vm._v("Контактное лицо:"),
                    ]),
                    _c("p", { staticClass: "m-0 fs-12 grey-color fw-600" }, [
                      _vm._v(_vm._s(_vm.client.contact_name)),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("b-col", { staticClass: "purchase_part-body_col" }, [
              _vm.client.ogrn
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ОГРН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.ogrn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.client.inn
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ИНН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.inn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.client.kpp
                ? _c("div", { staticClass: "mb-3" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" КПП: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.client.kpp) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "b-col",
              { staticClass: "mt-2", attrs: { cols: "12" } },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "text-uppercase custom-green-btn",
                    attrs: {
                      to: {
                        name: "CustomerShow",
                        params: { id: _vm.client.id },
                      },
                      variant: "custom-green",
                      size: "sm",
                      target: "_blank",
                    },
                  },
                  [_vm._v("Сведения")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }