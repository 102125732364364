<template>
    <b-container>
        <b-row>
            <b-col>
                <p class="mt-4">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' } }">Извещения </router-link>
                </p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' }, query: { status: 1 } }">Идет прием предложений</router-link>
                    </li>
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' }, query: { status: 2 } }">Подведены итоги</router-link>
                    </li>
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' }, query: { status: 7 } }">Закупка отменена</router-link>
                    </li>
                    <li class="list-item">
                        <router-link exact v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' }, query: { status: 8 } }">Закупка не состоялась</router-link>
                    </li>
                </ul>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' } }">ДОГОВОРЫ</router-link>
                </p>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <router-link :to="'/clients'" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">ЗАКАЗЧИКИ И ПОСТАВЩИКИ</router-link>
                </p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <router-link :to="'/clients/customers'">Реестр заказчиков</router-link>
                    </li>
                    <li class="list-item">
                        <router-link :to="'/clients/providers'">Реестр поставщиков</router-link>
                    </li>
                </ul>
                <p class="mt-4">
                    <router-link :to="'/quotation-requests'" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">Запросы КП</router-link>
                </p>
                <p class="mt-4">
                    <router-link :to="{ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }" class="text-uppercase fw-600 grey-color text-decoration-none fs-14">Импортозамещение </router-link>
                </p>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4" v-if="$links.marketplace_link">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.marketplace_link" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> БАШМАРКЕТ </a>
                </p>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.info_materials" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <!-- Add .atmo-link-off class to switch off link in themes  -->
                    <a :href="$links.legal_documents" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14" target="_blank"> Правовые документы </a>
                </p>
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color text-decoration-none fs-14 mt-4">Режим работы</p>
                <ul class="full_menu-list">
                    <li class="list-item">
                        <p class="grey-color fs-14">9:00 до 18:00 (по времени г. {{ city }})</p>
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    data() {
        return {
            test_zak: !!process.env.VUE_APP_TEST_ZAK_EMAIL,
            test_post: !!process.env.VUE_APP_TEST_POST_EMAIL
        };
    },
    created() {},
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    methods: {
        loginAsCustomer() {
            // console.log('login as customer');
            axios
                .post('/auth/login', {
                    email: process.env.VUE_APP_TEST_ZAK_EMAIL,
                    thumbprint: process.env.VUE_APP_TEST_ZAK_THUMB
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        // устанавливаем выбранный сертификат в храниилище
                        // this.$store.commit('set_current_certificate', {index: this.certIndex, thumbprint: thumbprint});
                        this.$store
                            .dispatch('login', {
                                token: response.data.access_token,
                                user: response.data.user,
                                organization: response.data.organization
                            })
                            .then(() => {
                                window.location.assign('/cabinet');
                            });
                    } else {
                        this.pushToast({
                            text: response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                })
                .catch((error) => {
                    this.pushToast({
                        text: error,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        loginAsProvider() {
            // console.log('login as provider');
            axios
                .post('/auth/login', {
                    email: process.env.VUE_APP_TEST_POST_EMAIL,
                    thumbprint: process.env.VUE_APP_TEST_POST_THUMB
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        // устанавливаем выбранный сертификат в храниилище
                        // this.$store.commit('set_current_certificate', {index: this.certIndex, thumbprint: thumbprint});
                        this.$store
                            .dispatch('login', {
                                token: response.data.access_token,
                                user: response.data.user,
                                organization: response.data.organization
                            })
                            .then(() => {
                                window.location.assign('/cabinet');
                            });
                    } else {
                        this.pushToast({
                            text: response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                })
                .catch((error) => {
                    this.pushToast({
                        text: error,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        }
    },
    computed: {
        city() {
            return Config.get('theme.variables.view.city');
        },
        ...mapGetters({
            informationSections: 'getInformation'
        }),
        links() {
            return __LINKS;
        },
        marketplaceLink() {
            return Config.get('theme.variables.links.other.purchases_map');
        }
    }
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}

.router-link-active {
    color: var(--green-color) !important;
}

.full_menu-feed:hover {
    color: var(--green-color);
}
</style>
