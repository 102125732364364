var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color mb-4 main_text",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Извещения")]
            ),
          ]),
        ],
        1
      ),
      _c("b-row", [_c("b-col", [_c("empty-request")], 1)], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }