var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color mb-4",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Закупки")]
            ),
            _c(
              "ul",
              { staticClass: "tabs" },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "li",
                  { key: "key_" + index },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "ProviderContractsCabinet",
                            params: { cat: tab.path },
                          },
                          exact: "",
                        },
                      },
                      [_vm._v(_vm._s(tab.title) + " ")]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("filter-multiple-select", {
            attrs: {
              options: _vm.forFilters.statuses,
              label: "Статус закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус",
            },
            model: {
              value: _vm.filter.status_id,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status_id", $$v)
              },
              expression: "filter.status_id",
            },
          }),
          _c("filter-input-text", {
            attrs: {
              label: "Рег.номер закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Введите рег.номер закупки",
            },
            model: {
              value: _vm.filter.reg_number,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "reg_number", $$v)
              },
              expression: "filter.reg_number",
            },
          }),
          _c("filter-multiple-select", {
            attrs: {
              options: _vm.forFilters.purchaseCategories,
              label: "Предмет закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите предметы закупки",
            },
            model: {
              value: _vm.filter.purchase_categories,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "purchase_categories", $$v)
              },
              expression: "filter.purchase_categories",
            },
          }),
          _c("filter-input-text", {
            attrs: {
              label: "Заказчик",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Введите название организации или ИНН",
            },
            model: {
              value: _vm.filter.customer,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "customer", $$v)
              },
              expression: "filter.customer",
            },
          }),
          _c(
            "b-col",
            { staticClass: "mb-3 text-right", attrs: { md: "12" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.applyFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cleanFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.purchases, function (purchase) {
        return [
          purchase.is_external
            ? _c("out-system-contract", {
                key: purchase.id,
                attrs: { purchase: purchase },
              })
            : _c("provider-contracts", {
                key: purchase.id,
                attrs: { purchase: purchase },
              }),
        ]
      }),
      !_vm.purchases.length && !_vm.loading ? _c("empty-request") : _vm._e(),
      _vm.purchases && _vm.purchases.length
        ? _c("b-pagination", {
            attrs: {
              "per-page": _vm.limit,
              "total-rows": _vm.count,
              align: "center",
            },
            on: { input: _vm.applyFilters },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }