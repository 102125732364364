var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.form.organization
    ? _c(
        "div",
        [
          _c("text-header", [_vm._v("Регистрационные данные организации")]),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "Полное наименование" },
            model: {
              value: _vm.form.organization.full_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "full_name", $$v)
              },
              expression: "form.organization.full_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "Сокращенное наименование" },
            model: {
              value: _vm.form.organization.short_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "short_name", $$v)
              },
              expression: "form.organization.short_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.organization.manager,
              label: "Руководитель",
            },
            model: {
              value: _vm.form.organization.manager,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "manager", $$v)
              },
              expression: "form.organization.manager",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "ИНН" },
            model: {
              value: _vm.form.organization.inn,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "inn", $$v)
              },
              expression: "form.organization.inn",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "КПП" },
            model: {
              value: _vm.form.organization.kpp,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "kpp", $$v)
              },
              expression: "form.organization.kpp",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "ОГРН/ОГРНИП" },
            model: {
              value: _vm.form.organization.ogrn,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "ogrn", $$v)
              },
              expression: "form.organization.ogrn",
            },
          }),
          _vm.isUserProvider
            ? _c("text-header", [
                _vm._v("Признак местного товаропроизводителя"),
              ])
            : _vm._e(),
          _vm.isUserProvider
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Является местным товаропроизводителем",
                    "label-class":
                      "fs-14 grey-color fw-700 d-flex align-items-baseline",
                    "label-cols-lg": "3",
                    "label-cols-sm": "4",
                  },
                },
                [
                  _c("b-form-checkbox", {
                    staticClass: "mt-2",
                    model: {
                      value: _vm.form.organization.is_local_producer,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form.organization,
                          "is_local_producer",
                          $$v
                        )
                      },
                      expression: "form.organization.is_local_producer",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("text-header", [_vm._v("Контактные данные организации")]),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.organization.contact_name,
              label: "ФИО контактного лица",
            },
            model: {
              value: _vm.form.organization.contact_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "contact_name", $$v)
              },
              expression: "form.organization.contact_name",
            },
          }),
          _c("form-row-edit-dadata-address", {
            attrs: {
              v: _vm.$v.form.organization.post_address,
              label: "Адрес почтовый",
            },
            model: {
              value: _vm.form.organization.post_address,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "post_address", $$v)
              },
              expression: "form.organization.post_address",
            },
          }),
          _c("form-row-edit-dadata-address", {
            attrs: {
              v: _vm.$v.form.organization.fact_address,
              label: "Адрес фактический",
            },
            model: {
              value: _vm.form.organization.fact_address,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "fact_address", $$v)
              },
              expression: "form.organization.fact_address",
            },
          }),
          _c("form-row-edit-email", {
            attrs: {
              "email-confirmed": _vm.form.organization.email_confirmed,
              v: _vm.$v.form.organization.email,
              value: _vm.form.organization.email,
              label: "Email",
              "with-validation": "",
              "is-edit": "",
            },
            on: {
              input: _vm.onEmailInput,
              value: function ($event) {
                _vm.form.organization.email = $event
              },
              "email-confirmed": function ($event) {
                _vm.form.organization.email_confirmed = $event
              },
            },
          }),
          _c("form-row-edit-phone", {
            attrs: {
              v: _vm.$v.form.organization.phone,
              value: _vm.form.organization.phone,
              label: "Телефон",
            },
            on: {
              value: function ($event) {
                _vm.form.organization.phone = $event
              },
            },
          }),
          _c("form-row-edit-text", {
            attrs: { label: "Адрес сайта" },
            model: {
              value: _vm.form.organization.site,
              callback: function ($$v) {
                _vm.$set(_vm.form.organization, "site", $$v)
              },
              expression: "form.organization.site",
            },
          }),
          _c(
            "b-row",
            { staticClass: "pt-3 pl-3" },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.$v.form.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.sendData },
                    },
                    [_vm._v("Сохранить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }