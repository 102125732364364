import axios from 'axios';

export default {
    getProviders() {
        return axios.get('/organizations/providers').then((response) => {
            return response.data;
        });
    },
    getOrganization(id) {
        return axios.get('/organizations/organization/' + id).then((response) => {
            return response.data;
        });
    },
    getCustomers() {
        return axios.get('/organizations/customers').then((response) => {
            return response.data;
        });
    },
    getFavorite() {
        return axios.get('/favorites').then((response) => {
            return response.data;
        });
    },
    removeFavorite(organization) {
        return axios.delete('/favorites/' + organization.id).then((response) => {
            return response.data;
        });
    },
    addFavorite(organization) {
        return axios.put('/favorites/' + organization.id).then((response) => {
            return response.data;
        });
    },
    getUsers(httpOptions) {
        return axios.get('/organizations/get_users', httpOptions);
    },
    getLimits() {
        return axios.get('/organizations/limits');
    },
    getAllLimits() {
        return axios.get('/organizations/all_limits');
    },
    getAddresses() {
        return axios.get('/organizations/addresses');
    },
    downloadOrganizationReportPDF(organizationId) {
        return axios.get(`/organizations/${organizationId}/report`, { responseType: 'blob' });
    }
};
