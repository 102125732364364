<template>
    <b-row>
        <b-col md="12">
            <b-nav class="list">
                <b-nav-item :active="customer" to="/clients/customers"> Заказчики </b-nav-item>
                <b-nav-item :active="!customer" to="/clients/providers"> Поставщики </b-nav-item>
            </b-nav>
        </b-col>
        <b-col md="12">
            <p class="fs-14 greyBg-color mb-4" v-if="customer">Сведения о государственных и муниципальных заказчиках {{ region_0 }} проводящих закупки в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС {{ application_name_short }}.</p>
            <p class="fs-14 greyBg-color mb-4" v-else>Сведения о поставщиках для нужд государственных и муниципальных заказчиков {{ region_0 }} в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС {{ application_name_short }}.</p>
        </b-col>
    </b-row>
</template>

<script>
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export default {
    name: 'ClientHeader',
    props: ['customer'],
    data() {
        return {};
    },
    computed: {
        application_name_short() {
            return Config.get('theme.variables.view.application_name_short');
        },
        region_0() {
            return Config.get('theme.variables.view.region')[0];
        }
    }
};
</script>

<style scoped>
.list {
    margin-top: 2px;
    margin-bottom: 30px;
}
.list li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
}
.list li + li a {
    padding-left: 30px;
}
.list li a {
    padding: 0px 3px;
    font-size: 32px;
    font-weight: 600;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
}
.list li a:hover {
    color: #fff !important;
}

@media screen and (max-width: 568px) {
    .list {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}
@media screen and (max-width: 485px) {
    .list {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .list li a {
        font-size: 20px;
    }
}
</style>

<style scoped>
.router-link-active {
    border-bottom: 2px solid #fff !important;
    color: #fff !important;
}
</style>
