<template>
    <div>
        <b-row class="column-992 mb-4">
            <b-col class="d-flex align-items-center">
                <p class="fs-32 grey-color m-0 main_text" style="line-height: 1">Пользователи организации</p>
            </b-col>
            <b-col class="text-right d-flex align-items-center justify-content-end start-992">
                <router-link :to="{ name: 'UserCreate' }" class="btn btn-custom-unstyle-grey" tag="button">
                    <img alt="plus" class="mr-2" src="/images/plus.svg" />
                    Добавить пользователя
                </router-link>
            </b-col>
        </b-row>

        <div class="table-responsive py-3">
            <b-table :busy="loading" :fields="fields" :items="users" :per-page="pagination.per_page" class="table-atmo" empty-text="Данные отсутствуют" show-empty>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(is_blocked)="row">
                    <span v-if="!row.item.is_blocked" class="active fw-600">Активен</span>
                    <span v-else class="blocked fw-600">Заблокирован</span>
                </template>
                <template #cell(created_at)="row">
                    {{ $formatDateTime(row.item.created_at) }}
                </template>
                <template #cell(actions)="row">
                    <div class="float-right">
                        <b-dropdown no-caret right size="sm" toggle-class="text-decoration-none" variant="link">
                            <template slot="button-content"><img alt="cog" src="/images/cog.svg" /></template>
                            <b-dropdown-item :to="{ name: 'UserEdit', params: { id: row.item.id } }" class="fs-14">Редактировать</b-dropdown-item>
                            <b-dropdown-item :disabled="row.item.is_blocked" @click="blockUser(row.item)" class="fs-14">Заблокировать</b-dropdown-item>
                            <b-dropdown-item :disabled="!row.item.is_blocked" @click="unblockUser(row.item)" class="fs-14">Разблокировать</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </template>
            </b-table>
            <b-pagination v-if="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="mt-4 mb-0 mx-auto" @input="applyFilters" />
        </div>

        <!--        <b-row>-->
        <!--            <b-col style="overflow-x: auto">-->
        <!--                <vuetable-->
        <!--                    ref="vuetable"-->
        <!--                    :css="vuetable2Config.table"-->
        <!--                    :fields="columns"-->
        <!--                    :http-fetch="fetchData"-->
        <!--                    :load-on-start="false"-->
        <!--                    :per-page="perPage"-->
        <!--                    :query-params="tableQueryParams"-->
        <!--                    :sort-order="sortOrder"-->
        <!--                    api-url="/organization/users"-->
        <!--                    data-path="data"-->
        <!--                    no-data-template="Нет данных"-->
        <!--                    pagination-path=""-->
        <!--                    @vuetable:pagination-data="onPaginationData"-->
        <!--                >-->
        <!--                    <div slot="is_blocked" slot-scope="props">-->
        <!--                        <span v-if="!props.rowData.is_blocked" class="active fw-600">Активен</span>-->
        <!--                        <span v-else class="blocked fw-600">Заблокирован</span>-->
        <!--                    </div>-->
        <!--                    <div slot="actions" slot-scope="props" class="float-right">-->
        <!--                        <b-dropdown no-caret right size="sm" toggle-class="text-decoration-none" variant="link">-->
        <!--                            <template slot="button-content"><img alt="cog" src="/images/cog.svg"/></template>-->
        <!--                            <b-dropdown-item :to="{ name: 'UserEdit', params: { id: props.rowData.id } }">Редактировать</b-dropdown-item>-->
        <!--                            <b-dropdown-item :disabled="props.rowData.is_blocked" @click="blockUser(props.rowData.id)">Заблокировать</b-dropdown-item>-->
        <!--                            <b-dropdown-item :disabled="!props.rowData.is_blocked" @click="unblockUser(props.rowData.id)">Разблокировать</b-dropdown-item>-->
        <!--                            &lt;!&ndash;              <b-dropdown-item @click="deleteUser(props.rowData.id)" :disabled='!props.rowData.can_be_deleted'>Удалить</b-dropdown-item>&ndash;&gt;-->
        <!--                        </b-dropdown>-->
        <!--                    </div>-->
        <!--                </vuetable>-->
        <!--            </b-col>-->
        <!--        </b-row>-->
        <!--        <b-row>-->
        <!--            <b-col>-->
        <!--                <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage"/>-->
        <!--            </b-col>-->
        <!--        </b-row>-->
    </div>
</template>

<script>
export default {
    name: 'UsersList',
    metaInfo: {
        title: 'Пользователи организации'
    },
    data() {
        return {
            loading: true,
            pagination: {},
            fields: [
                { key: 'id', sortField: 'id', label: '#', width: '100px' },
                { key: 'full_name', label: 'ФИО пользователя' },
                { key: 'email', sortField: 'email', label: 'Адрес электронной почты' },
                { key: 'post', sortField: 'post', label: 'Должность' },
                { key: 'created_at', label: 'Дата' },
                { key: 'is_blocked', sortField: 'is_blocked', label: 'Статус' },
                { key: 'actions', label: '', width: '100px' }
            ],
            users: [],
            sortOrder: [{ field: 'id', direction: 'asc' }],
            perPage: 10,
            showBlockModal: false,
            showUnblockModal: false,
            selectedUser: null,
            data: []
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        applyFilters() {
            this.loading = true;
            this.fillData();
        },
        async fillData() {
            this.loading = true;
            const response = await this.$api.cabinet.users.fetch({ page: this.pagination.current_page || 1 });
            this.users = response.data;
            this.pagination = response.meta;
            this.loading = false;
            this.$scrollToTop();
        },

        blockUser(user) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите заблокировать пользователя "' + user.full_name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Заблокировать',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async (value) => {
                    if (value) {
                        if (await this.$api.cabinet.users.block(user.id)) {
                            await this.fillData();
                        }
                    }
                });
        },

        unblockUser(user) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите разблокировать пользователя "' + user.full_name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Разблокировать',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async (value) => {
                    if (value) {
                        if (await this.$api.cabinet.users.unblock(user.id)) {
                            await this.fillData();
                        }
                    }
                });
        }
    }
};
</script>

<style>
.vuetable-body-wrapper {
    min-height: 200px;
}

.table-atmo {
    border-collapse: collapse;
}

.table-atmo thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
    vertical-align: middle;
}

.table-atmo thead th.sortable:hover {
    color: var(--green-color) !important;
}

.table-atmo tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

.table-atmo .active {
    color: var(--green-color);
}

.table-atmo .blocked {
    color: #f6b33a;
}

@media screen and (max-width: 1200px) {
    .main_text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .column-992 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .start-992 {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .add_btn {
        margin-top: 15px !important;
    }
}
</style>
