<template>
    <div v-if="!!form.organization">
        <text-header>Регистрационные данные организации</text-header>
        <form-row-edit-text v-model="form.organization.full_name" disabled label="Полное наименование" />
        <form-row-edit-text v-model="form.organization.short_name" disabled label="Сокращенное наименование" />
        <form-row-edit-text v-model="form.organization.manager" :v="$v.form.organization.manager" label="Руководитель" />
        <form-row-edit-text v-model="form.organization.inn" disabled label="ИНН" />
        <form-row-edit-text v-model="form.organization.kpp" disabled label="КПП" />
        <form-row-edit-text v-model="form.organization.ogrn" disabled label="ОГРН/ОГРНИП" />

        <text-header v-if="isUserProvider">Признак местного товаропроизводителя</text-header>
        <b-form-group v-if="isUserProvider" label="Является местным товаропроизводителем" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
            <b-form-checkbox v-model="form.organization.is_local_producer" class="mt-2"></b-form-checkbox>
        </b-form-group>

        <text-header>Контактные данные организации</text-header>
        <form-row-edit-text v-model="form.organization.contact_name" :v="$v.form.organization.contact_name" label="ФИО контактного лица" />
        <form-row-edit-dadata-address v-model="form.organization.post_address" :v="$v.form.organization.post_address" label="Адрес почтовый" />
        <form-row-edit-dadata-address v-model="form.organization.fact_address" :v="$v.form.organization.fact_address" label="Адрес фактический" />
        <form-row-edit-email
            :email-confirmed="form.organization.email_confirmed"
            :v="$v.form.organization.email"
            :value="form.organization.email"
            label="Email"
            with-validation
            is-edit
            @input="onEmailInput"
            @value="form.organization.email = $event"
            @email-confirmed="form.organization.email_confirmed = $event" />
        <form-row-edit-phone :v="$v.form.organization.phone" :value="form.organization.phone" label="Телефон" @value="form.organization.phone = $event" />
        <form-row-edit-text v-model="form.organization.site" label="Адрес сайта" />

        <b-row class="pt-3 pl-3">
            <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <b-button class="mr-3" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
            </b-overlay>
            <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <b-button :disabled="$v.form.$invalid" variant="custom-green" class="text-uppercase" @click="sendData">Сохранить</b-button>
            </b-overlay>
        </b-row>
    </div>
</template>

<script>
import { email, helpers, minLength, required } from 'vuelidate/lib/validators';
import CabinetOrganization from '@/services/api/cabinet/CabinetOrganization';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);

export default {
    name: 'OrganizationData',
    data() {
        return {
            isDataSending: false,
            form: {
                organization: null
            },
            certificate: null
        };
    },
    validations: {
        form: {
            organization: {
                manager: { required, minLength: minLength(3) },
                phone: { required, phone },
                email: { required, email },
                email_confirmed: { checked: (value) => value === true },
                post_address: { required },
                fact_address: { required },
                contact_name: { required, minLength: minLength(3) }
            }
        }
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.form.organization = {
                ...this.$store.state.organization,
                email_confirmed: true
            };
        },
        onEmailInput() {
            this.form.organization.email_confirmed = false;
        },
        onCancel() {
            this.$router.push('/cabinet').catch(() => {});
        },
        async sendData() {
            this.isDataSending = true;
            const formData = {
                manager: this.form.organization.manager,
                fact_address: this.form.organization.fact_address,
                post_address: this.form.organization.post_address,
                email: this.form.organization.email,
                email_confirmed: this.form.organization.email_confirmed,
                phone: this.form.organization.phone,
                site: this.form.organization.site,
                contact_name: this.form.organization.contact_name
            };
            if (this.isProvider()) {
                formData.is_local_producer = this.form.organization.is_local_producer;
            }

            if (await CabinetOrganization.sendChangeRequest(formData, this.signedFormXml, this.certificate)) {
                this.onCancel();
            }
            this.isDataSending = false;
        }
    },
    computed: {
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        }
    }
};
</script>
