<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-container class="d-flex flex-column justify-content-between h-100">
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mt-3 mb-4 main_text">Информационные материалы</p>
                    </b-col>
                </b-row>
                <ul class="tabs" style="margin-top: 115px">
                    <router-link tag="li" v-for="item in informationSections" :active="isActive(item.id)" :to="'/information/' + item.id" :key="item.id">
                        {{ item.title }}
                    </router-link>
                </ul>
            </b-container>
        </b-container>
        <router-view></router-view>
    </div>
</template>

<script>
import Config from '@core/js/ddriven/application/config/Config';
import { mapGetters } from 'vuex';

export default {
    name: 'Information',
    data() {
        return {
            // loading: true,
            baseUrl: Config.get('api.config.legacy.url') + '/information_sections/download/'
            // informationSections: [],
        };
    },
    created() {
        // Information.getSectionsList()
        //   .then(informationSections => {
        //     this.informationSections = informationSections;
        //   })
        //   .catch(error => console.log(error))
        //   .finally(() => {
        //     this.loading = false;
        //   })
    },
    methods: {
        isActive(id) {
            return this.$route.params.id === id;
        }
    },
    computed: {
        ...mapGetters({
            informationSections: 'getInformation'
        })
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}
.tabs {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.tabs li {
    border: 2px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100%;
    min-height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 8px;
    text-align: center;
}
.tabs li + li {
    margin-left: 40px;
}
.router-link-active {
    color: var(--green-color) !important;
    background-color: #fff;
}

@media screen and (max-width: 568px) {
    .tabs {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 20px !important;
    }
    .tabs li {
        margin: 10px 0;
    }
    .tabs li + li {
        margin-left: 0px;
    }
}
@media screen and (max-width: 470px) {
    .main_text {
        font-size: 40px !important;
    }
}
@media screen and (max-width: 370px) {
    .main_text {
        font-size: 32px !important;
    }
}
</style>
