<template>
    <deliverables-editable-list-controller v-slot:default="dsc" v-bind:initiallist="$data.deliverables" v-bind:max_price="$props.max_price">
        <DeliverablesEditableList.view v-bind:controller="dsc" v-bind:deliverables="dsc.list" v-bind:islisteditable="$props.islisteditable" v-bind:isquantityeditable="$props.isquantityeditable" v-bind:max_price="$props.max_price">
            <template v-slot:info>
                <slot name="info"></slot>
            </template>

            <template v-slot:errors>
                <p v-if="dsc.islistempty" class="atmo-error">Заполните спецификацию</p>
                <p v-if="!dsc.islistempty && dsc.errors.is_kbk_code_empty && !$props.iskbklimitoptional" class="atmo-error">У одной или нескольких позиций спецификации не заполнено поле "КБК".</p>
                <p v-if="!dsc.islistempty && dsc.errors.is_quantity_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".</p>
                <p v-if="!dsc.islistempty && dsc.errors.is_price_per_unit_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".</p>
                <p v-if="!dsc.islistempty && dsc.errors.is_specification_total_price_invalid" class="atmo-error">Общая стоимость спецификации превышает {{ $formatPrice($props.max_price) }} руб.</p>
                <p v-if="!dsc.islistempty && dsc.errors.has_kbk_limit_violations && !$props.iskbklimitoptional" class="atmo-error">Сумма стоимостей по одной или нескольким позициям спецификации превышает доступный лимит по КБК.</p>
            </template>
        </DeliverablesEditableList.view>
    </deliverables-editable-list-controller>
</template>

<script>
import DeliverablesEditableListController from '@core/js/viewmodels/purchases/item/update/deliverables/DeliverablesEditableListController.viewmodel';
import DeliverablesEditableList from './DeliverablesEditableList.view.vue';
import OriginalSpecItemVO from '@core/js/ddriven/domain/model/common/deliverable/update/general/harness/OriginalSpecItem.valueobject';
import DeliverableItemEditableVO from '@core/js/ddriven/domain/model/common/deliverable/update/general/DeliverableItemEditable.valueobject';

/**
 * All the below non-view responsibilities here are just a temporary exception.
 * I needed the adapter between the original code and new arcitecture module. So I decided
 * to put the adapter responsibilities into the view. The original code is planned to be
 * replaced with the rearchitected one soon. Hence this temporary solution is applied.
 */
export default {
    created() {
        this.$root.$on('deliverableslist:updated', this.processDeliverablesUpdate);
    },
    props: {
        specs: { required: true, type: Array },
        islisteditable: { default: true, type: Boolean },
        isquantityeditable: { default: true, type: Boolean },
        max_price: { default: 600000.0, type: Number }
    },
    data: function () {
        return {
            deliverables: []
        };
    },
    watch: {
        specs: {
            handler: function (newSpecs, oldSpecs) {
                if (newSpecs.length < 1 || (oldSpecs && newSpecs.length === oldSpecs.length)) {
                    return;
                }
                this.$data.deliverables = newSpecs.map((spec) => {
                    return DeliverableItemEditableVO.fromPurchaseDraft(spec);
                });
            },
            immediate: true
        }
    },
    components: {
        'deliverables-editable-list-controller': DeliverablesEditableListController,
        'DeliverablesEditableList.view': DeliverablesEditableList
    },
    methods: {
        processDeliverablesUpdate: function (deliverables) {
            if (this.$props.specs.length < 1 && deliverables.length === 0) {
                return;
            }

            this.$props.specs.splice(0);
            deliverables.forEach((deliverable, index) => {
                this.$props.specs.push(OriginalSpecItemVO.fromDeliverableItem(deliverable, index + 1));
            });
        }
    }
};
</script>
