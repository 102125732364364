<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <div class="purchase_part-header">
                        <b-col>
                            <p class="purchase_part-header_number">
                                <router-link :to="{ name: 'ImportSubstitutionRequestShow', params: { id: importSubstitutionRequest.id } }" class="white-system_link-style fw-600" exact> Запрос импортозамещения № {{ importSubstitutionRequest.reg_number }} </router-link>
                            </p>
                        </b-col>
                        <b-col cols="auto">
                            <p class="purchase_part-header_label fw-600">Прием предложений завершен</p>
                        </b-col>
                    </div>
                    <div class="purchase_part-subheader">
                        <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                            <span v-if="importSubstitutionRequest.replies_count" class="fs-11 green-color">
                                Предложений: <span class="fw-600 ws-nowrap">{{ importSubstitutionRequest.replies_count }}</span>
                            </span>
                            <span v-else class="fs-11 green-color">Предложений нет</span>
                        </b-col>
                    </div>
                    <div class="purchase_part-body row">
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">Прием предложений завершен</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет запроса:</p>
                                    <p class="fs-12 green-color fw-600">
                                        {{ importSubstitutionRequest.purchase_category_name }}
                                    </p>
                                </div>
                                <div v-if="importSubstitutionRequest.organization.id" class="my-2">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <p class="fs-12 green-color fw-600">
                                        <router-link :to="{ name: 'ProviderShow', params: { id: importSubstitutionRequest.organization.id } }" class="green-link" target="_blank">
                                            {{ importSubstitutionRequest.organization.name }}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col hidden-mobile" md="4">
                            <div>
                                <div class="my-2">
                                    <!--                                    <p class="fs-12 m-0 grey-color">-->
                                    <!--                                        Количество позиций:-->
                                    <!--                                        <span class="fw-600 ws-nowrap">{{ productionRequest.items_count }}</span>-->
                                    <!--                                    </p>-->
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'ImportSubstitutionCardClosed',
    props: {
        importSubstitutionRequest: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tablet: false
        };
    },
    created() {
        if (window.innerWidth < 768) {
            this.tablet = true;
        }
    },
    computed: {
        guest() {
            return !this.$store.getters.isLoggedIn;
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
}

.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #959ba4;
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
