<template>
    <div>
        <b-row class="mb-4">
            <b-col class="d-flex align-items-center justify-content-between">
                <p class="fs-32 grey-color main_text py-0 my-auto">Лицевой счет:</p>
                <b-btn variant="new-grey" class="fs-14 total" :to="{ name: 'CabinetProviderAccountingRefundRequestsCreate' }">
                    <img src="/images/minus-white.svg" alt="minus" class="mr-2" />
                    Запрос на возврат средств
                </b-btn>
                <b-btn variant="new-green" class="fs-14" @click="showDepositModal">
                    <img src="/images/plus-white.svg" alt="plus" class="mr-2" />
                    Пополнение счета
                </b-btn>
                <b-overlay :show="isExportLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-btn variant="new-yellow" class="fs-14" @click="downloadAccountingXLS">
                        <img src="/images/xlsx-white.svg" alt="xls" class="mr-2" />
                        Выгрузка в .XLSX
                    </b-btn>
                </b-overlay>
            </b-col>
        </b-row>

        <accounting-statistics></accounting-statistics>
        <accounting-details></accounting-details>

        <!--DEPOSIT MODAL WINDOW -->
        <b-modal hide-footer centered hide-header ref="attention-modal" id="attention-modal" size="lg" v-model="isDepositModalVisible">
            <div class="d-block">
                <div class="w-100 fs-24 fw-600 green-color text-left">Внимание!</div>
                <p class="grey-color fs-14 mt-2">Пополнение ЛС в ИС с целью обеспечения возможности участия в закупках.</p>
                <form-row-edit-price label="Сумма пополнения" v-model="replenishmentForm.neededDeposit" :v="$v.replenishmentForm.neededDeposit" />
                <form-row-edit-email label="Email" :value="replenishmentForm.email" @value="replenishmentForm.email = $event" :v="$v.replenishmentForm.email" />
                <b-row class="mt-5">
                    <b-col>
                        <b-button variant="custom-green" class="text-uppercase" @click="sendInvoiceToEmail" :disabled="$v.replenishmentForm.$invalid">ОТПРАВИТЬ НА E-MAIL </b-button>
                        <b-button variant="custom-outline-secondary" class="text-uppercase ml-2" @click="downloadInvoice" :disabled="$v.replenishmentForm.$invalid">СКАЧАТЬ PDF </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
import DownloadFilesApi from '@/services/api/DownloadFiles';
import AccountingStatistics from '@/components/cabinets/provider/accounting/accounting-statistics';
import AccountingDetails from '@/components/cabinets/provider/accounting/accounting-details';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import { required, email, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import FileDownload from 'js-file-download';
import Accounting from '@/services/api/cabinet/provider/Accounting';

export default {
    name: 'CabinetProviderAccount',
    components: {
        FormRowEditEmail,
        AccountingDetails,
        AccountingStatistics
    },
    data() {
        return {
            isDepositModalVisible: false,
            replenishmentForm: {
                neededDeposit: 0,
                email: ''
            },
            isExportLoading: false,
            account: null
        };
    },
    validations: {
        replenishmentForm: {
            neededDeposit: { required, minValue: minValue(0.01) },
            email: { required, email }
        }
    },
    methods: {
        async showDepositModal() {
            this.replenishmentForm.email = this.getUser.email;
            this.isDepositModalVisible = true;
        },
        //deposit
        downloadInvoice() {
            DownloadFilesApi.downloadInvoice('/account/invoice/download', 'Invoice.pdf', { amount: this.replenishmentForm.neededDeposit });
        },
        sendInvoiceToEmail() {
            const url = '/account/mail/sending';
            const data = { email: this.replenishmentForm.email, amount: this.replenishmentForm.neededDeposit };
            DownloadFilesApi.sendFileToEmail(url, data).then(() => {
                this.pushToast({
                    text: 'Счет отправлен на Ваш email',
                    title: 'Успех',
                    variant: 'green'
                });
            });
        },
        downloadAccountingXLS() {
            console.log('Download Accounting XLS');
            this.isExportLoading = true;
            Accounting.getAccountingXlsExport().then((response) => {
                FileDownload(response.data, 'Операции по ЛС.xlsx');
                this.isExportLoading = false;
            });
        }
    },
    computed: {
        ...mapGetters(['getUser'])
    }
};
</script>

<style scoped>
.account_block div + div {
    border-left: 1px solid #959ba450;
}
@media screen and (max-width: 992px) {
    .account_block div + div {
        border-top: 1px solid var(--grey-color);
        border-left: none;
    }
}
</style>

<style>
.cTable {
    border-collapse: collapse;
}

.cTable thead th {
    background: #e4e6e8 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 11px 24px !important;
    color: var(--grey-color) !important;
}

.cTable tbody td {
    font-size: 12px !important;
    padding: 11px 24px !important;
    border-bottom: 1px solid #dee2e6 !important;
    color: var(--grey-color) !important;
}
.cTable tbody tr {
    outline: none;
}
.cTable tbody tr:hover {
    cursor: pointer;
    background: rgba(228, 230, 232, 0.5);
}
.cTable tbody .b-table-empty-row:hover {
    cursor: default;
    background: transparent;
}
#attention-modal .modal-body {
    padding: 28px 40px;
}
#filter-modal .modal-body {
    padding: 28px 40px;
}
.available {
    background-color: var(--green-color);
}
.total {
    background-color: #959ba4;
}
.blocked {
    background-color: #f6b33a;
}
</style>
