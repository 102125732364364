<template>
    <b-container fluid class="show_bg py-5">
        <b-container v-for="purchase in purchases" :key="purchase.id" v-bind="purchase">
            <b-row>
                <b-col>
                    <div class="purchase_part">
                        <div class="purchase_part-header">
                            <p class="purchase_part-header_number">№ {{ purchase.reg_number }}</p>
                            <p class="purchase_part-header_label">
                                <img class="mr-1" src="/images/check.svg" alt="check_label" />
                                {{ purchase.status }}
                            </p>
                        </div>
                        <div class="purchase_part-body row">
                            <b-col class="purchase_part-body_col">
                                <div>
                                    <p class="fs-12 m-0">До окончания подачи предложений</p>
                                    <p class="fs-18 m-0 fw-600 mb-5">
                                        {{ purchase.purchase_end }}
                                    </p>
                                    <p class="fs-12">Статус закупки:</p>
                                    <p class="fs-12 fw-600">{{ purchase.status }}</p>
                                </div>
                            </b-col>
                            <b-col class="purchase_part-body_col">
                                <div>
                                    <p class="fs-12 fw-600 m-0">Предмет закупки:</p>
                                    <p class="fs-12 mb-3">
                                        {{ purchase.purchase_name }}
                                    </p>
                                    <p class="fs-12 m-0">Заказчик:</p>
                                    <p class="green-color text-uppercase fs-14">
                                        {{ purchase.organization }}
                                    </p>
                                </div>
                            </b-col>
                            <b-col class="purchase_part-body_col">
                                <div>
                                    <p class="fs-12 m-0" v-if="!!purchase.planned_end_date">
                                        Планируемая дата исполнения:
                                        <span class="fw-600">{{ purchase.planned_end_date }}</span>
                                    </p>
                                    <p class="fs-12 m-0">
                                        Количество позиций:
                                        <span class="fw-600">{{ purchase.ordersize }}</span>
                                    </p>
                                    <p class="fs-12 mb-3">
                                        Начальная максимальная цена контракта, руб.:
                                        <span class="fw-600">{{ $formatPrice(purchase.start_price) }}</span>
                                    </p>
                                    <p class="fs-12 m-0" v-if="purchase.delivery && purchase.delivery.length">Место доставки или указание на самовывоз:</p>
                                    <p class="fs-12 m-0 fw-600" v-for="deliver in purchase.delivery" :key="deliver.id" v-bind="deliver">
                                        {{ deliver.address }}
                                    </p>
                                </div>
                            </b-col>
                            <b-col class="purchase_part-body_col">
                                <div>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600">
                                        <img class="mr-1" src="/images/info.svg" alt="check_label" />
                                        Информация о закупке
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600">
                                        <img class="mr-1" src="/images/chart.svg" alt="check_label" />
                                        Динамика предложений
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600">
                                        <img class="mr-1" src="/images/coins.svg" alt="check_label" />
                                        {{ $formatPrice(purchase.start_price) }}
                                    </p>
                                </div>
                            </b-col>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import axios from 'axios';

export default {
    props: ['filters', 'purchases'],
    data() {
        return {
            current_route: null
        };
    },
    created() {
        this.current_route = this.$route.params.path;
    },
    watch: {
        $route: function (to) {
            let route = to.params.path;
            this.current_route = route;
            axios('/purchases', {
                params: { status: this.current_route }
            })
                .then((res) => {
                    this.purchases = res.data.data;
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                })
                .finally((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        }
    }
};
</script>

<style scoped>
.show_bg {
    background-color: #f5f5f7;
}

.purchase_part {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 12px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 24px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
</style>
