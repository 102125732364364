<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <subheader-customer-cabinet :purchase="purchase"></subheader-customer-cabinet>
                    <div class="purchase_part-body row">
                        <b-col md="4" class="purchase_part-body_col">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color fw-600">Мое ценовое предложение</p>
                                </div>
                                <p v-if="purchase.myOffer && purchase.is_hidden === false && purchase.myOffer.offer_status_id !== OFFERS_STATUS.status_declined" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/grey_medal.svg" alt="check_label" />
                                    </span>
                                    <span class="fs-12 grey-color">{{ purchase.myOffer.position }}</span>
                                </p>
                                <p v-if="purchase.myOffer" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/wallet.svg" alt="check_label" />
                                    </span>
                                    <span class="fs-12 grey-color">{{ $formatPrice(purchase.myOffer.total_cost) }} руб.</span>
                                </p>
                                <p v-if="purchase.myOffer" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                    <span style="width: 30px">
                                        <img src="/images/grey_secundomer.svg" alt="check_label" />
                                    </span>
                                    <span class="fs-12 grey-color">{{ getDateTimeFormat(purchase.myOffer.created_at) }}</span>
                                </p>
                            </div>
                        </b-col>
                        <b-col md="4" class="purchase_part-body_col">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                                    <p class="fs-12 green-color fw-600">{{ purchase.purchase_name }}</p>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <router-link class="system_link-style fw-600 text-uppercase fs-12" :to="'/clients/customers/' + purchase.org_id">
                                        {{ purchase.organization }}
                                    </router-link>
                                </div>
                                <div class="my-2" v-if="purchase.national_project_name">
                                    <p class="fs-12 m-0 grey-color">Национальный проект:</p>
                                    <p class="fs-12 m-0 grey-color fw-600">
                                        <span class="fw-600">{{ purchase.national_project_name }}</span>
                                    </p>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">
                                        Начальная максимальная цена контракта:
                                        <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.start_price) }} руб.</span>
                                    </p>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="4" class="purchase_part-body_col">
                            <div class="h-100 d-flex flex-column justify-content-between">
                                <div>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img src="/images/search.svg" alt="check_label" />
                                        </span>

                                        <!-- Rearchitected. Clean up later. -->
                                        <!-- <router-link class="system_link-style" :to="{ name: 'OrderInfo', params: { order: purchase.id } }" exact> Информация о закупке </router-link> -->
                                        <router-link class="system_link-style" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id } }" exact>Информация о закупке</router-link>
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img src="/images/dynamic.svg" alt="check_label" />
                                        </span>

                                        <!-- Rearchitected. Clean up later. -->
                                        <!-- <router-link class="system_link-style" :to="{ name: 'ProposalDynamics', params: { order: purchase.id } }" exact> Динамика предложений </router-link> -->
                                        <router-link class="system_link-style" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-proposals' }" exact>Динамика предложений</router-link>
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2" v-if="purchase && purchase.organizationFinal">
                                        <span style="width: 30px">
                                            <img src="/images/small_helmet.svg" alt="check_label" />
                                        </span>

                                        <!-- Rearchitected. Clean up later. -->
                                        <!-- <router-link class="system_link-style" :to="{ name: 'FinalProtocol', params: { order: purchase.id } }" exact> Итоговый протокол </router-link> -->
                                        <router-link class="system_link-style" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-proposals' }" exact>Итоговый протокол</router-link>
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2" v-if="purchase && purchase.contract">
                                        <span style="width: 30px">
                                            <img src="/images/contract.svg" alt="check_label" />
                                        </span>

                                        <!-- Rearchitected. Clean up later. -->
                                        <!-- <router-link class="system_link-style" :to="{ name: 'Contract', params: { order: purchase.id } }" exact> Договор </router-link> -->
                                        <router-link class="system_link-style" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract' }" exact>Договор</router-link>
                                    </p>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2" v-if="purchase && purchase.contract && purchase.contract.act">
                                        <span style="width: 30px">
                                            <img src="/images/file_small.svg" alt="check_label" />
                                        </span>

                                        <!-- Rearchitected. Clean up later. -->
                                        <!-- <router-link class="system_link-style" :to="{ name: 'ContractExecution', params: { order: purchase.id } }" exact>Исполнение договора</router-link> -->
                                        <router-link class="system_link-style" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract-fulfillment' }" exact>Исполнение договора</router-link>
                                    </p>
                                </div>
                                <div>
                                    <b-btn
                                        class="btn-card-action btn-card-action-green"
                                        @click="checkProvider(purchase.id)"
                                        :disabled="checkingProvider"
                                        v-if="$store.getters.isLoggedIn && $store.getters.isProvider && (purchase.status_id === PURCHASE_STATUS.status_start || purchase.is_hidden === true) && isBeforeNow(purchase.purchase_end)">
                                        Изменить предложение
                                    </b-btn>
                                </div>
                                <purchase-supporting-buttons :purchase="purchase" />
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import SubheaderCustomerCabinet from '@/components/purchases/PurchaseStatusCard/parts/SubheaderCustomerCabinet';
import { __OFFERS_STATUS, __PURCHASE_STATUS } from '@/const.js';
import PurchaseSupportingButtons from '@/components/elements/PurchaseSupportingButtons';

export default {
    components: { PurchaseSupportingButtons, SubheaderCustomerCabinet },
    props: ['purchase', 'checkingProvider'],
    name: 'PurchaseProviderCabinet',
    data() {
        return {
            OFFERS_STATUS: {},
            PURCHASE_STATUS: {}
        };
    },
    created() {
        this.OFFERS_STATUS = __OFFERS_STATUS;
        this.PURCHASE_STATUS = __PURCHASE_STATUS;
    },
    methods: {
        isBeforeNow(date) {
            let purchaseEnd = date.split(' ').join('T') + '+0500';
            let today = new Date().getTime();
            let endDate = new Date(purchaseEnd).getTime();
            return endDate > today;
        },
        checkProvider(id) {
            this.$emit('checkProvider', id);
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-body_col + .purchase_part-body_col::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

.purchase_part-body span {
    text-transform: none;
}
</style>
