var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "show_bg py-5", attrs: { fluid: "" } },
    _vm._l(_vm.purchases, function (purchase) {
      return _c(
        "b-container",
        _vm._b({ key: purchase.id }, "b-container", purchase, false),
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", { staticClass: "purchase_part" }, [
                  _c("div", { staticClass: "purchase_part-header" }, [
                    _c("p", { staticClass: "purchase_part-header_number" }, [
                      _vm._v("№ " + _vm._s(purchase.reg_number)),
                    ]),
                    _c("p", { staticClass: "purchase_part-header_label" }, [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/check.svg", alt: "check_label" },
                      }),
                      _vm._v(" " + _vm._s(purchase.status) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "purchase_part-body row" },
                    [
                      _c("b-col", { staticClass: "purchase_part-body_col" }, [
                        _c("div", [
                          _c("p", { staticClass: "fs-12 m-0" }, [
                            _vm._v("До окончания подачи предложений"),
                          ]),
                          _c("p", { staticClass: "fs-18 m-0 fw-600 mb-5" }, [
                            _vm._v(" " + _vm._s(purchase.purchase_end) + " "),
                          ]),
                          _c("p", { staticClass: "fs-12" }, [
                            _vm._v("Статус закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 fw-600" }, [
                            _vm._v(_vm._s(purchase.status)),
                          ]),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "purchase_part-body_col" }, [
                        _c("div", [
                          _c("p", { staticClass: "fs-12 fw-600 m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 mb-3" }, [
                            _vm._v(" " + _vm._s(purchase.purchase_name) + " "),
                          ]),
                          _c("p", { staticClass: "fs-12 m-0" }, [
                            _vm._v("Заказчик:"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "green-color text-uppercase fs-14" },
                            [_vm._v(" " + _vm._s(purchase.organization) + " ")]
                          ),
                        ]),
                      ]),
                      _c("b-col", { staticClass: "purchase_part-body_col" }, [
                        _c(
                          "div",
                          [
                            !!purchase.planned_end_date
                              ? _c("p", { staticClass: "fs-12 m-0" }, [
                                  _vm._v(" Планируемая дата исполнения: "),
                                  _c("span", { staticClass: "fw-600" }, [
                                    _vm._v(_vm._s(purchase.planned_end_date)),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("p", { staticClass: "fs-12 m-0" }, [
                              _vm._v(" Количество позиций: "),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(_vm._s(purchase.ordersize)),
                              ]),
                            ]),
                            _c("p", { staticClass: "fs-12 mb-3" }, [
                              _vm._v(
                                " Начальная максимальная цена контракта, руб.: "
                              ),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(
                                  _vm._s(_vm.$formatPrice(purchase.start_price))
                                ),
                              ]),
                            ]),
                            purchase.delivery && purchase.delivery.length
                              ? _c("p", { staticClass: "fs-12 m-0" }, [
                                  _vm._v(
                                    "Место доставки или указание на самовывоз:"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._l(purchase.delivery, function (deliver) {
                              return _c(
                                "p",
                                _vm._b(
                                  {
                                    key: deliver.id,
                                    staticClass: "fs-12 m-0 fw-600",
                                  },
                                  "p",
                                  deliver,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(deliver.address) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("b-col", { staticClass: "purchase_part-body_col" }, [
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/info.svg",
                                  alt: "check_label",
                                },
                              }),
                              _vm._v(" Информация о закупке "),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/chart.svg",
                                  alt: "check_label",
                                },
                              }),
                              _vm._v(" Динамика предложений "),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600",
                            },
                            [
                              _c("img", {
                                staticClass: "mr-1",
                                attrs: {
                                  src: "/images/coins.svg",
                                  alt: "check_label",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatPrice(purchase.start_price)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }