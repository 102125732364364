<template>
    <div>
        <system-preloader v-if="loading"></system-preloader>
        <b-row class="mb-4">
            <b-col class="d-flex justify-content-between">
                <p class="fs-32 grey-color main_text" style="line-height: 1">Коммерческие предложения</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <ul class="tabs mb-3 float-right">
                    <li v-for="(tab, index) in tabs" :key="'key_' + index">
                        <router-link :to="{ name: 'ProviderQuotationsList', params: { cat: tab.path } }" exact>{{ tab.title }}</router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>
        <div v-if="!loading">
            <div v-for="quotation in quotations" :key="quotation.id">
                <quotation-card-open :quotation="quotation" v-if="quotation.status_id === 1"> </quotation-card-open>
                <quotation-card-closed :quotation="quotation" v-if="quotation.status_id === 2"></quotation-card-closed>
            </div>
            <b-pagination align="center" v-if="quotations && quotations.length" @input="fetchData" class="mb-3" v-model="page" :total-rows="pagination.total" :per-page="pagination.per_page"> </b-pagination>
        </div>
    </div>
</template>

<script>
import Quotations from '@/services/api/cabinet/provider/Quotations';
import QuotationCardOpen from '@/components/quotations/CardOpen';
import QuotationCardClosed from '@/components/quotations/CardClosed';

export default {
    name: 'ProviderQuotationsList',
    components: {
        QuotationCardOpen,
        QuotationCardClosed
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Все',
                    path: 'all'
                },
                {
                    title: 'Активные',
                    path: 'active'
                },
                {
                    title: 'Завершенные',
                    path: 'finished'
                }
            ],
            page: 1,
            quotations: [],
            loading: true,
            sortOrder: [{ field: 'created_at', direction: 'asc' }],
            pagination: {
                current_page: 1,
                per_page: 5,
                total: 0
            }
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;
            await Quotations.getQuotations(this.$route.params.cat, this.page)
                .then((res) => {
                    this.pagination = res.data;
                    this.quotations = res.data.data;
                    delete this.pagination.data;
                    this.loading = false;
                    this.$scrollToTop();
                })
                .catch((error) => console.log(error));
        },
        async routeFetchData() {
            this.page = 1;
            await this.fetchData();
        }
    },
    watch: {
        $route: 'routeFetchData'
    }
};
</script>

<style scoped>
.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.tabs li .router-link-active {
    color: var(--green-color) !important;
    border-bottom: 2px solid var(--green-color) !important;
}

@media screen and (max-width: 1200px) {
    .main_text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .column-992 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
</style>
