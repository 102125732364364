var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color main_text",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Коммерческие предложения")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "ul",
              { staticClass: "tabs mb-3 float-right" },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "li",
                  { key: "key_" + index },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "ProviderQuotationsList",
                            params: { cat: tab.path },
                          },
                          exact: "",
                        },
                      },
                      [_vm._v(_vm._s(tab.title))]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      !_vm.loading
        ? _c(
            "div",
            [
              _vm._l(_vm.quotations, function (quotation) {
                return _c(
                  "div",
                  { key: quotation.id },
                  [
                    quotation.status_id === 1
                      ? _c("quotation-card-open", {
                          attrs: { quotation: quotation },
                        })
                      : _vm._e(),
                    quotation.status_id === 2
                      ? _c("quotation-card-closed", {
                          attrs: { quotation: quotation },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.quotations && _vm.quotations.length
                ? _c("b-pagination", {
                    staticClass: "mb-3",
                    attrs: {
                      align: "center",
                      "total-rows": _vm.pagination.total,
                      "per-page": _vm.pagination.per_page,
                    },
                    on: { input: _vm.fetchData },
                    model: {
                      value: _vm.page,
                      callback: function ($$v) {
                        _vm.page = $$v
                      },
                      expression: "page",
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }