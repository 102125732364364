var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "new_quotation" },
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-5", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-52 fw-600 white-color mb-4" },
                          [
                            _vm._v(
                              "Подача коммерческого предложения на запрос КП № " +
                                _vm._s(_vm.quotationRequest.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _vm._v(" "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("p", { staticClass: "block_head-text" }, [
                            _vm._v("Информация о заказчике"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.quotationRequest.organization.full_name)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("ИНН:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.quotationRequest.organization.inn))]
                      ),
                    ],
                    1
                  ),
                  _vm.quotationRequest.organization.kpp
                    ? _c(
                        "b-row",
                        {
                          staticClass: "border-bottom py-3",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "3" },
                            },
                            [_vm._v("КПП:")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.quotationRequest.organization.kpp)
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.quotationRequest.organization.post_address
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес электронной почты:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:" +
                                  _vm.quotationRequest.organization.email,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.quotationRequest.organization.email)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Номер контактного телефона:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "tel:+" +
                                  _vm.quotationRequest.organization.phone,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.quotationRequest.organization.phone)
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о поставщике"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Полное наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$store.state.user.organization.full_name)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Сокращенное наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.user.organization.short_name
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.user.organization.post_address
                            ) +
                              " " +
                              _vm._s(
                                _vm.$store.state.user.organization.fact_address
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("ОГРН")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$store.state.user.organization.ogrn)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("ИНН")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.$store.state.user.organization.inn))]
                      ),
                    ],
                    1
                  ),
                  _vm.$store.state.user.organization.kpp
                    ? _c(
                        "b-row",
                        { staticClass: "py-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "3" },
                            },
                            [_vm._v("КПП")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$store.state.user.organization.kpp)
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о запросе КП"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Рег.номер запроса КП:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.quotationRequest.reg_number))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Предмет закупки:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.quotationRequest.name))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Срок сбора КП:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getDateTimeFormat(
                                _vm.quotationRequest.deadline
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Документ:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "system_link-style",
                              attrs: {
                                href:
                                  _vm.fileUrl +
                                  _vm.quotationRequest.document_path,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.quotationRequest.document_name))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о коммерческом предложении"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "fs-14 grey-color mt-2",
                          attrs: { md: "3" },
                        },
                        [_vm._v("Срок действия коммерческого предложения:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c("date-time-picker", {
                            class: _vm.$v.form.deadline.$invalid
                              ? "is-invalid"
                              : "",
                            attrs: {
                              id: "purchase_planned_contract_date",
                              label: "Укажите срок действия КП",
                              "only-date": true,
                              formatted: "DD.MM.YYYY",
                              format: "YYYY-MM-DD",
                              buttonNowTranslation: "Сегодня",
                              autoClose: "",
                              "min-date": new Date().toJSON().slice(0, 10),
                            },
                            model: {
                              value: _vm.form.deadline,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deadline", $$v)
                              },
                              expression: "form.deadline",
                            },
                          }),
                          _vm.$v.form.deadline.$invalid
                            ? _c(
                                "b-form-invalid-feedback",
                                { staticStyle: { display: "block" } },
                                [_vm._v("Поле не заполнено ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("form-row-specification-table", {
                    attrs: {
                      v: _vm.$v.form.specs,
                      "no-add-spec": "",
                      "no-delete-spec": "",
                      "with-price": "",
                      "spec-sum": _vm.specSum,
                      "spec-errors": _vm.specErrors,
                      "spec-error-message": _vm.specErrorMessage,
                    },
                    model: {
                      value: _vm.form.specs,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "specs", $$v)
                      },
                      expression: "form.specs",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Документы"),
                          ]),
                          !_vm.form.document
                            ? _c(
                                "file-uploader",
                                {
                                  attrs: { "is-main": true },
                                  on: { set_file: _vm.addFile },
                                },
                                [_vm._v("Добавить документ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pb-4" },
                    [
                      _c("b-col", [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Наименование")]),
                              _c("th"),
                            ]),
                          ]),
                          _vm.form.document
                            ? _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "fs-14",
                                          staticStyle: {
                                            "padding-top": "0",
                                            "padding-bottom": "0",
                                            color:
                                              "var(--green-color) !important",
                                          },
                                          attrs: {
                                            variant: "link",
                                            size: "xs",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$commonDocumentDownload(
                                                _vm.form.document
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.document.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { attrs: { width: "1" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            background: "transparent",
                                          },
                                          attrs: {
                                            variant: "light",
                                            size: "sm",
                                          },
                                          on: { click: _vm.removeFile },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/images/trash_icon.svg",
                                              alt: "trash_icon",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      attrs: { colspan: "3", align: "center" },
                                    },
                                    [_vm._v("Документ не добавлен.")]
                                  ),
                                ]),
                              ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-3",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelQuotation },
                            },
                            [_vm._v("ОТМЕНИТЬ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "custom-green",
                                disabled: !_vm.isValid,
                              },
                              on: { click: _vm.prepareForm },
                            },
                            [_vm._v("ПОДАТЬ ПРЕДЛОЖЕНИЕ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                title: "Подписать предложение",
                size: "lg",
                centered: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { close: _vm.cancelSignModal },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "border-bottom py-3 d-flex" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-50 fs-14 grey-color" },
                                  [_vm._v("Рег. номер запроса КП:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-50 fs-14 fw-600 grey-color",
                                  },
                                  [
                                    _vm._v(
                                      "№ " +
                                        _vm._s(_vm.quotationRequest.reg_number)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "border-bottom py-3 d-flex" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-50 fs-14 grey-color" },
                                  [_vm._v("Общая стоимость предложения:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-50 fs-14 fw-600 grey-color",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$formatPrice(_vm.specSum)) +
                                        " руб."
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Xml представление")]
                            ),
                          ]),
                          _c("b-form-textarea", {
                            attrs: { disabled: "", rows: "20" },
                            model: {
                              value: _vm.formXml,
                              callback: function ($$v) {
                                _vm.formXml = $$v
                              },
                              expression: "formXml",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "py-3 d-flex" }, [
                    _c("div", { staticClass: "w-50 fs-14 grey-color" }, [
                      _vm._v("Сертификат"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "w-50 fs-14 fw-600 grey-color" },
                      [
                        _c("b-input", {
                          attrs: {
                            disabled: "",
                            value: _vm.currentCertSubject,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("ОТМЕНИТЬ")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            variant: "custom-green",
                            disabled: _vm.certIndex == null,
                          },
                          on: { click: _vm.signForm },
                        },
                        [_vm._v("ПОДПИСАТЬ")]
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            variant: "custom-green",
                            disabled: _vm.certIndex == null,
                          },
                          on: { click: _vm.sendData },
                        },
                        [_vm._v("ОТПРАВИТЬ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }