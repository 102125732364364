var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color mb-4 main_text",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Избранное (Заказчики)")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm._l(_vm.customers, function (customer) {
            return _c(
              "b-col",
              _vm._b(
                {
                  key: customer.id,
                  staticClass: "mb-3",
                  attrs: { cols: "12" },
                },
                "b-col",
                customer,
                false
              ),
              [
                _c(
                  "div",
                  { staticClass: "purchase_part" },
                  [
                    _c(
                      "div",
                      { staticClass: "purchase_part-header" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "purchase_part-header_number text-uppercase white-system_link-style",
                            attrs: { to: "/clients/customers/" + customer.id },
                          },
                          [_vm._v(" Заказчик ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "purchase_part-body" },
                      [
                        _c("b-col", { staticClass: "purchase_part-body_col" }, [
                          _c("div", { staticClass: "my-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-12 fw-600 m-0 text-uppercase grey-color",
                              },
                              [
                                _vm._v(" Заказчик: "),
                                _c(
                                  "span",
                                  { staticClass: "fw-400 grey-color" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(customer.full_name) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "my-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-12 fw-600 m-0 text-uppercase grey-color",
                              },
                              [
                                _vm._v(" ИНН: "),
                                _c(
                                  "span",
                                  { staticClass: "fw-400 grey-color" },
                                  [_vm._v(" " + _vm._s(customer.inn) + " ")]
                                ),
                              ]
                            ),
                          ]),
                          customer.kpp
                            ? _c("div", { staticClass: "my-2" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-12 fw-600 m-0 text-uppercase grey-color",
                                  },
                                  [
                                    _vm._v(" КПП: "),
                                    _c(
                                      "span",
                                      { staticClass: "fw-400 grey-color" },
                                      [_vm._v(" " + _vm._s(customer.kpp) + " ")]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "my-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-12 fw-600 m-0 text-uppercase grey-color",
                              },
                              [
                                _vm._v(" Почтовый адрес: "),
                                _c(
                                  "span",
                                  { staticClass: "fw-400 grey-color" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(customer.post_address) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "b-col",
                          {
                            staticClass: "d-flex align-items-center",
                            attrs: { cols: "auto" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "d-flex align-items-center",
                                    attrs: { variant: "custom-green" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFavorite(customer)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-1",
                                      attrs: {
                                        src: "/images/full_star.svg",
                                        alt: "empty_star",
                                      },
                                    }),
                                    _vm._v(" УБРАТЬ ИЗ ИЗБРАННОГО "),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          !_vm.customers.length && !_vm.loading
            ? _c("b-col", [_c("empty-request")], 1)
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }