var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                {
                  staticClass: "atmo-override-color purchase_part-header",
                  class: _vm.cardCssClass,
                },
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_number" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "white-system_link-style fw-600",
                            attrs: {
                              to: {
                                name: "OrderInfo",
                                params: { order: _vm.purchase.id },
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "ДОГОВОР № " +
                                _vm._s(_vm.purchase.reg_number) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [
                        _vm._v(" " + _vm._s(_vm.purchase.status_name) + " "),
                        _vm.purchase.status_id === 6 ||
                        _vm.purchase.status_id === 5 ||
                        _vm.purchase.status_id === 10
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_times.svg",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 4
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: { src: "/images/file_check.svg", alt: "" },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 3
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_contract.svg",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-subheader" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { xs: "12", sm: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("НМЦК: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(
                            _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) +
                              " руб."
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { xs: "12", sm: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("Предложений: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.purchase.count_offers)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { xs: "12", sm: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("Лучшее: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(
                            _vm._s(
                              _vm.purchase.min_offer
                                ? _vm.$formatPrice(_vm.purchase.min_offer) +
                                    " руб."
                                : "-"
                            ) + " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { xs: "12", sm: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "fs-11 green-color" }, [
                        _vm._v("Снижение: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(
                            _vm._s(_vm.purchase.discount_percentage) + " %"
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "3" },
                    },
                    [
                      _c("div", [
                        _vm.purchase.status_id === 3 ||
                        _vm.purchase.status_id === 4 ||
                        _vm.purchase.status_id === 5 ||
                        _vm.purchase.status_id === 6
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Договор заключен:"),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-12 m-0 grey-color fw-600 time_to_end",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateTimeFormat(
                                          _vm.purchase.contract_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "3" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 grey-color fw-600" }, [
                            _vm._v(
                              " " + _vm._s(_vm.purchase.purchase_name) + " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2 d-flex flex-column" },
                          [
                            _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                              _vm._v("Заказчик:"),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style fw-600 text-uppercase fs-12",
                                attrs: {
                                  to:
                                    "/clients/customers/" +
                                    _vm.purchase.customer_id,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.purchase.customer_name) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "my-2 d-flex flex-column" },
                          [
                            _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                              _vm._v("Поставщик:"),
                            ]),
                            _vm.purchase.provider_id
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "system_link-style fw-600 text-uppercase fs-12",
                                    attrs: {
                                      to:
                                        "/clients/providers/" +
                                        _vm.purchase.provider_id,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.purchase.provider_name) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col hidden-mobile",
                      attrs: { md: "3" },
                    },
                    [
                      _c("div", [
                        _vm.purchase.national_project_name
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Национальный проект:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 m-0 grey-color fw-600" },
                                [
                                  _c("span", { staticClass: "fw-600" }, [
                                    _vm._v(
                                      _vm._s(_vm.purchase.national_project_name)
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        !!_vm.purchase.planned_end_date
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Планируемая дата исполнения:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateFormat(
                                          _vm.purchase.planned_end_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v(" Количество позиций: "),
                            _c("span", { staticClass: "fw-600" }, [
                              _vm._v(_vm._s(_vm.purchase.items_count)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "my-2" }, [
                          _vm.purchase.contract_price
                            ? _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(" Цена договора: "),
                                _c("span", { staticClass: "fw-600" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatPrice(
                                        _vm.purchase.contract_price
                                      )
                                    ) + " руб."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.purchase.delivery && _vm.purchase.delivery.length
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 m-0 grey-color" },
                                  [
                                    _vm._v(
                                      "Место доставки или указание на самовывоз:"
                                    ),
                                  ]
                                ),
                                _vm._l(
                                  _vm.purchase.delivery,
                                  function (deliver) {
                                    return _c(
                                      "p",
                                      _vm._b(
                                        {
                                          key: deliver.id,
                                          staticClass:
                                            "fs-12 m-0 grey-color fw-600",
                                        },
                                        "p",
                                        deliver,
                                        false
                                      ),
                                      [
                                        _vm._v(
                                          " " + _vm._s(deliver.address) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "3" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/search.svg",
                                    alt: "search",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "OrderInfo",
                                      params: { order: _vm.purchase.id },
                                    },
                                    exact: "",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Информация о закупке")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/dynamic.svg",
                                    alt: "check_label",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "ProposalDynamics",
                                      params: { order: _vm.purchase.id },
                                    },
                                    exact: "",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Динамика предложений")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "d-flex align-items-center green-color fs-12 fw-600 my-2",
                            },
                            [
                              _c("span", { staticStyle: { width: "30px" } }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/small_helmet.svg",
                                    alt: "check_label",
                                  },
                                }),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "system_link-style",
                                  attrs: {
                                    to: {
                                      name: "FinalProtocol",
                                      params: { order: _vm.purchase.id },
                                    },
                                    exact: "",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Итоговый протокол")]
                              ),
                            ],
                            1
                          ),
                          _vm.purchase && _vm.purchase.contract
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/contract.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        to: {
                                          name: "Contract",
                                          params: { order: _vm.purchase.id },
                                        },
                                        exact: "",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Договор")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.purchase &&
                          _vm.purchase.contract &&
                          [3, 4, 5, 6].includes(_vm.purchase.status_id)
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/file_small.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        to: {
                                          name: "PurchaseExecutionDocuments",
                                          params: { order: _vm.purchase.id },
                                        },
                                        exact: "",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Исполнение договора")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("purchase-supporting-buttons", {
                            attrs: { purchase: _vm.purchase },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }