var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canBeCanceled && !_vm.purchase.change_length
    ? _c(
        "div",
        [
          _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-danger my-1",
              on: { click: _vm.cancelClick },
            },
            [
              _vm._v("Снять с публикации"),
              _vm.purchase.can_be_canceled_till
                ? [
                    _c("br"),
                    _vm._v(
                      "(До " +
                        _vm._s(
                          _vm.getDateTimeFormat(
                            _vm.purchase.can_be_canceled_till
                          )
                        ) +
                        ")"
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("cancel-purchase-publishing-modal", {
            attrs: { purchase: _vm.purchase, show: _vm.showModal },
            on: { cancel: _vm.cancelModal, ok: _vm.cancelPurchasePublishing },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }