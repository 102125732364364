var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-responsive py-3" },
    [
      _c("b-table", {
        staticClass: "cTable",
        attrs: {
          busy: _vm.loading,
          fields: _vm.fields,
          items: _vm.history,
          "per-page": _vm.pagination.per_page,
          "empty-text": "История пуста",
          "show-empty": "",
        },
        scopedSlots: _vm._u([
          {
            key: "table-busy",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "text-center my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle mr-1" }),
                    _c("strong", [_vm._v("Загрузка данных...")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(created_at)",
            fn: function (row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$formatDateTime(row.item.created_at)) + " "
                ),
              ]
            },
          },
          {
            key: "cell(operation_type_id)",
            fn: function (row) {
              return [
                _c("strong", [
                  _vm._v(_vm._s(row.item.operation_type_name) + " "),
                ]),
                row.item.accountable_type === "purchase"
                  ? [
                      _vm._v(" (Извещение № "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "purchases.grouped.item.view",
                              params: {
                                group: "fl44",
                                id: row.item.accountable.id,
                              },
                            },
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(row.item.accountable.reg_number) + " "
                          ),
                        ]
                      ),
                      _vm._v(" ) "),
                    ]
                  : _vm._e(),
                row.item.accountable_type === "refundRequest"
                  ? [
                      _vm._v(
                        " (Запрос на возврат средств № " +
                          _vm._s(row.item.accountable.reg_number) +
                          ")"
                      ),
                    ]
                  : _vm._e(),
                row.item.accountable_type === "organizationReport"
                  ? [
                      _vm._v(
                        "№ " +
                          _vm._s(row.item.accountable.reg_number) +
                          ", ИНН " +
                          _vm._s(row.item.accountable.inn)
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(amount)",
            fn: function (row) {
              return [
                _c("div", { staticClass: "text-right" }, [
                  _vm._v(" " + _vm._s(_vm.$formatPrice(row.item.amount)) + " "),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm.pagination.total
        ? _c("b-pagination", {
            staticClass: "mt-4 mb-0 mx-auto",
            attrs: {
              "per-page": _vm.pagination.per_page,
              "total-rows": _vm.pagination.total,
              align: "center",
            },
            on: { input: _vm.applyFilters },
            model: {
              value: _vm.pagination.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.pagination, "current_page", $$v)
              },
              expression: "pagination.current_page",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }