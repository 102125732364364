var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { login: _vm.$store.state.token != "" } },
    [
      _c(
        "b-container",
        { staticClass: "home_carousel p-0", attrs: { fluid: "" } },
        [
          _c(
            "b-carousel",
            {
              ref: "main_carousel",
              staticStyle: { "min-height": "345px", border: "none" },
              attrs: { id: "carousel-1", interval: 10000 },
              model: {
                value: _vm.mainSliderSlide,
                callback: function ($$v) {
                  _vm.mainSliderSlide = $$v
                },
                expression: "mainSliderSlide",
              },
            },
            _vm._l(_vm.mainSlider, function (slide, index) {
              return _c(
                "b-carousel-slide",
                {
                  key: "main_slide_" + index,
                  staticClass: "main_slide",
                  staticStyle: { "min-height": "345px", border: "none" },
                  style: { backgroundImage: "url(/images/" + slide.img + ")" },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "pt-2" }, [
                            _c("p", {
                              staticClass: "carousel_caption-head",
                              domProps: { innerHTML: _vm._s(slide.title) },
                            }),
                            _c("p", {
                              staticClass: "carousel_caption-desc mb-0",
                              domProps: {
                                innerHTML: _vm._s(slide.description),
                              },
                            }),
                            slide.button
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "carousel_caption-button mt-4",
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          href: slide.button.link,
                                          target: "_blank",
                                          variant: "custom-white",
                                        },
                                      },
                                      [_vm._v(_vm._s(slide.button.text))]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "b-container",
            { staticClass: "btns" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "button",
                      {
                        staticClass: "custom_slider_btn-prev",
                        attrs: { name: "custom_slider_btn-prev" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.main_carousel.prev()
                          },
                        },
                      },
                      [_vm._v("<")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "custom_slider_btn-next",
                        attrs: { name: "custom_slider_btn-next" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.main_carousel.next()
                          },
                        },
                      },
                      [_vm._v(">")]
                    ),
                    _c(
                      "ul",
                      { staticClass: "slider_dots" },
                      _vm._l(_vm.mainSlider, function (slide, index) {
                        return _c("li", {
                          key: index,
                          staticClass: "slider_dot",
                          class: { activeDot: _vm.mainSliderSlide === index },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.main_carousel.setSlide(index)
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "greyBg", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h2", { staticClass: "block_head-text" }, [
                      _vm._v("Как это работает"),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "ml-auto d-flex align-items-center news_tabs",
                      attrs: { cols: "auto" },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "tabs" },
                        _vm._l(_vm.howItWork.tabs, function (tab, index) {
                          return _c(
                            "li",
                            {
                              key: "key_" + index,
                              class: { "active-tab": _vm.actual === tab.key },
                              on: {
                                click: function ($event) {
                                  return _vm.setActualHowItWork(tab.key)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tab.title) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "for-scroll mb-2" }, [
                      _c("div", { staticClass: "graph-wrapper" }, [
                        _c(
                          "div",
                          { staticClass: "how_it_work-box mt-4" },
                          _vm._l(
                            _vm.howItWork.life_cycle,
                            function (item, cIndex) {
                              return _c(
                                "div",
                                {
                                  key: "cycle_" + cIndex,
                                  staticClass: "how_it_work-part",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "how_it_work-part_head" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 fs-14 green-color text-center px-2 fw-600",
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      item.time
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-items-center mt-auto mb-2",
                                              staticStyle: {
                                                "line-height": "1",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  alt: "time",
                                                  src: "/images/time.svg",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 mt-2 fs-10 grey-color",
                                                },
                                                [_vm._v(_vm._s(item.time))]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("div", { staticClass: "line" }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center num_parent",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "how_it_work-part_head-num text-center",
                                        },
                                        [_vm._v(_vm._s(item.number))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "how_it_work-part_body-box",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "how_it_work-part_body-part",
                                        },
                                        [
                                          _c(
                                            "transition-group",
                                            { attrs: { name: "animate" } },
                                            _vm._l(
                                              item.description,
                                              function (desc, key) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "transition" + key + desc,
                                                  },
                                                  [
                                                    _vm.actual === "all" ||
                                                    (desc.text &&
                                                      _vm.actual === key)
                                                      ? _c(
                                                          "p",
                                                          {
                                                            class: {
                                                              "px-2 pt-3":
                                                                desc.text,
                                                            },
                                                          },
                                                          [
                                                            desc.text &&
                                                            desc.text.length
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "fake-badget mx-auto",
                                                                    class: {
                                                                      "atmo-badget":
                                                                        key ===
                                                                        "atmo",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ru[
                                                                            key
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "d-block fs-12 grey-color text-center",
                                                                class: {
                                                                  " pb-2":
                                                                    desc.text,
                                                                },
                                                                staticStyle: {
                                                                  "line-height":
                                                                    "14px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      desc.text
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loadData || _vm.articles.length
        ? _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "block_head-text" }, [
                          _vm._v("Новости"),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "ml-auto d-flex align-items-center news_tabs",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "tabs" },
                            _vm._l(_vm.tabs, function (tab, index) {
                              return _c(
                                "li",
                                {
                                  key: "key_" + tab.type_id,
                                  class: {
                                    "active-tab": _vm.activeTab === index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveTab(
                                        index,
                                        tab.type_id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(tab.type_title) + " ")]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.loadData
                    ? _c("b-row", [_c("b-col", [_c("news-preloader")], 1)], 1)
                    : _vm._e(),
                  !_vm.loadData && _vm.articles.length
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "news_swiper" },
                            [
                              _c(
                                "swiper",
                                {
                                  staticClass: "swiper-container mb-5",
                                  attrs: { options: _vm.swiperMultipleSlides },
                                },
                                _vm._l(
                                  _vm.getActiveNews,
                                  function (message, mIndex) {
                                    return _c(
                                      "swiper-slide",
                                      {
                                        key:
                                          "message_" +
                                          message.type_id +
                                          "_" +
                                          mIndex,
                                        staticClass: "slide p-0",
                                        attrs: { width: "270" },
                                      },
                                      [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "article_head d-flex",
                                              class: {
                                                news_head:
                                                  message.type_id === 1,
                                                work_head:
                                                  message.type_id === 2,
                                                update_head:
                                                  message.type_id === 3,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  alt: "lifebuoy",
                                                  src: "/images/lifebuoy.svg",
                                                },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    to:
                                                      "/articles/" + message.id,
                                                    tag: "p",
                                                  },
                                                },
                                                [_vm._v(_vm._s(message.title))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "article_body" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "m-0",
                                                  staticStyle: {
                                                    "white-space": "pre-line",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.lengthControl(
                                                          message.description,
                                                          120
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "article_footer" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDateFormat(
                                                      message.published_at
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "btn btn-light btn-sm fs-12 mt-1 px-4 fw-100",
                                                  staticStyle: {
                                                    "text-transform":
                                                      "uppercase",
                                                    color: "var(--grey-color)",
                                                    "background-color":
                                                      "#f5f5f7",
                                                  },
                                                  attrs: {
                                                    to:
                                                      "/articles/" + message.id,
                                                    size: "sm",
                                                    tag: "button",
                                                    variant: "light",
                                                  },
                                                },
                                                [_vm._v(" Подробнее ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "swiper-button-prev custom-icon",
                                  attrs: { slot: "button-prev" },
                                  slot: "button-prev",
                                },
                                [_vm._v("<")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "swiper-button-next custom-icon",
                                  attrs: { slot: "button-next" },
                                  slot: "button-next",
                                },
                                [_vm._v(">")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.loadData && !_vm.articles.length
                    ? _c("b-row", [_c("b-col", [_c("news-preloader")], 1)], 1)
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h2", { staticClass: "block_head-text" }, [
                      _c(
                        "a",
                        {
                          staticStyle: {
                            color: "var(--green-color) !important",
                          },
                          attrs: {
                            href: _vm.links.info_materials,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Информационные материалы")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              !!_vm.informationSections.length
                ? _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.informationSections, function (item) {
                      return _c(
                        "b-col",
                        {
                          key: item.id,
                          staticClass: "info_materials-part mb-3",
                          attrs: { lg: "3", md: "6" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "wrapper h-100" },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass:
                                    "d-flex flex-nowrap align-items-start",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c("ThemeFileIcon"),
                                  _c("div", { staticClass: "pl-2" }, [
                                    _c("p", [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "atmo-override-link-off m-0 green-color fs-14 fw-600 text-uppercase cursor-pointer",
                                          attrs: {
                                            href: item.link,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.title) + " ")]
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-0 mt-3 fs-12 fw-400 grey-color position-relative info-desc",
                                      },
                                      [
                                        _vm._v(" " + _vm._s(item.desc) + " "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "atmo-override-link-off read_more cursor-pointer text-decoration-none small_info-arrow",
                                            attrs: {
                                              href: item.link,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-chevron-right",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
              !_vm.informationSections.length
                ? _c(
                    "b-row",
                    [_c("b-col", [_c("information-preloader")], 1)],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", {
                      staticClass: "fs-11 mb-3 mt-5 grey-color info-part",
                      staticStyle: { "line-height": "24px" },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }