import axios from 'axios';

export default {
    deleteAddress(id) {
        return axios.delete('/cabinet/customer/addresses/' + id);
    },
    createAddress(payload) {
        return axios.post('/cabinet/customer/addresses', payload).then((response) => {
            return response.data.data;
        });
    },
    getAddressesList() {
        return axios.get('/cabinet/customer/addresses').then((response) => {
            return response.data.data;
        });
    }
};
