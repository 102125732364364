var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-nav",
            { staticClass: "list" },
            [
              _c(
                "b-nav-item",
                { attrs: { active: _vm.customer, to: "/clients/customers" } },
                [_vm._v(" Заказчики ")]
              ),
              _c(
                "b-nav-item",
                { attrs: { active: !_vm.customer, to: "/clients/providers" } },
                [_vm._v(" Поставщики ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-col", { attrs: { md: "12" } }, [
        _vm.customer
          ? _c("p", { staticClass: "fs-14 greyBg-color mb-4" }, [
              _vm._v(
                "Сведения о государственных и муниципальных заказчиках " +
                  _vm._s(_vm.region_0) +
                  " проводящих закупки в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС " +
                  _vm._s(_vm.application_name_short) +
                  "."
              ),
            ])
          : _c("p", { staticClass: "fs-14 greyBg-color mb-4" }, [
              _vm._v(
                "Сведения о поставщиках для нужд государственных и муниципальных заказчиков " +
                  _vm._s(_vm.region_0) +
                  " в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС " +
                  _vm._s(_vm.application_name_short) +
                  "."
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }