<template>
    <div :class="{ login: $store.state.token != '' }">
        <b-container class="home_carousel p-0" fluid>
            <b-carousel id="carousel-1" ref="main_carousel" v-model="mainSliderSlide" :interval="10000" style="min-height: 345px; border: none">
                <b-carousel-slide v-for="(slide, index) in mainSlider" :key="'main_slide_' + index" :style="{ backgroundImage: 'url(/images/' + slide.img + ')' }" class="main_slide" style="min-height: 345px; border: none">
                    <b-container>
                        <b-row>
                            <b-col class="pt-2">
                                <p class="carousel_caption-head" v-html="slide.title"></p>
                                <p class="carousel_caption-desc mb-0" v-html="slide.description"></p>
                                <div class="carousel_caption-button mt-4" v-if="slide.button">
                                    <b-button :href="slide.button.link" target="_blank" variant="custom-white">{{ slide.button.text }}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-carousel-slide>
            </b-carousel>

            <!-- REFACTOR: Move slider controls under the slider -->
            <!-- to fix z-index management -->
            <b-container class="btns">
                <b-row>
                    <b-col>
                        <button class="custom_slider_btn-prev" name="custom_slider_btn-prev" @click="$refs.main_carousel.prev()">&lt;</button>
                        <button class="custom_slider_btn-next" name="custom_slider_btn-next" @click="$refs.main_carousel.next()">&gt;</button>
                        <ul class="slider_dots">
                            <li v-for="(slide, index) in mainSlider" :key="index" :class="{ activeDot: mainSliderSlide === index }" class="slider_dot" @click="$refs.main_carousel.setSlide(index)"></li>
                        </ul>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="greyBg" fluid style="">
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="block_head-text">Как это работает</h2>
                    </b-col>
                    <b-col class="ml-auto d-flex align-items-center news_tabs" cols="auto">
                        <ul class="tabs">
                            <li v-for="(tab, index) in howItWork.tabs" :key="'key_' + index" :class="{ 'active-tab': actual === tab.key }" @click="setActualHowItWork(tab.key)">
                                {{ tab.title }}
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div class="for-scroll mb-2">
                            <div class="graph-wrapper">
                                <div class="how_it_work-box mt-4">
                                    <div v-for="(item, cIndex) in howItWork.life_cycle" :key="'cycle_' + cIndex" class="how_it_work-part">
                                        <div class="how_it_work-part_head">
                                            <p class="mb-2 fs-14 green-color text-center px-2 fw-600">{{ item.title }}</p>
                                            <div v-if="item.time" class="d-flex flex-column align-items-center mt-auto mb-2" style="line-height: 1">
                                                <img alt="time" src="/images/time.svg" />
                                                <p class="mb-0 mt-2 fs-10 grey-color">{{ item.time }}</p>
                                            </div>
                                            <div class="line"></div>
                                        </div>
                                        <div class="d-flex justify-content-center num_parent">
                                            <p class="how_it_work-part_head-num text-center">{{ item.number }}</p>
                                        </div>

                                        <div class="how_it_work-part_body-box">
                                            <div class="how_it_work-part_body-part">
                                                <transition-group name="animate">
                                                    <div v-for="(desc, key) in item.description" :key="'transition' + key + desc">
                                                        <p v-if="actual === 'all' || (desc.text && actual === key)" :class="{ 'px-2 pt-3': desc.text }">
                                                            <span v-if="desc.text && desc.text.length" :class="{ 'atmo-badget': key === 'atmo' }" class="fake-badget mx-auto">
                                                                {{ ru[key] }}
                                                            </span>
                                                            <span :class="{ ' pb-2': desc.text }" class="d-block fs-12 grey-color text-center" style="line-height: 14px">
                                                                {{ desc.text }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </transition-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container v-if="loadData || articles.length" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="block_head-text">Новости</h2>
                    </b-col>
                    <b-col class="ml-auto d-flex align-items-center news_tabs" cols="auto">
                        <ul class="tabs">
                            <li v-for="(tab, index) in tabs" :key="'key_' + tab.type_id" :class="{ 'active-tab': activeTab === index }" @click="setActiveTab(index, tab.type_id)">
                                {{ tab.type_title }}
                            </li>
                        </ul>
                    </b-col>
                </b-row>
                <b-row v-if="loadData">
                    <b-col>
                        <news-preloader></news-preloader>
                    </b-col>
                </b-row>
                <b-row v-if="!loadData && articles.length">
                    <b-col class="news_swiper">
                        <swiper :options="swiperMultipleSlides" class="swiper-container mb-5">
                            <swiper-slide v-for="(message, mIndex) in getActiveNews" :key="'message_' + message.type_id + '_' + mIndex" class="slide p-0" width="270">
                                <div>
                                    <div
                                        :class="{
                                            news_head: message.type_id === 1,
                                            work_head: message.type_id === 2,
                                            update_head: message.type_id === 3
                                        }"
                                        class="article_head d-flex">
                                        <img alt="lifebuoy" src="/images/lifebuoy.svg" />
                                        <router-link :to="'/articles/' + message.id" class="cursor-pointer" tag="p">{{ message.title }}</router-link>
                                    </div>
                                    <div class="article_body">
                                        <p class="m-0" style="white-space: pre-line">
                                            {{ lengthControl(message.description, 120) }}
                                        </p>
                                    </div>
                                    <div class="article_footer">
                                        <span>{{ getDateFormat(message.published_at) }}</span>
                                        <router-link :to="'/articles/' + message.id" class="btn btn-light btn-sm fs-12 mt-1 px-4 fw-100" size="sm" style="text-transform: uppercase; color: var(--grey-color); background-color: #f5f5f7" tag="button" variant="light"> Подробнее </router-link>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div slot="button-prev" class="swiper-button-prev custom-icon">&lt;</div>
                        <div slot="button-next" class="swiper-button-next custom-icon">&gt;</div>
                    </b-col>
                </b-row>
                <b-row v-if="!loadData && !articles.length">
                    <b-col>
                        <news-preloader></news-preloader>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="greyBg pb-4" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="block_head-text"><a :href="links.info_materials" style="color: var(--green-color) !important" target="_blank">Информационные материалы</a></h2>
                    </b-col>
                </b-row>
                <b-row v-if="!!informationSections.length" class="mt-2">
                    <b-col v-for="item in informationSections" :key="item.id" class="info_materials-part mb-3" lg="3" md="6">
                        <div class="wrapper h-100">
                            <b-row class="d-flex flex-nowrap align-items-start" no-gutters>
                                <!-- Replace for themable image -->
                                <ThemeFileIcon></ThemeFileIcon>
                                <!-- <img src="/images/file.svg" alt="file" /> -->

                                <div class="pl-2">
                                    <p>
                                        <!-- Link off -->
                                        <a :href="item.link" class="atmo-override-link-off m-0 green-color fs-14 fw-600 text-uppercase cursor-pointer" target="_blank">
                                            {{ item.title }}
                                        </a>
                                    </p>
                                    <p class="mb-0 mt-3 fs-12 fw-400 grey-color position-relative info-desc">
                                        {{ item.desc }}
                                        <!-- Link off -->
                                        <a :href="item.link" class="atmo-override-link-off read_more cursor-pointer text-decoration-none small_info-arrow" target="_blank">
                                            <i class="fas fa-chevron-right"></i>
                                        </a>
                                    </p>
                                </div>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="!informationSections.length">
                    <b-col>
                        <information-preloader></information-preloader>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-11 mb-3 mt-5 grey-color info-part" style="line-height: 24px">
                            <!-- footer text -->
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<style src="swiper/dist/css/swiper.css"></style>

<script>
import Articles from '@/services/api/Articles';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import moment from 'moment-timezone';
import 'moment/locale/ru';
import { __LINKS } from '@/const';

import ThemeFileIcon from 'themes/views/pages/home/ThemeFileIcon.view.vue';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export default {
    name: 'Home',
    metaInfo: {
        title: 'Главная'
    },
    components: {
        swiper,
        swiperSlide,
        ThemeFileIcon
    },
    data() {
        return {
            ru: {
                provider: 'Поставщик',
                customer: 'Заказчик',
                atmo: Config.get('theme.variables.view.application_name_short')
            },
            mainSliderSlide: 0,
            slide: 0,
            activeTab: 0,
            activeId: 0,
            tabs: [],
            articles: [],
            swiperMultipleSlides: {
                slidesPerView: 4,
                spaceBetween: 35,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            actual: 'all',
            howItWork: {
                tabs: [
                    { title: 'Все', key: 'all' },
                    { title: 'Заказчик', key: 'customer' },
                    { title: 'Поставщик', key: 'provider' },
                    { title: Config.get('theme.variables.view.application_name_short'), key: 'atmo' }
                ],
                life_cycle: [
                    {
                        title: `Получение доступа к ${Config.get('theme.variables.view.application_name_short')}`,
                        time: '',
                        number: '01',
                        description: {
                            atmo: {
                                text: null
                            },
                            customer: {
                                text: 'Подтверждение заявки на регистрацию. Начало работы в личном кабинете.'
                            },
                            provider: {
                                text: 'Подача заявки на аккредитацию. Получение доступа к личному кабинету после одобрения заявки.'
                            }
                        }
                    },
                    {
                        title: 'Добавление пользователей организации и назначение полномочий',
                        time: '',
                        number: '02',
                        description: {
                            atmo: {
                                text: null
                            },
                            customer: {
                                text: `Руководитель организации Заказчика в личном кабинете добавляет пользователей, назначает полномочия для доступа к функциональности ${Config.get('theme.variables.view.application_name_short')}.`
                            },
                            provider: {
                                text: `Руководитель организации Поставщика в личном кабинете добавляет пользователей, назначает полномочия для доступа к функциональности ${Config.get('theme.variables.view.application_name_short')}.`
                            }
                        }
                    },
                    {
                        title: 'Мониторинг закупок',
                        time: '',
                        number: '03',
                        description: {
                            atmo: {
                                text: null
                            },
                            customer: {
                                text: 'Анализирует схожие закупки, ценовые предложения поставщиков, условия закупок.'
                            },
                            provider: {
                                text: 'Отслеживает закупки по своим видам деятельности, формирует стратегию участия в закупках.'
                            }
                        }
                    },
                    {
                        title: 'Проведение закупочной сессии',
                        time: '2 часа или 2 рабочих дня',
                        number: '04',
                        description: {
                            atmo: {
                                text: null
                            },
                            customer: {
                                text: 'Заказчик формирует объявление о закупочной сессии, создает спецификацию, указывает условия закупки. Отслеживает ход проведения закупочной сессии.'
                            },
                            provider: {
                                text: 'Подает ценовые предложения. Изменение ценовых предложений во время закупочной сессии.'
                            }
                        }
                    },
                    {
                        title: 'Подведение итогов',
                        time: '',
                        number: '05',
                        description: {
                            atmo: {
                                text: 'Определение Победителя. Формирование итогового протокола закупочной сессии.'
                            },
                            customer: {
                                text: 'После подведения итогов направляет Победителю проект договора.'
                            },
                            provider: {
                                text: null
                            }
                        }
                    },
                    {
                        title: 'Контрактование',
                        time: '3 рабочих дня',
                        number: '06',
                        description: {
                            atmo: {
                                text: null
                            },
                            customer: {
                                text: 'Подписание договора с Победителем или направление ответа на протокол разногласий.'
                            },
                            provider: {
                                text: 'Подписание договора Поставщиком или направление протокола разногласий.'
                            }
                        }
                    },
                    {
                        title: 'Исполнение договора',
                        time: '',
                        number: '07',
                        description: {
                            atmo: {
                                text: 'Фиксирует факт оплаты по исполненной части договора.'
                            },
                            customer: {
                                text: 'Прикрепляет документы об исполнении договора: акт приема-передачи товара / акт выполненных работ, акт о наличии претензий, соглашение / уведомление о расторжении договора'
                            },
                            provider: {
                                text: 'Вносит информацию об исполнении договора.'
                            }
                        }
                    }
                ]
            },
            loadData: true,
            loadInformation: true,
            mainSlider: [
                {
                    title: `<h1 class="fs-48 mb-0 fw-600">${Config.get('theme.variables.view.application_name')}</h1>`,
                    description: `<span class="fs-18">Обеспечение закупок для государственных и муниципальных предприятий ${Config.get('theme.variables.view.region')[0]} ` + '<br>согласно п.4,5 части 1 статьи 93 44-ФЗ с учетом требований п.2 статьи 73 Бюджетного кодекса РФ.</span>',
                    img: 'homeSlider.svg',
                    button: null
                },
                {
                    title: '<h2 class="fs-48 mb-0"><span class="fw-600">Заказчикам</span> – обеспечение закупок <br> для нужд организации.</h2>',
                    description:
                        `<span class="fs-18">Сведения о государственных и муниципальных заказчиках ${Config.get('theme.variables.view.region')[0]} <br> ` +
                        `проводящих закупки в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС ${Config.get('theme.variables.view.application_name_short')}.</span>`,
                    img: 'main_slider_customer.png',
                    button: null
                },
                {
                    title: '<h2 class="fs-48 mb-0"><span class="fw-600">Поставщикам</span> – равный доступ <br> к малым закупкам региона.</h2>',
                    description:
                        `<span class="fs-18">Сведения о поставщиках для нужд государственных и муниципальных заказчиков <br> ${Config.get('theme.variables.view.region')[0]}  ` +
                        `в рамках п.4,5 части 1 статьи 93 44-ФЗ зарегистрированных в ИС ${Config.get('theme.variables.view.application_name_short')}.</span>`,
                    img: 'main_slider_provider.png',
                    button: null
                },
                {
                    title: '<h2 class="fs-48 mb-0"><span class="fw-600">Bash Market</span> – ваши покупатели уже здесь.</h2>',
                    description: `<span class="fs-18">Маркетплейс, который выходит за рамки госзакупок и открывает новые возможности для бизнеса в Республике Башкортостан</span>`,
                    img: 'slider_bashmarket.png',
                    button: {
                        link: 'https://bash.market',
                        text: 'Начните продавать'
                    }
                }
            ],
            informationSections: [
                {
                    id: 1,
                    title: 'Руководства',
                    desc: `В данном разделе размещены инструкции и руководства по работе ИС ${Config.get('theme.variables.view.application_name_short')}`,
                    link: __LINKS.user_guide
                },
                {
                    id: 2,
                    title: 'Видеоматериалы',
                    desc: `В данном разделе размещены видеоролики демонстрирующие работу ИС ${Config.get('theme.variables.view.application_name_short')}`,
                    link: __LINKS.videos
                },
                {
                    id: 3,
                    title: 'Часто задаваемые вопросы',
                    desc: `В разделе представлены ответы на часто задаваемые вопросы пользователей ИС ${Config.get('theme.variables.view.application_name_short')}`,
                    link: __LINKS.faq
                },
                {
                    id: 4,
                    title: 'Правовые документы',
                    desc: `В разделе содержатся документы, регламентирующие взаимоотношения оператора и пользователей ИС ${Config.get('theme.variables.view.application_name_short')}`,
                    link: __LINKS.legal_documents
                }
            ]
        };
    },
    created() {
        this.setWindowWidth();
        this.getArticles();
    },
    methods: {
        getArticles() {
            Articles.getArticles()
                .then((resp) => {
                    this.articles = resp.articles;
                    this.tabs = resp.tabs;
                    this.tabs.unshift({
                        type_id: 0,
                        type_title: 'Все'
                    });
                    this.loadData = false;
                })
                .catch(() => {
                    this.loadData = false;
                })
                .finally(() => {
                    this.loadData = false;
                });
        },
        setActualHowItWork(key) {
            this.actual = key;
        },
        setActiveTab(index, type_id) {
            this.activeTab = index;
            this.activeId = type_id;
        },
        lengthControl(str, length) {
            if (str.length <= length) {
                return str;
            } else {
                return str.slice(0, length) + '...';
            }
        },
        setWindowWidth() {
            if (window.innerWidth < 559) {
                this.swiperMultipleSlides.slidesPerView = 1;
            } else if (window.innerWidth < 769) {
                this.swiperMultipleSlides.slidesPerView = 2;
            } else if (window.innerWidth < 1200) {
                this.swiperMultipleSlides.slidesPerView = 3;
            } else this.swiperMultipleSlides.slidesPerView = 4;
        },
        getDateFormat(date) {
            return moment.tz(date, Config.get('theme.variables.timezone')).format('DD.MM.YYYY');
        }
    },
    computed: {
        links() {
            return __LINKS;
        },
        getActiveNews() {
            if (this.articles.length) {
                return this.articles.filter((elem) => {
                    if (this.activeId === 0) {
                        return elem;
                    } else {
                        return elem.type_id === this.activeId;
                    }
                });
            } else {
                return [];
            }
        }
    }
};
</script>

<style scoped>
.btns {
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: 300px;
}

.btns .col {
    /* z-index: 1; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.slider_dots {
    position: absolute;
    padding: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    top: 150px;
    left: 190px;
}

.slider_dot {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    border: 1px solid #ffffff9a;
}

.login .slider_dots {
    left: 15px;
}

.activeDot,
.slider_dot:hover {
    background: #ffffff9a;
}

.slider_dot + .slider_dot {
    margin-left: 8px;
}

.how_it_work-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    /*padding: 15px;*/
}

.how_it_work-part {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
}

.how_it_work-part_head {
    position: relative;
    min-height: 100px;
    overflow: hidden;
}

.line::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 1px dotted #aaa;
}

.line::after {
    content: '';
    width: 5px;
    height: 5px;
    background: var(--grey-color);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.how_it_work-part_head-num {
    z-index: 0;
    margin-top: auto;
    color: #fff;
    font-size: 14px;
    /*background-color: var(--green-color);*/
    width: 30px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    /*border-radius: 50%;*/
    margin-bottom: 0;
    /* z-index: 2; */
    line-height: 1;
    /*border: 2px solid #fff;*/
    background-image: url('/images/landingNumberWrapper.svg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.num_parent {
    position: relative;
}

.num_parent::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: #959ba4;
    opacity: 0.5;
}

.how_it_work-part_body-part {
    margin: 14px 7px 0;
    -webkit-box-shadow: 0px 2px 4px #0000000a;
    -moz-box-shadow: 0px 2px 4px #0000000a;
    box-shadow: 0px 2px 4px #0000000a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: relative;
}

.animate-enter-active {
    position: relative;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
}

.animate-leave-active {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -o-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    -moz-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.animate-enter {
    position: relative;
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}

.animate-leave-to {
    position: absolute;
    -webkit-transform: translateX(110px);
    -ms-transform: translateX(110px);
    -moz-transform: translateX(110px);
    -o-transform: translateX(110px);
    transform: translateX(110px);
    opacity: 0;
}

.how_it_work-part_body-part p {
    background-color: #fff;
    margin: 0;
}

.how_it_work-part_body-part p::before {
    content: '';
    display: block;
    position: absolute;
    top: -7px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) rotate(45deg);
    -moz-transform: translateX(-50%) rotate(45deg);
    -o-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    width: 12px;
    /*box-shadow: 0px 2px 4px #0000000A;*/
    height: 12px;
    background-color: #fff;
    opacity: 0.5;
}

.fake-badget {
    width: 100%;
    max-width: 101px;
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    padding: 4px;
    color: var(--green-color);
    text-transform: uppercase;
    background-color: var(--greyBg-color);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 14px;
}

.atmo-badget {
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    background-color: var(--green-color);
}

.main_slide {
    min-height: 345px;
    background-position: 50%;
}

.img-box {
    width: 100%;
    padding-bottom: 50px;
}

.news_head {
    background-color: var(--green-color);
}

.work_head {
    background-color: #f9cd45;
}

.update_head {
    background-color: var(--grey-color);
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    padding-top: 10px;
    border-bottom: 1px solid #e8ced3;
}

.tabs li + li {
    margin-left: 26px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.tabs li:hover {
    color: var(--green-color);
}

.active-tab {
    color: var(--green-color);
    border-bottom: 2px solid var(--green-color) !important;
}

.article_head {
    padding: 25px;
}

.article_head p {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    margin-left: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
}

.slide {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
    height: auto;
}

.slide > div {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
}

.article_head {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.article_body {
    padding: 20px;
}

.article_body p {
    font-size: 12px;
    line-height: 24px;
}

.article_footer {
    padding: 0 20px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    margin-top: auto;
}

.article_footer span {
    color: var(--grey-color);
    font-size: 12px;
}

.swiper-button-disabled {
    display: none;
}

.custom-icon {
    background-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    background-color: rgba(38, 184, 154, 0.21);
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.custom-icon i {
    color: var(--green-color);
}

.custom-icon i.fa-chevron-right {
    margin-left: 2px;
}

.custom-icon i.fa-chevron-left {
    margin-right: 2px;
}

.read_more {
    background-image: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    background-color: rgba(38, 184, 154, 0.21);
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.small_info-arrow::before {
    content: '';
    width: 5px;
    height: 5px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: var(--green-color) var(--green-color) transparent transparent;
    position: absolute;
    top: 58%;
    left: 30%;
}

.wrapper {
    padding: 30px;
    border: 1px solid var(--grey-color);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.start_btn {
    margin-top: 52px;
    padding: 8px 12px;
}

.news_swiper {
    position: relative;
    margin-bottom: 5px;
}

/* REFACTOR: Fix z-index vs sticky header. */
/* .news_swiper .swiper-container {
    z-index: 0;
} */

/* REFACTOR: Fix z-index vs sticky header. */
.news_swiper .swiper-container,
.news-swiper .swiper-button-prev,
.news-swiper .swiper-button-next {
    z-index: 0;
}

.how_it_work-img {
    width: 100%;
    margin: 0 !important;
}

.info-desc {
    display: inline;
}

.small_info-arrow {
    position: absolute;
    bottom: -1px;
    right: -20px;
}

.ruler {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 0 40px;
}

.ruler::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: var(--grey-color);
    top: 0px;
}

.ruler::after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    border: 2px solid var(--grey-color);
    right: 0;
    top: -4px;
    border-left-color: transparent;
    border-top-color: transparent;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.ruler-scale {
    position: relative;
    padding-top: 5px;
    color: var(--grey-color);
    font-size: 7px;
    min-width: 25px;
    max-width: 25px;
    width: 25px;
}

.ruler-scale::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 6px;
    background-color: var(--grey-color);
    top: -2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 22;
}

.graph {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    align-items: flex-end;
    padding: 0 40px;
}

.graph-part {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    align-items: flex-start;
    background-color: rgba(38, 184, 154, 0.2);
    -webkit-border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    -moz-border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
}

.graph-part + .graph-part {
    margin-left: -22px;
}

.graph-part:hover {
    background-color: rgba(38, 184, 154, 0.5);
}

.graph-part:hover .graph-part_desc {
    color: var(--green-color);
    font-weight: 600;
}

.graph-part_desc {
    display: block;
    position: relative;
    top: -125px;
    font-size: 14px;
    white-space: nowrap;
    color: var(--grey-color);
}

.graph-part_desc::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
    bottom: -5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.graph-part::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    bottom: 5px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.graph-part::after {
    content: '';
    display: block;
    width: 1px;
    height: -webkit-calc(100% + 85px);
    height: -moz-calc(100% + 85px);
    height: calc(100% + 85px);
    background-color: rgba(51, 51, 51, 0.5);
    position: absolute;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.graph-wrapper {
    min-width: 1240px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    justify-content: flex-start;
    /*padding-top: 100px;*/
    /*margin-bottom: 50px;*/
}

.for-scroll {
    z-index: -1;
    /*padding-top: 33px;*/
    width: 100%;
    margin-bottom: -17px;
    padding-bottom: 17px;
}

@media screen and (max-width: 1300px) {
    .for-scroll {
        overflow-x: auto;
    }

    /*.for-scroll::after{*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    background: rgba(255,255,255,0.7);*/
    /*    width: 27px;*/
    /*    min-width: 27px;*/
    /*    max-width: 27px;*/
    /*    height: 27px;*/
    /*    min-height: 27px;*/
    /*    max-height: 27px;*/
    /*    position: absolute;*/
    /*    top: 50%;*/
    /*    right: -15px;*/
    /*    transform: translateY(-50%);*/
    /*    transform-origin: center;*/
    /*    border-radius: 50%;*/
    /*    content: '\f054';*/
    /*    font-size: 16px;*/
    /*    margin-left: 2px;*/
    /*    color: var(--green-color);*/
    /*    font-family: 'Font Awesome 5 Free';*/
    /*    font-weight: 900;*/
    /*}*/
}

@media screen and (max-width: 1200px) {
    .home_carousel {
        background-position: 65%;
    }
}

@media screen and (max-width: 992px) {
    .info_materials-part {
        margin-top: 20px;
    }

    .main_slide {
        background-position: 65%;
    }

    .slider_dots {
        top: 120px;
    }

    .carousel_caption-desc {
        min-height: 72px;
    }
}

@media screen and (max-width: 768px) {
    .news_tabs {
        margin-bottom: 20px;
    }

    .info_materials-part + .info_materials-part {
        margin-top: 20px !important;
    }

    .how_it_work-img {
        min-width: 1300px;
    }

    .img-box {
        overflow-x: auto;
        margin-bottom: -17px;
    }

    .main_slide {
        background-position: 70%;
    }

    .slider_dots {
        top: 200px;
        left: auto;
        right: 15px;
    }

    .carousel_caption-desc {
        min-height: 96px;
    }
}

@media screen and (max-width: 568px) {
    .news_tabs {
        display: none !important;
    }

    .carousel_caption-desc {
        font-size: 12px !important;
    }

    .hidden-mobile {
        display: none;
    }

    .start_btn {
        margin-top: 45px;
        /*margin-left: 10px;*/
    }

    .block_head-text {
        font-size: 22px;
        margin: 11px 0;
    }

    .home_carousel + .greyBg .block_head-text {
        margin-bottom: 40px;
    }

    .article_head {
        padding: 18px;
    }

    .article_body {
        padding: 18px;
    }

    .article_body p {
        line-height: 20px;
    }

    .article_footer {
        padding: 0 20px 20px;
    }

    .news_swiper .swiper-container {
        margin-bottom: 25px !important;
    }

    .wrapper {
        padding: 18px 30px;
    }

    .info_materials-part + .info_materials-part {
        margin-top: 16px !important;
    }

    .info-part {
        margin-top: 30px !important;
        font-size: 10px !important;
        line-height: 20px !important;
        margin-bottom: 1px !important;
    }

    .info-desc {
        display: none;
    }

    .info_materials-part .wrapper div {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -moz-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .slider_dots {
        top: 200px;
    }
}

@media screen and (max-width: 450px) {
    .carousel_caption-head {
        min-height: 92px !important;
    }

    .slider_dots {
        top: 200px;
    }

    .carousel_caption-desc {
        line-height: 16px !important;
    }
}

@media screen and (max-width: 414px) {
    .carousel_caption-desc {
        font-size: 10px !important;
        line-height: 20px !important;
    }
}

@media screen and (max-width: 320px) {
    .carousel_caption-desc {
        line-height: 1.4 !important;
    }

    .block_head-text {
        line-height: 25px;
        margin: 30px 0 !important;
    }

    .slider_dots {
        top: 250px;
    }
}
</style>

<style>
.home_carousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    outline: none;
}

.how_it_work-part + .how_it_work-part .how_it_work-part_head::before {
    content: '';
    width: 0px;
    height: 100%;
    border-left: 1px dotted #aaa;
    position: absolute;
}

.how_it_work-part:last-child .num_parent::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    -moz-transform: translateY(-50%) rotate(45deg);
    -o-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    width: 10px;
    height: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: #959ba4 #959ba4 transparent transparent;
    opacity: 0.5;
}

.home_carousel .carousel-caption {
    position: static;
    /*top: 20px;*/
    text-align: left;
    padding: 0;
}

.home_carousel .carousel_caption-head {
    line-height: 50px;
    margin-top: 55px;
    min-height: 76px;
}

.home_carousel .carousel_caption-desc {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: var(--greyBg-color);
}

.swiper-button-disabled {
    display: none;
}

.carousel-control-next,
.carousel-control-prev {
    width: auto !important;
}

.custom_slider_btn-prev,
.custom_slider_btn-next {
    background-image: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.custom_slider_btn-prev:hover,
.custom_slider_btn-next:hover {
    background: rgba(255, 255, 255, 0.7);
}

.custom_slider_btn-prev,
.custom_slider_btn-next {
    color: transparent;
    position: relative;
}

.swiper-button-prev::before,
.custom_slider_btn-prev::before {
    content: '';
    width: 10px;
    height: 10px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: transparent transparent var(--green-color) var(--green-color);
    position: absolute;
    top: 18px;
    left: 40%;
}

.swiper-button-next::before,
.custom_slider_btn-next::before {
    content: '';
    width: 10px;
    height: 10px;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(45deg) translate(-50%, -50%);
    -moz-transform: rotate(45deg) translate(-50%, -50%);
    -ms-transform: rotate(45deg) translate(-50%, -50%);
    -o-transform: rotate(45deg) translate(-50%, -50%);
    transform: rotate(45deg) translate(-50%, -50%);
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: var(--green-color) var(--green-color) transparent transparent;
    position: absolute;
    top: 18px;
    left: 30%;
}

.swiper-button-next,
.swiper-button-prev {
    color: transparent;
}

@media screen and (max-width: 1200px) {
    .login .carousel-indicators {
        left: 0 !important;
    }
}

@media screen and (max-width: 992px) {
    .home_carousel .carousel_caption-head {
        margin-top: 40px !important;
    }

    .login .carousel-indicators {
        left: 0 !important;
    }
}

@media screen and (max-width: 769px) {
    .login .carousel-indicators {
        left: 0 !important;
    }

    .carousel_caption-head h1 {
        font-size: 32px !important;
    }

    .carousel_caption-head {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .home_carousel .carousel_caption-head {
        margin-top: 25px !important;
    }

    .home_carousel .carousel_caption-head h1,
    .home_carousel .carousel_caption-head h2 {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 568px) {
    .custom_slider_btn-prev,
    .custom_slider_btn-next {
        display: none;
    }
}

@media screen and (max-width: 450px) {
}
</style>
