var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-offer-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-52 fw-600 white-color mt-3 mb-3" },
                          [_vm._v("№ " + _vm._s(_vm.purchase.reg_number))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _vm._v(
                            "Форма предоставления наилучшего предложения на объявление о закупочной сессии"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-container", [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "fs-14 my-1 my-3",
                    staticStyle: { color: "white !important" },
                    attrs: {
                      variant: "danger",
                      size: "lg",
                      href: "https://app.hinted.me/simulations/669e4103-056e-40fd-9586-f0e0c63a2bba/view",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mx-1",
                      staticStyle: { height: "16px" },
                      attrs: {
                        src: "/images/youtube.svg",
                        alt: "documentation",
                      },
                    }),
                    _vm._v(" Инструкция по формированию и подаче предложения"),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      label: "Наименование",
                      "router-link": {
                        name: "CustomerShow",
                        params: { id: _vm.purchase.customer.id },
                      },
                    },
                    model: {
                      value: _vm.purchase.customer.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "name", $$v)
                      },
                      expression: "purchase.customer.name",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "ИНН" },
                    model: {
                      value: _vm.purchase.customer.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "inn", $$v)
                      },
                      expression: "purchase.customer.inn",
                    },
                  }),
                  _vm.purchase.customer.kpp
                    ? _c("text-row", {
                        attrs: { label: "КПП" },
                        model: {
                          value: _vm.purchase.customer.kpp,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.customer, "kpp", $$v)
                          },
                          expression: "purchase.customer.kpp",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.customer.ogrn
                    ? _c("text-row", {
                        attrs: { label: "ОГРН" },
                        model: {
                          value: _vm.purchase.customer.ogrn,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.customer, "ogrn", $$v)
                          },
                          expression: "purchase.customer.ogrn",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.additional_contact_info
                    ? _c("text-row", {
                        attrs: {
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.additional_contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "additional_contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.additional_contact_info",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _c("text-row-datetime", {
                    attrs: { label: "Дата и время размещения закупки" },
                    model: {
                      value: _vm.purchase.purchase_start_datetime,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_start_datetime", $$v)
                      },
                      expression: "purchase.purchase_start_datetime",
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      label: "Дата и время окончания приема предложений",
                    },
                    model: {
                      value: _vm.purchase.purchase_end_datetime,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_end_datetime", $$v)
                      },
                      expression: "purchase.purchase_end_datetime",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Предмет закупки" },
                    model: {
                      value:
                        _vm.purchase.terms_of_purchase.purchase_category_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.terms_of_purchase,
                          "purchase_category_name",
                          $$v
                        )
                      },
                      expression:
                        "purchase.terms_of_purchase.purchase_category_name",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Объект закупки" },
                    model: {
                      value: _vm.purchase.terms_of_purchase.purchase_object,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.terms_of_purchase,
                          "purchase_object",
                          $$v
                        )
                      },
                      expression: "purchase.terms_of_purchase.purchase_object",
                    },
                  }),
                  _vm.purchase.terms_of_purchase.national_project_name
                    ? _c("text-row", {
                        attrs: { label: "Национальный проект" },
                        model: {
                          value:
                            _vm.purchase.terms_of_purchase
                              .national_project_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.terms_of_purchase,
                              "national_project_name",
                              $$v
                            )
                          },
                          expression:
                            "purchase.terms_of_purchase.national_project_name",
                        },
                      })
                    : _vm._e(),
                  _c("text-row", {
                    attrs: { label: "Тип закупки" },
                    model: {
                      value: _vm.purchase.terms_of_purchase.order_type_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.terms_of_purchase,
                          "order_type_name",
                          $$v
                        )
                      },
                      expression: "purchase.terms_of_purchase.order_type_name",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Продолжительность закупочной сессии" },
                    model: {
                      value: _vm.purchase.terms_of_purchase.duration_type_title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.terms_of_purchase,
                          "duration_type_title",
                          $$v
                        )
                      },
                      expression:
                        "purchase.terms_of_purchase.duration_type_title",
                    },
                  }),
                  _vm.purchase.terms_of_purchase.with_advance
                    ? _c("text-row", {
                        attrs: { label: "Размер аванса, %" },
                        model: {
                          value:
                            _vm.purchase.terms_of_purchase.advance_percentage,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.terms_of_purchase,
                              "advance_percentage",
                              $$v
                            )
                          },
                          expression:
                            "purchase.terms_of_purchase.advance_percentage",
                        },
                      })
                    : _vm._e(),
                  _c("text-row-price", {
                    attrs: { label: "Начальная максимальная цена контракта" },
                    model: {
                      value: _vm.purchase.start_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "start_price", $$v)
                      },
                      expression: "purchase.start_price",
                    },
                  }),
                  _c("text-row-date", {
                    attrs: { label: "Планируемая дата заключения договора" },
                    model: {
                      value:
                        _vm.purchase.terms_of_purchase.planned_contract_date,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.terms_of_purchase,
                          "planned_contract_date",
                          $$v
                        )
                      },
                      expression:
                        "purchase.terms_of_purchase.planned_contract_date",
                    },
                  }),
                  _c("text-row-documents", {
                    attrs: { label: "Проект договора" },
                    model: {
                      value: _vm.purchase.contract_project_document,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "contract_project_document", $$v)
                      },
                      expression: "purchase.contract_project_document",
                    },
                  }),
                  _vm.purchase.additional_documents.length > 0
                    ? _c("text-row-documents", {
                        attrs: { label: "Дополнительные документы" },
                        model: {
                          value: _vm.purchase.additional_documents,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "additional_documents", $$v)
                          },
                          expression: "purchase.additional_documents",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.terms_of_purchase.additional_info
                    ? _c("text-row", {
                        attrs: {
                          label:
                            "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ",
                        },
                        model: {
                          value: _vm.purchase.terms_of_purchase.additional_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase.terms_of_purchase,
                              "additional_info",
                              $$v
                            )
                          },
                          expression:
                            "purchase.terms_of_purchase.additional_info",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          (_vm.purchase.terms_of_delivery.addresses &&
            _vm.purchase.terms_of_delivery.addresses.length > 0) ||
          _vm.purchase.terms_of_delivery.delivery_information
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("text-header", [_vm._v("Условия поставки")]),
                      _vm.purchase.terms_of_delivery.addresses &&
                      _vm.purchase.terms_of_delivery.addresses.length > 0
                        ? _c("text-row-addresses", {
                            attrs: {
                              label:
                                "Адреса для доставки товаров/выполнения работ/оказания услуг",
                            },
                            model: {
                              value: _vm.purchase.terms_of_delivery.addresses,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.terms_of_delivery,
                                  "addresses",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.terms_of_delivery.addresses",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.terms_of_delivery.delivery_information
                        ? _c("text-row", {
                            attrs: {
                              label:
                                "График поставки товаров (выполнения работ, оказания услуг)",
                            },
                            model: {
                              value:
                                _vm.purchase.terms_of_delivery
                                  .delivery_information,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.terms_of_delivery,
                                  "delivery_information",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.terms_of_delivery.delivery_information",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [
                    _vm._v("Требования к участникам закупки"),
                  ]),
                  _c("text-row", {
                    attrs: {
                      "label-cols-sm": 11,
                      "label-cols-lg": 11,
                      "value-class": "text-right mr-2",
                      label:
                        "Участником закупки могут быть только субъекты малого предпринимательства (СМП)",
                      value: _vm.purchase.supplier_requirements.only_msp
                        ? "Да"
                        : "Нет",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      "label-cols-sm": 11,
                      "label-cols-lg": 11,
                      "value-class": "text-right mr-2",
                      label:
                        "Заказчик установил требование к участникам по рейтингу в ИС АТМО и может отклонить предложение участника с рейтингом ниже 4,5 балла",
                      value: _vm.purchase.supplier_requirements
                        .supplier_reqs_rating
                        ? "Да"
                        : "Нет",
                    },
                  }),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_a
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям п.1 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_a.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_a,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_a",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_a",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c(
                        "div",
                        {
                          staticClass: "form-row",
                          staticStyle: { "word-break": "break-all" },
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                              _c("strong", [
                                _vm._v(
                                  "Участник закупки должен соответствовать следующим требованиям"
                                ),
                              ]),
                              _vm._v(
                                " - " +
                                  _vm._s(
                                    _vm.purchase.supplier_requirements
                                      .supplier_reqs_a_text
                                  )
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_a
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                            _c("strong", [
                              _vm._v(
                                "Участник закупки должен подтвердить соответствие указанным требованиям, приложив следующие документы:"
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.purchase.supplier_requirements
                      .supplier_required_documents,
                    function (item, index) {
                      return _c("form-row-single-line-document-upload", {
                        key: index,
                        attrs: {
                          title: item.name,
                          v: _vm.$v.form.supplier_requirements
                            .supplier_required_documents.$each[index]
                            .storedDocument,
                        },
                        model: {
                          value:
                            _vm.form.supplier_requirements
                              .supplier_required_documents[index]
                              .storedDocument,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.supplier_requirements
                                .supplier_required_documents[index],
                              "storedDocument",
                              $$v
                            )
                          },
                          expression:
                            "form.supplier_requirements.supplier_required_documents[index].storedDocument",
                        },
                      })
                    }
                  ),
                  _vm.purchase.supplier_requirements.supplier_reqs_b
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_b
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям п.3-5, 7-11 ч.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_b.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_b,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_b",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_b",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_c
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements.supplier_reqs_c
                                .$invalid,
                            label:
                              "Подтверждаю, что соответствую требованиям ч.1.1 ст.31 Федерального закона «О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд» от 05.04.2013 №44-ФЗ",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_c.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements.supplier_reqs_c,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_c",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_c",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.supplier_reqs_sonko
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "11",
                            "label-cols-lg": "11",
                            state:
                              !_vm.$v.form.supplier_requirements
                                .supplier_reqs_sonko.$invalid,
                            label:
                              "Подтверждаю, оргчнизация является социально ориентированной некоммерческой организацией (СОНКО)",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2 text-right",
                            attrs: {
                              state:
                                !_vm.$v.form.supplier_requirements
                                  .supplier_reqs_sonko.$invalid,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements
                                  .supplier_reqs_sonko,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "supplier_reqs_sonko",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.supplier_reqs_sonko",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.supplier_requirements.additional.state[0]
                    ? _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                            _c("strong", [
                              _vm._v(
                                "Участник должен подтвердить соответствие следующим дополнительным требованиям, приложив документы:"
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.purchase.supplier_requirements.additional.requirements,
                    function (requirement, index) {
                      return _c("form-row-single-line-document-upload", {
                        key: `req-additional-${index}`,
                        attrs: {
                          title: `${index + 1}. ${
                            requirement.description
                          } (причина: ${requirement.reason})`,
                          v: _vm.$v.form.supplier_requirements.additional
                            .requirements.$each[index].attachment,
                        },
                        model: {
                          value:
                            _vm.form.supplier_requirements.additional
                              .requirements[index].attachment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.supplier_requirements.additional
                                .requirements[index],
                              "attachment",
                              $$v
                            )
                          },
                          expression:
                            "form.supplier_requirements.additional.requirements[index].attachment",
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Контакты")]),
                  _c("form-row-edit-text", {
                    attrs: {
                      label: "Контактное лицо поставщика",
                      v: _vm.$v.form.contact_info,
                    },
                    model: {
                      value: _vm.form.contact_info,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact_info", $$v)
                      },
                      expression: "form.contact_info",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c(
                    "b-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v("№")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v("Рег.номер ТРУ")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  attrs: { rowspan: "2" },
                                },
                                [
                                  _c(
                                    "abbr",
                                    { attrs: { title: "Характеристики ТРУ" } },
                                    [_vm._v("Хар-ки ТРУ")]
                                  ),
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v("Наименование товара (работ, услуг)")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  attrs: { rowspan: "2" },
                                },
                                [_vm._v("Код ОКПД2")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: {
                                    "text-align": "center",
                                    "border-right": "2px solid #dee2e6",
                                  },
                                  attrs: { colspan: "4" },
                                },
                                [_vm._v("Извещение")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: "3" },
                                },
                                [_vm._v("Предложение")]
                              ),
                            ]),
                            _c("tr", [
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Количество"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Единица"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Цена за ед., руб."),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Стоимость, руб."),
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "fw-600",
                                  staticStyle: { "min-width": "150px" },
                                },
                                [_vm._v("Цена за ед., руб.")]
                              ),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Стоимость, руб."),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.purchase.specifications,
                              function (item, index) {
                                return _c("fragment", { key: item.id }, [
                                  _c(
                                    "tr",
                                    {
                                      class: _vm.visible[index]
                                        ? "border-bottom-0 border-top-0"
                                        : "",
                                    },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [_vm._v(_vm._s(index + 1))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                id:
                                                  "spec-ktru-popover-" +
                                                  (index + 1),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.ktru_reg_number)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target:
                                                  "spec-ktru-popover-" +
                                                  (index + 1),
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "title",
                                                    fn: function () {
                                                      return [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Рег.номер ТРУ:"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.ktru_reg_number
                                                            )
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("b", [_vm._v("Группа ТРУ:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru_group_name)
                                              ),
                                              _c("br"),
                                              _c("b", [
                                                _vm._v("Наименование:"),
                                              ]),
                                              _vm._v(
                                                " " + _vm._s(item.ktru_name)
                                              ),
                                              _c("br"),
                                              _c("b", [_vm._v("Тип:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru_type_name)
                                              ),
                                              _c("br"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName: "v-b-toggle",
                                                  value: "collapse-" + index,
                                                  expression:
                                                    "'collapse-' + index",
                                                },
                                              ],
                                              staticClass: "atmo-button-icon",
                                            },
                                            [
                                              !_vm.visible[index]
                                                ? _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        title:
                                                          "Показать характеристики",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "atmo-icon-show",
                                                      }),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        title:
                                                          "Скрыть характеристики",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "atmo-icon-hide",
                                                      }),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                          _c("b-collapse", {
                                            staticClass: "d-none",
                                            attrs: { id: "collapse-" + index },
                                            model: {
                                              value: _vm.visible[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.visible,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression: "visible[index]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [_vm._v(_vm._s(item.okpd_name))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [_vm._v(_vm._s(item.count))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-lowercase align-text-top",
                                        },
                                        [_vm._v(_vm._s(item.okei_name))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.price > 0
                                                ? _vm.$formatPrice(item.price)
                                                : "—"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.price > 0
                                                ? _vm.$formatPrice(
                                                    _vm.purchaseSpecificationItemPriceTotal(
                                                      item.price,
                                                      item.count
                                                    )
                                                  )
                                                : "—"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "align-text-top" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("currency-input", {
                                                directives: [
                                                  {
                                                    name: "stop-number-mousewheel",
                                                    rawName:
                                                      "v-stop-number-mousewheel",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control fs-14",
                                                class: _vm.$v.form.items.$each[
                                                  item.id
                                                ].price.$invalid
                                                  ? "is-invalid"
                                                  : "",
                                                attrs: {
                                                  "allow-negative": false,
                                                  currency: "RUB",
                                                  locale: "ru",
                                                },
                                                on: {
                                                  input:
                                                    _vm.recalculateTotalSum,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.items[item.id]
                                                      .price,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.items[item.id],
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.items[item.id].price",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "align-text-top text-nowrap",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatPrice(
                                                _vm.sumTotalItem(item, item.id)
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.visible[index],
                                          expression: "visible[index]",
                                        },
                                      ],
                                    },
                                    [
                                      _c("td", { attrs: { colspan: "11" } }, [
                                        item.ktru_description
                                          ? _c("p", [
                                              _c("b", [_vm._v("Описание:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru_description)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru_specifications &&
                                        item.ktru_specifications.length > 0
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Характеристики:"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        item.ktru_specifications &&
                                        item.ktru_specifications.length > 0
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                item.ktru_specifications,
                                                function (spec, specIndex) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key:
                                                        index +
                                                        "_spec_" +
                                                        specIndex,
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(spec.name)
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(spec.value) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("text-row-price", {
                    attrs: { label: "Итоговая стоимость предложения" },
                    model: {
                      value: _vm.totalSum,
                      callback: function ($$v) {
                        _vm.totalSum = $$v
                      },
                      expression: "totalSum",
                    },
                  }),
                  _vm.$v.form.$invalid
                    ? [
                        _vm.$v.form.items.$invalid
                          ? _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                'Одна или нескольких позиций спецификации содержит недопустимое значение поля "Цена за ед., руб.". Значение должно быть больше 0, и не должно превышать цену позиции, указанную в извещении.'
                              ),
                            ])
                          : _vm._e(),
                        _vm.$v.form.total_cost.$invalid &&
                        !_vm.$v.form.total_cost.maxValue
                          ? _c("p", { staticClass: "text-danger" }, [
                              _vm._v(
                                "Итоговая стоимость предложения не должна превышать стартовую цену извещения или стоимость ранее поданного вами предложения."
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("p", { staticClass: "block_head-text" }, [
                            _vm._v("Документы"),
                          ]),
                          _c(
                            "file-uploader",
                            { on: { set_file: _vm.addFile } },
                            [_vm._v("Добавить документ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pb-4" },
                    [
                      _c("b-col", [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticStyle: { width: "50px" } }),
                              _c("th", [_vm._v("Наименование")]),
                              _c("th"),
                            ]),
                          ]),
                          _vm.form.documents.length
                            ? _c(
                                "tbody",
                                _vm._l(
                                  _vm.form.documents,
                                  function (document, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [_vm._v(_vm._s(index + 1))]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "fs-14",
                                              staticStyle: {
                                                "padding-top": "0",
                                                "padding-bottom": "0",
                                                color:
                                                  "var(--green-color) !important",
                                              },
                                              attrs: {
                                                variant: "link",
                                                size: "xs",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$documentDownload(
                                                    document
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(document.name))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { attrs: { width: "1" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticStyle: {
                                                background: "transparent",
                                              },
                                              attrs: {
                                                variant: "light",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFile(index)
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/images/trash_icon.svg",
                                                  alt: "trash_icon",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      attrs: { colspan: "3", align: "center" },
                                    },
                                    [_vm._v("Документы не добавлены.")]
                                  ),
                                ]),
                              ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelOffer },
                            },
                            [_vm._v("ОТМЕНИТЬ")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled:
                                  _vm.$v.form.$invalid ||
                                  !_vm.$store.getters.getCanSign,
                              },
                              on: { click: _vm.checkResources },
                            },
                            [_vm._v("ПОДАТЬ ПРЕДЛОЖЕНИЕ")]
                          ),
                          !_vm.$store.getters.getCanSign
                            ? _c("span", { staticClass: "text-danger pt-1" }, [
                                _vm._v(
                                  "Подача предложения возможна только при авторизации при помощи ЭЦП"
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "attention-modal",
              attrs: { title: "Внимание!", size: "lg" },
              model: {
                value: _vm.showAttentionModal,
                callback: function ($$v) {
                  _vm.showAttentionModal = $$v
                },
                expression: "showAttentionModal",
              },
            },
            [
              _c("div", { staticClass: "d-block" }, [
                _c("div", { staticClass: "py-3 d-flex" }, [
                  _c("div", { staticClass: "fs-14 grey-color text-center" }, [
                    _vm._v(
                      "С вашего лицевого счета будут заблокированы денежные средства для обеспечения проведения торгов, согласно оферты ИС " +
                        _vm._s(_vm.application_name_short)
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: {
                        click: function ($event) {
                          _vm.showAttentionModal = false
                        },
                      },
                    },
                    [_vm._v("ОТМЕНИТЬ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-green" },
                      on: { click: _vm.acceptConfirmation },
                    },
                    [_vm._v("ПРОДОЛЖИТЬ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "attention-modal",
              attrs: { "hide-footer": "", title: "Внимание!", size: "lg" },
              model: {
                value: _vm.showDepositModal,
                callback: function ($$v) {
                  _vm.showDepositModal = $$v
                },
                expression: "showDepositModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block" },
                [
                  _c(
                    "div",
                    { staticClass: "w-100 fs-14 grey-color text-left" },
                    [
                      _vm._v(
                        "Для подачи предложения в закупочной сессии на лицевом счете Вашей организации не хватает " +
                          _vm._s(_vm.needDep) +
                          " рублей."
                      ),
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "w-100 fs-14 fw-600 grey-color form-group" },
                    [
                      _c("b-input", {
                        directives: [
                          {
                            name: "stop-number-mousewheel",
                            rawName: "v-stop-number-mousewheel",
                          },
                        ],
                        attrs: {
                          type: "number",
                          step: "any",
                          min: _vm.needDep,
                          state: _vm.checkDepositValid,
                        },
                        model: {
                          value: _vm.neededDeposit,
                          callback: function ($$v) {
                            _vm.neededDeposit = $$v
                          },
                          expression: "neededDeposit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-input", {
                            attrs: {
                              type: "email",
                              placeholder: "Введите почту",
                              state: _vm.checkEmailValid,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "custom-green" },
                              on: { click: _vm.sendInvoiceToEmail },
                            },
                            [_vm._v("ОТПРАВИТЬ НА E-MAIL")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase ml-2",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.downloadInvoice },
                            },
                            [_vm._v("СКАЧАТЬ PDF")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                title: "Подписать предложение",
                size: "lg",
                centered: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              on: { close: _vm.cancelSignModal },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("text-row", {
                            attrs: { label: "Закупочная сессия" },
                            model: {
                              value: _vm.purchase.reg_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase, "reg_number", $$v)
                              },
                              expression: "purchase.reg_number",
                            },
                          }),
                          _c("text-row-price", {
                            attrs: { label: "Итоговая стоимость предложения" },
                            model: {
                              value: _vm.form.total_cost,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "total_cost", $$v)
                              },
                              expression: "form.total_cost",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("XML")]
                            ),
                          ]),
                          !_vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: {
                                  disabled: "",
                                  rows: "20",
                                  "no-resize": "",
                                  "no-auto-shrink": "",
                                  wrap: "off",
                                },
                                model: {
                                  value: _vm.formXml,
                                  callback: function ($$v) {
                                    _vm.formXml = $$v
                                  },
                                  expression: "formXml",
                                },
                              })
                            : _vm._e(),
                          _vm.isSignedXml
                            ? _c("b-form-textarea", {
                                staticClass: "text-monospace fs-12",
                                attrs: { disabled: "", rows: "20" },
                                model: {
                                  value: _vm.signedFormXml,
                                  callback: function ($$v) {
                                    _vm.signedFormXml = $$v
                                  },
                                  expression: "signedFormXml",
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c("text-row", {
                    attrs: {
                      label: "Сертификат",
                      value: _vm.certificate?.subjectName,
                    },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: {
                        variant: "custom-outline-secondary",
                        disabled: _vm.isDataSending,
                      },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("ОТМЕНИТЬ")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isSigningXml,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled: !_vm.certificate || !_vm.summLimit,
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("ПОДПИСАТЬ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            rounded: "",
                            opacity: "0.6",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                variant: "custom-green",
                                disabled: !_vm.certificate || !_vm.summLimit,
                              },
                              on: { click: _vm.sendData },
                            },
                            [_vm._v("ОТПРАВИТЬ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }