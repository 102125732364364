var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color mb-4",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Извещения")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("filter-multiple-select", {
            attrs: {
              options: _vm.forFilters.statuses,
              label: "Статус закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите статус",
            },
            model: {
              value: _vm.filter.status_id,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "status_id", $$v)
              },
              expression: "filter.status_id",
            },
          }),
          _c("filter-input-text", {
            attrs: {
              label: "Рег.номер закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Введите рег.номер закупки",
            },
            model: {
              value: _vm.filter.reg_number,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "reg_number", $$v)
              },
              expression: "filter.reg_number",
            },
          }),
          _c("filter-multiple-select", {
            attrs: {
              options: _vm.forFilters.purchaseCategories,
              label: "Предмет закупки",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Выберите предметы закупки",
            },
            model: {
              value: _vm.filter.purchase_categories,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "purchase_categories", $$v)
              },
              expression: "filter.purchase_categories",
            },
          }),
          _c("filter-input-text", {
            attrs: {
              label: "Поставщик",
              "label-class": "grey-color",
              lg: "6",
              md: "6",
              placeholder: "Введите название поставщика",
            },
            model: {
              value: _vm.filter.provider,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "provider", $$v)
              },
              expression: "filter.provider",
            },
          }),
          _c(
            "b-col",
            { staticClass: "mb-3 text-right", attrs: { md: "12" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  attrs: { variant: "custom-green" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.getPurchases(true)
                    },
                  },
                },
                [_vm._v("НАЙТИ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase ml-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cleanFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("СБРОС")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.purchases, function (purchase) {
        return _c(
          "div",
          { key: purchase.id },
          [
            purchase.is_external
              ? _c("out-atmo-purchase-card", {
                  attrs: { purchase: purchase },
                  on: { refresh: _vm.refresh },
                })
              : _c("customer-purchase-card", {
                  staticClass: "mb-3",
                  attrs: { purchase: purchase },
                  on: {
                    cancelHoldTime: _vm.cancelHoldTime,
                    cancelPublishing: _vm.cancelPurchasePublishing,
                    copy: _vm.copyPurchase,
                    onDelete: _vm.preDelete,
                    onPublish: _vm.prePublish,
                    prolong: _vm.prolongPurchase,
                    refresh: _vm.refresh,
                  },
                }),
          ],
          1
        )
      }),
      !_vm.purchases.length && !_vm.loading ? _c("empty-request") : _vm._e(),
      _vm.purchases
        ? _c("b-pagination", {
            staticClass: "mb-3",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getPurchases()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "delete-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Удалить закупку",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите удалить закупку " +
                _vm._s(_vm.number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showDeleteModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _vm._v("  "),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-danger" },
                  on: { click: _vm.deletePurchase },
                },
                [_vm._v("Удалить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "publish-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Опубликовать закупку",
          },
          on: { hidden: _vm.clearEmails },
          model: {
            value: _vm.showPublishModal,
            callback: function ($$v) {
              _vm.showPublishModal = $$v
            },
            expression: "showPublishModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left mb-3" }, [
            _vm._v(
              "Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке."
            ),
          ]),
          _c(
            "div",
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    "close-on-select": true,
                    multiple: true,
                    options: _vm.organizations,
                    "preserve-search": false,
                    taggable: true,
                    "deselect-label": "Отменить",
                    label: "name",
                    placeholder:
                      "Выберите организации / укажите адреса электронной почты",
                    "select-label": "Выбрать",
                    "selected-label": "Выбрано",
                    "tag-placeholder": "Добавить",
                    "track-by": "id",
                  },
                  on: { tag: _vm.addTag },
                  model: {
                    value: _vm.emails,
                    callback: function ($$v) {
                      _vm.emails = $$v
                    },
                    expression: "emails",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [_vm._v("Нет данных.")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [
                      _vm._v(
                        "Записей, соответствующих вашему запросу, не найдено."
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100 text-",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: {
                    click: function ($event) {
                      _vm.showPublishModal = false
                    },
                  },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: !_vm.emails.length,
                        variant: "custom-green",
                      },
                      on: { click: _vm.publishPurchase },
                    },
                    [_vm._v("Опубликовать")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }