var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4 column-992",
            },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color m-0 main_text",
                  staticStyle: { "line-height": "1" },
                },
                [_vm._v("Системные уведомления")]
              ),
              _c(
                "b-btn",
                {
                  staticStyle: { background: "transparent" },
                  attrs: { variant: "custom-unstyle-grey" },
                  on: { click: _vm.goToSettings },
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { src: "/images/ring.svg", alt: "ring" },
                  }),
                  _vm._v(" Настроить получение уведомлений "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "cTable",
        attrs: {
          "show-empty": "",
          "empty-text": "Уведомления отсутствуют",
          items: _vm.notifications,
          fields: _vm.fields,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
        },
        on: {
          "row-clicked": function ($event) {
            return _vm.showMessage($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(read_at)",
            fn: function (row) {
              return [
                _c("span", { class: { new: !row.item.read_at } }, [
                  _vm._v(
                    " " +
                      _vm._s(!row.item.read_at ? "Новое" : "Прочитано") +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm.getDateTimeFormat(row.item.created_at)) + " "
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center d-flex justify-content-center" },
            [
              _vm.notifications.length
                ? _c("b-pagination", {
                    staticClass: "mt-4 mb-3 mx-auto",
                    attrs: {
                      "total-rows": _vm.notifications.length,
                      "per-page": _vm.perPage,
                    },
                    on: { input: _vm.$scrollToTop },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-1",
            title: _vm.showingMess.type_name,
            visible: _vm.showModal,
            "hide-footer": "",
            centered: "",
          },
          on: { hidden: _vm.hideModal },
        },
        [
          _vm.showingMess.data
            ? _c("p", { staticClass: "my-4" }, [
                _vm._v(" " + _vm._s(_vm.showingMess.data.message) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "w-100 d-flex justify-content-end" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "text-uppercase",
                  attrs: { variant: "custom-green" },
                  on: { click: _vm.hideModal },
                },
                [_vm._v("Закрыть")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }