<template>
    <div>
        <b-row>
            <b-col class="d-flex align-items-center justify-content-between mb-4 column-992">
                <p class="fs-32 grey-color m-0 main_text" style="line-height: 1">Создание пользователя</p>
            </b-col>
        </b-row>

        <b-card class="transparent-card" no-body>
            <form-row-edit-text v-model="form.user.last_name" :v="$v.form.user.last_name" label="Фамилия" @change="onLastNameChange" />
            <form-row-edit-text v-model="form.user.first_name" :v="$v.form.user.first_name" label="Имя" />
            <form-row-edit-text v-model="form.user.middle_name" label="Отчество" />
            <form-row-edit-phone :v="$v.form.user.phone" :value="form.user.phone" label="Телефон" @value="form.user.phone = $event" />
            <b-form-group :state="$v.form.user.post.$invalid && showErrors ? !$v.form.user.post.$invalid : null" class="w-100" label="Должность" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
                <b-form-select v-model="form.user.post" :state="!$v.form.user.post.$invalid" aria-describedby="formdata_post_liveFeedback" class="fs-14">
                    <option disabled value="">Выберите должность</option>
                    <option>Руководитель организации</option>
                    <option>Администратор организации</option>
                    <option>Лицо, уполномоченное на размещение информации и документов</option>
                    <option>Лицо, имеющее право подписи документов от имени организации</option>
                </b-form-select>
                <b-form-invalid-feedback v-if="showErrors && $v.form.user.post.$invalid" id="formdata_post_liveFeedback" :state="!$v.form.user.post.$invalid && showErrors"> Поле обязательно для заполнения. </b-form-invalid-feedback>
            </b-form-group>
            <form-row-edit-text label="Логин" v-model="form.user.username" description="Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля" disabled />
            <form-row-edit-text label="Пароль" v-model="form.user.password" @value="form.user.password = $event" :v="$v.form.user.password" type="password" />
            <form-row-edit-text label="Подтверждение пароля" v-model="form.user.password_confirmation" @value="form.user.password_confirmation = $event" :v="$v.form.user.password_confirmation" type="password" />
            <form-row-edit-email :v="$v.form.user.email" :value="form.user.email" label="Email" @value="form.user.email = $event" />
            <form-row-edit-text v-model.trim="form.certificate.thumbprint" :v="$v.form.certificate.thumbprint" label="SHA1-код сертификата (отпечаток/fingerprint)" />
            <!--            <b-row class="py-3">-->
            <!--                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Время работы</b-col>-->
            <!--                <b-col class="fs-14 grey-color" md="6">-->
            <!--                    <b-row class="py-3">-->
            <!--                        <b-checkbox v-model="form.user.ignore_work_time"></b-checkbox>-->
            <!--                        Без ограничений по времени-->
            <!--                    </b-row>-->
            <!--                    <b-row>-->
            <!--                        <b-col class="fs-14 grey-color" md="4">-->
            <!--                            <b>Начало</b><br/>-->
            <!--                            Часы:-->
            <!--                            <b-form-input v-model="form.user.work_start_hour" v-stop-number-mousewheel :disabled="form.user.ignore_work_time" max="23" min="0" type="number"/>-->
            <!--                            Минуты:-->
            <!--                            <b-form-input v-model="form.user.work_start_minute" v-stop-number-mousewheel :disabled="form.user.ignore_work_time" max="59" min="0" type="number"/>-->
            <!--                        </b-col>-->
            <!--                        <b-col class="fs-14 grey-color" md="4">-->
            <!--                            <b>Конец</b><br/>-->
            <!--                            Часы:-->
            <!--                            <b-form-input v-model="form.user.work_end_hour" v-stop-number-mousewheel :disabled="form.user.ignore_work_time" max="23" min="0" type="number"/>-->
            <!--                            Минуты:-->
            <!--                            <b-form-input v-model="form.user.work_end_minute" v-stop-number-mousewheel :disabled="form.user.ignore_work_time" max="59" min="0" type="number"/>-->
            <!--                        </b-col>-->
            <!--                    </b-row>-->
            <!--                </b-col>-->
            <!--            </b-row>-->
            <b-row class="pt-3 pl-3">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mr-3" variant="custom-outline-secondary" @click="onCancel"> Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.form.$invalid" variant="custom-green" @click="onSubmit">Добавить</b-button>
                </b-overlay>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, helpers, minLength, required, sameAs } from 'vuelidate/lib/validators';
import Auth from '@/services/api/Auth';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const thumbprint = helpers.regex('thumbprint', /^[A-Fa-f\d(^\s)]+$/);
const allowable = helpers.regex('allowable', /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/);
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserCreate',
    metaInfo: {
        title: 'Пользователи организации - Создание пользователя'
    },
    mixins: [validationMixin],
    data() {
        return {
            isDataSending: false,
            showErrors: true,
            loading: true,
            form: {
                user: {
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    phone: '',
                    email: '',
                    post: '',
                    username: '',
                    password: '',
                    password_confirmation: '',
                    work_start_hour: 9,
                    work_start_minute: 0,
                    work_end_hour: 18,
                    work_end_minute: 0,
                    ignore_work_time: true
                },
                certificate: {
                    thumbprint: ''
                }
            }
        };
    },
    validations: {
        form: {
            user: {
                last_name: { required },
                first_name: { required },
                post: { required, minLength: minLength(3) },
                phone: { required, phone },
                email: { required, email },
                password: { required, minLength: minLength(8), allowable, containsSymbols },
                password_confirmation: {
                    required,
                    confirmPassword: sameAs((form) => form.password)
                }
            },
            certificate: {
                thumbprint: { required, thumbprint }
            }
        }
    },
    mounted() {},
    methods: {
        async onLastNameChange() {
            this.form.user.username = await Auth.getUsername(this.form.user.last_name);
            console.log(this.form.user.username);
        },
        onCancel() {
            this.$router.push({ name: 'UsersList' }).catch(() => {});
        },
        async onSubmit() {
            this.showErrors = true;
            // if (!this.$v.$invalid) {
            this.isDataSending = true;
            if (await this.$api.cabinet.users.post({ form: this.form })) {
                this.onCancel();
            }
            this.isDataSending = false;

            // Users.store(this.form)
            //   .then(() => {
            //     this.pushToast({
            //       text: 'Пользователь успешно добавлен',
            //       title: 'Успех',
            //       variant: 'green',
            //     });
            //     this.$router.push({ name: 'UsersList' }).catch(() => {});
            //   })
            //   // eslint-disable-next-line no-console
            //   .catch((error) => console.error(error));
            // }
        }
    },
    computed: {
        phoneState: function () {
            let re = /^7[0-9+]{10}$/;
            return re.test(this.form.user.phone);
        }
    }
};
</script>

<style>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>
