import axios from 'axios';

export default {
    getQuotationRequests(filters) {
        return axios
            .get('/quotation_requests', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    },
    get(id) {
        return axios.get(`/quotation_requests/${id}`);
    },
    getOffers(filters) {
        return axios.post('/cabinet/provider/offers', { filters: filters });
    },
    downloadPDF(id) {
        return axios.get(`/quotation_requests/${id}/download`, { responseType: 'blob' });
    }
};
