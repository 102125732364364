<template>
    <div>
        <system-preloader v-if="isLoading"></system-preloader>
        <div v-if="!isLoading">
            <text-header>Создание запроса на возврат средств</text-header>
            <h5 class="font-weight-bold my-3 text-danger">Предупреждаем Вас о том, что с момента отправки запроса на возврат средств сумма, указанная в нем, перестанет быть доступной на лицевом счете Вашей организации.</h5>
            <text-row-price label="Доступные на ЛС средства" v-model="statistics.available" />
            <form-row-edit-price label="Сумма возврата" v-model="form.amount" :v="$v.form.amount" :limit="statistics.available" />
            <form-row-single-document-upload-new title="Реквизиты для возврата средств" v-model="form.document" :v="$v.form.document" />
            <div class="text-left">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2 fs-14" @click="onCancel">Отмена</b-button>
                <b-button variant="custom-green" class="text-uppercase mx-2 fs-14" @click="validateForm" :disabled="$v.form.$invalid || !$store.getters.getCanSign">Подписать и отправить</b-button>
                <span v-if="!$store.getters.getCanSign" class="text-danger pt-1">Подписание возможно только при авторизации при помощи ЭЦП</span>
            </div>
        </div>
        <b-modal ref="sign-form-modal" title="Подписать заявку" size="lg" v-model="showSignModal" scrollable centered no-close-on-esc no-close-on-backdrop @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3 px-3">
                    <b-tab active>
                        <template slot="title">
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <text-header>Организация</text-header>
                        <text-row label="Наименование организации" v-model="formObject.refund_request.organization.name" />
                        <text-row label="ИНН" v-model="formObject.refund_request.organization.inn" />
                        <text-row label="КПП" v-model="formObject.refund_request.organization.kpp" />
                        <text-row label="ОГРН" v-model="formObject.refund_request.organization.ogrn" />
                        <text-header>Пользователь</text-header>
                        <text-row label="ФИО" v-model="formObject.refund_request.user.name" />
                        <text-header>Запрос на возврат средств</text-header>
                        <text-row-price label="Сумма возврата" v-model="formObject.refund_request.amount" />
                        <text-row-documents label="Реквизиты для возврата средств" v-model="formDocuments" />
                    </b-tab>
                    <b-tab>
                        <template slot="title">
                            <span class="system_link-style fs-16">Xml представление</span>
                        </template>
                        <b-form-textarea disabled v-model="signedFormXml" rows="20"></b-form-textarea>
                    </b-tab>
                </b-tabs>
                <b-row class="py-5 px-3">
                    <b-col md="3" class="fs-14 grey-color d-flex align-items-center"> Сертификат </b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <b-input disabled :value="currentCertSubject"></b-input>
                    </b-col>
                </b-row>
            </div>
            <div slot="modal-footer" class="w-100 px-3">
                <b-button variant="custom-outline-secondary" class="mr-2 text-uppercase" @click="cancelSignModal" :disabled="isDataSending">Отмена</b-button>
                <b-overlay :show="isSigningXml" v-if="!isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="certIndex == null" @click="signForm">Подписать</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" v-if="isSignedXml" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-green" class="text-uppercase" :disabled="certIndex == null" @click="sendData">Отправить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CabinetProviderAccounting from '@/services/api/cabinet/provider/Accounting';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import CadesMixins from '@/mixins/CadesMixins';
import Accounting from '@/services/api/cabinet/provider/Accounting';

export default {
    name: 'cabinet-provider-accounting-refund-request-create',
    mixins: [CadesMixins],
    data() {
        return {
            isLoading: true,
            statistics: {
                total: 0,
                available: 0,
                blocked: 0
            },
            form: {
                amount: 0,
                document: null
            },
            showSignModal: false,
            isSigningXml: false,
            isSignedXml: false,
            currentCertSubject: '',
            isDataSending: false,
            signedFormXml: ''
        };
    },
    validations() {
        return {
            form: {
                amount: { required, minValue: minValue(0.01), maxValue: maxValue(this.statistics.available) },
                document: { required }
            }
        };
    },
    async mounted() {
        const response = await CabinetProviderAccounting.getStatistics();
        this.statistics = response.data;
        this.isLoading = false;
    },
    methods: {
        validateForm() {
            if (this.hasCertificates() === false) {
                this.loadCertificates();
            } else {
                this.showSignForm();
            }
            this.$on('certificates_loaded', () => {
                this.showSignForm();
            });
        },
        showSignForm() {
            this.showSignModal = true;
            this.signedFormXml = this.formXml;
            let parsedCerts = this.$store.state.parsedCertificates;
            this.certIndex = this.$store.state.currentCertificateIndex;
            let currentParsedCert = parsedCerts[this.certIndex];
            this.currentCertSubject = currentParsedCert.subject;
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await this.SignXMLForm(this.formXml)
                .then((resp) => {
                    return resp;
                })
                .catch((error) => {
                    this.pushToast({
                        text: error.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return false;
                })
                .finally(() => {
                    this.isSigningXml = false;
                });
            this.isSignedXml = !!this.signedFormXml;
        },
        async sendData() {
            this.isDataSending = true;
            await Accounting.storeRefundRequest(this.form, this.signedFormXml).finally(() => {
                this.isDataSending = false;
            });
            this.onCancel();
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        onCancel() {
            this.$router.push({ name: 'ProviderAccountCabinet' });
        }
    },
    computed: {
        formDocuments() {
            return [this.form.document];
        },
        formObject() {
            return {
                refund_request: {
                    organization: {
                        id: this.$store.state.organization.id,
                        name: this.$store.state.organization.name,
                        inn: this.$store.state.organization.inn,
                        kpp: this.$store.state.organization.kpp,
                        ogrn: this.$store.state.organization.ogrn
                    },
                    user: {
                        name: this.$store.state.user.name
                    },
                    amount: this.form.amount,
                    document: this.form.document
                        ? {
                              id: this.form.document.id ?? null,
                              name: this.form.document.name ?? ''
                          }
                        : null
                }
            };
        },
        formXml() {
            return this.json2xml(this.formObject);
        }
    }
};
</script>
