<template>
    <div>
        <b-row>
            <b-col>
                <p class="fs-32 grey-color mb-4 main_text" style="line-height: 1">Извещения</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <empty-request></empty-request>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'Main'
};
</script>
