<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-container>
                <client-header :customer="true"></client-header>
                <filters :provider="false" placeholder-postfix="заказчика" :form="form" @applyFilters="applyFilters" @clearForm="clearFilter" />
            </b-container>
        </b-container>
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="greyBg pb-4">
            <b-container v-if="!loading && !clients.length">
                <empty-request></empty-request>
            </b-container>
            <b-container>
                <div style="width: 100%">
                    <b-pagination align="center" @change="applyFilters" v-model="meta.current_page" :total-rows="meta.total" :per-page="meta.per_page" style="padding-bottom: 0" />
                </div>
                <b-row class="pt-4">
                    <client-card v-for="item in clients" :key="item.id" :client="item"></client-card>
                </b-row>
                <div style="width: 100%">
                    <b-pagination align="center" @change="applyFilters" v-model="meta.current_page" :total-rows="meta.total" :per-page="meta.per_page" />
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import ClientHeader from '@/components/clients/components/ClientHeader';
import Filters from '@/components/clients/components/Filters';
import ClientCard from '@/components/clients/components/CustomerCard';

export default {
    components: {
        ClientCard,
        Filters,
        ClientHeader
    },
    metaInfo: {
        title: 'Реестр заказчиков'
    },
    name: 'Customer',
    data() {
        return {
            loading: true,
            activeProvider: this.$route.path === '/clients/providers',
            clients: [],
            count: 0,
            user: {},
            form: {
                organization_name: '',
                code: '',
                inn: '',
                level: null,
                kpp: '',
                status: null,
                phone: '',
                address: '',
                email: '',
                suggestionOptions: {
                    token: process.env.VUE_APP_DADATA_TOKEN,
                    type: 'ADDRESS',
                    scrollOnFocus: false,
                    triggerSelectOnBlur: false,
                    triggerSelectOnEnter: false,
                    addon: 'none'
                },
                fiasAddress: ''
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    created() {
        this.user = this.$store.state.user;
    },
    mounted() {
        this.applyFilters();
    },
    methods: {
        applyFilters(value = 1) {
            this.loading = true;
            this.meta.current_page = value;
            this.getCustomers();
        },
        clearFilter() {
            this.form.organization_name = '';
            this.form.fiasAddress = '';
            this.form.inn = '';
            this.form.kpp = '';
            this.form.phone = '';
            this.form.email = '';
            this.form.sort = '';
            this.applyFilters();
        },
        async getCustomers() {
            const responseData = await axios.post('/clients/customers', { ...this.form, page: this.meta.current_page });
            this.clients = responseData.data.data;
            this.meta = responseData.data.meta;
            this.$scrollToTop();
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}
</style>
