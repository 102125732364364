<template>
    <div>
        <b-row>
            <b-col>
                <p class="fs-32 grey-color mb-4 main_text" style="line-height: 1">Мои предложения</p>
            </b-col>
        </b-row>
        <b-row class="mb-4 filtres">
            <b-col cols="12" sm="12" md="6" xl="4" class="mb-3">
                <multiselect
                    :options="purchaseStatuses"
                    v-model="filters.purchaseStatus"
                    :multiple="true"
                    :clear-on-select="false"
                    :preserve-search="false"
                    placeholder="Выберите статус извещения"
                    deselect-label="Отменить"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    label="title"
                    track-by="title">
                    <span slot="noOptions">Нет данных.</span>
                    <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                </multiselect>
            </b-col>
            <b-col cols="12" sm="12" md="6" xl="4" class="mb-3">
                <multiselect :options="offerStatuses" v-model="filters.offerStatus" :multiple="true" :clear-on-select="false" :preserve-search="false" placeholder="Выберите статус предложения" deselect-label="Отменить" select-label="Выбрать" selected-label="Выбрано" label="title" track-by="title">
                    <span slot="noOptions">Нет данных.</span>
                    <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                </multiselect>
            </b-col>
            <b-col cols="12" sm="12" md="12" xl="4" class="mb-3">
                <div class="d-flex justify-content-between">
                    <b-btn @click.prevent="applyFilters" variant="custom-green" class="text-uppercase">НАЙТИ</b-btn>
                    <b-btn @click.prevent="cleanFilters" variant="custom-outline-secondary" class="text-uppercase">СБРОС</b-btn>
                </div>
            </b-col>
        </b-row>
        <b-row v-for="purchase in purchases" :key="purchase.id" class="mb-3">
            <purchase-provider-cabinet :purchase="purchase" @checkProvider="checkProvider" :checkingProvider="checkingProvider"> </purchase-provider-cabinet>
        </b-row>
        <b-row v-if="!purchases.length && !loading">
            <b-col>
                <empty-request></empty-request>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PurchasesApi from '@/services/api/Purchases';
import PurchaseProviderCabinet from '@/components/purchases/PurchaseStatusCard/PurchaseProviderCabinet';

export default {
    name: 'Main',
    components: { PurchaseProviderCabinet },
    data() {
        return {
            filters: {
                offerStatus: [
                    { title: 'Актуально', id: 1 },
                    { title: 'Победитель', id: 4 }
                ],
                purchaseStatus: null
            },
            purchaseStatuses: [
                { title: 'Идет прием предложений', id: 1 },
                { title: 'Подведены итоги', id: 2 },
                { title: 'Закупка отменена', id: 7 },
                { title: 'Закупка не состоялась', id: 8 },
                // {title: 'Черновик', id: 11},
                { title: 'Заблокирован', id: 12 }
                // {title: 'Сохранен и подписан', id: 13},
            ],
            offerStatuses: [
                { title: 'Актуально', id: 1 },
                { title: 'Отозвано', id: 2 },
                { title: 'Отклонено', id: 3 },
                { title: 'Победитель', id: 4 }
            ],
            purchases: {},
            checkingProvider: false,
            loading: true
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            await this.applyFilters();
            // window.Echo.channel('purchase_change_status').listen('PurchaseChangeStatusBroadcast', (response) => {
            //   console.log(this.checkUpdate(response));
            //   if (this.checkUpdate(response)) {
            //     this.applyFilters(false);
            //   }
            // });
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getOffers();
        },
        cleanFilters() {
            this.filters.offerStatus = null;
            this.filters.purchaseStatus = null;
            this.applyFilters();
        },
        getOffers() {
            PurchasesApi.getOffers(this.filters)
                .then((purchases) => {
                    this.purchases = purchases.data.data;
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        checkUpdate(response) {
            return Boolean(this.purchases.find((x) => x.id === response.data));
        },
        checkProvider(purchase_id) {
            this.checkingProvider = true;
            PurchasesApi.checkProvider(purchase_id)
                .then(() => {
                    this.$router.push({ name: 'PurchasesNewOffer', params: { id: purchase_id } }).catch(() => {});
                })
                .catch((error) => {
                    this.pushToast({
                        text: error.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                })
                .finally(() => {
                    this.checkingProvider = false;
                });
        }
    },
    beforeDestroy() {
        // window.Echo.leave('purchase_change_status');
    }
};
</script>

<style>
.filtres .multiselect__tag {
    margin-top: 3px !important;
    margin-bottom: -3px !important;
}
</style>
