<template>
    <b-card class="fs-14 my-2">
        <b-card-title class="fs-14">
            <b-row>
                <b-col md="8" class="my-1">
                    Поставщик:<br />
                    <router-link :to="{ name: 'ProviderShow', params: { id: offer.organization.id } }" target="_blank" class="font-weight-bold">
                        <img v-if="offer.organization && offer.organization.region_code === '02'" src="/images/icons/02.png" alt="02" class="mr-2" style="max-height: 20px" />
                        {{ offer.organization.name }}
                    </router-link>
                </b-col>
                <b-col md="4" class="my-1">
                    Цена предложения, руб.:<br />
                    <strong>{{ $formatPrice(offer.price) }}</strong>
                </b-col>
            </b-row>
        </b-card-title>
        <b-row>
            <b-col class="my-1" md="4">
                Время подачи предложения:<br />
                <strong>{{ getDateTimeFormatSeconds(offer.created_at) }}</strong
                ><br />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="my-1" md="4">
                <p>
                    Рег.номер ТРУ: <strong>{{ offer.ktru_item.reg_number }}</strong>
                </p>
                <p>
                    Наименование ТРУ: <strong>{{ offer.ktru_item.name }}</strong>
                </p>
                <p>
                    Код ОКПД2: <strong>{{ offer.ktru_item.okpd_code }}</strong>
                </p>
                <p>
                    Единица измерения: <strong>{{ offer.ktru_item.okei_local_symbol }}</strong>
                </p>
                <p>
                    Страна происхождения: <strong>{{ offer.ktru_item.country_of_origin_name }}</strong>
                </p>
            </b-col>
            <b-col md="8">
                <p v-if="offer.ktru_item.description"><strong>Описание</strong> - {{ offer.ktru_item.description }}</p>
                <ul v-if="offer.ktru_item.specs.length > 0">
                    <li v-for="(spec, index) in offer.ktru_item.specs" :key="'spec_' + index">
                        <strong>{{ spec.name }}</strong> - {{ spec.value }}
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    name: 'ImportSubstitutionOfferCard',
    props: ['offer', 'index'],
    data() {
        return {
            ktruDetailsVisible: false
        };
    }
};
</script>

<style scoped>
a {
    color: var(--green-color);
}
</style>
