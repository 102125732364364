<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between column-992">
                <p class="fs-32 grey-color mb-4 main_text" style="line-height: 1">Последние закупки</p>
                <ul class="tabs mb-3">
                    <li v-for="(tab, index) in tabs" :key="'key_' + index">
                        <router-link :to="{ name: 'ShowPurchases', params: { cat: tab.path } }" exact>{{ tab.title }}</router-link>
                    </li>
                </ul>
            </b-col>
        </b-row>
        <show-purchases :purchases="purchases" v-if="!loading" @refresh="fetchData"></show-purchases>
        <empty-request v-if="!loading && !purchases.length"></empty-request>
    </div>
</template>

<script>
const ShowPurchases = () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/lastPurchases/Show');
import MainPage from '../../../services/api/cabinet/customer/MainPage';

export default {
    name: 'Main',
    components: {
        ShowPurchases
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Все закупки',
                    path: 'all'
                },
                {
                    title: 'Закупки в ИС',
                    path: 'in_atmo'
                },
                {
                    title: 'Внесистемные закупки',
                    path: 'outside_atmo'
                }
            ],
            purchases: {
                purchases: 'purchases'
            },
            loading: true
        };
    },
    mounted() {
        this.fetchData();
        // window.Echo.channel('purchase_change_status').listen('PurchaseChangeStatusBroadcast', (response) => {
        //   if (this.checkUpdate(response)) {
        //     this.fetchData();
        //   }
        // });
    },
    beforeDestroy() {
        // window.Echo.leave('purchase_change_status');
    },
    watch: {
        $route: 'fetchData'
    },
    methods: {
        async fetchData() {
            await MainPage.getData(this.$route.params.cat)
                .then((res) => {
                    this.purchases = res.data;
                    this.loading = false;
                })
                .catch((error) => console.log(error));
        },
        checkUpdate(response) {
            return Boolean(this.purchases.find((x) => x.id === response.data));
        }
    }
};
</script>

<style scoped>
.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.tabs li .router-link-active {
    color: var(--green-color) !important;
    border-bottom: 2px solid var(--green-color) !important;
}

@media screen and (max-width: 1200px) {
    .main_text {
        font-size: 26px !important;
    }
}

@media screen and (max-width: 992px) {
    .column-992 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
</style>
