<template>
    <div>
        <b-row>
            <b-col class="text-right" md="12">
                <b-btn :href="$links.purchase_categories" class="fs-14 my-1 mx-2" size="lg" style="height: 40px" target="_blank" variant="danger"> Категории предметов закупок</b-btn>
                <b-btn :href="$links.manual_ktru_requests_add" class="fs-14 my-1 mx-2" size="lg" style="height: 40px" target="_blank" variant="danger"> Инструкция по добавлению позиции КТРУ</b-btn>
                <b-btn :href="$links.ktru_requests_video_manual" class="fs-14 my-1 ml-2" size="lg" style="height: 40px" target="_blank" variant="danger">
                    <img alt="documentation" class="mx-1" src="/images/youtube.svg" style="height: 16px" />
                    Видеоинструкция
                </b-btn>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="8">
                <p class="fs-32 grey-color">Запросы на добавление позиций КТРУ</p>
            </b-col>
            <b-col class="text-right" md="4">
                <b-btn :to="{ name: 'KtruRequestsCreate' }" class="fs-14 my-1" size="sm" variant="custom-green"> Сформировать запрос</b-btn>
            </b-col>
        </b-row>
        <div class="table-responsive">
            <vuetable
                ref="vuetable"
                :append-params="{ filter: filter }"
                :css="vuetable2Config.table"
                :fields="columns"
                :http-fetch="fetchData"
                :load-on-start="true"
                :query-params="queryParams"
                :sort-order="sortOrder"
                api-url="/ktru_requests"
                data-path="data"
                no-data-template="Данные не найдены"
                pagination-path="meta"
                @vuetable:pagination-data="onPaginationData">
                <template #name="props">
                    <p><strong>Название:</strong> {{ props.rowData.name }}</p>
                    <p v-if="props.rowData.description"><strong>Описание:</strong> {{ props.rowData.description }}</p>
                </template>
                <div slot="specs" slot-scope="props" style="max-width: 450px">
                    <ul>
                        <li v-for="(item, key) in props.rowData.specs" v-bind:key="item + key" style="word-wrap: break-word">
                            <span style="font-weight: 900">{{ item.name }}</span
                            >: {{ item.value }}
                        </li>
                    </ul>
                </div>
                <template #status_id="props">
                    <template v-if="props.rowData.status_id === 1">
                        <b>Черновик</b>
                        <br />
                        <br />
                        <b-button :to="{ name: 'KtruRequestsEdit', params: { id: props.rowData.id } }" class="mb-1 w-100" size="sm">Редактировать</b-button>
                        <b-button class="mb-1 w-100" size="sm" variant="danger" @click="deleteRequest(props.rowData.id)">Удалить</b-button>
                    </template>
                    <template v-if="props.rowData.status_id === 2">
                        <b>На рассмотрении</b>
                    </template>
                    <template v-if="props.rowData.status_id === 3">
                        <b>Принято</b>
                        <br />
                        <br />
                        Рег.номер созданной позиции КТРУ - <strong>{{ props.rowData.ktru_item_reg_number }}</strong>
                    </template>
                    <template v-if="props.rowData.status_id === 4">
                        <b>Отклонено</b>. <br />
                        <b>Комментарий:</b> {{ props.rowData.comment }}<br />
                        <br />
                        <b-button :to="{ name: 'KtruRequestsEdit', params: { id: props.rowData.id } }" class="mb-1 w-100" size="sm">Редактировать</b-button>
                        <b-button class="mb-1 w-100" size="sm" variant="danger" @click="deleteRequest(props.rowData.id)">Удалить</b-button>
                    </template>
                </template>
            </vuetable>
        </div>
        <div class="float-right d-flex">
            <vuetable-pagination ref="pagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onChangePage" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'KtruRequestsList',
    data() {
        return {
            columns: [
                { name: 'name', sortField: 'name', title: 'Название/описание', width: '200px' },
                { name: 'specs', title: 'Спецификация', width: '450px' },
                { name: 'status_id', title: 'Статус', width: '200px' }
            ],
            sortOrder: [{ field: 'created_at', direction: 'desc' }],
            filter: {}
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {},
        queryParams(sortOrder, currentPage, perPage) {
            return {
                sortField: sortOrder[0].sortField,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage
            };
        },
        deleteRequest(requestId) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите удалить запрос на добавление позиции КТРУ?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        axios.delete('/ktru_requests/' + requestId).then(() => {
                            this.$refs.vuetable.refresh();
                        });
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.error(err);
                });
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        fetchData(apiUrl, httpOptions) {
            return axios.get(apiUrl, httpOptions);
        }
    }
};
</script>
