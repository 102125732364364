<template>
    <div v-if="!loading">
        <b-row>
            <b-col class="d-flex align-items-center justify-content-between mb-4 column-992">
                <p class="fs-32 grey-color m-0 main_text" style="line-height: 1">Редактирование пользователя {{ form.user.full_name }}</p>
            </b-col>
        </b-row>

        <b-card class="transparent-card" no-body>
            <form-row-edit-text v-model="form.user.last_name" :v="$v.form.user.last_name" disabled label="Фамилия" />
            <form-row-edit-text v-model="form.user.first_name" :v="$v.form.user.first_name" disabled label="Имя" />
            <form-row-edit-text v-model="form.user.middle_name" disabled label="Отчество" />
            <form-row-edit-phone :v="$v.form.user.phone" :value="form.user.phone" disabled label="Телефон" @value="form.user.phone = $event" />
            <b-form-group :state="!$v.form.user.post.$invalid" class="w-100" label="Должность" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
                <b-form-select v-model="form.user.post" :state="!$v.form.user.post.$invalid" aria-describedby="formdata_post_liveFeedback" class="fs-14">
                    <option disabled value="">Выберите должность</option>
                    <option>Руководитель организации</option>
                    <option>Администратор организации</option>
                    <option>Лицо, уполномоченное на размещение информации и документов</option>
                    <option>Лицо, имеющее право подписи документов от имени организации</option>
                </b-form-select>
                <b-form-invalid-feedback v-if="$v.form.user.post.$invalid" id="formdata_post_liveFeedback" :state="!$v.form.user.post.$invalid"> Поле обязательно для заполнения. </b-form-invalid-feedback>
            </b-form-group>
            <form-row-edit-text label="Логин" v-model="form.user.username" description="Запомните или запишите логин - он будет нужен в случае необходимости сброса пароля" disabled />
            <form-row-edit-text label="Новый пароль" v-model="form.user.password" @value="form.user.password = $event" :v="form.user.password ? $v.form.user.password : null" type="password" />
            <form-row-edit-text label="Подтверждение пароля" v-model="form.user.password_confirmation" @value="form.user.password_confirmation = $event" :v="form.user.password ? $v.form.user.password_confirmation : null" type="password" />
            <form-row-edit-email :v="$v.form.user.email" :value="form.user.email" disabled label="Email" @value="form.user.email = $event" />
            <form-row-edit-text v-model.trim="form.certificate.thumbprint" :v="$v.form.certificate.thumbprint" label="SHA1-код сертификата (отпечаток/fingerprint)" />
            <b-row class="pt-3 pl-3">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mr-3" variant="custom-outline-secondary" @click="onCancel"> Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.form.$invalid" variant="custom-green" @click="handleShowConfirmModal"> Сохранить</b-button>
                </b-overlay>
                <organization-user-edit-confirm-modal :show="showConfirmModal" @cancel="handleCancelConfirmModal" @ok="onSubmit" />
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, helpers, minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import OrganizationUserEditConfirmModal from '@/components/common/OrganizationUserEditConfirmModal';

const phone = helpers.regex('phone', /^7[0-9+]{10}$/);
const thumbprint = helpers.regex('thumbprint', /^[A-Fa-f\d(^\s)]+$/);
const allowable = helpers.withParams({ type: 'allowable' }, (value) => (value ? /^[a-zA-Z0-9-_=+~!@#$%^&*() ,.<>?/\\|`]+$/.test(value) : true));
const containsSymbols = helpers.withParams({ type: 'containsSymbols' }, (value) => (value ? /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value) : true));

export default {
    name: 'UserEdit',
    metaInfo: {
        title: 'Пользователи организации - Редактирование пользователя'
    },
    components: {
        OrganizationUserEditConfirmModal
    },
    mixins: [validationMixin],
    data() {
        return {
            showConfirmModal: false,
            loading: true,
            isDataSending: false,
            form: {
                user: {
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    email: '',
                    phone: '',
                    post: '',
                    username: '',
                    password: '',
                    password_confirmation: ''
                },
                certificate: {
                    thumbprint: ''
                }
            }
        };
    },
    validations: {
        form: {
            user: {
                post: { required, minLength: minLength(3) },
                phone: { required, phone },
                email: { required, email },
                password: { minLength: minLength(8), allowable, containsSymbols },
                password_confirmation: {
                    required: requiredIf((form) => form.password),
                    confirmPassword: sameAs((form) => (form.password ? form.password : form.password_confirmation))
                }
            },
            certificate: {
                thumbprint: { required, thumbprint }
            }
        }
    },
    mounted() {
        this.fillData();
    },
    methods: {
        handleShowConfirmModal() {
            this.showConfirmModal = true;
        },
        handleCancelConfirmModal() {
            this.showConfirmModal = false;
        },
        async fillData() {
            let formdata = await this.$api.cabinet.users.get(this.$route.params.id);
            this.form.user = formdata.user;
            this.form.certificate = formdata.certificate;
            this.loading = false;
        },
        onCancel() {
            this.$router.push({ name: 'UsersList' }).catch(() => {});
        },
        async onSubmit() {
            this.isDataSending = true;
            this.handleCancelConfirmModal();
            if (await this.$api.cabinet.users.put(this.$route.params.id, { form: this.form })) {
                this.$router.push({ name: 'UsersList' }).catch(() => {});
            }
            this.isDataSending = false;
        }
    }
};
</script>

<style>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>
