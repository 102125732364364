<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div v-if="purchase.status_id !== PURCHASE_STATUS.status_draft && purchase.status_id !== PURCHASE_STATUS.status_draft_save_signed" class="purchase_part">
                    <subheader-customer-cabinet :purchase="purchase"></subheader-customer-cabinet>
                    <div class="purchase_part-subheader">
                        <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                            <span class="fs-11 green-color"
                                >Начальная максимальная цена контракта: <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.start_price) }} руб.</span></span
                            >
                        </b-col>
                        <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                            <span class="fs-11 green-color"
                                >Предложений: <span class="fw-600 ws-nowrap">{{ purchase.offersCount }}</span></span
                            >
                        </b-col>
                        <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                            <span class="fs-11 green-color"
                                >Лучшее: <span class="fw-600 ws-nowrap">{{ purchase.bestOffer ? $formatPrice(purchase.bestOffer) + ' руб.' : '-' }} </span></span
                            >
                        </b-col>
                        <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                            <span class="fs-11 green-color"
                                >Снижение: <span class="fw-600 ws-nowrap">{{ purchase.discount }} %</span></span
                            >
                        </b-col>
                    </div>
                    <div class="purchase_part-body row">
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div v-if="purchase.status_id === 1 && !purchase.is_hold" class="my-2">
                                    <p class="fs-12 m-0 grey-color">До окончания подачи предложений:</p>
                                    <p class="fs-18 m-0 grey-color fw-600 time_to_end">
                                        <timer-new v-if="!!purchase.purchase_end" :data="purchase.purchase_end"></timer-new>
                                    </p>
                                </div>
                                <div v-if="!!purchase.planned_end_date" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Планируемая дата исполнения:</p>
                                    <p class="fw-600 fs-12 m-0 grey-color">{{ getDateFormat(purchase.planned_end_date) }}</p>
                                </div>
                                <div v-if="purchase.national_project_name" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Национальный проект:</p>
                                    <p class="fs-12 m-0 grey-color fw-600 mb-4 time_to_end">
                                        <span class="fw-600">{{ purchase.national_project_name }}</span>
                                    </p>
                                </div>
                                <div v-if="isPurchaseCreator()" class="my-2">
                                    <purchase-comment-edit-block :purchase="purchase" @refresh="refresh" />
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                                    <p class="fs-12 green-color fw-600">{{ purchase.purchase_name }}</p>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <router-link :to="'/clients/customers/' + purchase.org_id" class="system_link-style fw-600 text-uppercase fs-12">{{ purchase.organization }}</router-link>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 my-2 grey-color">
                                        Количество позиций:
                                        <span class="fw-600 ws-nowrap">{{ purchase.items_count }}</span>
                                    </p>
                                    <p class="fs-12 my-2 grey-color">
                                        Начальная максимальная цена контракта:
                                        <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.start_price) }} руб.</span>
                                    </p>
                                </div>
                                <div v-if="purchase.delivery && purchase.delivery.length" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Место доставки или указание на самовывоз:</p>
                                    <p v-for="deliver in purchase.delivery" :key="deliver.id" class="fs-12 m-0 grey-color fw-600" v-bind="deliver">{{ deliver.address }}</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div class="h-100">
                                <div>
                                    <p class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img alt="check_label" src="/images/search.svg" />
                                        </span>
                                        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
                                        <router-link class="system_link-style" exact target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id } }">Информация о закупке </router-link>
                                    </p>
                                    <p v-if="purchase.offersCount > 0" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img alt="check_label" src="/images/dynamic.svg" />
                                        </span>
                                        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
                                        <router-link class="system_link-style" exact target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-proposals' }"> Динамика предложений </router-link>
                                    </p>
                                    <p v-if="purchase && purchase.organizationFinal" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img alt="check_label" src="/images/small_helmet.svg" />
                                        </span>
                                        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
                                        <router-link class="system_link-style" exact target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-supplier-info' }"> Итоговый протокол </router-link>
                                    </p>
                                    <p v-if="(purchase && purchase.status_id === PURCHASE_STATUS.status_protocol_formed) || purchase.contract" class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img alt="check_label" src="/images/grey_contract.svg" />
                                        </span>
                                        <!-- REARCHITECTURED: replaced the link to the rearchitected page. -->
                                        <router-link class="system_link-style" exact target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract' }"> Договор </router-link>
                                    </p>
                                    <p
                                        v-if="(purchase && purchase.contract && purchase.contract.act) || ($store.getters.isLoggedIn && !$store.getters.isProvider && purchase.org_id === $store.state.user.organization_id && [3, 4, 5, 6].includes(purchase.status_id))"
                                        class="d-flex align-items-center green-color fs-12 fw-600 my-2">
                                        <span style="width: 30px">
                                            <img alt="check_label" src="/images/grey_file_small.svg" />
                                        </span>
                                        <router-link class="system_link-style" exact target="_blank" v-bind:to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: purchase.id }, hash: '#atmo-contract-fulfillment' }">Исполнение договора </router-link>
                                    </p>
                                </div>
                                <div>
                                    <template v-if="isPurchaseCreator()">
                                        <b-btn v-if="purchase.rating_allowed && $store.getters.isLoggedIn && !$store.getters.isProvider" class="btn btn-card-action btn-card-action my-1" @click="ratingModal_visible = true">Оценить поставщика </b-btn>
                                        <b-btn v-if="purchase.conditions.customer_can_prolong_purchase" class="btn-card-action my-1" @click="prolongPurchase">Продлить закупочную сессию до {{ getDateTimeFormat(purchase.max_prolong_datetime) }} </b-btn>
                                        <cancel-purchase-button :purchase="purchase" @cancel="refresh" />
                                        <router-link v-if="$store.getters.isLoggedIn && isPurchaseCreator() && purchase.allow_external_purchase" :to="{ name: 'ExternalPurchaseCreate', params: { id: purchase.id } }" class="btn btn-card-action btn-card-action-light my-1" tag="button">
                                            Закупить вне ИС
                                        </router-link>
                                        <router-link v-if="purchase.conditions.customer_can_change_purchase_length" :to="{ name: 'PurchasesShow', params: { id: purchase.id } }" class="btn btn-card-action btn-card-action-light my-1" style="font-size: 12px !important" tag="button"
                                            >Сделать не срочной
                                        </router-link>
                                        <cancel-purchase-publishing-button :purchase="purchase" @click="cancelPurchasePublishing"></cancel-purchase-publishing-button>
                                        <cancel-hold-time-button :purchase="purchase" @click="cancelHoldTime"></cancel-hold-time-button>
                                        <copy-purchase-button :purchase="purchase" @click="copyPurchase"></copy-purchase-button>
                                    </template>
                                    <purchase-supporting-buttons :purchase="purchase" />
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
                <div v-else class="purchase_part">
                    <subheader-customer-cabinet :purchase="purchase"></subheader-customer-cabinet>
                    <div class="purchase_part-body row">
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div v-if="!!purchase.planned_end_date" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Планируемая дата исполнения:</p>
                                    <p class="fw-600 fs-12 m-0 grey-color">{{ getDateFormat(purchase.planned_end_date) }}</p>
                                </div>
                                <div v-if="isPurchaseCreator()" class="my-2">
                                    <purchase-comment-edit-block :purchase="purchase" @refresh="refresh" />
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                                    <p class="fs-12 green-color fw-600">{{ purchase.purchase_name }}</p>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <router-link :to="'/clients/customers/' + purchase.org_id" class="system_link-style fw-600 text-uppercase fs-12">{{ purchase.organization }}</router-link>
                                </div>
                                <div class="my-2">
                                    <p v-if="!!purchase.planned_end_date" class="fs-12 my-2 grey-color">
                                        Планируемая дата исполнения:
                                        <span class="fw-600">{{ getDateFormat(purchase.planned_end_date) }}</span>
                                    </p>
                                    <p class="fs-12 my-2 grey-color">
                                        Начальная максимальная цена контракта:
                                        <span class="fw-600">{{ $formatPrice(purchase.start_price) }} руб.</span>
                                    </p>
                                </div>
                                <div v-if="purchase.delivery && purchase.delivery.length" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Место доставки или указание на самовывоз:</p>
                                    <p v-for="deliver in purchase.delivery" :key="deliver.id" class="fs-12 m-0 grey-color fw-600" v-bind="deliver">{{ deliver.address }}</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div class="h-100">
                                <div>
                                    <router-link
                                        v-if="(purchase.status_id === PURCHASE_STATUS.status_draft || purchase.status_id === PURCHASE_STATUS.status_draft_save_signed) && purchase.change_length === false"
                                        class="btn btn-card-action btn-card-action-green my-1"
                                        tag="button"
                                        v-bind:to="{ name: 'purchases.grouped.item.edit', params: { group: 'fl44', id: purchase.id } }"
                                        >Редактировать
                                    </router-link>
                                    <b-btn v-if="purchase.status_id === PURCHASE_STATUS.status_draft_save_signed" class="btn-card-action btn-card-action-green my-1" @click="publish(purchase.id, purchase.reg_number)">Опубликовать </b-btn>
                                    <b-btn
                                        v-if="(purchase.status_id !== PURCHASE_STATUS.status_draft && purchase.change_length === false) || (purchase.status_id !== PURCHASE_STATUS.status_draft_save_signed && purchase.change_length === false)"
                                        class="btn-card-action btn-card-action-light my-1"
                                        style="font-size: 12px !important"
                                        @click="remove(purchase.id, purchase.reg_number)"
                                        >Удалить
                                    </b-btn>
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal v-if="ratingModal_visible" id="ratingModal" v-model="ratingModal_visible" centered hide-footer title="Оцените поставщика">
            <b-row class="feedback_modal">
                <b-col>
                    <b-form class="px-4 pb-4" @submit.prevent="onSubmitRating">
                        <b-row class="py-2" no-gutters>
                            <b-col class="fs-14 grey-color">
                                <h5>{{ purchase.organizationFinal.full_name }}</h5>
                            </b-col>
                        </b-row>
                        <b-row class="py-2" no-gutters>
                            <b-col class="fs-14 grey-color">
                                <b-form-input v-model="ratingValue" v-stop-number-mousewheel max="5" min="1" required type="number"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row class="mt-5">
                            <b-col>
                                <b-button :disabled="ratingSending" class="mr-2 text-uppercase" type="reset" variant="custom-outline-secondary" @click="cancelRatingModal">Отмена</b-button>
                                <b-button :disabled="ratingSending" class="text-uppercase" type="submit" variant="custom-green">Отправить</b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>
import { __PURCHASE_STATUS } from '@/const';
import SubheaderCustomerCabinet from '@/components/purchases/PurchaseStatusCard/parts/SubheaderCustomerCabinet';
import Purchases from '@/services/api/Purchases';
import CancelPurchasePublishingButton from '@/components/common/CancelPurchasePublishingButton';
import CancelHoldTimeButton from '@/components/common/CancelHoldTimeButton';
import CopyPurchaseButton from '@/components/common/CopyPurchaseButton';
import TimerNew from '@/components/elements/TimerNew';
import PurchaseSupportingButtons from '@/components/elements/PurchaseSupportingButtons';
import PurchaseCommentEditBlock from '@/components/common/purchase-comment-edit-block';
import CancelPurchaseButton from '@/components/common/CancelPurchaseButton';

export default {
    name: 'PurchaseCustomerCabinet',
    props: ['purchase'],
    PURCHASE_STATUS: {},
    components: {
        PurchaseSupportingButtons,
        TimerNew,
        SubheaderCustomerCabinet,
        CancelPurchasePublishingButton,
        CancelHoldTimeButton,
        CopyPurchaseButton,
        PurchaseCommentEditBlock,
        CancelPurchaseButton
    },
    data() {
        return {
            ratingModal_visible: false,
            ratingValue: 5,
            ratingSending: false
        };
    },
    created() {
        this.PURCHASE_STATUS = __PURCHASE_STATUS;
    },
    methods: {
        remove(id, number) {
            this.$emit('onDelete', { id: id, number: number });
        },
        publish(id, number) {
            this.$emit('onPublish', { id: id, number: number });
        },
        async copyPurchase() {
            Purchases.copyPurchase(this.purchase.id).then((response) => {
                this.$router.push({ name: 'purchases.grouped.item.edit', params: { group: 'fl44', id: response.data } });
                // DEPRECATED: Replaced by the rearchitedted page.
                // const newPurchase = response.data;
                // this.$emit('copy', newPurchase);
            });
        },
        cancelRatingModal() {
            this.ratingValue = 5;
            this.ratingModal_visible = false;
            this.ratingSending = false;
        },
        onSubmitRating() {
            this.ratingSending = true;
            Purchases.setRating(this.purchase_id, parseInt(this.ratingValue)).then(() => {
                this.cancelRatingModal();
                this.$emit('onRating');
            });
        },
        cancelPurchasePublishing() {
            this.$emit('cancelPublishing', this.purchase);
        },
        cancelHoldTime() {
            this.$emit('cancelHoldTime', this.purchase);
        },
        prolongPurchase() {
            this.$emit('prolong', this.purchase);
        },
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        canChangeLength: function () {
            return (
                this.purchase.allow_external_purchase &&
                this.purchase.change_length === false &&
                this.purchase.purchase_length === 2 &&
                this.purchase.control_response === null &&
                (this.purchase.status_id === this.PURCHASE_STATUS.status_start || this.PURCHASE_STATUS.status_fail) &&
                this.$store.getters.isLoggedIn &&
                !this.$store.getters.isProvider
            );
        },
        purchase_id: function () {
            return this.purchase.id;
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
<style>
.draft.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.draft .purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.draft .purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
</style>
