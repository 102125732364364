var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loader
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-3", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-36 fw-600 white-color my-2 if-tablet",
                          },
                          [
                            _vm._v(
                              "Внесистемная закупка № " +
                                _vm._s(_vm.purchase.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.purchase
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("text-header", [_vm._v("Информация о заказчике")]),
                      _c("text-row", {
                        attrs: { label: "Название" },
                        model: {
                          value: _vm.purchase.customer.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.customer, "name", $$v)
                          },
                          expression: "purchase.customer.name",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "ИНН" },
                        model: {
                          value: _vm.purchase.customer.inn,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.customer, "inn", $$v)
                          },
                          expression: "purchase.customer.inn",
                        },
                      }),
                      _vm.purchase.customer.kpp
                        ? _c("text-row", {
                            attrs: { label: "КПП" },
                            model: {
                              value: _vm.purchase.customer.kpp,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase.customer, "kpp", $$v)
                              },
                              expression: "purchase.customer.kpp",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.ogrn
                        ? _c("text-row", {
                            attrs: { label: "ОГРН" },
                            model: {
                              value: _vm.purchase.customer.ogrn,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase.customer, "ogrn", $$v)
                              },
                              expression: "purchase.customer.ogrn",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.customer.address
                        ? _c("text-row", {
                            attrs: { label: "Почтовый адрес" },
                            model: {
                              value: _vm.purchase.customer.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase.customer, "address", $$v)
                              },
                              expression: "purchase.customer.address",
                            },
                          })
                        : _vm._e(),
                      _c("text-header", [_vm._v("Информация о закупке")]),
                      _c("text-row", {
                        attrs: { label: "Способ закупки" },
                        model: {
                          value: _vm.purchase.purchase_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_method", $$v)
                          },
                          expression: "purchase.purchase_method",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Предмет закупки" },
                        model: {
                          value: _vm.purchase.purchase_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_name", $$v)
                          },
                          expression: "purchase.purchase_name",
                        },
                      }),
                      _vm.purchase.national_project_name
                        ? _c("text-row", {
                            attrs: { label: "Национальный проект" },
                            model: {
                              value: _vm.purchase.national_project_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase,
                                  "national_project_name",
                                  $$v
                                )
                              },
                              expression: "purchase.national_project_name",
                            },
                          })
                        : _vm._e(),
                      _c("text-row", {
                        attrs: { label: "Тип закупки" },
                        model: {
                          value: _vm.purchase.order_type_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "order_type_name", $$v)
                          },
                          expression: "purchase.order_type_name",
                        },
                      }),
                      _vm.purchase.failed_purchase_id
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Номер несостоявшейся закупки:",
                                "label-class":
                                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                "label-cols-lg": "3",
                                "label-cols-sm": "4",
                              },
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "fs-14 my-auto col-form-label" },
                                [
                                  _vm.purchase.failed_purchase_id
                                    ? _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "purchases.grouped.item.view",
                                              params: {
                                                group: "fl44",
                                                id: _vm.purchase
                                                  .failed_purchase_id,
                                              },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.purchase
                                                  .failed_purchase_reg_number
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("text-row-price", {
                        attrs: { label: "Общая стоимость" },
                        model: {
                          value: _vm.purchase.start_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "start_price", $$v)
                          },
                          expression: "purchase.start_price",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: { label: "Дата заключения договора" },
                        model: {
                          value: _vm.purchase.planned_contract_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "planned_contract_date", $$v)
                          },
                          expression: "purchase.planned_contract_date",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: { label: "Дата исполнения договора" },
                        model: {
                          value: _vm.purchase.planned_end_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "planned_end_date", $$v)
                          },
                          expression: "purchase.planned_end_date",
                        },
                      }),
                      _c("text-header", [_vm._v("Спецификация")]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("№")]),
                              _c("th", [_vm._v("Рег.номер ТРУ")]),
                              _c("th", [
                                _c(
                                  "abbr",
                                  { attrs: { title: "Характеристики ТРУ" } },
                                  [_vm._v("Хар-ки ТРУ")]
                                ),
                              ]),
                              _c("th", [
                                _vm._v("Наименование товара (работ, услуг)"),
                              ]),
                              _c("th", [_vm._v("Код ОКПД2")]),
                              _c("th", [_vm._v("Количество")]),
                              _c("th", [_vm._v("Ед.изм")]),
                              _c("th", [_vm._v("Цена за ед., руб.")]),
                              _c("th", [_vm._v("Стоимость, руб.")]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.purchase.items, function (item, index) {
                              return _c(
                                "fragment",
                                _vm._b(
                                  { key: item.id },
                                  "fragment",
                                  item,
                                  false
                                ),
                                [
                                  _c(
                                    "tr",
                                    {
                                      class: _vm.visible[index]
                                        ? "border-bottom-0"
                                        : "",
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(index + 1))]),
                                      _c(
                                        "td",
                                        [
                                          !!item.ktru
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      id:
                                                        "spec-ktru-popover-" +
                                                        (index + 1),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.ktru.reg_number
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        "spec-ktru-popover-" +
                                                        (index + 1),
                                                      placement: "top",
                                                      triggers: "hover",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Рег.номер ТРУ:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.ktru
                                                                      .reg_number
                                                                  )
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v("Группа ТРУ:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.ktru
                                                            .ktru_group_name
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _c("b", [
                                                      _vm._v("Наименование:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.ktru.name)
                                                    ),
                                                    _c("br"),
                                                    _c("b", [_vm._v("Тип:")]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.ktru.type)
                                                    ),
                                                    _c("br"),
                                                  ]
                                                ),
                                              ]
                                            : [_vm._v("—")],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        [
                                          item.ktru
                                            ? [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-toggle",
                                                        rawName: "v-b-toggle",
                                                        value:
                                                          "collapse-" + index,
                                                        expression:
                                                          "'collapse-' + index",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "atmo-button-icon",
                                                  },
                                                  [
                                                    !_vm.visible[index]
                                                      ? _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title:
                                                                "Показать характеристики",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "atmo-icon-show",
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title:
                                                                "Скрыть характеристики",
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "atmo-icon-hide",
                                                            }),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                                _c("b-collapse", {
                                                  staticClass: "d-none",
                                                  attrs: {
                                                    id: "collapse-" + index,
                                                  },
                                                  model: {
                                                    value: _vm.visible[index],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.visible,
                                                        index,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "visible[index]",
                                                  },
                                                }),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c("td", [_vm._v(_vm._s(item.name))]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            item.okpd ? item.okpd.code : "—"
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.count))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          item.okei
                                            ? [
                                                _vm._v(
                                                  _vm._s(item.okei.local_symbol)
                                                ),
                                              ]
                                            : [_vm._v("—")],
                                        ],
                                        2
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$formatPrice(item.price))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatPrice(
                                                item.count * item.price
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.visible[index],
                                          expression: "visible[index]",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "align-text-top",
                                          attrs: { colspan: "9" },
                                        },
                                        [
                                          item.ktru
                                            ? [
                                                item.ktru.description
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v("Описание:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.ktru
                                                              .description
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Характеристики:"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c(
                                                      "ul",
                                                      _vm._l(
                                                        item.ktru.specs,
                                                        function (
                                                          spec,
                                                          specIndex
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key:
                                                                index +
                                                                "_spec_" +
                                                                specIndex,
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    spec.Name
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " - " +
                                                                  _vm._s(
                                                                    spec.Value
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            : [_vm._v("—")],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]),
                      ]),
                      this.hasLimits
                        ? _c(
                            "div",
                            { staticClass: "my-2 atmo-page-content" },
                            [
                              _c("KBKLimitsList.view", {
                                attrs: { limits: _vm.$data.limits },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("text-header", { attrs: { id: "supplier-info" } }, [
                        _vm._v("Поставщик"),
                      ]),
                      !_vm.purchase.is_private_provider
                        ? [
                            _c("text-row", {
                              attrs: { label: "Название" },
                              model: {
                                value: _vm.purchase.supplier.full_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier,
                                    "full_name",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier.full_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "ИНН" },
                              model: {
                                value: _vm.purchase.supplier.inn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.purchase.supplier, "inn", $$v)
                                },
                                expression: "purchase.supplier.inn",
                              },
                            }),
                            _vm.purchase.supplier.kpp
                              ? _c("text-row", {
                                  attrs: { label: "КПП" },
                                  model: {
                                    value: _vm.purchase.supplier.kpp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier,
                                        "kpp",
                                        $$v
                                      )
                                    },
                                    expression: "purchase.supplier.kpp",
                                  },
                                })
                              : _vm._e(),
                            _vm.purchase.supplier.ogrn
                              ? _c("text-row", {
                                  attrs: { label: "ОГРН" },
                                  model: {
                                    value: _vm.purchase.supplier.ogrn,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier,
                                        "ogrn",
                                        $$v
                                      )
                                    },
                                    expression: "purchase.supplier.ogrn",
                                  },
                                })
                              : _vm._e(),
                            _c("text-row", {
                              attrs: { label: "Фактический адрес" },
                              model: {
                                value: _vm.purchase.supplier.address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier,
                                    "address",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier.address",
                              },
                            }),
                          ]
                        : [
                            _c("text-row", {
                              attrs: {
                                label: "Название",
                                value: "Физическое лицо",
                              },
                            }),
                          ],
                      _c("text-header", { attrs: { id: "contract" } }, [
                        _vm._v("Информация о договоре"),
                      ]),
                      _c("text-row", {
                        attrs: {
                          value: _vm.purchase.status,
                          label: "Статус договора",
                        },
                      }),
                      _c("text-row", {
                        attrs: {
                          label: "Номер договора в системе учета заказчика",
                        },
                        model: {
                          value: _vm.purchase.customer_contract_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "customer_contract_number",
                              $$v
                            )
                          },
                          expression: "purchase.customer_contract_number",
                        },
                      }),
                      _vm.purchase.main_file &&
                      (!_vm.purchase.is_private_provider ||
                        (_vm.purchase.is_private_provider &&
                          _vm.isPurchaseCreator()))
                        ? _c("text-row-raw", { attrs: { label: "Договор" } }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "fs-14 col-form-label d-inline-block",
                                attrs: {
                                  href: _vm.$getAttachmentDownloadLink(
                                    _vm.purchase.main_file.id
                                  ),
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.purchase.main_file.name))]
                            ),
                          ])
                        : _vm._e(),
                      _vm.purchase.supplier_accounting
                        ? [
                            _c("text-header", [
                              _vm._v("Банковские реквизиты поставщика"),
                            ]),
                            _c("text-row", {
                              attrs: {
                                value: _vm.getBankTypeTitle(
                                  _vm.purchase.supplier_accounting.bank_type
                                ),
                                label: "Вид обслуживающей организации",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "БИК банка" },
                              model: {
                                value: _vm.purchase.supplier_accounting.bik,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bik",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.bik",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Название банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_name",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Город банка" },
                              model: {
                                value:
                                  _vm.purchase.supplier_accounting.bank_city,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "bank_city",
                                    $$v
                                  )
                                },
                                expression:
                                  "purchase.supplier_accounting.bank_city",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Кор. счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.ks,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "ks",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.ks",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Расчетный счет" },
                              model: {
                                value: _vm.purchase.supplier_accounting.rs,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.supplier_accounting,
                                    "rs",
                                    $$v
                                  )
                                },
                                expression: "purchase.supplier_accounting.rs",
                              },
                            }),
                            _vm.purchase.supplier_accounting.bank_type !==
                            "bank"
                              ? _c("text-row", {
                                  attrs: { label: "Лицевой счет" },
                                  model: {
                                    value: _vm.purchase.supplier_accounting.ls,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.purchase.supplier_accounting,
                                        "ls",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "purchase.supplier_accounting.ls",
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "text-header",
                        { attrs: { id: "contract-fulfillment" } },
                        [_vm._v("Исполнение договора")]
                      ),
                      _vm.isPurchaseCreator()
                        ? _c(
                            "div",
                            { staticClass: "atmo-content-group" },
                            [
                              _c("h5", [_vm._v("Изменить статус закупки")]),
                              _c("form-row-change-contract-status", {
                                attrs: {
                                  disabled:
                                    _vm.purchaseStatus ===
                                    _vm.purchase.status_id,
                                },
                                on: { "change-status": _vm.changeStatus },
                                model: {
                                  value: _vm.purchaseStatus,
                                  callback: function ($$v) {
                                    _vm.purchaseStatus = $$v
                                  },
                                  expression: "purchaseStatus",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.purchase.is_private_provider ||
                      (_vm.purchase.is_private_provider &&
                        _vm.isPurchaseCreator())
                        ? _c("div", { staticClass: "atmo-content-group" }, [
                            _c("h5", [_vm._v("Документы об исполнении")]),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "atmo-content-table atmo-editable-attachments",
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("№")]),
                                    _c("th", [_vm._v("Наименование")]),
                                    _vm.isPurchaseCreator()
                                      ? _c("th", [_vm._v(" ")])
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  [
                                    _vm._l(
                                      _vm.executionDocuments,
                                      function (file, index) {
                                        return _c("tr", { key: file.id }, [
                                          _c("td", {
                                            domProps: {
                                              textContent: _vm._s(index + 1),
                                            },
                                          }),
                                          _c("td", [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "fs-14 col-form-label d-inline-block",
                                                attrs: {
                                                  href: _vm.$getAttachmentDownloadLink(
                                                    file.id
                                                  ),
                                                  target: "_blank",
                                                },
                                              },
                                              [_vm._v(_vm._s(file.name))]
                                            ),
                                          ]),
                                          _vm.isPurchaseCreator()
                                            ? _c("td", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "atmo-button-icon",
                                                    attrs: {
                                                      disabled: _vm.isWaiting,
                                                      title:
                                                        "Удалить файл из списка",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDeleteFile(
                                                          file,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "atmo-icon-trash",
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    _vm.purchase.files.length === 0
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "atmo-centered",
                                              attrs: { colspan: "3" },
                                            },
                                            [_vm._v("Не добавлено документов")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm.isPurchaseCreator()
                              ? _c("ul", { staticClass: "atmo-buttons" }, [
                                  _c("li", [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "atmo-button",
                                        attrs: { disabled: _vm.isWaiting },
                                      },
                                      [
                                        _c("span", [_vm._v("Добавить")]),
                                        _c("input", {
                                          ref: "fileload",
                                          attrs: {
                                            id: "fileload",
                                            disabled: _vm.isWaiting,
                                            accept:
                                              ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx",
                                            type: "file",
                                          },
                                          on: { change: _vm.add },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }