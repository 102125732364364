var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "column-992 mb-4" },
        [
          _c("b-col", { staticClass: "d-flex align-items-center" }, [
            _c(
              "p",
              {
                staticClass: "fs-32 grey-color m-0 main_text",
                staticStyle: { "line-height": "1" },
              },
              [_vm._v("Пользователи организации")]
            ),
          ]),
          _c(
            "b-col",
            {
              staticClass:
                "text-right d-flex align-items-center justify-content-end start-992",
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-custom-unstyle-grey",
                  attrs: { to: { name: "UserCreate" }, tag: "button" },
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { alt: "plus", src: "/images/plus.svg" },
                  }),
                  _vm._v(" Добавить пользователя "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive py-3" },
        [
          _c("b-table", {
            staticClass: "table-atmo",
            attrs: {
              busy: _vm.loading,
              fields: _vm.fields,
              items: _vm.users,
              "per-page": _vm.pagination.per_page,
              "empty-text": "Данные отсутствуют",
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle mr-1" }),
                        _c("strong", [_vm._v("Загрузка данных...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(is_blocked)",
                fn: function (row) {
                  return [
                    !row.item.is_blocked
                      ? _c("span", { staticClass: "active fw-600" }, [
                          _vm._v("Активен"),
                        ])
                      : _c("span", { staticClass: "blocked fw-600" }, [
                          _vm._v("Заблокирован"),
                        ]),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatDateTime(row.item.created_at)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "no-caret": "",
                              right: "",
                              size: "sm",
                              "toggle-class": "text-decoration-none",
                              variant: "link",
                            },
                          },
                          [
                            _c("template", { slot: "button-content" }, [
                              _c("img", {
                                attrs: { alt: "cog", src: "/images/cog.svg" },
                              }),
                            ]),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "fs-14",
                                attrs: {
                                  to: {
                                    name: "UserEdit",
                                    params: { id: row.item.id },
                                  },
                                },
                              },
                              [_vm._v("Редактировать")]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "fs-14",
                                attrs: { disabled: row.item.is_blocked },
                                on: {
                                  click: function ($event) {
                                    return _vm.blockUser(row.item)
                                  },
                                },
                              },
                              [_vm._v("Заблокировать")]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                staticClass: "fs-14",
                                attrs: { disabled: !row.item.is_blocked },
                                on: {
                                  click: function ($event) {
                                    return _vm.unblockUser(row.item)
                                  },
                                },
                              },
                              [_vm._v("Разблокировать")]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.pagination.total
            ? _c("b-pagination", {
                staticClass: "mt-4 mb-0 mx-auto",
                attrs: {
                  "per-page": _vm.pagination.per_page,
                  "total-rows": _vm.pagination.total,
                  align: "center",
                },
                on: { input: _vm.applyFilters },
                model: {
                  value: _vm.pagination.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "current_page", $$v)
                  },
                  expression: "pagination.current_page",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }