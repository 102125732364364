<template>
    <div style="background-color: var(--greyBg-color)">
        <b-container v-if="$store.state.user && $store.state.organization" class="main_layer py-4" fluid>
            <b-container v-if="isUserCustomer">
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mt-2 mb-4 if-tablet">Личный кабинет заказчика</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-14 greyBg-color">
                            Наименование организации: <strong>{{ $store.state.organization.short_name }}</strong>
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-14 greyBg-color">
                            Пользователь:
                            <strong>{{ $store.state.user.last_name }} {{ $store.state.user.first_name }} {{ $store.state.user.middle_name }}</strong>
                            <span class="pl-5">
                                Логин:
                                <strong>{{ $store.state.user.username }}</strong>
                            </span>
                        </p>
                        <p v-if="features.telegram_bot" class="fs-14 greyBg-color">
                            ID Пользователя для Telegram:
                            <strong>{{ $store.state.user.id }}</strong>
                        </p>
                        <a v-if="!$store.state.user.is_chatme_notifiable && features.telegram_bot" :href="links.telegram_events_bot" class="btn btn-custom-white" target="_blank">Подключить уведомления о событиях в ИС</a>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-button variant="custom-white" class="mt-5 mr-2" :to="{ name: 'purchases.grouped.item.create', params: { group: 'fl44' } }">СОЗДАТЬ ЗАКУПКУ</b-button>
                        <b-button variant="custom-outline-light" :to="{ name: 'ExternalPurchaseCreate' }" class="mt-5">ДОБАВИТЬ ВНЕСИСТЕМНУЮ ЗАКУПКУ</b-button>
                    </b-col>
                    <b-col md="6"></b-col>
                </b-row>
            </b-container>
            <b-container v-if="isUserProvider">
                <b-row>
                    <b-col md="9">
                        <p class="fs-52 fw-600 white-color mb-3 mt-2 if-tablet">Личный кабинет поставщика</p>
                        <p class="fs-14 greyBg-color">
                            Наименование организации:
                            <strong>{{ $store.state.organization.short_name }}</strong>
                        </p>
                        <p class="fs-14 greyBg-color">
                            Пользователь:
                            <strong>
                                {{ $store.state.user.last_name }}
                                {{ $store.state.user.first_name }}
                                {{ $store.state.user.middle_name }}
                            </strong>
                            <span class="pl-5">
                                Логин:
                                <strong>{{ $store.state.user.username }}</strong>
                            </span>
                        </p>
                        <p v-if="features.telegram_bot" class="fs-14 greyBg-color">
                            ID Пользователя для Telegram:
                            <strong>{{ $store.state.user.id }}</strong>
                        </p>
                        <a v-if="!$store.state.user.is_chatme_notifiable && features.telegram_bot" :href="links.telegram_events_bot" class="btn btn-custom-white" target="_blank">Подключить уведомления о событиях в ИС и размещении новых извещений</a>
                        <div class="my-2">
                            <router-link v-if="!getOrganizationHasPayments" :to="{ name: 'ProviderAccountCabinet' }" class="btn btn-custom-danger text-uppercase">Пополнить лицевой счет</router-link>
                        </div>
                    </b-col>
                    <b-col md="3">
                        <div class="text-right">
                            <b-button variant="custom-white" class="my-2 mx-2" :to="{ name: 'OrganizationReliabilityReport' }">Проверка контрагентов</b-button>
                            <b-button v-if="getCertificateNeedToBeProlonged" :href="links.prolong_certificate" variant="custom-danger" class="text-uppercase my-2 mx-2" target="_blank">Продлить ЭЦП</b-button>
                            <b-button v-if="features.marketplace_integration" class="text-uppercase my-2 mx-2" variant="custom-white" @click="goToBashmarket">ПЕРЕЙТИ НА БАШМАРКЕТ</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid>
            <b-container>
                <b-row class="py-5">
                    <b-col md="3" sm="12">
                        <b-nav class="w-100 provider_menu cabinet_menu mb-3" vertical>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('ShowPurchases')" :to="{ name: 'ShowPurchases', params: { cat: 'all' } }" exact>Личный кабинет</b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('CustomerQuotationRequestsList')" :to="{ name: 'CustomerQuotationRequestsList', params: { cat: 'all' } }" exact>Запросы КП </b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('CustomerPurchasesCabinet')" :to="{ name: 'CustomerPurchasesCabinet', params: { cat: 'all' } }" exact>Мои извещения </b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('CustomerContractsCabinet')" :to="{ name: 'CustomerContractsCabinet', params: { cat: 'all' } }" exact>Договоры </b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('KtruRequestsList')" :to="{ name: 'KtruRequestsList' }" exact>Запросы на добавление КТРУ</b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('NotificationsList')" :to="{ name: 'NotificationsList' }">Системные уведомления</b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('FavoriteCustomerCabinet')" :to="{ name: 'FavoriteCustomerCabinet' }" exact>Избранное (Поставщики)</b-nav-item>
                            <b-nav-item v-if="isUserCustomer" :active="isActive('CustomerAddressesCabinet')" :to="{ name: 'CustomerAddressesCabinet' }" exact>Адресная книга</b-nav-item>
                            <li v-if="isUserCustomer" class="nav-item" @click="customerCompanySubmenu = !customerCompanySubmenu">
                                <a :class="{ 'router-link-active': checkCompanyInfo }" class="nav-link" href="javascript:void(0)" target="_self">
                                    Об организации
                                    <b-collapse id="provider_company" v-model="customerCompanySubmenu" class="mt-2 cabinet_submenu">
                                        <b-dropdown-item :active="isActive('OrganizationData')" :to="{ name: 'OrganizationData' }">Информация об организации</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('UserData')" :to="{ name: 'UserData' }">Данные учетной записи</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('UsersList')" :to="{ name: 'UsersList' }">Пользователи организации</b-dropdown-item>
                                    </b-collapse>
                                </a>
                            </li>

                            <!--              <b-nav-item v-if="isUserProvider" :active="isActive('ProviderMainCabinet')" :to="{ name: 'ProviderMainCabinet' }" exact>Личный кабинет </b-nav-item>-->
                            <b-nav-item v-if="isUserProvider" :active="isActive('ProviderOffersCabinet')" :to="{ name: 'ProviderOffersCabinet' }">Мои предложения</b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('ProviderQuotationsList')" :to="{ name: 'ProviderQuotationsList', params: { cat: 'all' } }">Коммерческие предложения </b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('ProviderContractsCabinet')" :to="{ name: 'ProviderContractsCabinet', params: { cat: 'all' } }">Договоры</b-nav-item>
                            <b-nav-item v-if="isProductionsAllowed && isUserProvider" :active="isActive('ProviderProductionsList')" :to="{ name: 'ProviderProductionsList' }">Мое производство </b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('ProviderAccountCabinet')" :to="{ name: 'ProviderAccountCabinet' }">Лицевой счет</b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('KtruRequestsList')" :to="{ name: 'KtruRequestsList' }">Запросы на добавление КТРУ</b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('NotificationsList')" :to="{ name: 'NotificationsList' }">Системные уведомления</b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('FavoriteProviderCabinet')" :to="{ name: 'FavoriteProviderCabinet' }">Избранное (Заказчики)</b-nav-item>
                            <b-nav-item v-if="isUserProvider" :active="isActive('OrganizationReliabilityReport')" :to="{ name: 'OrganizationReliabilityReport' }">Проверка контрагентов</b-nav-item>
                            <li v-if="isUserProvider" class="nav-item" @click="providerCompanySubmenu = !providerCompanySubmenu">
                                <a :class="{ 'router-link-active': checkCompanyInfo }" class="nav-link" href="javascript:void(0)" target="_self">
                                    Об организации
                                    <b-collapse id="provider_company" v-model="providerCompanySubmenu" class="mt-2 cabinet_submenu">
                                        <b-dropdown-item :active="isActive('OrganizationData')" :to="{ name: 'OrganizationData' }">Информация об организации</b-dropdown-item>
                                        <!--                    <b-dropdown-item :active="isActive('ProviderBankPart')" :to="{ name: 'ProviderBankPart' }">Банковские реквизиты </b-dropdown-item>-->
                                        <b-dropdown-item :active="isActive('UserData')" :to="{ name: 'UserData' }">Данные учетной записи</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('UsersList')" :to="{ name: 'UsersList' }">Пользователи организации</b-dropdown-item>
                                    </b-collapse>
                                </a>
                            </li>
                        </b-nav>
                    </b-col>
                    <b-col md="9" sm="12">
                        <router-view></router-view>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    metaInfo: {
        title: 'Личный кабинет'
    },
    name: 'Cabinet',
    data() {
        return {
            mobileMenu_show: false,
            mobileMenu: false,
            current_route: null,
            customerCompanySubmenu: false,
            marketplaceCustomerRegisterLink: Config.get('theme.variables.links.other.marketplace_registration'),
            providerInfoSubmenu: false,
            providerRequestsSubmenu: false,
            providerCompanySubmenu: false,
            providerAccountingSubmenu: false
        };
    },
    methods: {
        isActive(path) {
            return this.$route.name === path;
        },
        async goToBashmarket() {
            await this.$api.integrations.gotoBashmarket();
        }
    },
    mounted() {
        if (!this.$store.state.organization) {
            this.$router.push('/').catch(() => {});
        }
        if (this.$route.path === '/cabinet') {
            if (this.$store.state.organization.is_provider) {
                this.$router.push({ name: 'ProviderMainCabinet' }).catch(() => {});
            } else {
                this.$router.push({ name: 'ShowPurchases', params: { cat: 'all' } }).catch(() => {});
            }
        }
    },
    computed: {
        features() {
            return Config.get('theme.features');
        },
        links() {
            return __LINKS;
        },
        ...mapGetters(['isProductionsAllowed', 'getOrganizationHasPayments', 'getCertificateNeedToBeProlonged']),
        checkCompanyInfo() {
            return Boolean(this.$route.path.match('/cabinet/organization_data') || this.$route.path.match('/cabinet/user_data') || this.$route.path.match('/cabinet/users'));
        },
        isUserCustomer() {
            return this.$store.state.organization && !this.$store.state.organization.is_provider;
        },
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        },
        checkRequestsInfo() {
            return Boolean(this.$route.path.match('/cabinet/provider/production_requests') || this.$route.path.match('/cabinet/provider/production_replies'));
        },
        checkAccountingSubmenu() {
            return Boolean(this.$route.path.match('/cabinet/provider/account') || this.$route.path.match('/cabinet/provider/accounting'));
        }
    },
    watch: {
        $route() {
            this.customerCompanySubmenu = this.checkCompanyInfo;
            this.providerCompanySubmenu = this.checkCompanyInfo;
            this.providerRequestsSubmenu = this.checkRequestsInfo;
            this.providerAccountingSubmenu = this.checkAccountingSubmenu;
            if (this.$route.path === '/cabinet') {
                if (this.$store.state.organization.is_provider) {
                    this.$router.push({ name: 'ProviderMainCabinet' }).catch(() => {});
                } else {
                    this.$router.push({ name: 'ShowPurchases', params: { cat: 'all' } }).catch(() => {});
                }
            }
        }
    }
};
</script>

<style scoped>
.cabinet_menu .atmo-badge {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 2px 6px;
    font-size: 0.8em;
    color: white;
    background-color: #dc3545;
    border-radius: 3px;
}

.cabinet_menu {
    position: -webkit-sticky;
    position: sticky;
    top: 160px;
    -webkit-box-shadow: 0 0 10px -5px #aaa;
    -moz-box-shadow: 0 0 10px -5px #aaa;
    box-shadow: 0 0 10px -5px #aaa;
}

.cabinet_menu li {
    position: relative;
    background: #fff;
    /*border-radius: 4px;*/
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 16px;
    color: var(--grey-color);
}

.cabinet_menu li .router-link-active,
.cabinet_menu li .active,
.cabinet_menu li.notify_active {
    border-left: 8px solid var(--green-color);
    /*border-radius: 4px 0 0 4px;*/
    color: var(--green-color);
    font-weight: 600;
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 200px;
}

@media screen and (max-width: 769px) {
    .if-tablet {
        font-size: 40px !important;
    }
}
</style>

<style>
#privider_info .dropdown-item {
    white-space: unset !important;
}

.provider_menu .dropdown-item.active {
    background: transparent;
    color: var(--green-color);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}
</style>
