var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "create" },
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-3", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-36 fw-600 white-color my-2 if-tablet",
                          },
                          [
                            _vm._v(
                              "Внесистемная закупка № " +
                                _vm._s(_vm.purchase.reg_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color mb-1" }, [
                          _vm._v(
                            "«" +
                              _vm._s(_vm.$store.state.organization.short_name) +
                              "»"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color" }, [
                          _vm._v(
                            " Пользователь: " +
                              _vm._s(_vm.$store.state.user.full_name) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticStyle: { "background-color": "#f5f5f7" },
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                { staticClass: "my-3" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "8" } },
                        [_c("text-header", [_vm._v(" Информация о закупке ")])],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("youtube-link-button", {
                            staticClass: "text-right",
                            attrs: {
                              href: _vm.$links.external_purchase_edit_manual,
                              text: "Инструкция по внесистемным закупкам",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("form-row-edit-textarea", {
                    attrs: {
                      rows: 5,
                      label:
                        "Примечание к закупке (отображается только в ЛК Заказчика)",
                    },
                    model: {
                      value: _vm.purchase.purchase_comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_comment", $$v)
                      },
                      expression: "purchase.purchase_comment",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.limitYears,
                      disabled: "",
                      label: "Год ПГ (Плана-графика)",
                      "label-field": "id",
                      placeholder: "Выберите год ПГ (Плана-графика)",
                    },
                    model: {
                      value: _vm.purchase.purchase_limit_year,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_limit_year", $$v)
                      },
                      expression: "purchase.purchase_limit_year",
                    },
                  }),
                  !_vm.isBasedOnFailedPurchase &&
                  !_vm.isBeforeUncategorizedLimit &&
                  !_vm.customerCanCreateHiddenPurchase
                    ? _c("form-row-single-select", {
                        attrs: {
                          options: _vm.forFilters.externalPurchaseCategories,
                          v: _vm.$v.purchase.external_purchase_category_id,
                          disabled: "",
                          label: "Категория закупки",
                          "label-field": "name",
                          placeholder: "Выберите категорию закупки",
                        },
                        model: {
                          value: _vm.purchase.external_purchase_category_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "external_purchase_category_id",
                              $$v
                            )
                          },
                          expression: "purchase.external_purchase_category_id",
                        },
                      })
                    : _vm._e(),
                  _vm.isBasedOnFailedPurchase
                    ? _c("form-row-single-select", {
                        attrs: {
                          options: [_vm.failedPurchaseData],
                          v: _vm.$v.purchase.failed_purchase_id,
                          disabled: "",
                          label: "Номер несостоявшейся закупки",
                          "label-field": "reg_number",
                          placeholder: "Выберите несостоявшуюся закупку",
                        },
                        model: {
                          value: _vm.purchase.failed_purchase_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "failed_purchase_id", $$v)
                          },
                          expression: "purchase.failed_purchase_id",
                        },
                      })
                    : _vm._e(),
                  !this.purchase.purchase_category_id ||
                  (this.purchase.purchase_category_id &&
                    _vm.$v.purchase.purchase_category_id.$anyDirty)
                    ? _c("form-row-edit-text", {
                        attrs: {
                          v: _vm.$v.purchase.tru_name,
                          description:
                            'В связи с изменениями в формах создания/редактирования извещений и внесистемных закупок, данное поле более не будет использоваться, пожалуйста, выберите подходящий "Предмет закупки" в поле ниже',
                          disabled: "",
                          label: "Наименование закупки",
                        },
                        model: {
                          value: _vm.purchase.tru_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "tru_name", $$v)
                          },
                          expression: "purchase.tru_name",
                        },
                      })
                    : _vm._e(),
                  _vm.isHiddenPurchaseCategory
                    ? _c("form-row-edit-text", {
                        attrs: {
                          disabled: "",
                          label: "Предмет закупки",
                          placeholder: "Выберите предмет закупки",
                        },
                        model: {
                          value: _vm.purchase.purchase_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_name", $$v)
                          },
                          expression: "purchase.purchase_name",
                        },
                      })
                    : _vm._e(),
                  !_vm.isHiddenPurchaseCategory
                    ? _c("form-row-single-select", {
                        attrs: {
                          disabled:
                            this.purchase.purchase_category_id &&
                            !_vm.$v.purchase.purchase_category_id.$anyDirty,
                          options: _vm.forFilters.purchaseCategories,
                          v: _vm.$v.purchase.purchase_category_id,
                          label: "Предмет закупки",
                          placeholder: "Выберите предмет закупки",
                        },
                        model: {
                          value: _vm.purchase.purchase_category_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_category_id", $$v)
                          },
                          expression: "purchase.purchase_category_id",
                        },
                      })
                    : _vm._e(),
                  _c("form-row-single-select", {
                    attrs: {
                      disabled: _vm.isBasedOnFailedPurchase,
                      options: _vm.forFilters.orderTypes,
                      v: _vm.$v.purchase.order_type_id,
                      label: "Тип закупки",
                      "label-field": "text",
                      placeholder: "Выберите тип закупки",
                    },
                    model: {
                      value: _vm.purchase.order_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "order_type_id", $$v)
                      },
                      expression: "purchase.order_type_id",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      v: _vm.$v.purchase.planned_contract_date,
                      disabled: "",
                      label: "Дата заключения договора",
                    },
                    model: {
                      value: _vm.purchase.planned_contract_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "planned_contract_date", $$v)
                      },
                      expression: "purchase.planned_contract_date",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      "max-date": _vm.$now(),
                      "min-date": _vm.purchase.planned_contract_date,
                      v: _vm.$v.purchase.planned_end_date,
                      label: "Дата исполнения договора",
                    },
                    model: {
                      value: _vm.purchase.planned_end_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "planned_end_date", $$v)
                      },
                      expression: "purchase.planned_end_date",
                    },
                  }),
                  _vm.features.kbk_limits
                    ? _c("form-row-edit-checkbox", {
                        attrs: {
                          label: "Закупка при отсутствии ЛБО (лимитов по КБК)",
                          disabled: _vm.withoutLimits,
                        },
                        model: {
                          value: _vm.iskbklimitoptional,
                          callback: function ($$v) {
                            _vm.iskbklimitoptional = $$v
                          },
                          expression: "iskbklimitoptional",
                        },
                      })
                    : _vm._e(),
                  _vm.features.kbk_limits && !_vm.iskbklimitoptional
                    ? _c("form-row-edit-checkbox", {
                        attrs: { label: "Авансовый отчет" },
                        model: {
                          value: _vm.purchase.is_advance_report,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "is_advance_report", $$v)
                          },
                          expression: "purchase.is_advance_report",
                        },
                      })
                    : _vm._e(),
                  _c("DeliverablesSelector.view", {
                    staticClass: "atmo-page-content my-2 py-2",
                    attrs: {
                      islisteditable: false,
                      isquantityeditable: !_vm.isBasedOnFailedPurchase,
                      specs: _vm.purchase.specs,
                      max_price: _vm.priceLimit,
                    },
                  }),
                  !_vm.iskbklimitoptional
                    ? _c("KBKLimitsEditableList.view", {
                        staticClass: "atmo-page-content my-2 py-2",
                        attrs: {
                          initiallist: _vm.purchase.limits,
                          limitfilterpresets: {
                            year: _vm.purchase.purchase_limit_year,
                            purchase_type_id: _vm.purchase.order_type_id,
                            purchase_id: _vm.purchase.failed_purchase_id,
                          },
                          totalamount: _vm.spec_total_amount,
                        },
                      })
                    : _vm._e(),
                  _c("text-header", [_vm._v("Информация о поставщике")]),
                  _vm.purchase.is_private_provider
                    ? [
                        _vm.purchase.is_private_provider
                          ? _c("text-row", {
                              attrs: {
                                label: "Наименование",
                                value: "Физическое лицо",
                              },
                            })
                          : _vm._e(),
                      ]
                    : [
                        _c("text-row", {
                          staticClass: "mb-0",
                          attrs: { label: "ИНН" },
                          model: {
                            value: _vm.purchase.organizationFinal.inn,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.organizationFinal,
                                "inn",
                                $$v
                              )
                            },
                            expression: "purchase.organizationFinal.inn",
                          },
                        }),
                        _vm.purchase.organizationFinal.kpp
                          ? _c("text-row", {
                              staticClass: "mb-0",
                              attrs: { label: "КПП" },
                              model: {
                                value: _vm.purchase.organizationFinal.kpp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.purchase.organizationFinal,
                                    "kpp",
                                    $$v
                                  )
                                },
                                expression: "purchase.organizationFinal.kpp",
                              },
                            })
                          : _vm._e(),
                        _c("text-row", {
                          staticClass: "mb-0",
                          attrs: { label: "ОГРН" },
                          model: {
                            value: _vm.purchase.organizationFinal.ogrn,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.organizationFinal,
                                "ogrn",
                                $$v
                              )
                            },
                            expression: "purchase.organizationFinal.ogrn",
                          },
                        }),
                        _c("text-row", {
                          staticClass: "mb-0",
                          attrs: { label: "Наименование" },
                          model: {
                            value: _vm.purchase.organizationFinal.full_name,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.organizationFinal,
                                "full_name",
                                $$v
                              )
                            },
                            expression: "purchase.organizationFinal.full_name",
                          },
                        }),
                        _c("text-row", {
                          staticClass: "mb-0",
                          attrs: { label: "Фактический адрес" },
                          model: {
                            value: _vm.purchase.organizationFinal.fact_address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.purchase.organizationFinal,
                                "fact_address",
                                $$v
                              )
                            },
                            expression:
                              "purchase.organizationFinal.fact_address",
                          },
                        }),
                      ],
                  _c("text-header", [_vm._v("Информация о договоре")]),
                  _c("text-row", {
                    attrs: { label: "Статус закупки" },
                    model: {
                      value: _vm.purchase.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "status", $$v)
                      },
                      expression: "purchase.status",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    attrs: {
                      "label-cols-lg": 3,
                      v: _vm.$v.purchase.main_file,
                      title: "Договор",
                    },
                    model: {
                      value: _vm.purchase.main_file,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "main_file", $$v)
                      },
                      expression: "purchase.main_file",
                    },
                  }),
                  _c("form-row-edit-text", {
                    attrs: {
                      v: _vm.$v.purchase.customer_contract_number,
                      label: "Номер договора в системе учета заказчика",
                      description: _vm.Config.get(
                        "theme.variables.view.customer_contract_number_description"
                      ),
                    },
                    model: {
                      value: _vm.purchase.customer_contract_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "customer_contract_number", $$v)
                      },
                      expression: "purchase.customer_contract_number",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.features.kbk_limits &&
                            !_vm.iskbklimitoptional &&
                            !_vm.purchase.is_advance_report,
                          expression:
                            "features.kbk_limits && !iskbklimitoptional && !purchase.is_advance_report",
                        },
                      ],
                    },
                    [
                      _c("text-header", [
                        _vm._v("Банковские реквизиты поставщика"),
                      ]),
                      _c("p", [
                        _c("span", [
                          _vm._v(
                            "Укажите банковские реквизиты поставщика для передачи в РИС."
                          ),
                        ]),
                      ]),
                      _c(
                        "b-form-row",
                        [
                          _c("frc-select", {
                            attrs: {
                              options: _vm.bankTypes,
                              v: _vm.$v.bank.bank_type,
                              label: "Вид обслуживающей организации",
                            },
                            model: {
                              value: _vm.bank.bank_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "bank_type", $$v)
                              },
                              expression: "bank.bank_type",
                            },
                          }),
                          _c(
                            "b-col",
                            { attrs: { lg: 6, sm: 12 } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    state: _vm.$v.bank.bik.$invalid,
                                    label: "БИК банка:",
                                    "label-class": "fs-14 grey-color fw-700",
                                    "label-for": "supplier-accounting-bik",
                                  },
                                },
                                [
                                  _c("VueSuggestions", {
                                    staticClass: "fs-14 form-control",
                                    class: _vm.$v.bank.bik.$invalid
                                      ? "is-invalid"
                                      : "is-valid",
                                    attrs: {
                                      id: "supplier-accounting-bik",
                                      model: _vm.bank.bik,
                                      options: _vm.suggestionOptionsBank,
                                      placeholder: "Начните вводить",
                                    },
                                  }),
                                  _vm.errorsBik
                                    ? [
                                        _c("b-form-invalid-feedback", [
                                          _vm._v(
                                            "Поле обязательно для заполнения"
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.bank.bank_name,
                              disabled: "disabled",
                              label: "Название банка",
                            },
                            model: {
                              value: _vm.bank.bank_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "bank_name", $$v)
                              },
                              expression: "bank.bank_name",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.bank.bank_city,
                              disabled: "",
                              label: "Город банка",
                            },
                            model: {
                              value: _vm.bank.bank_city,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "bank_city", $$v)
                              },
                              expression: "bank.bank_city",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: { v: _vm.$v.bank.ks, label: "Кор. счет" },
                            model: {
                              value: _vm.bank.ks,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "ks", $$v)
                              },
                              expression: "bank.ks",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            attrs: {
                              v: _vm.$v.bank.rs,
                              label: "Расчетный счет",
                            },
                            model: {
                              value: _vm.bank.rs,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "rs", $$v)
                              },
                              expression: "bank.rs",
                            },
                          }),
                          _c("form-row-edit-text-compact", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.bank.bank_type !== "bank",
                                expression: "bank.bank_type !== 'bank'",
                              },
                            ],
                            attrs: { v: _vm.$v.bank.ls, label: "Лицевой счет" },
                            model: {
                              value: _vm.bank.ls,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "ls", $$v)
                              },
                              expression: "bank.ls",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("text-header", [_vm._v("Документы об исполнении")]),
                  _c(
                    "p",
                    [
                      _c("strong", { staticClass: "text-danger" }, [
                        _vm._v("Важно!"),
                      ]),
                      _vm._v(
                        " Редактирование статуса закупки и перечня документов об исполнении производите через "
                      ),
                      _c(
                        "router-link",
                        {
                          staticStyle: {
                            "text-decoration": "underline",
                            color: "var(--green-color)",
                          },
                          attrs: {
                            to: {
                              name: "ExternalPurchaseShow",
                              params: { purchase: this.$route.params.id },
                              hash: "#contract-fulfillment",
                            },
                          },
                        },
                        [_vm._v("форму просмотра")]
                      ),
                      _vm._v(". "),
                    ],
                    1
                  ),
                  _c("form-row-documents", {
                    attrs: { title: "" },
                    model: {
                      value: _vm.purchase.files,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "files", $$v)
                      },
                      expression: "purchase.files",
                    },
                  }),
                  _vm.specErrors
                    ? _c("p", {
                        staticClass: "text-danger",
                        domProps: { textContent: _vm._s(_vm.specErrorMessage) },
                      })
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "my-3 mt-5" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-4 text-uppercase",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.onCancel },
                                },
                                [_vm._v("Отмена")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    disabled: !_vm.isValid,
                                    variant: "custom-green",
                                  },
                                  on: { click: _vm.sendData },
                                },
                                [_vm._v("Сохранить")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }