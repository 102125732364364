<template>
    <b-container class="form-header py-4" fluid>
        <b-container>
            <b-row>
                <b-col>
                    <p class="fw-600 white-color mb-4 mt-2 if-tablet" :class="titleClass">{{ title }}</p>
                </b-col>
            </b-row>
            <b-row v-if="!withoutCurrentOrganization">
                <b-col>
                    <p class="fs-14 greyBg-color mb-1">&laquo;{{ $store.state.organization.short_name }}&raquo;</p>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'FormHeader',
    props: {
        title: {
            type: String,
            required: true
        },
        withoutCurrentOrganization: {
            type: Boolean,
            default: false
        },
        titleClass: {
            type: String,
            default: 'fs-52'
        }
    }
};
</script>

<style>
.form-header {
    background-image: url('/images/purchases_head.svg');
    min-height: 200px;
}
</style>
