var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "12" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "fs-14 my-1 mx-2",
                  staticStyle: { height: "40px" },
                  attrs: {
                    href: _vm.$links.purchase_categories,
                    size: "lg",
                    target: "_blank",
                    variant: "danger",
                  },
                },
                [_vm._v(" Категории предметов закупок")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "fs-14 my-1 mx-2",
                  staticStyle: { height: "40px" },
                  attrs: {
                    href: _vm.$links.manual_ktru_requests_add,
                    size: "lg",
                    target: "_blank",
                    variant: "danger",
                  },
                },
                [_vm._v(" Инструкция по добавлению позиции КТРУ")]
              ),
              _c(
                "b-btn",
                {
                  staticClass: "fs-14 my-1 ml-2",
                  staticStyle: { height: "40px" },
                  attrs: {
                    href: _vm.$links.ktru_requests_video_manual,
                    size: "lg",
                    target: "_blank",
                    variant: "danger",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mx-1",
                    staticStyle: { height: "16px" },
                    attrs: { alt: "documentation", src: "/images/youtube.svg" },
                  }),
                  _vm._v(" Видеоинструкция "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "8" } }, [
            _c("p", { staticClass: "fs-32 grey-color" }, [
              _vm._v("Запросы на добавление позиций КТРУ"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { md: "4" } },
            [
              _c(
                "b-btn",
                {
                  staticClass: "fs-14 my-1",
                  attrs: {
                    to: { name: "KtruRequestsCreate" },
                    size: "sm",
                    variant: "custom-green",
                  },
                },
                [_vm._v(" Сформировать запрос")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("vuetable", {
            ref: "vuetable",
            attrs: {
              "append-params": { filter: _vm.filter },
              css: _vm.vuetable2Config.table,
              fields: _vm.columns,
              "http-fetch": _vm.fetchData,
              "load-on-start": true,
              "query-params": _vm.queryParams,
              "sort-order": _vm.sortOrder,
              "api-url": "/ktru_requests",
              "data-path": "data",
              "no-data-template": "Данные не найдены",
              "pagination-path": "meta",
            },
            on: { "vuetable:pagination-data": _vm.onPaginationData },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function (props) {
                  return [
                    _c("p", [
                      _c("strong", [_vm._v("Название:")]),
                      _vm._v(" " + _vm._s(props.rowData.name)),
                    ]),
                    props.rowData.description
                      ? _c("p", [
                          _c("strong", [_vm._v("Описание:")]),
                          _vm._v(" " + _vm._s(props.rowData.description)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "specs",
                fn: function (props) {
                  return _c("div", { staticStyle: { "max-width": "450px" } }, [
                    _c(
                      "ul",
                      _vm._l(props.rowData.specs, function (item, key) {
                        return _c(
                          "li",
                          {
                            key: item + key,
                            staticStyle: { "word-wrap": "break-word" },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "900" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _vm._v(": " + _vm._s(item.value) + " "),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                },
              },
              {
                key: "status_id",
                fn: function (props) {
                  return [
                    props.rowData.status_id === 1
                      ? [
                          _c("b", [_vm._v("Черновик")]),
                          _c("br"),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 w-100",
                              attrs: {
                                to: {
                                  name: "KtruRequestsEdit",
                                  params: { id: props.rowData.id },
                                },
                                size: "sm",
                              },
                            },
                            [_vm._v("Редактировать")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 w-100",
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRequest(props.rowData.id)
                                },
                              },
                            },
                            [_vm._v("Удалить")]
                          ),
                        ]
                      : _vm._e(),
                    props.rowData.status_id === 2
                      ? [_c("b", [_vm._v("На рассмотрении")])]
                      : _vm._e(),
                    props.rowData.status_id === 3
                      ? [
                          _c("b", [_vm._v("Принято")]),
                          _c("br"),
                          _c("br"),
                          _vm._v(" Рег.номер созданной позиции КТРУ - "),
                          _c("strong", [
                            _vm._v(_vm._s(props.rowData.ktru_item_reg_number)),
                          ]),
                        ]
                      : _vm._e(),
                    props.rowData.status_id === 4
                      ? [
                          _c("b", [_vm._v("Отклонено")]),
                          _vm._v(". "),
                          _c("br"),
                          _c("b", [_vm._v("Комментарий:")]),
                          _vm._v(" " + _vm._s(props.rowData.comment)),
                          _c("br"),
                          _c("br"),
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 w-100",
                              attrs: {
                                to: {
                                  name: "KtruRequestsEdit",
                                  params: { id: props.rowData.id },
                                },
                                size: "sm",
                              },
                            },
                            [_vm._v("Редактировать")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-1 w-100",
                              attrs: { size: "sm", variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRequest(props.rowData.id)
                                },
                              },
                            },
                            [_vm._v("Удалить")]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "float-right d-flex" },
        [
          _c("vuetable-pagination", {
            ref: "pagination",
            attrs: { css: _vm.vuetable2Config.pagination },
            on: { "vuetable-pagination:change-page": _vm.onChangePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }