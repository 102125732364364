var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showForm
        ? _c(
            "b-container",
            { staticClass: "register_page_header_block", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("div", { staticClass: "fs-52 fw-600 white-color pt-3" }, [
                    _vm._v("Регистрация заказчика"),
                  ]),
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100 register_customer py-3",
                      attrs: { "content-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.organization.$invalid,
                                number: "01",
                              },
                              slot: "title",
                            },
                            [
                              _vm._v(
                                " Регистрационные и контактные данные организации"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.user.$invalid,
                                number: "02",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Идентификационные данные пользователя")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.check_rules.$invalid,
                                number: "03",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Завершение создания заявления")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showForm
        ? _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100",
                      attrs: { "nav-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [_vm._v("Данные организации")]),
                          _c("form-row-edit-text", {
                            attrs: {
                              disabled: "",
                              label: "Полное наименование",
                            },
                            model: {
                              value: _vm.form.organization.full_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "full_name",
                                  $$v
                                )
                              },
                              expression: "form.organization.full_name",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              disabled: "",
                              label: "Сокращенное наименование",
                            },
                            model: {
                              value: _vm.form.organization.short_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "short_name",
                                  $$v
                                )
                              },
                              expression: "form.organization.short_name",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { disabled: "", label: "Руководитель" },
                            model: {
                              value: _vm.form.organization.manager,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "manager", $$v)
                              },
                              expression: "form.organization.manager",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { disabled: "", label: "ИНН" },
                            model: {
                              value: _vm.form.organization.inn,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "inn", $$v)
                              },
                              expression: "form.organization.inn",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { disabled: "", label: "КПП" },
                            model: {
                              value: _vm.form.organization.kpp,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "kpp", $$v)
                              },
                              expression: "form.organization.kpp",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { disabled: "", label: "ОГРН / ОГРНИП" },
                            model: {
                              value: _vm.form.organization.ogrn,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "ogrn", $$v)
                              },
                              expression: "form.organization.ogrn",
                            },
                          }),
                          _c("form-row-single-select", {
                            attrs: {
                              disabled:
                                _vm.form.organization.municipality_id !== null,
                              options: _vm.directories.budgetaryManagers,
                              v: _vm.$v.form.organization.budgetary_manager_id,
                              "allow-empty": "",
                              description: "или",
                              label: "ГРБС",
                              placeholder: "Выберите ГРБС",
                            },
                            model: {
                              value: _vm.form.organization.budgetary_manager_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "budgetary_manager_id",
                                  $$v
                                )
                              },
                              expression:
                                "form.organization.budgetary_manager_id",
                            },
                          }),
                          _c("form-row-single-select", {
                            attrs: {
                              disabled:
                                _vm.form.organization.budgetary_manager_id !==
                                null,
                              options: _vm.directories.municipalities,
                              v: _vm.$v.form.organization.municipality_id,
                              "allow-empty": "",
                              label: "Муниципалитет",
                              placeholder: "Выберите Муниципалитет",
                            },
                            model: {
                              value: _vm.form.organization.municipality_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "municipality_id",
                                  $$v
                                )
                              },
                              expression: "form.organization.municipality_id",
                            },
                          }),
                          _c("text-header", [_vm._v("Контактная информация")]),
                          _c("form-row-edit-dadata-address", {
                            attrs: {
                              v: _vm.$v.form.organization.post_address,
                              label: "Адрес почтовый",
                            },
                            model: {
                              value: _vm.form.organization.post_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "post_address",
                                  $$v
                                )
                              },
                              expression: "form.organization.post_address",
                            },
                          }),
                          _c("form-row-edit-dadata-address", {
                            attrs: {
                              v: _vm.$v.form.organization.fact_address,
                              label: "Адрес фактический",
                            },
                            model: {
                              value: _vm.form.organization.fact_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "fact_address",
                                  $$v
                                )
                              },
                              expression: "form.organization.fact_address",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              "email-confirmed":
                                _vm.form.organization.email_confirmed,
                              v: _vm.$v.form.organization.email,
                              value: _vm.form.organization.email,
                              label: "Адрес электронной почты",
                              "with-validation": "",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.organization.email_confirmed = $event
                              },
                            },
                          }),
                          _c("form-row-edit-phone", {
                            attrs: {
                              v: _vm.$v.form.organization.phone,
                              value: _vm.form.organization.phone,
                              label: "Телефон",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.phone = $event
                              },
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { label: "Адрес сайта" },
                            model: {
                              value: _vm.form.organization.site,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "site", $$v)
                              },
                              expression: "form.organization.site",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contact_name,
                              label:
                                "ФИО контактного лица при осуществлении закупок",
                            },
                            model: {
                              value: _vm.form.organization.contact_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "contact_name",
                                  $$v
                                )
                              },
                              expression: "form.organization.contact_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Идентификационные данные пользователя"),
                          ]),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.last_name,
                              label: "Фамилия",
                            },
                            on: { change: _vm.onLastNameChange },
                            model: {
                              value: _vm.form.user.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "last_name", $$v)
                              },
                              expression: "form.user.last_name",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.first_name,
                              label: "Имя",
                            },
                            model: {
                              value: _vm.form.user.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "first_name", $$v)
                              },
                              expression: "form.user.first_name",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: { label: "Отчество" },
                            model: {
                              value: _vm.form.user.middle_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "middle_name", $$v)
                              },
                              expression: "form.user.middle_name",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              label: "Логин",
                              description:
                                "Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля",
                              disabled: "",
                            },
                            model: {
                              value: _vm.form.user.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "username", $$v)
                              },
                              expression: "form.user.username",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              label: "Пароль",
                              v: _vm.$v.form.user.password,
                              type: "password",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.password = $event
                              },
                            },
                            model: {
                              value: _vm.form.user.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "password", $$v)
                              },
                              expression: "form.user.password",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              label: "Подтверждение пароля",
                              v: _vm.$v.form.user.password_confirmation,
                              type: "password",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.password_confirmation = $event
                              },
                            },
                            model: {
                              value: _vm.form.user.password_confirmation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.user,
                                  "password_confirmation",
                                  $$v
                                )
                              },
                              expression: "form.user.password_confirmation",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              "email-confirmed": _vm.form.user.email_confirmed,
                              v: _vm.$v.form.user.email,
                              value: _vm.form.user.email,
                              label: "Адрес электронной почты",
                              "with-validation": "",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.user.email_confirmed = $event
                              },
                            },
                          }),
                          _vm.$isPhoneConfirmationNeeded
                            ? _c("form-row-edit-phone", {
                                attrs: {
                                  "phone-confirmed":
                                    _vm.form.user.phone_confirmed,
                                  v: _vm.$v.form.user.phone,
                                  value: _vm.form.user.phone,
                                  label: "Телефон",
                                  "with-validation": "",
                                },
                                on: {
                                  value: function ($event) {
                                    _vm.form.user.phone = $event
                                  },
                                  "phone-confirmed": function ($event) {
                                    _vm.form.user.phone_confirmed = $event
                                  },
                                },
                              })
                            : _c("form-row-edit-phone", {
                                attrs: {
                                  v: _vm.$v.form.user.phone,
                                  value: _vm.form.user.phone,
                                  label: "Телефон",
                                },
                                on: {
                                  value: function ($event) {
                                    _vm.form.user.phone = $event
                                  },
                                },
                              }),
                          _c("form-row-single-select", {
                            attrs: {
                              options: _vm.directories.posts,
                              v: _vm.$v.form.user.post,
                              label: "Должность",
                              "label-field": "name",
                              placeholder: "Выберите должность",
                              "track-by": "name",
                            },
                            model: {
                              value: _vm.form.user.post,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "post", $$v)
                              },
                              expression: "form.user.post",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Завершение создания заявления"),
                          ]),
                          _c("registration-confirmation-text-block"),
                          _c(
                            "b-form-group",
                            { attrs: { state: !_vm.$v.form.check_rules } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { state: !_vm.$v.form.check_rules },
                                  model: {
                                    value: _vm.form.check_rules,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "check_rules", $$v)
                                    },
                                    expression: "form.check_rules",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "grey-color fw-600" },
                                    [
                                      _vm._v(
                                        "Подтверждаю перечисленные выше условия"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-5" },
                    [
                      _vm.tabIndex > 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.previousTab },
                            },
                            [_vm._v("Назад")]
                          )
                        : _vm._e(),
                      _vm.tabIndex < 2
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: { variant: "custom-green" },
                              on: { click: _vm.nextTab },
                            },
                            [_vm._v("Далее")]
                          )
                        : _vm._e(),
                      _vm.tabIndex === 2
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: {
                                disabled: _vm.$v.form.$invalid,
                                variant: "custom-green",
                              },
                              on: { click: _vm.makeXml },
                            },
                            [_vm._v("Готово")]
                          )
                        : _vm._e(),
                      _vm.tabIndex === 2 && _vm.$v.form.$invalid
                        ? _c("span", { staticClass: "text-danger mx-2" }, [
                            _vm._v(
                              "Заполните все обязательные поля на каждой вкладке."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "select-organization",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Укажите ИНН организации",
          },
          model: {
            value: _vm.showOrgModal,
            callback: function ($$v) {
              _vm.showOrgModal = $$v
            },
            expression: "showOrgModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-input-group",
                { staticClass: "mt-3", attrs: { prepend: "ИНН" } },
                [
                  _c("b-form-input", {
                    attrs: { trim: "" },
                    model: {
                      value: _vm.inn,
                      callback: function ($$v) {
                        _vm.inn = $$v
                      },
                      expression: "inn",
                    },
                  }),
                ],
                1
              ),
              _vm.foundOrganizationBranches || _vm.kpp
                ? _c(
                    "b-input-group",
                    { staticClass: "mt-3", attrs: { prepend: "КПП" } },
                    [
                      _c("b-form-input", {
                        attrs: { trim: "" },
                        model: {
                          value: _vm.kpp,
                          callback: function ($$v) {
                            _vm.kpp = $$v
                          },
                          expression: "kpp",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { md: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase float-right",
                          attrs: {
                            disabled: _vm.isFind,
                            variant: "custom-green",
                          },
                          on: { click: _vm.findOrg },
                        },
                        [
                          _vm.isFind
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _vm._v(" Найти "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !!_vm.tmpOrganization
                ? _c(
                    "b-row",
                    { staticClass: "py-3" },
                    [
                      _c("b-col", { staticClass: "fs-16 grey-color" }, [
                        _vm._v(" Это ваша организация ?"),
                      ]),
                      _c("b-col", { staticClass: "fs-16 grey-color fw-600" }, [
                        _vm._v(
                          " " + _vm._s(_vm.tmpOrganization.full_name) + " "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelCertificate },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    disabled: _vm.tmpOrganization == null,
                    variant: "custom-green",
                  },
                  on: { click: _vm.showCreateForm },
                },
                [_vm._v("Подтвердить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "select-cert-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Выберите сертификат",
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "multiselect",
                {
                  attrs: {
                    "allow-clear": false,
                    "allow-empty": false,
                    "close-on-select": true,
                    multiple: false,
                    options: _vm.certificates,
                    "preserve-search": false,
                    "show-labels": false,
                    label: "text",
                    placeholder: "Выберите сертификат",
                    "track-by": "value",
                  },
                  on: { input: _vm.onCertificateChange },
                  scopedSlots: _vm._u([
                    {
                      key: "singleLabel",
                      fn: function (props) {
                        return [
                          _c("strong", [_vm._v("Субъект:")]),
                          _vm._v(" " + _vm._s(props.option.text)),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Срок действия:")]),
                          _vm._v(
                            " с " +
                              _vm._s(
                                _vm.getDateFormat(props.option.valid_from)
                              ) +
                              " по " +
                              _vm._s(_vm.getDateFormat(props.option.valid_to)) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _c("strong", [_vm._v("Субъект:")]),
                          _vm._v(" " + _vm._s(props.option.text)),
                          _c("br"),
                          _c("br"),
                          _c("strong", [_vm._v("Срок действия:")]),
                          _vm._v(
                            " с " +
                              _vm._s(
                                _vm.getDateFormat(props.option.valid_from)
                              ) +
                              " по " +
                              _vm._s(_vm.getDateFormat(props.option.valid_to)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectCertificate,
                    callback: function ($$v) {
                      _vm.selectCertificate = $$v
                    },
                    expression: "selectCertificate",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "noOptions" }, slot: "noOptions" },
                    [_vm._v("Нет данных")]
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "noResult" }, slot: "noResult" },
                    [_vm._v("По вашему запросу сертификаты не найдены")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelCertificate },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    disabled: _vm.certIndex == null,
                    variant: "custom-green",
                  },
                  on: { click: _vm.checkCert },
                },
                [_vm._v(" Продолжить")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "sign-form-modal",
          attrs: {
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            scrollable: "",
            size: "lg",
            title: "Подписать заявление",
          },
          on: { close: _vm.cancelSignModal },
          model: {
            value: _vm.showSignModal,
            callback: function ($$v) {
              _vm.showSignModal = $$v
            },
            expression: "showSignModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _c(
              "div",
              { staticClass: "d-block text-left" },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "mt-3" } },
                  [
                    _c(
                      "b-tab",
                      { attrs: { active: "" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            { staticClass: "system_link-style fs-16" },
                            [_vm._v("Форма")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "px-3" },
                          [
                            _c("text-header", [
                              _vm._v("Регистрационные данные организации"),
                            ]),
                            _c("text-row", {
                              attrs: { label: "Сокращенное наименование" },
                              model: {
                                value: _vm.form.organization.short_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.organization,
                                    "short_name",
                                    $$v
                                  )
                                },
                                expression: "form.organization.short_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Руководитель" },
                              model: {
                                value: _vm.form.organization.manager,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.organization,
                                    "manager",
                                    $$v
                                  )
                                },
                                expression: "form.organization.manager",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "ИНН" },
                              model: {
                                value: _vm.form.organization.inn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "inn", $$v)
                                },
                                expression: "form.organization.inn",
                              },
                            }),
                            _vm.form.organization.kpp
                              ? _c("text-row", {
                                  attrs: { label: "КПП" },
                                  model: {
                                    value: _vm.form.organization.kpp,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.organization,
                                        "kpp",
                                        $$v
                                      )
                                    },
                                    expression: "form.organization.kpp",
                                  },
                                })
                              : _vm._e(),
                            _c("text-row", {
                              attrs: { label: "ОГРН / ОГРНИП" },
                              model: {
                                value: _vm.form.organization.ogrn,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "ogrn", $$v)
                                },
                                expression: "form.organization.ogrn",
                              },
                            }),
                            _c("text-header", [
                              _vm._v("Контактные данные организации"),
                            ]),
                            _c("text-row", {
                              attrs: { label: "Адрес почтовый" },
                              model: {
                                value: _vm.form.organization.post_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.organization,
                                    "post_address",
                                    $$v
                                  )
                                },
                                expression: "form.organization.post_address",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Адрес фактический" },
                              model: {
                                value: _vm.form.organization.fact_address,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.organization,
                                    "fact_address",
                                    $$v
                                  )
                                },
                                expression: "form.organization.fact_address",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Адрес электронной почты" },
                              model: {
                                value: _vm.form.organization.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "email", $$v)
                                },
                                expression: "form.organization.email",
                              },
                            }),
                            _c("text-row-phone", {
                              attrs: { label: "Телефон" },
                              model: {
                                value: _vm.form.organization.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "phone", $$v)
                                },
                                expression: "form.organization.phone",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Адрес сайта" },
                              model: {
                                value: _vm.form.organization.site,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "site", $$v)
                                },
                                expression: "form.organization.site",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Факс" },
                              model: {
                                value: _vm.form.organization.fax,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.organization, "fax", $$v)
                                },
                                expression: "form.organization.fax",
                              },
                            }),
                            _c("text-row", {
                              attrs: {
                                label:
                                  "ФИО контактного лица при осуществлении закупок",
                              },
                              model: {
                                value: _vm.form.organization.contact_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.organization,
                                    "contact_name",
                                    $$v
                                  )
                                },
                                expression: "form.organization.contact_name",
                              },
                            }),
                            _c("text-header", [
                              _vm._v("Контактные данные организации"),
                            ]),
                            _c("text-row", {
                              attrs: { label: "Фамилия" },
                              model: {
                                value: _vm.form.user.last_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "last_name", $$v)
                                },
                                expression: "form.user.last_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Имя" },
                              model: {
                                value: _vm.form.user.first_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "first_name", $$v)
                                },
                                expression: "form.user.first_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Отчество" },
                              model: {
                                value: _vm.form.user.middle_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "middle_name", $$v)
                                },
                                expression: "form.user.middle_name",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Адрес электронной почты" },
                              model: {
                                value: _vm.form.user.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "email", $$v)
                                },
                                expression: "form.user.email",
                              },
                            }),
                            _c("text-row-phone", {
                              attrs: { label: "Телефон" },
                              model: {
                                value: _vm.form.user.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "phone", $$v)
                                },
                                expression: "form.user.phone",
                              },
                            }),
                            _c("text-row", {
                              attrs: { label: "Должность" },
                              model: {
                                value: _vm.form.user.post,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.user, "post", $$v)
                                },
                                expression: "form.user.post",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "b-tab",
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "span",
                            { staticClass: "system_link-style fs-16" },
                            [_vm._v("Xml версия")]
                          ),
                        ]),
                        _c(
                          "pre",
                          { staticClass: "fs-10 grey-color p-3 greyBg m-0" },
                          [_vm._v(_vm._s(_vm.signedFormXml))]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "p-3 d-flex align-items-center" }, [
                  _c("div", { staticClass: "w-50 fs-14 grey-color" }, [
                    _vm._v("Сертификат"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-50 fs-14 fw-600 grey-color" },
                    [
                      _c("b-input", {
                        staticClass: "fs-14",
                        attrs: { value: _vm.currentCertSubject, disabled: "" },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelSignModal },
                },
                [_vm._v("Отмена")]
              ),
              !_vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isSigningXml,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      !_vm.isSignedXml
                        ? _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: _vm.certIndex == null,
                                variant: "custom-green",
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("Подписать")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isSignedXml
                ? _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isDataSending,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _vm.isSignedXml
                        ? _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: _vm.certIndex == null,
                                variant: "custom-green",
                              },
                              on: { click: _vm.register },
                            },
                            [_vm._v("Зарегистрироваться")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }