var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "purchase_part-header",
      class: "status_color_" + _vm.purchase.status_id,
    },
    [
      _c(
        "b-row",
        { staticClass: "w-100 m-0" },
        [
          _c("b-col", { attrs: { md: "9", lg: "9" } }, [
            _vm.purchase.status_id === 11 || _vm.purchase.status_id === 13
              ? _c(
                  "p",
                  {
                    staticClass: "purchase_part-header_number",
                    staticStyle: {
                      "white-space": "nowrap",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                    },
                  },
                  [
                    _vm._v(" № " + _vm._s(_vm.purchase.reg_number) + " "),
                    _vm.purchase.purchase_object
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "text-overflow": "ellipsis ellipsis",
                            },
                          },
                          [
                            _vm._v(
                              ' На закупку "' +
                                _vm._s(_vm.purchase.purchase_object) +
                                '"'
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _c(
                  "p",
                  {
                    staticClass: "purchase_part-header_number",
                    staticStyle: {
                      "white-space": "nowrap",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                    },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "white-system_link-style fw-600",
                        attrs: {
                          to: {
                            name: "purchases.grouped.item.view",
                            params: { group: "fl44", id: _vm.purchase.id },
                          },
                          target: "_blank",
                        },
                      },
                      [_vm._v("№ " + _vm._s(_vm.purchase.reg_number))]
                    ),
                    _vm.purchase.purchase_object
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "text-overflow": "ellipsis ellipsis",
                            },
                          },
                          [
                            _vm._v(
                              ' На закупку "' +
                                _vm._s(_vm.purchase.purchase_object) +
                                '"'
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ]),
          _c(
            "b-col",
            { staticClass: "float-right", attrs: { md: "3", lg: "3" } },
            [
              _c(
                "p",
                {
                  staticClass: "purchase_part-header_label fw-600 float-right",
                },
                [
                  _vm.purchase.status_id === 1
                    ? [
                        _vm.purchase.is_hold
                          ? [_vm._v(" Подведение итогов ")]
                          : [
                              _vm._v(" " + _vm._s(_vm.purchase.status) + " "),
                              _vm.purchase.status_id === 1
                                ? _c("img", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      src: "/images/secundomer.svg",
                                      alt: "check_label",
                                    },
                                  })
                                : _vm._e(),
                            ],
                      ]
                    : [
                        _vm._v(" " + _vm._s(_vm.purchase.status) + " "),
                        _vm.purchase.status_id === 2
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_helmet.svg",
                                alt: "check_label",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 3
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_contract.svg",
                                alt: "check_label",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 7 ||
                        _vm.purchase.status_id === 8
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_times.svg",
                                alt: "check_label",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 6 ||
                        _vm.purchase.status_id === 5
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: {
                                src: "/images/white_times.svg",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _vm.purchase.status_id === 4
                          ? _c("img", {
                              staticClass: "ml-2",
                              attrs: { src: "/images/file_check.svg", alt: "" },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }