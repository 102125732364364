<template>
    <div class="table-responsive py-3">
        <b-table :busy="loading" :fields="fields" :items="history" :per-page="pagination.per_page" class="cTable" empty-text="История пуста" show-empty>
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle mr-1"></b-spinner>
                    <strong>Загрузка данных...</strong>
                </div>
            </template>
            <template #cell(created_at)="row">
                {{ $formatDateTime(row.item.created_at) }}
            </template>
            <template #cell(operation_type_id)="row">
                <strong>{{ row.item.operation_type_name }}&nbsp;</strong>
                <template v-if="row.item.accountable_type === 'purchase'">
                    (Извещение №
                    <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: row.item.accountable.id } }" target="_blank">
                        {{ row.item.accountable.reg_number }}
                    </router-link>
                    )
                </template>
                <template v-if="row.item.accountable_type === 'refundRequest'"> (Запрос на возврат средств № {{ row.item.accountable.reg_number }})</template>
                <template v-if="row.item.accountable_type === 'organizationReport'">№ {{ row.item.accountable.reg_number }}, ИНН {{ row.item.accountable.inn }}</template>
            </template>
            <template #cell(amount)="row">
                <div class="text-right">
                    {{ $formatPrice(row.item.amount) }}
                </div>
            </template>
        </b-table>
        <b-pagination v-if="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="mt-4 mb-0 mx-auto" @input="applyFilters" />
    </div>
</template>

<script>
import CabinetProviderAccounting from '@/services/api/cabinet/provider/Accounting';

export default {
    name: 'AccountingDetails',
    data() {
        return {
            loading: true,
            pagination: {},
            showFilterModal: false,
            history: [],
            fields: [
                { key: 'created_at', label: 'Дата' },
                { key: 'operation_type_id', label: 'Тип операции' },
                { key: 'amount', label: 'Сумма, руб.' }
            ],
            disabled: false
        };
    },
    created() {
        this.fillData();
    },
    methods: {
        applyFilters() {
            this.loading = true;
            this.fillData();
        },
        async fillData() {
            this.loading = true;
            const response = await CabinetProviderAccounting.getDetails({ page: this.pagination.current_page || 1, filters: this.filters });
            this.history = response.data.data;
            this.pagination = response.data.meta;
            this.loading = false;
            this.$scrollToTop();
        }
    }
};
</script>

<style scoped>
.calendar_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    user-select: none;
    pointer-events: none;
}

.purchase_part-header {
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.purchase_part-header_number {
    font-size: 12px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: flex;
    font-size: 12px;
    font-weight: 400;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    padding: 18px 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
