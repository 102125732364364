var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create" },
    [
      _c("form-header", {
        attrs: { title: "Запрос коммерческих предложений" },
      }),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("p", { staticClass: "block_head-text" }, [
                        _vm._v("Информация о заказчике"),
                      ]),
                      _c("img", {
                        attrs: {
                          src: "/images/certificate.svg",
                          alt: "certificate",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v("Наименование:")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(_vm._s(_vm.$store.state.organization.full_name))]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v("ИНН:")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(_vm._s(_vm.$store.state.organization.inn))]
                  ),
                ],
                1
              ),
              _vm.$store.state.organization.kpp
                ? _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("КПП:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.$store.state.organization.kpp))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v("Адрес:")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(_vm._s(_vm.$store.state.organization.post_address))]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v("Адрес электронной почты:")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(_vm._s(_vm.$store.state.organization.email))]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v("Номер контактного телефона:")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$parsePhone(_vm.$store.state.organization.phone)
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                _vm._v("Информация о запросе КП"),
              ]),
              _c("form-row-single-select", {
                attrs: {
                  label: "Предмет закупки",
                  placeholder: "Выберите предмет закупки",
                  options: _vm.purchaseCategories,
                  v: _vm.$v.form.purchase_category_id,
                },
                model: {
                  value: _vm.form.purchase_category_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchase_category_id", $$v)
                  },
                  expression: "form.purchase_category_id",
                },
              }),
              _c("form-row-edit-date", {
                attrs: {
                  label: "Срок сбора КП",
                  placeholder: "Укажите срок сбора КП",
                  v: _vm.$v.form.deadline,
                  "min-date": new Date().toJSON().slice(0, 10),
                },
                model: {
                  value: _vm.form.deadline,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deadline", $$v)
                  },
                  expression: "form.deadline",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("form-row-specification-table", {
                attrs: {
                  v: _vm.$v.form.specs,
                  "with-count-edit": "",
                  "spec-errors": _vm.specErrors,
                  "spec-error-message": _vm.specErrorMessage,
                },
                model: {
                  value: _vm.form.specs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "specs", $$v)
                  },
                  expression: "form.specs",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                        _vm._v("Техническое задание "),
                        _c("span", { staticClass: "red-star" }, [_vm._v("*")]),
                      ]),
                      _c(
                        "file-uploader",
                        {
                          attrs: {
                            "is-main": true,
                            "is-disabled": !!_vm.form.document,
                          },
                          on: { set_file: _vm.addFile },
                        },
                        [_vm._v("Загрузить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-3", attrs: { "no-gutters": "" } },
                [
                  _c("b-col", { staticClass: "fs-14 grey-color" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }),
                          _c("th", [_vm._v("Наименование")]),
                          _c("th", { attrs: { width: "1" } }),
                        ]),
                      ]),
                      _c("tbody", [
                        _vm.form.document !== null
                          ? _c("tr", [
                              _c("td", [_vm._v("1")]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "fs-14",
                                      staticStyle: {
                                        "padding-top": "0",
                                        "padding-bottom": "0",
                                        color: "var(--green-color) !important",
                                      },
                                      attrs: { variant: "link", size: "xs" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$commonDocumentDownload(
                                            _vm.form.document
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.form.document.name))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticStyle: {
                                        background: "transparent",
                                      },
                                      attrs: { variant: "light", size: "sm" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeFile(0, true)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/trash_icon.svg",
                                          alt: "trash_icon",
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("tr", [
                              _c(
                                "td",
                                { attrs: { colspan: "3", align: "center" } },
                                [_vm._v("Не добавлено документов")]
                              ),
                            ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("p", { staticClass: "fs-32 grey-color py-4" }, [
                _vm._v("Приглашение поставщиков"),
              ]),
              _c(
                "b-row",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: {
                        state: _vm.$v.form.emails.$invalid
                          ? !_vm.$v.form.emails.$invalid
                          : null,
                        md: "3",
                      },
                    },
                    [
                      _c("span", { staticClass: "fs-14 grey-color fw-700" }, [
                        _vm._v(
                          "Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке "
                        ),
                        _c("span", { staticClass: "red-star" }, [_vm._v("*")]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.organizations,
                            multiple: true,
                            "close-on-select": true,
                            "preserve-search": false,
                            taggable: true,
                            "tag-placeholder": "Добавить",
                            placeholder:
                              "Выберите организации / укажите адреса электронной почты",
                            "deselect-label": "Отменить",
                            "select-label": "Выбрать",
                            "selected-label": "Выбрано",
                            label: "name",
                            "track-by": "id",
                          },
                          on: { tag: _vm.addTag },
                          model: {
                            value: _vm.form.emails,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "emails", $$v)
                            },
                            expression: "form.emails",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Нет данных.")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [
                              _vm._v(
                                "Записей, соответствующих вашему запросу, не найдено."
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm.$v.form.emails.$invalid
                        ? _c("b-form-invalid-feedback", [
                            _vm._v("Поле не заполнено"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "my-3 mt-5 mb-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-4 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Отменить ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            variant: "custom-green",
                            disabled: !_vm.isFormValid,
                          },
                          on: { click: _vm.validateForm },
                        },
                        [_vm._v("Разместить запрос ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "sign-form-modal",
          attrs: {
            title: "Подписать заявку",
            size: "lg",
            scrollable: "",
            centered: "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
          },
          on: { close: _vm.cancelSignModal },
          model: {
            value: _vm.showSignModal,
            callback: function ($$v) {
              _vm.showSignModal = $$v
            },
            expression: "showSignModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-tabs",
                { attrs: { "content-class": "mt-3 px-3" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { active: "" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", { staticClass: "system_link-style fs-16" }, [
                          _vm._v("Форма"),
                        ]),
                      ]),
                      _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                        _vm._v("Заказчик"),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "border-bottom py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v("Наименование заказчика")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$store.state.organization.full_name)
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "border-bottom py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v("ИНН Заказчика")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [_vm._v(_vm._s(_vm.$store.state.organization.inn))]
                          ),
                        ],
                        1
                      ),
                      _vm.$store.state.organization.kpp
                        ? _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("КПП Заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.organization.kpp)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$store.state.organization.post_address
                        ? _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Адрес")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$store.state.organization.post_address
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$store.state.organization.email
                        ? _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Адрес электронной почты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.organization.email)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$store.state.organization.phone
                        ? _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Номер контактного телефона")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.organization.phone)
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                        _vm._v("Запрос КП"),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "border-bottom py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v("Наименование запроса КП")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.purchaseCategoryName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "border-bottom py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v("Дата окончания приема предложений ")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getDateFormat(_vm.form.deadline)) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "border-bottom py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v(" Спецификации ")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            _vm._l(_vm.form.specs, function (sp, index) {
                              return _c("div", { key: index }, [
                                _vm._v(" - "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(sp.name) +
                                      " " +
                                      _vm._s(sp.count) +
                                      " " +
                                      _vm._s(sp.okei.text)
                                  ),
                                ]),
                              ])
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "fs-14 grey-color d-flex align-items-center",
                              attrs: { md: "3" },
                            },
                            [_vm._v(" Документы ")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              !!_vm.form.document
                                ? _c("div", [
                                    _vm._v(" - "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.form.document.name)),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "b-tab",
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", { staticClass: "system_link-style fs-16" }, [
                          _vm._v("Xml представление"),
                        ]),
                      ]),
                      _c("b-form-textarea", {
                        attrs: { disabled: "", rows: "20" },
                        model: {
                          value: _vm.formXml,
                          callback: function ($$v) {
                            _vm.formXml = $$v
                          },
                          expression: "formXml",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-5 px-3" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "fs-14 grey-color d-flex align-items-center",
                      attrs: { md: "3" },
                    },
                    [_vm._v(" Сертификат ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _c("b-input", {
                        attrs: { disabled: "", value: _vm.currentCertSubject },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100 px-3",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelSignModal },
                },
                [_vm._v("Отмена")]
              ),
              !_vm.isSignedXml
                ? _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        variant: "custom-green",
                        disabled: _vm.certIndex == null,
                      },
                      on: { click: _vm.signForm },
                    },
                    [_vm._v("Подписать ")]
                  )
                : _vm._e(),
              _vm.isSignedXml
                ? _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        variant: "custom-green",
                        disabled: _vm.certIndex == null,
                      },
                      on: { click: _vm.sendData },
                    },
                    [_vm._v("Отправить ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }