import axios from 'axios';
import store from '@/store';

export default {
    getStatistics() {
        return axios.get('/cabinet/provider/accounting/statistics');
    },
    getDetails(payload) {
        return axios.get('/cabinet/provider/accounting/details', { params: { ...payload } });
    },
    getRefundRequestsList(paginationData) {
        return axios.get('/cabinet/provider/refund_requests', { params: { ...paginationData } });
    },
    async storeRefundRequest(form, xml) {
        const url = `/cabinet/provider/refund_requests`;
        try {
            const response = await axios.post(url, {
                form: form,
                xml: xml,
                thumbprint: store.state.currentThumbprint,
                certificate: store.getters.currentParsedCertificate
            });
            await store.dispatch('showSuccessToast', 'Запрос на возврат средств успешно подан, средства заблокированы на ЛС.');
            return response.data;
        } catch (err) {
            store.dispatch('showError', err).then(() => {});
        }
    },
    getAccountingXlsExport() {
        return axios.get('/cabinet/provider/accounting/xls', { responseType: 'blob' });
    }
};
