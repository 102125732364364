var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Уважаемый пользователь!",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        visible: _vm.show,
      },
      on: { close: _vm.cancelModal, cancel: _vm.cancelModal },
    },
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", [
            _c("p", { staticClass: "fs-14 grey-color" }, [
              _vm._v(
                "При внесении изменений в сведения о сертификате электронной подписи в личном кабинете организации в ИС, пользователь несет персональную ответственность, которая регламентирована законом 63-ФЗ «Об электронной подписи»."
              ),
            ]),
            _c("p", { staticClass: "fs-14 grey-color" }, [
              _vm._v(
                "При внесении неверного SHA1-кода (отпечатка) сертификата, пользователь не сможет авторизоваться в личном кабинете ИС."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: { type: "reset", variant: "custom-outline-secondary" },
                  on: { click: _vm.cancelModal },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: { type: "submit", variant: "custom-green" },
                  on: { click: _vm.okModal },
                },
                [_vm._v("Сохранить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }