<template>
    <b-container class="mt-4">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <div class="purchase_part-header">
                        <b-col>
                            <p class="purchase_part-header_number">
                                <router-link class="white-system_link-style fw-600" :to="{ name: 'QuotationRequestShow', params: { id: quotationRequest.id } }" exact> № {{ quotationRequest.reg_number }} </router-link>
                            </p>
                        </b-col>
                        <b-col cols="auto">
                            <p class="purchase_part-header_label fw-600">
                                Прием КП открыт
                                <img class="ml-2" src="/images/secundomer.svg" alt="secundomer" />
                            </p>
                        </b-col>
                    </div>
                    <div class="purchase_part-subheader">
                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                            <span class="fs-11 green-color" v-if="quotationRequest.quotations && quotationRequest.quotations.length">
                                Предложений: <span class="fw-600 ws-nowrap">{{ quotationRequest.quotations.length }}</span>
                            </span>
                            <span class="fs-11 green-color" v-else>Предложений нет</span>
                        </b-col>
                    </div>
                    <div class="purchase_part-body row">
                        <b-col md="4" class="purchase_part-body_col">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">До окончания подачи КП:</p>
                                    <p class="fs-18 m-0 grey-color fw-600 time_to_end">
                                        <timer v-if="!!quotationRequest.deadline" :data="quotationRequest.deadline" @timeout="overTime = true"></timer>
                                    </p>
                                </div>
                                <div class="my-2" v-if="$store.getters.isLoggedIn && !$store.getters.isProvider && quotationRequest.status_id === 1 && quotationRequest.organization_id === $store.getters.getUser.organization_id">
                                    <b-btn class="btn-card-action btn-danger" @click="showStopQuotationRequestDialog">Завершить сбор КП</b-btn>
                                </div>
                                <div class="my-2">
                                    <b-btn
                                        variant="custom-green-small"
                                        class="text-uppercase"
                                        style="font-size: 12px !important"
                                        :to="{ name: 'QuotationCreate', params: { id: quotationRequest.id } }"
                                        :disabled="overTime"
                                        v-if="!overTime && $store.getters.isLoggedIn && $store.getters.isProvider && quotationRequest.status_id === 1 && !quotationRequest.my_quotation && !tablet">
                                        Подать коммерческое предложение
                                    </b-btn>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="4" class="purchase_part-body_col">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Наименование:</p>
                                    <p class="fs-12 green-color fw-600">
                                        {{ quotationRequest.name }}
                                    </p>
                                </div>
                                <div class="my-2 d-flex flex-column">
                                    <p class="fs-12 grey-color m-0">Заказчик:</p>
                                    <router-link class="system_link-style fw-600 text-uppercase fs-12" :to="'/clients/customers/' + quotationRequest.organization_id">
                                        {{ quotationRequest.organization.name }}
                                    </router-link>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="4" class="purchase_part-body_col hidden-mobile">
                            <div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">
                                        Количество позиций:
                                        <span class="fw-600 ws-nowrap">{{ quotationRequest.items.length }}</span>
                                    </p>
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Timer from '@/components/elements/Timer';
import QuotationRequests from '@/services/api/cabinet/customer/QuotationRequests';

export default {
    name: 'QuotationRequestCardOpen',
    props: ['quotationRequest'],
    components: {
        Timer
    },
    data() {
        return {
            tablet: false,
            overTime: false
        };
    },
    created() {
        if (window.innerWidth < 768) {
            this.tablet = true;
        }
    },
    methods: {
        showStopQuotationRequestDialog() {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите завершить сбор коммерческих предложений?', {
                    okTitle: 'Завершить',
                    okVariant: 'danger',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'secondary',
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        QuotationRequests.stop(this.quotationRequest.id).then(() => {
                            this.$emit('reloadData');
                        });
                    }
                });
        }
    },
    computed: {
        guest() {
            return !this.$store.getters.isLoggedIn;
        },
        creator() {
            return this.$store.getters.getUser.organization.id === this.form.organization.id;
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #959ba4;
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}
@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}
@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
