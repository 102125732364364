<template>
    <div>
        <div v-for="purchase in purchases" :key="purchase.id">
            <purchase-card-for-cabinet v-if="purchase.is_external" :purchase="purchase" @refresh="refresh"></purchase-card-for-cabinet>
            <customer-purchase-card
                v-else
                class="mb-3"
                :purchase="purchase"
                @onDelete="preDelete"
                @onCancel="preCancelPurchase"
                @onPublish="prePublish"
                @cancelPublishing="cancelPurchasePublishing"
                @cancelHoldTime="cancelHoldTime"
                @copy="copyPurchase"
                @prolong="prolongPurchase"
                @refresh="refresh"></customer-purchase-card>
        </div>
        <b-modal ref="delete-modal" title="Удалить закупку" v-model="showDeleteModal" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-left">Вы действительно хотите удалить закупку {{ number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showDeleteModal = false">Отмена</b-button>&nbsp;
                <b-button variant="custom-danger" class="text-uppercase" @click="deletePurchase">Удалить</b-button>
            </div>
        </b-modal>
        <b-modal ref="cancel-modal" title="Отменить закупку" v-model="showCancelModal" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-left">Вы действительно хотите отменить закупку {{ number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showCancelModal = false">Нет</b-button>&nbsp;
                <b-button variant="custom-danger" class="text-uppercase" @click="cancelPurchase">Да, отменить</b-button>
            </div>
        </b-modal>
        <b-modal ref="publish-modal" title="Опубликовать закупку" v-model="showPublishModal" no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-left">Вы действительно хотите опубликовать закупку {{ number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="showPublishModal = false">Отмена</b-button>&nbsp;
                <b-button variant="custom-green" class="text-uppercase" @click="publishPurchase">Опубликовать</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CustomerPurchaseCard from '@/components/purchases/PurchaseStatusCard/PurchaseCustomerCabinet';
import PurchaseCardForCabinet from '@/components/purchases/outAtmo/PurchaseCardForCabinet';
import Purchases from '@/services/api/Purchases';
import CabinetPurchasesService from '@/services/api/cabinet/customer/purchases';

export default {
    components: { CustomerPurchaseCard, PurchaseCardForCabinet },
    data() {
        return {
            number: '',
            id: null,
            showDeleteModal: false,
            showPublishModal: false,
            showCancelModal: false
        };
    },
    props: ['purchases'],
    methods: {
        copyPurchase(newPurchase) {
            this.$router
                .push({
                    name: 'PurchasesCreate',
                    params: { id: newPurchase }
                })
                .catch(() => {});
        },
        preDelete(obj) {
            this.id = obj.id;
            this.number = obj.number;
            this.showDeleteModal = true;
        },
        preCancelPurchase(obj) {
            this.id = obj.id;
            this.number = obj.number;
            this.showCancelModal = true;
        },
        cancelPurchase() {
            Purchases.cancel(this.id)
                .then((resp) => {
                    if (resp.data.message) {
                        this.pushToast({
                            text: resp.data.message,
                            title: 'Успех',
                            variant: 'green'
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        this.pushToast({
                            text: err.response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.number = '';
                    this.id = null;
                    this.showCancelModal = false;
                    this.$emit('refresh');
                });
        },
        cancelPurchasePublishing(purchase) {
            CabinetPurchasesService.cancelPurchasePublishing(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Извещение № ' + purchase.reg_number + ' снято с публикации и доступно для редактирования',
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.$emit('refresh');
                });
        },
        cancelHoldTime(purchase) {
            CabinetPurchasesService.cancelHoldTime(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Для извещения № ' + purchase.reg_number + ' произведена операция досрочного подведения итогов',
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.$emit('refresh');
                });
        },

        deletePurchase() {
            // eslint-disable-next-line no-undef
            axios.delete('/purchases/' + this.id).then(() => {
                this.number = '';
                this.id = null;
                this.showDeleteModal = false;
                this.applyFilters();
            });
        },
        refresh() {
            this.$emit('refresh');
        },
        prePublish(obj) {
            this.id = obj.id;
            this.number = obj.number;
            this.showPublishModal = true;
        },
        publishPurchase() {
            // eslint-disable-next-line no-undef
            axios
                .put('/purchases/' + this.id, {})
                .then(() => {
                    this.number = '';
                    this.id = null;
                    this.showPublishModal = false;
                    this.applyFilters();
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        this.pushToast({
                            text: err.response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                });
        },
        prolongPurchase(purchase) {
            CabinetPurchasesService.prolong(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Прием предложений для извещения № ' + purchase.reg_number + ' продлен до ' + this.getDateTimeFormat(purchase.max_prolong_datetime),
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.$emit('refresh');
                });
        }
    }
};
</script>
