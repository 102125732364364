<template>
    <div>
        <b-container v-if="!!organization" class="main_layer py-4" fluid>
            <b-container>
                <b-row class="mt-2">
                    <b-col class="d-flex align-items-center justify-content-between column-768">
                        <p class="fs-52 fw-600 white-color mb-4 main_text">Сведения о заказчике</p>
                        <div class="d-flex info_block">
                            <p v-if="user && organization && user.organization.is_provider === true" class="mb-0 mr-4">
                                <span v-if="organization.is_favorite === true" class="cursor-pointer white-color fs-15 align-items-center" v-on:click="removeFavorite(organization)">
                                    <img alt="empty_star" class="mr-1" src="/images/full_star.svg" />
                                    УБРАТЬ ИЗ ИЗБРАННОГО
                                </span>
                                <span v-else class="cursor-pointer white-color fs-15 align-items-center" v-on:click="addFavorite(organization)">
                                    <img alt="empty_star" class="mr-1" src="/images/empty_star.svg" />
                                    В ИЗБРАННОЕ
                                </span>
                            </p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <p class="fs-14 white-color text-uppercase mb-4">
                                {{ organization.full_name }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <span class="mr-2 fs-14 white-color fw-100">ОГРН</span>
                            <span class="m-0 fs-14 white-color fw-600">{{ organization.ogrn }}</span>
                        </div>
                        <div>
                            <span class="mr-2 fs-14 white-color fw-100">ИНН</span>
                            <span class="m-0 fs-14 white-color fw-600">{{ organization.inn }}</span>
                        </div>
                        <div v-if="organization.kpp">
                            <span class="mr-2 fs-14 white-color fw-100">КПП</span>
                            <span class="m-0 fs-14 white-color fw-600">{{ organization.kpp }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container>
            <b-row v-if="!!organization">
                <b-col class="pb-5">
                    <text-header>Сведения об организации</text-header>
                    <text-row :value="organization.full_name" label="Полное наименование" />
                    <text-row :value="organization.short_name" label="Сокращенное наименование" />
                    <text-row :value="organization.post_address" label="Почтовый адрес" />
                    <text-row :value="organization.fact_address" label="Фактический адрес" />

                    <text-header>Контактная информация</text-header>
                    <text-row :value="organization.email" label="Адрес электронной почты" />
                    <text-row-phone :value="organization.phone" label="Телефон" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import OrganizationsApi from '@/services/api/Organizations';

export default {
    name: 'CustomerShow',
    data() {
        return {
            organization: null,
            user: {}
        };
    },
    mounted() {
        this.getData();
        this.user = this.$store.state.user;
    },
    methods: {
        getData() {
            axios.get('/clients/info/' + this.$route.params.id).then((resp) => {
                this.organization = resp.data.organization;
            });
        },
        parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})/, '+$1 ($2) ');
            return '-';
        },
        addFavorite(organization) {
            OrganizationsApi.addFavorite(organization)
                .then(() => {
                    this.organization.is_favorite = true;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        },
        removeFavorite(organization) {
            OrganizationsApi.removeFavorite(organization)
                .then(() => {
                    this.organization.is_favorite = false;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 300px;
}

@media screen and (max-width: 992px) {
    .main_text {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 768px) {
    .column-768 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .info_block {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 470px) {
    .main_text {
        font-size: 32px !important;
    }

    .purchase_part-header_label {
        white-space: nowrap;
    }
}

@media screen and (max-width: 470px) {
    .main_text {
        line-height: 1;
    }
}
</style>
