'use strict';

import DeliverableItemRequirementVO from '../../../DeliverableItemRequirement.valueobject';
import GSCDetailsVO from '../../../GSCDetails.valueobject';
import DeliverableItemEditableVO from '../DeliverableItemEditable.valueobject';

export interface IOriginalSpecItem {
    count: number;
    price: number;
    ktru_item_id: number | null;
    name?: string;
    okei: {
        id: number;
        local_symbol: string;
    };
    okpd: {
        id: number;
        code: string;
        name: string;
    };
    ktru: IKTRU;
}

type IKTRU = {
    id: number | null;
    reg_number: string;
    ktru_group_name: string;
    type: string | null;
    name: string;
    ktru_type_name: string;
    specs: ISpecInner[];
};

type ISpecInner = {
    Name: string;
    Value: string;
};

export default class OriginalSpecItemVO {
    count: number;
    price: number;
    ktru_item_id: number | null;
    name: string | null;
    okei: {
        id: number;
        local_symbol: string;
    } | null;
    okpd: {
        id: number;
        code: string;
        name: string;
    } | null;
    ktru: IKTRU | null;

    constructor(data?: IOriginalSpecItem) {
        this.count = data?.count ?? 0;
        this.price = data?.price ?? 0;
        this.ktru_item_id = data?.ktru_item_id ?? null;
        this.name = data?.name ?? null;
        this.okei = data?.okei ?? null;
        this.okpd = data?.okpd ?? null;
        this.ktru = data?.ktru ?? null;
    }

    public static fromDeliverableItem(deliverable: DeliverableItemEditableVO, order: number): OriginalSpecItemVO {
        const data: IOriginalSpecItem = {
            count: deliverable.quantity as number,
            price: deliverable.price_per_unit as number,
            ktru_item_id: (deliverable.gsc_details as GSCDetailsVO)?.id ?? null,
            name: deliverable.title!,
            okei: {
                id: deliverable.okei_id as number,
                local_symbol: deliverable.okei_symbolic_code as string
            },
            okpd: {
                id: deliverable.okpd_id as number,
                code: deliverable.okpd_code as string,
                name: deliverable.okpd_title as string
            },
            ktru: {
                id: (deliverable.gsc_details as GSCDetailsVO)?.id as number,
                reg_number: (deliverable.gsc_details as GSCDetailsVO)?.registration_number as string,
                ktru_group_name: (deliverable.gsc_details as GSCDetailsVO)?.group as string,
                name: (deliverable.gsc_details as GSCDetailsVO)?.title as string,
                type: (deliverable.gsc_details as GSCDetailsVO)?.type as string,
                ktru_type_name: (deliverable.gsc_details as GSCDetailsVO)?.type as string,
                specs:
                    deliverable.requirements?.map((requirement: DeliverableItemRequirementVO) => {
                        return { Name: requirement.title, Value: requirement.description } as ISpecInner;
                    }) ?? []
            }
        };

        return new OriginalSpecItemVO(data);
    }
}
