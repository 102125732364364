var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-between column-992" },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color mb-4 main_text",
                  staticStyle: { "line-height": "1" },
                },
                [_vm._v("Последние закупки")]
              ),
              _c(
                "ul",
                { staticClass: "tabs mb-3" },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    "li",
                    { key: "key_" + index },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "ShowPurchases",
                              params: { cat: tab.path },
                            },
                            exact: "",
                          },
                        },
                        [_vm._v(_vm._s(tab.title))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("show-purchases", {
            attrs: { purchases: _vm.purchases },
            on: { refresh: _vm.fetchData },
          })
        : _vm._e(),
      !_vm.loading && !_vm.purchases.length ? _c("empty-request") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }