var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "greyBg pb-5", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "p",
                  {
                    staticClass: "fs-32 grey-color my-4",
                    staticStyle: { "line-height": "1" },
                  },
                  [_vm._v(_vm._s(_vm.section.title))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("span", { staticClass: "fs-14 grey-color" }, [
                  _vm._v(_vm._s(_vm.section.desc)),
                ]),
              ]),
            ],
            1
          ),
          _vm.loading ? _c("system-preloader") : _vm._e(),
          !_vm.loading && !!_vm.section.files.length
            ? _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { width: "1" } }, [_vm._v("#")]),
                          _c("th", [_vm._v("Название")]),
                          _c("th", { attrs: { width: "1" } }),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.section.files, function (file, index) {
                          return _c("tr", { key: file.id }, [
                            _c("td", [_vm._v(_vm._s(index + 1) + ".")]),
                            _c("td", [_vm._v(_vm._s(file.name))]),
                            _c(
                              "td",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { variant: "custom-green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.downloadFile(
                                          file.id,
                                          file.original_name,
                                          file.name,
                                          file.extention
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Скачать")]
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.loading && !_vm.section.files.length
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [_c("empty-request")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }