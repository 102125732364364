import axios from 'axios';

export default {
    getQuotations(filters) {
        return axios
            .get('/quotations', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    },
    getXml(payload) {
        return axios.post('/xml/quotation-form', { quotation: payload });
    },
    get(id) {
        return axios.get('/quotations/' + id);
    },
    downloadPDF(id) {
        return axios.get(`/quotations/${id}/download`, { responseType: 'blob' });
    }
};
