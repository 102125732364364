var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "fs-32 grey-color mb-4 main_text",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Редактирование реквизитов")]
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" Расчетный счет ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: { required: "", trim: "" },
                            model: {
                              value: _vm.bank.account,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "account", $$v)
                              },
                              expression: "bank.account",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" БИК ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("VueSuggestions", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "#########",
                                expression: "'#########'",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              required: "",
                              id: "bik-input",
                              model: _vm.bank.bik,
                              placeholder: "Начните вводить",
                              options: _vm.suggestionOptions,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" Корреспондентский счет ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              readonly: "",
                              value: _vm.bank.corr_account,
                              trim: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" Наименование банка ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              readonly: "",
                              value: _vm.bank.bank_name,
                              trim: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" Адрес банка ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              readonly: "",
                              value: _vm.bank.bank_address,
                              trim: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "4" },
                        },
                        [_vm._v(" Дополнительные реквизиты и информация ")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "8" } },
                        [
                          _c("b-form-textarea", {
                            attrs: { trim: "" },
                            model: {
                              value: _vm.bank.dop_info,
                              callback: function ($$v) {
                                _vm.$set(_vm.bank, "dop_info", $$v)
                              },
                              expression: "bank.dop_info",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-form-group", {
                    attrs: { label: "", "label-cols": "4" },
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        type: "button",
                        to: "/cabinet/provider/info/bank",
                        variant: "custom-outline-secondary",
                      },
                    },
                    [_vm._v("Назад")]
                  ),
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "custom-green" } },
                    [_vm._v("Сохранить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }