import axios from 'axios';

export default {
    deleteRequisite(id) {
        return axios.delete('/cabinet/provider/bank_account/' + id).then((response) => {
            return response.data;
        });
    },
    getRequisite(id) {
        return axios.get('/cabinet/provider/bank_account/' + id).then((response) => {
            return response.data.data;
        });
    },
    updateRequisite(id, payload) {
        return axios.put('/cabinet/provider/bank_account/' + id, payload).then((resp) => {
            return resp.data;
        });
    },
    changeMainRequisite(id) {
        return axios.get('/cabinet/provider/bank_account/change_main/' + id).then((response) => {
            return response.data;
        });
    },
    createRequisite(payload) {
        return axios.post('/cabinet/provider/bank_account', payload).then((response) => {
            return response.data;
        });
    },
    getRequisitesList() {
        return axios.get('/cabinet/provider/bank_account').then((response) => {
            return response.data.data;
        });
    }
};
