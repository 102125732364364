import { render, staticRenderFns } from "./PurchaseCustomerCabinet.vue?vue&type=template&id=89620a0c&scoped=true&"
import script from "./PurchaseCustomerCabinet.vue?vue&type=script&lang=js&"
export * from "./PurchaseCustomerCabinet.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseCustomerCabinet.vue?vue&type=style&index=0&id=89620a0c&scoped=true&lang=css&"
import style1 from "./PurchaseCustomerCabinet.vue?vue&type=style&index=1&id=89620a0c&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89620a0c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-contract/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89620a0c')) {
      api.createRecord('89620a0c', component.options)
    } else {
      api.reload('89620a0c', component.options)
    }
    module.hot.accept("./PurchaseCustomerCabinet.vue?vue&type=template&id=89620a0c&scoped=true&", function () {
      api.rerender('89620a0c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/purchases/PurchaseStatusCard/PurchaseCustomerCabinet.vue"
export default component.exports