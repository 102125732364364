<template>
    <b-row>
        <b-col md="5">
            <multiselect v-model="text" :allow-empty="false" :clear-on-select="true" :close-on-select="true" :label="labelField" :multiple="false" :options="purchaseStatuses" :preserve-search="false" :show-labels="false" :track-by="trackBy" placeholder="Выберите статус"> </multiselect>
        </b-col>
        <b-col>
            <ul class="atmo-buttons">
                <li>
                    <b-button variant="custom-green" @click="handleClick" class="text-uppercase" :disabled="disabled">Изменить статус</b-button>
                </li>
            </ul>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: 'form-row-change-contract-status',
    props: {
        value: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        labelField: {
            type: String,
            default: 'name'
        },
        trackBy: {
            type: String,
            default: 'id'
        }
    },
    data() {
        return {
            purchaseStatuses: [
                { id: 3, name: 'Договор заключен' },
                { id: 4, name: 'Договор исполнен' },
                { id: 5, name: 'Договор не исполнен' },
                { id: 6, name: 'Договор расторгнут' }
            ]
        };
    },
    methods: {
        clearAll() {
            this.text = null;
        },
        handleClick() {
            this.$emit('change-status', this.value);
        }
    },
    computed: {
        text: {
            get() {
                return this.purchaseStatuses ? this.purchaseStatuses.find((e) => e[this.trackBy] === this.value) : null;
            },
            set(value) {
                this.$emit('input', value ? value[this.trackBy] : null);
            }
        },
        id() {
            return 'form_row_change_contract_status_' + this._uid;
        }
    }
};
</script>

<style>
.multiselect__clear {
    position: absolute;
    right: 40px;
    height: 40px;
    width: 40px;
    display: block;
    cursor: pointer;
    z-index: 2;
}

.multiselect__clear:after,
.multiselect__clear:before {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 16px;
    background: #aaa;
    top: 12px;
    right: 4px;
}

.multiselect__clear:before {
    transform: rotate(45deg);
}

.multiselect__clear:after {
    transform: rotate(-45deg);
}
</style>
