var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
        "label-cols-lg": "3",
        "label-cols-sm": "4",
      },
    },
    [
      _c(
        "div",
        { staticClass: "fs-14 grey-color", attrs: { id: _vm.id } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }