var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase
        ? _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о заказчике"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("form-row-show-text", {
                    attrs: { label: "Наименование" },
                    model: {
                      value: _vm.purchase.organization.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "name", $$v)
                      },
                      expression: "purchase.organization.name",
                    },
                  }),
                  _c("form-row-show-text", {
                    attrs: { label: "ИНН" },
                    model: {
                      value: _vm.purchase.organization.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "inn", $$v)
                      },
                      expression: "purchase.organization.inn",
                    },
                  }),
                  _vm.purchase.organization.kpp
                    ? _c("form-row-show-text", {
                        attrs: { label: "КПП" },
                        model: {
                          value: _vm.purchase.organization.kpp,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.organization, "kpp", $$v)
                          },
                          expression: "purchase.organization.kpp",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.organization.ogrn
                    ? _c("form-row-show-text", {
                        attrs: { label: "ОГРН" },
                        model: {
                          value: _vm.purchase.organization.ogrn,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.organization, "ogrn", $$v)
                          },
                          expression: "purchase.organization.ogrn",
                        },
                      })
                    : _vm._e(),
                  _c("form-row-show-text", {
                    attrs: { label: "Адрес" },
                    model: {
                      value: _vm.purchase.organization.post_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.organization, "post_address", $$v)
                      },
                      expression: "purchase.organization.post_address",
                    },
                  }),
                  _vm.purchase.additional_contact_info
                    ? _c("form-row-show-text", {
                        attrs: {
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.additional_contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "additional_contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.additional_contact_info",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { staticClass: "pb-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "block_head-text" }, [
                      _vm._v("Условия закупки"),
                    ]),
                  ]),
                ],
                1
              ),
              _c("form-row-show-text", {
                attrs: { label: "Предмет закупки" },
                model: {
                  value: _vm.purchase.purchase_category_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchase, "purchase_category_name", $$v)
                  },
                  expression: "purchase.purchase_category_name",
                },
              }),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Предмет закупки: ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.purchase.purchase_category_name) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Вид оплаты: ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(" " + _vm._s(_vm.purchase.payment_type) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Условия оплаты: ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(" " + _vm._s(_vm.purchase.payment_term) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Начальная максимальная цена контракта, руб.: ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Дата и время размещения закупки ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatDateTimeSeconds(
                              _vm.purchase.purchase_start_datetime
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Дата и время окончания подачи ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatDateTimeSeconds(
                              _vm.purchase.purchase_end_datetime
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Планируемая дата заключения договора ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getDateFormat(
                              _vm.purchase.planned_contract_date
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "border-bottom py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [_vm._v(" Приложение заказчика к объявлению ")]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.purchase.documents, function (document) {
                          return _c(
                            "li",
                            { key: document.id },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "fs-14",
                                  staticStyle: {
                                    "padding-top": "0",
                                    "padding-bottom": "0",
                                    color: "var(--green-color) !important",
                                  },
                                  attrs: { variant: "link", size: "xs" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$commonDocumentDownload(
                                        document
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(document.name))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "py-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                    [
                      _vm._v(
                        " Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством РФ "
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                    [_vm._v(" " + _vm._s(_vm.purchase.additional_info) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }