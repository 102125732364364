<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between">
                <p class="fs-32 grey-color mb-4" style="line-height: 1">Извещения</p>
            </b-col>
        </b-row>
        <b-row class="mb-4">
            <filter-multiple-select v-model="filter.status_id" :options="forFilters.statuses" label="Статус закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" />
            <filter-input-text v-model="filter.reg_number" label="Рег.номер закупки" label-class="grey-color" lg="6" md="6" placeholder="Введите рег.номер закупки" />
            <filter-multiple-select v-model="filter.purchase_categories" :options="forFilters.purchaseCategories" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />
            <filter-input-text v-model="filter.provider" label="Поставщик" label-class="grey-color" lg="6" md="6" placeholder="Введите название поставщика" />

            <b-col class="mb-3 text-right" md="12">
                <b-btn class="text-uppercase ml-2" variant="custom-green" @click.prevent="getPurchases(true)">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
            </b-col>
        </b-row>
        <div v-for="purchase in purchases" :key="purchase.id">
            <out-atmo-purchase-card v-if="purchase.is_external" :purchase="purchase" @refresh="refresh"></out-atmo-purchase-card>
            <customer-purchase-card v-else :purchase="purchase" class="mb-3" @cancelHoldTime="cancelHoldTime" @cancelPublishing="cancelPurchasePublishing" @copy="copyPurchase" @onDelete="preDelete" @onPublish="prePublish" @prolong="prolongPurchase" @refresh="refresh"></customer-purchase-card>
        </div>
        <empty-request v-if="!purchases.length && !loading"></empty-request>

        <b-pagination v-if="purchases" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getPurchases()"></b-pagination>

        <b-modal ref="delete-modal" v-model="showDeleteModal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Удалить закупку">
            <div class="d-block text-left">Вы действительно хотите удалить закупку {{ number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showDeleteModal = false">Отмена</b-button>&nbsp;
                <b-button class="text-uppercase" variant="custom-danger" @click="deletePurchase">Удалить</b-button>
            </div>
        </b-modal>
        <b-modal ref="publish-modal" v-model="showPublishModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Опубликовать закупку" @hidden="clearEmails">
            <div class="d-block text-left mb-3">Выберите или введите почтовые ящики поставщиков, на них будут отправлены приглашения к закупке.</div>
            <div>
                <multiselect
                    v-model="emails"
                    :close-on-select="true"
                    :multiple="true"
                    :options="organizations"
                    :preserve-search="false"
                    :taggable="true"
                    deselect-label="Отменить"
                    label="name"
                    placeholder="Выберите организации / укажите адреса электронной почты"
                    select-label="Выбрать"
                    selected-label="Выбрано"
                    tag-placeholder="Добавить"
                    track-by="id"
                    @tag="addTag">
                    <span slot="noOptions">Нет данных.</span>
                    <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                </multiselect>
            </div>

            <div slot="modal-footer" class="w-100 text-">
                <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showPublishModal = false">Отмена</b-button>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!emails.length" class="text-uppercase" variant="custom-green" @click="publishPurchase">Опубликовать</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import CustomerPurchaseCard from '@/components/purchases/PurchaseStatusCard/PurchaseCustomerCabinet';
import OutAtmoPurchaseCard from '@/components/purchases/outAtmo/PurchaseCardForCabinet';
import CabinetPurchasesService from '@/services/api/cabinet/customer/purchases';
import FilterMultipleSelect from '@/components/common/filter-components/filter-multiple-select';
import Directories from '@/services/api/Directories';

export default {
    name: 'Purchases',
    components: { FilterMultipleSelect, CustomerPurchaseCard, OutAtmoPurchaseCard },
    data() {
        return {
            statusList: [
                { title: 'Идет прием предложений', id: 1 },
                { title: 'Подведены итоги', id: 2 },
                { title: 'Закупка отменена', id: 7 },
                { title: 'Закупка не состоялась', id: 8 },
                { title: 'Черновик', id: 11 },
                { title: 'Заблокирован', id: 12 },
                { title: 'Сохранен и подписан', id: 13 }
            ],
            purchases: [],
            loading: false,
            number: '',
            id: null,
            showDeleteModal: false,
            showPublishModal: false,
            showCancelModal: false,
            filter: {
                status_id: [],
                purchase_categories: [],
                reg_number: '',
                provider: ''
            },
            forFilters: {
                purchaseCategories: [],
                statuses: [
                    { name: 'Идет прием предложений', id: 1 },
                    { name: 'Подведены итоги', id: 2 },
                    { name: 'Закупка отменена', id: 7 },
                    { name: 'Закупка не состоялась', id: 8 },
                    { name: 'Черновик', id: 11 },
                    { name: 'Заблокирован', id: 12 },
                    { name: 'Сохранен и подписан', id: 13 }
                ]
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            },
            organizations: [],
            emails: [],
            isDataSending: false
        };
    },
    async mounted() {
        await this.fillFilters();
        await this.getPurchases();
        this.getOrganizationsList();
    },
    watch: {
        $route: 'applyFilters'
    },
    methods: {
        getFilters(page = true) {
            return {
                filter: { ...this.filter },
                // status: this.getStatus(),
                page: page ? this.meta.current_page : 1
            };
        },
        async fillFilters() {
            const responseData = await Promise.all([Directories.getPurchaseCategories()]);
            this.forFilters.purchaseCategories = responseData[0].data.data;
        },
        async getPurchases(page = true, loading = true) {
            this.loading = loading;
            const purchasesData = await CabinetPurchasesService.getPurchasesList(this.getFilters(page));
            this.purchases = purchasesData.data.data;
            this.meta = purchasesData.data.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        refresh() {
            this.getPurchases();
        },

        copyPurchase(newPurchase) {
            this.$router
                .push({
                    name: 'PurchasesCreate',
                    params: { id: newPurchase }
                })
                .catch(() => {});
        },
        getOrganizationsList() {
            axios
                .get('/providers/list')
                .then((resp) => {
                    this.organizations = resp.data;
                })
                .catch((error) => console.log(error));
        },
        addTag(newTag) {
            const tag = {
                email: newTag,
                id: newTag,
                name: newTag
            };
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(tag.email)) {
                this.emails.push(tag);
                this.organizations.push(tag);
            }
        },
        clearEmails() {
            this.emails = [];
        },
        preDelete(obj) {
            this.id = obj.id;
            this.number = obj.number;
            this.showDeleteModal = true;
        },
        checkUpdate(response) {
            return Boolean(this.purchases.find((x) => x.id === response.data));
        },
        cancelPurchasePublishing(purchase) {
            CabinetPurchasesService.cancelPurchasePublishing(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Извещение № ' + purchase.reg_number + ' снято с публикации и доступно для редактирования',
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.getPurchases();
                });
        },
        cancelHoldTime(purchase) {
            CabinetPurchasesService.cancelHoldTime(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Для извещения № ' + purchase.reg_number + ' произведена операция досрочного подведения итогов',
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.getPurchases();
                });
        },
        deletePurchase() {
            // eslint-disable-next-line no-undef
            axios.delete('/purchases/' + this.id).then(() => {
                this.number = '';
                this.id = null;
                this.showDeleteModal = false;
                this.applyFilters();
            });
        },
        prePublish(obj) {
            this.id = obj.id;
            this.number = obj.number;
            const purchase = this.purchases.find((e) => e.id === obj.id);
            if (!!purchase && (purchase.was_canceled || this.isDevEnv())) {
                this.publishPurchase();
            } else {
                this.showPublishModal = true;
            }
        },
        publishPurchase() {
            this.isDataSending = true;
            // eslint-disable-next-line no-undef
            axios
                .put('/purchases/' + this.id, { emails: this.emails })
                .then(() => {
                    this.number = '';
                    this.id = null;
                    this.showPublishModal = false;
                    this.applyFilters();
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        this.pushToast({
                            text: err.response.data.message,
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                    }
                })
                .finally(() => {
                    this.clearEmails();
                    this.isDataSending = false;
                });
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getPurchases();
        },
        cleanFilters() {
            this.filter.status_id.splice(0);
            this.filter.reg_number = '';
            this.filter.provider = '';
            this.filter.purchase_categories.splice(0);
            this.getPurchases(false);
        },
        prolongPurchase(purchase) {
            CabinetPurchasesService.prolong(purchase)
                .then(() => {
                    this.pushToast({
                        text: 'Прием предложений для извещения № ' + purchase.reg_number + ' продлен до ' + this.getDateTimeFormat(purchase.max_prolong_datetime),
                        title: 'Успех',
                        variant: 'green',
                        timer: 5000
                    });
                })
                .catch((err) => {
                    this.showError(err);
                })
                .finally(() => {
                    this.getPurchases();
                });
        }
    }
};
</script>
<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}
</style>
