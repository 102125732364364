import { render, staticRenderFns } from "./form-header.vue?vue&type=template&id=8cf9b256&"
import script from "./form-header.vue?vue&type=script&lang=js&"
export * from "./form-header.vue?vue&type=script&lang=js&"
import style0 from "./form-header.vue?vue&type=style&index=0&id=8cf9b256&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-contract/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8cf9b256')) {
      api.createRecord('8cf9b256', component.options)
    } else {
      api.reload('8cf9b256', component.options)
    }
    module.hot.accept("./form-header.vue?vue&type=template&id=8cf9b256&", function () {
      api.rerender('8cf9b256', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/form-rows/form-header.vue"
export default component.exports