<template>
    <div>
        <system-preloader v-if="loading"></system-preloader>
        <b-row>
            <b-col class="d-flex align-items-center justify-content-between mb-4 column-992">
                <p class="fs-32 grey-color m-0 main_text" style="line-height: 1">Создание запроса на добавление позиций КТРУ</p>
            </b-col>
        </b-row>
        <b-row v-if="!$store.state.NewKtruRequest.create_method">
            <div class="d-block text-center w-75 m-auto pb-5">
                <b-btn class="btn btn-block btn-custom-green mb-4" @click="createKtruBasedOnExisting"> Выбрать существующую позицию КТРУ для создания новой позиции на ее основе</b-btn>
                <b-btn class="btn btn-block btn-custom-green" @click="createNewKtru"> Добавить новую позицию КТРУ вручную</b-btn>
            </div>
        </b-row>
        <b-card v-if="$store.state.NewKtruRequest.create_method" class="transparent-card" no-body>
            <form-row-single-select v-model="form.ktru_group_id" :options="forFilters.ktruGroups" :v="$v.form.ktru_group_id" label="Группа КТРУ" placeholder="Выберите группу КТРУ" />
            <form-row-single-select v-model="form.ktru_type_id" :options="forFilters.ktruTypes" :v="$v.form.ktru_type_id" label="Тип КТРУ" placeholder="Выберите тип КТРУ" />
            <form-row-single-select v-model="form.country_of_origin_id" :options="forFilters.countries" :v="$v.form.country_of_origin_id" label="Страна происхождения" placeholder="Выберите страну происхождения" />
            <form-row-edit-text v-model="form.name" :v="$v.form.name" label="Название" />
            <form-row-edit-textarea v-model="form.description" :rows="5" label="Описание" />
            <form-row-single-select v-model="form.okei_id" :options="forFilters.okeis" :v="$v.form.okei_id" label="Единица измерения (ОКЕИ)" label-field="breadcrumb" placeholder="Выберите единицу измерения (ОКЕИ)" />
            <form-row-single-select v-model="form.okpd_id" :options="forFilters.okpds" :v="$v.form.okpd_id" label="ОКПД2" label-field="breadcrumb" placeholder="Выберите ОКПД2" />
            <b-table-simple class="table-atmo">
                <b-thead>
                    <b-tr>
                        <b-th style="width: 350px">Название</b-th>
                        <b-th>Значение</b-th>
                        <b-th class="align-right" style="width: 100px">
                            <b-button size="sm" variant="primary" @click="addSpec">Добавить</b-button>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in $v.form.specs.$each.$iter" :key="index">
                        <b-td valign="top">
                            <b-form-input v-model="item.Name.$model" :disabled="index == 0" :state="!item.Name.$invalid" class="fs-14"></b-form-input>
                        </b-td>
                        <b-td>
                            <b-form-textarea v-model="item.Value.$model" class="fs-14" :state="!item.Value.$invalid"></b-form-textarea>
                        </b-td>
                        <b-td class="text-right">
                            <b-button :disabled="index == 0" size="sm" style="background: transparent" variant="light" @click="removeSpec(index)">
                                <img alt="trash_icon" src="/images/trash_icon.svg" />
                            </b-button>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <div class="text-right mt-3">
                <b-button variant="custom-outline-secondary" class="mx-2 text-uppercase" @click="onCancel">Отмена</b-button>
                <b-button variant="custom-danger" class="text-uppercase mx-2" @click="onSaveDraft">Сохранить черновик</b-button>
                <b-button variant="custom-green" class="text-uppercase mx-2" @click="onSubmit" :disabled="$v.form.$invalid">Разместить запрос</b-button>
            </div>
        </b-card>
        <ktru-select-modal :show="showKtruSelectModal" @cancel="cancelKtruSelectModal" @select="processKtruSelect"></ktru-select-modal>
    </div>
</template>

<style scoped>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Directories from '@/services/api/Directories';
import KtruSelectModal from '@/components/common/KtruSelectModal';
import axios from 'axios';

const initialFormSpecs = {
    Name: 'Минимальные требования',
    Value: ''
};
const initialForm = {
    id: null,
    name: '',
    existing_ktru_item_id: null,
    ktru_group_id: null,
    ktru_type_id: null,
    description: '',
    okei_id: null,
    okpd_id: null,
    country_of_origin_id: null,
    specs: [{ ...initialFormSpecs }]
};

export default {
    name: 'KtruRequestsCreate',
    mixins: [validationMixin],
    components: {
        KtruSelectModal
    },
    data() {
        return {
            showKtruSelectModal: false,
            selectedKtru: null,
            form: {
                ...initialForm
            },
            forFilters: {
                ktruGroups: [],
                ktruTypes: [],
                okeis: [],
                okpds: [],
                countries: []
            },
            loading: true,
            t: null,
            savingForm: false
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: (value) => {
                    return value.trim().length > 3;
                }
            },
            ktru_type_id: { required },
            ktru_group_id: { required },
            country_of_origin_id: { required },
            okei_id: { required },
            okpd_id: { required },
            specs: {
                required,
                $each: {
                    Name: { required },
                    Value: { required }
                }
            }
        }
    },
    mounted() {
        // this.form = initialForm;
        this.fillData().then(() => {
            setTimeout(() => {
                this.form.id = null;
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        async fillData() {
            const data = await Promise.all([Directories.getKtruGroupsSelectItems(), Directories.getKtruTypes(), Directories.getOkeis(), Directories.getOkpds(), this.$api.dictionaries.countries.getSelectItems()]);
            this.forFilters.ktruGroups = data[0].data;
            this.forFilters.ktruTypes = data[1].data;
            this.forFilters.okeis = data[2].data;
            this.forFilters.okpds = data[3].data;
            this.forFilters.countries = data[4].data ?? [];
        },
        createKtruBasedOnExisting() {
            this.$store.commit('NewKtruRequest/SET_CREATE_METHOD', 1);
            this.showKtruSelectModal = true;
        },
        createNewKtru() {
            this.$store.commit('NewKtruRequest/SET_CREATE_METHOD', 2);
        },
        cancelKtruSelectModal() {
            this.$store.commit('NewKtruRequest/RESET');
            this.showKtruSelectModal = false;
        },
        processKtruSelect(selectedKtru) {
            this.showKtruSelectModal = false;
            this.$store.commit('NewKtruRequest/SET_EXISTING_KTRU', selectedKtru);
            this.form.name = selectedKtru.name;
            this.form.existing_ktru_item_id = selectedKtru.id;
            const foundKtruGroup = this.forFilters.ktruGroups.find((i) => i.id === selectedKtru.ktru_group_id);
            this.form.ktru_group_id = foundKtruGroup ? selectedKtru.ktru_group_id : null;
            this.form.ktru_type_id = selectedKtru.ktru_type_id;
            this.form.description = selectedKtru.description;
            this.form.okei_id = selectedKtru.okei_id;
            this.form.okpd_id = selectedKtru.okpd_id;
            this.form.country_of_origin_id = selectedKtru.country_of_origin_id ?? null;
            this.form.specs = [...this.form.specs, ...selectedKtru.specs];
        },
        async saveDraft(send) {
            // eslint-disable-next-line no-undef
            const response = await axios.post('/ktru_requests/save-draft', {
                ktruRequest: this.form,
                id: this.form.id,
                send
            });
            this.form.id = response.data;
        },
        async onSaveDraft() {
            this.savingForm = true;
            await this.saveDraft(false);
            this.onCancel();
        },
        async onSubmit() {
            this.savingForm = true;
            await this.saveDraft(true);
            this.onCancel();
        },
        onCancel() {
            this.$store.commit('NewKtruRequest/RESET');
            this.form = initialForm;
            this.form.id = null;
            this.form.specs.splice(0);
            this.form.specs.push({ ...initialFormSpecs });
            this.$router.push({ name: 'KtruRequestsList' }).catch(() => {});
        },
        addSpec() {
            const specLength = this.form.specs.length;
            if (this.form.specs[specLength - 1].Name.trim() + this.form.specs[specLength - 1].Value.trim() !== '') {
                this.form.specs.push({ Name: '', Value: '' });
            }
        },
        removeSpec(index) {
            this.form.specs.splice(index, 1);
        }
    }
};
</script>
