var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("client-header", { attrs: { customer: true } }),
              _c("filters", {
                attrs: {
                  provider: false,
                  "placeholder-postfix": "заказчика",
                  form: _vm.form,
                },
                on: {
                  applyFilters: _vm.applyFilters,
                  clearForm: _vm.clearFilter,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          !_vm.loading && !_vm.clients.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _c(
            "b-container",
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("b-pagination", {
                    staticStyle: { "padding-bottom": "0" },
                    attrs: {
                      align: "center",
                      "total-rows": _vm.meta.total,
                      "per-page": _vm.meta.per_page,
                    },
                    on: { change: _vm.applyFilters },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "pt-4" },
                _vm._l(_vm.clients, function (item) {
                  return _c("client-card", {
                    key: item.id,
                    attrs: { client: item },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "center",
                      "total-rows": _vm.meta.total,
                      "per-page": _vm.meta.per_page,
                    },
                    on: { change: _vm.applyFilters },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }