var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "purchase_part" },
              [
                _c("subheader-customer-cabinet", {
                  attrs: { purchase: _vm.purchase },
                }),
                _c(
                  "div",
                  { staticClass: "purchase_part-body row" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "purchase_part-body_col",
                        attrs: { md: "4" },
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "my-2" }, [
                            _c(
                              "p",
                              { staticClass: "fs-12 m-0 grey-color fw-600" },
                              [_vm._v("Мое ценовое предложение")]
                            ),
                          ]),
                          _vm.purchase.myOffer &&
                          _vm.purchase.is_hidden === false &&
                          _vm.purchase.myOffer.offer_status_id !==
                            _vm.OFFERS_STATUS.status_declined
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/grey_medal.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "fs-12 grey-color" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.purchase.myOffer.position)
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.purchase.myOffer
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/wallet.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "fs-12 grey-color" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.purchase.myOffer.total_cost
                                          )
                                        ) + " руб."
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.purchase.myOffer
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/grey_secundomer.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "fs-12 grey-color" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDateTimeFormat(
                                            _vm.purchase.myOffer.created_at
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "purchase_part-body_col",
                        attrs: { md: "4" },
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "my-2" }, [
                            _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                              _vm._v("Предмет закупки:"),
                            ]),
                            _c(
                              "p",
                              { staticClass: "fs-12 green-color fw-600" },
                              [_vm._v(_vm._s(_vm.purchase.purchase_name))]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "my-2 d-flex flex-column" },
                            [
                              _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                                _vm._v("Заказчик:"),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "system_link-style fw-600 text-uppercase fs-12",
                                  attrs: {
                                    to:
                                      "/clients/customers/" +
                                      _vm.purchase.org_id,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.organization) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.purchase.national_project_name
                            ? _c("div", { staticClass: "my-2" }, [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 m-0 grey-color" },
                                  [_vm._v("Национальный проект:")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "fs-12 m-0 grey-color fw-600",
                                  },
                                  [
                                    _c("span", { staticClass: "fw-600" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.purchase.national_project_name
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "my-2" }, [
                            _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                              _vm._v(
                                " Начальная максимальная цена контракта: "
                              ),
                              _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatPrice(_vm.purchase.start_price)
                                  ) + " руб."
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "purchase_part-body_col",
                        attrs: { md: "4" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-100 d-flex flex-column justify-content-between",
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/search.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                        },
                                        exact: "",
                                      },
                                    },
                                    [_vm._v("Информация о закупке")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { width: "30px" } },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/dynamic.svg",
                                          alt: "check_label",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style",
                                      attrs: {
                                        to: {
                                          name: "purchases.grouped.item.view",
                                          params: {
                                            group: "fl44",
                                            id: _vm.purchase.id,
                                          },
                                          hash: "#atmo-proposals",
                                        },
                                        exact: "",
                                      },
                                    },
                                    [_vm._v("Динамика предложений")]
                                  ),
                                ],
                                1
                              ),
                              _vm.purchase && _vm.purchase.organizationFinal
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { width: "30px" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/images/small_helmet.svg",
                                              alt: "check_label",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "system_link-style",
                                          attrs: {
                                            to: {
                                              name: "purchases.grouped.item.view",
                                              params: {
                                                group: "fl44",
                                                id: _vm.purchase.id,
                                              },
                                              hash: "#atmo-proposals",
                                            },
                                            exact: "",
                                          },
                                        },
                                        [_vm._v("Итоговый протокол")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.purchase && _vm.purchase.contract
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { width: "30px" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/images/contract.svg",
                                              alt: "check_label",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "system_link-style",
                                          attrs: {
                                            to: {
                                              name: "purchases.grouped.item.view",
                                              params: {
                                                group: "fl44",
                                                id: _vm.purchase.id,
                                              },
                                              hash: "#atmo-contract",
                                            },
                                            exact: "",
                                          },
                                        },
                                        [_vm._v("Договор")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.purchase &&
                              _vm.purchase.contract &&
                              _vm.purchase.contract.act
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "d-flex align-items-center green-color fs-12 fw-600 my-2",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { width: "30px" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "/images/file_small.svg",
                                              alt: "check_label",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "system_link-style",
                                          attrs: {
                                            to: {
                                              name: "purchases.grouped.item.view",
                                              params: {
                                                group: "fl44",
                                                id: _vm.purchase.id,
                                              },
                                              hash: "#atmo-contract-fulfillment",
                                            },
                                            exact: "",
                                          },
                                        },
                                        [_vm._v("Исполнение договора")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              [
                                _vm.$store.getters.isLoggedIn &&
                                _vm.$store.getters.isProvider &&
                                (_vm.purchase.status_id ===
                                  _vm.PURCHASE_STATUS.status_start ||
                                  _vm.purchase.is_hidden === true) &&
                                _vm.isBeforeNow(_vm.purchase.purchase_end)
                                  ? _c(
                                      "b-btn",
                                      {
                                        staticClass:
                                          "btn-card-action btn-card-action-green",
                                        attrs: {
                                          disabled: _vm.checkingProvider,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkProvider(
                                              _vm.purchase.id
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Изменить предложение ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("purchase-supporting-buttons", {
                              attrs: { purchase: _vm.purchase },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }