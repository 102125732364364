var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4 column-992",
            },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color m-0 main_text",
                  staticStyle: { "line-height": "1" },
                },
                [_vm._v("Создание пользователя")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "transparent-card", attrs: { "no-body": "" } },
        [
          _c("form-row-edit-text", {
            attrs: { v: _vm.$v.form.user.last_name, label: "Фамилия" },
            on: { change: _vm.onLastNameChange },
            model: {
              value: _vm.form.user.last_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "last_name", $$v)
              },
              expression: "form.user.last_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { v: _vm.$v.form.user.first_name, label: "Имя" },
            model: {
              value: _vm.form.user.first_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "first_name", $$v)
              },
              expression: "form.user.first_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { label: "Отчество" },
            model: {
              value: _vm.form.user.middle_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "middle_name", $$v)
              },
              expression: "form.user.middle_name",
            },
          }),
          _c("form-row-edit-phone", {
            attrs: {
              v: _vm.$v.form.user.phone,
              value: _vm.form.user.phone,
              label: "Телефон",
            },
            on: {
              value: function ($event) {
                _vm.form.user.phone = $event
              },
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                state:
                  _vm.$v.form.user.post.$invalid && _vm.showErrors
                    ? !_vm.$v.form.user.post.$invalid
                    : null,
                label: "Должность",
                "label-class":
                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                "label-cols-lg": "3",
                "label-cols-sm": "4",
              },
            },
            [
              _c(
                "b-form-select",
                {
                  staticClass: "fs-14",
                  attrs: {
                    state: !_vm.$v.form.user.post.$invalid,
                    "aria-describedby": "formdata_post_liveFeedback",
                  },
                  model: {
                    value: _vm.form.user.post,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.user, "post", $$v)
                    },
                    expression: "form.user.post",
                  },
                },
                [
                  _c("option", { attrs: { disabled: "", value: "" } }, [
                    _vm._v("Выберите должность"),
                  ]),
                  _c("option", [_vm._v("Руководитель организации")]),
                  _c("option", [_vm._v("Администратор организации")]),
                  _c("option", [
                    _vm._v(
                      "Лицо, уполномоченное на размещение информации и документов"
                    ),
                  ]),
                  _c("option", [
                    _vm._v(
                      "Лицо, имеющее право подписи документов от имени организации"
                    ),
                  ]),
                ]
              ),
              _vm.showErrors && _vm.$v.form.user.post.$invalid
                ? _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: "formdata_post_liveFeedback",
                        state:
                          !_vm.$v.form.user.post.$invalid && _vm.showErrors,
                      },
                    },
                    [_vm._v(" Поле обязательно для заполнения. ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("form-row-edit-text", {
            attrs: {
              label: "Логин",
              description:
                "Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля",
              disabled: "",
            },
            model: {
              value: _vm.form.user.username,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "username", $$v)
              },
              expression: "form.user.username",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              label: "Пароль",
              v: _vm.$v.form.user.password,
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.form.user.password = $event
              },
            },
            model: {
              value: _vm.form.user.password,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "password", $$v)
              },
              expression: "form.user.password",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              label: "Подтверждение пароля",
              v: _vm.$v.form.user.password_confirmation,
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.form.user.password_confirmation = $event
              },
            },
            model: {
              value: _vm.form.user.password_confirmation,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "password_confirmation", $$v)
              },
              expression: "form.user.password_confirmation",
            },
          }),
          _c("form-row-edit-email", {
            attrs: {
              v: _vm.$v.form.user.email,
              value: _vm.form.user.email,
              label: "Email",
            },
            on: {
              value: function ($event) {
                _vm.form.user.email = $event
              },
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.certificate.thumbprint,
              label: "SHA1-код сертификата (отпечаток/fingerprint)",
            },
            model: {
              value: _vm.form.certificate.thumbprint,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form.certificate,
                  "thumbprint",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.certificate.thumbprint",
            },
          }),
          _c(
            "b-row",
            { staticClass: "pt-3 pl-3" },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-3",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v(" Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.$v.form.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Добавить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }