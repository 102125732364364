var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "create" },
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "fs-52 fw-600 white-color mb-4 mt-2 if-tablet",
                          },
                          [
                            _vm._v("Коммерческое предложение"),
                            _c("br"),
                            _vm._v("№ " + _vm._s(_vm.form.reg_number)),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color mb-3" }, [
                          _c("b", [
                            _vm._v(
                              "К запросу КП № " +
                                _vm._s(_vm.form.quotation_request.reg_number)
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color mb-1" }, [
                          _vm._v(
                            "«" + _vm._s(_vm.form.organization.short_name) + "»"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о заказчике"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.quotation_request.organization.full_name
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("ИНН:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.form.quotation_request.organization.inn)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.form.quotation_request.organization.kpp
                    ? _c(
                        "b-row",
                        {
                          staticClass: "border-bottom py-3",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "3" },
                            },
                            [_vm._v("КПП:")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.quotation_request.organization.kpp
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.quotation_request.organization
                                .post_address
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес электронной почты:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.quotation_request.organization.email
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Номер контактного телефона:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$parsePhone(
                                _vm.form.quotation_request.organization.phone
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о поставщике"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.form.organization.full_name))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("ИНН:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.form.organization.inn))]
                      ),
                    ],
                    1
                  ),
                  _vm.form.organization.kpp
                    ? _c(
                        "b-row",
                        {
                          staticClass: "border-bottom py-3",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "3" },
                            },
                            [_vm._v("КПП:")]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "fs-14 grey-color",
                              attrs: { md: "9" },
                            },
                            [_vm._v(_vm._s(_vm.form.organization.kpp))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.form.organization.post_address))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Адрес электронной почты:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.form.organization.email))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Номер контактного телефона:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$parsePhone(_vm.form.organization.phone))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о запросе КП"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Предмет закупки:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.form.quotation_request.purchase_category_id
                                ? _vm.form.quotation_request.purchase_category
                                    .name
                                : _vm.form.quotation_request.name
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Дата размещения:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getDateTimeFormat(
                                _vm.form.quotation_request.created_at
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Срок сбора КП:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getDateTimeFormat(
                                _vm.form.quotation_request.deadline
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Информация о коммерческом предложении"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    {
                      staticClass: "border-bottom py-3",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Дата размещения:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.getDateTimeFormat(_vm.form.created_at))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Срок действия коммерческого предложения:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _vm._v(
                            _vm._s(_vm.getDateTimeFormat(_vm.form.deadline))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [
                          _vm._v(
                            "Итоговая сумма коммерческого предложения, руб.:"
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.$formatPrice(_vm.form.cost)))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Спецификации"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "fs-14 grey-color" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("№"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Рег.номер ТРУ"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Наименование товара/работы/услуги"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Код ОКПД2"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Описание/характеристики"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Количество"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Ед.изм"),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Цена за ед., руб."),
                              ]),
                              _c("th", { staticClass: "fw-600" }, [
                                _vm._v("Стоимость, руб."),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.form.quotation_items,
                              function (item, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "align-text-top" }, [
                                    _vm._v(_vm._s(index + 1)),
                                  ]),
                                  _c("td", { staticClass: "align-text-top" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.ktru ? item.ktru.reg_number : "—"
                                      )
                                    ),
                                  ]),
                                  _c("td", { staticClass: "align-text-top" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("td", { staticClass: "align-text-top" }, [
                                    _vm._v(
                                      _vm._s(item.okpd ? item.okpd.code : "—")
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "align-text-top" },
                                    [
                                      item.ktru
                                        ? [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-toggle",
                                                    rawName: "v-b-toggle",
                                                    value: "collapse-" + index,
                                                    expression:
                                                      "'collapse-' + index",
                                                  },
                                                ],
                                                attrs: {
                                                  size: "sm",
                                                  variant: "custom-green",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.visible[index]
                                                      ? "Скрыть"
                                                      : "Показать"
                                                  ) + " описание/характеристики"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "b-collapse",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  id: "collapse-" + index,
                                                },
                                                model: {
                                                  value: _vm.visible[index],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.visible,
                                                      index,
                                                      $$v
                                                    )
                                                  },
                                                  expression: "visible[index]",
                                                },
                                              },
                                              [
                                                item.ktru.description
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v("Описание:"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.ktru
                                                              .description
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c("p", [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Характеристики:"
                                                        ),
                                                      ]),
                                                    ])
                                                  : _vm._e(),
                                                item.ktru.specs &&
                                                item.ktru.specs.length > 0
                                                  ? _c(
                                                      "ul",
                                                      _vm._l(
                                                        item.ktru.specs,
                                                        function (
                                                          spec,
                                                          specIndex
                                                        ) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key:
                                                                index +
                                                                "_spec_" +
                                                                specIndex,
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    spec.Name
                                                                  )
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " - " +
                                                                  _vm._s(
                                                                    spec.Value
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        : [_vm._v("—")],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "align-text-top",
                                      staticStyle: { "text-align": "right" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          parseFloat(item.count).toPrecision()
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("td", { staticClass: "align-text-top" }, [
                                    _vm._v(_vm._s(item.okei.local_symbol)),
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "align-text-top",
                                      staticStyle: { "text-align": "right" },
                                      attrs: { nowrap: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$formatPrice(item.price))
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "align-text-top",
                                      staticStyle: { "text-align": "right" },
                                      attrs: { nowrap: "" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$formatPrice(item.total_sum))
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "pb-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "block_head-text" }, [
                          _vm._v("Документы по коммерческому предложению"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-3", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "fs-14 grey-color" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [_c("th", [_vm._v("Документ")])]),
                          ]),
                          _c("tbody", [
                            _vm.form.document
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "fs-14",
                                          staticStyle: {
                                            "padding-top": "0",
                                            "padding-bottom": "0",
                                            color:
                                              "var(--green-color) !important",
                                          },
                                          attrs: {
                                            variant: "link",
                                            size: "xs",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$commonDocumentDownload(
                                                _vm.form.document
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.form.document.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("tr", [
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v("Документы не предоставлены"),
                                  ]),
                                ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "my-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.pdfLoading,
                                rounded: "",
                                opacity: "0.6",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.downloadPDF },
                                },
                                [_vm._v("СКАЧАТЬ ПЕЧАТНУЮ ФОРМУ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }