import axios from 'axios';

export default {
    get(id) {
        return axios.get('/external_purchases/' + id);
    },
    edit(id) {
        return axios.get('/external_purchases/' + id + '/edit');
    },
    store(payload) {
        return axios.post('/external_purchases', payload);
    },
    update(id, payload) {
        return axios.put('/external_purchases/' + id, payload);
    },
    findProvider(payload) {
        return axios.post('/external_purchases/find_provider', payload);
    },
    getPurchaseStatuses() {
        return axios.get('/external_purchases/purchase_statuses');
    }
};
