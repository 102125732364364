<template>
    <b-modal title="Уважаемый пользователь!" centered hide-footer no-close-on-backdrop no-close-on-esc @close="cancelModal" @cancel="cancelModal" :visible="show">
        <b-row class="mt-2">
            <b-col>
                <p class="fs-14 grey-color">При внесении изменений в сведения о сертификате электронной подписи в личном кабинете организации в ИС, пользователь несет персональную ответственность, которая регламентирована законом 63-ФЗ «Об электронной подписи».</p>
                <p class="fs-14 grey-color">При внесении неверного SHA1-кода (отпечатка) сертификата, пользователь не сможет авторизоваться в личном кабинете ИС.</p>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col class="text-right">
                <b-button type="reset" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelModal">Отмена</b-button>
                <b-button type="submit" class="text-uppercase" variant="custom-green" @click="okModal">Сохранить</b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    name: 'OrganizationUserEditConfirmModal',
    props: ['show'],
    data() {
        return {};
    },
    methods: {
        cancelModal() {
            this.$emit('cancel');
        },
        okModal() {
            this.$emit('ok');
        }
    }
};
</script>

<style></style>
