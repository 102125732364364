<template>
    <div>
        <b-row>
            <b-col>
                <p class="fs-32 grey-color mb-4 main_text" style="line-height: 1">Избранное (Поставщики)</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="mb-3" v-for="customer in customers" :key="customer.id" v-bind="customer">
                <div class="purchase_part">
                    <div class="purchase_part-header" style="background-color: var(--orange-color)">
                        <router-link :to="'/clients/providers/' + customer.id" class="purchase_part-header_number text-uppercase white-system_link-style"> Поставщик </router-link>
                    </div>
                    <b-row class="purchase_part-body">
                        <b-col class="purchase_part-body_col">
                            <div class="my-2">
                                <p class="fs-12 fw-600 m-0 text-uppercase grey-color">
                                    Поставщик: <span class="fw-400 grey-color"> {{ customer.full_name }} </span>
                                </p>
                            </div>
                            <div class="my-2">
                                <p class="fs-12 fw-600 m-0 text-uppercase grey-color">
                                    ИНН: <span class="fw-400 grey-color"> {{ customer.inn }} </span>
                                </p>
                            </div>
                            <div v-if="customer.kpp" class="my-2">
                                <p class="fs-12 fw-600 m-0 text-uppercase grey-color">
                                    КПП: <span class="fw-400 grey-color"> {{ customer.kpp }} </span>
                                </p>
                            </div>
                            <div class="my-2">
                                <p class="fs-12 fw-600 m-0 text-uppercase grey-color">
                                    Почтовый адрес: <span class="fw-400 grey-color"> {{ customer.post_address }} </span>
                                </p>
                            </div>
                        </b-col>
                        <b-col cols="auto" class="d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <b-btn v-on:click="removeFavorite(customer)" variant="custom-orange" class="d-flex align-items-center">
                                    <img class="mr-1" src="/images/full_star.svg" alt="empty_star" />
                                    УБРАТЬ ИЗ ИЗБРАННОГО
                                </b-btn>
                            </div>
                        </b-col>
                        <!--                        <b-col cols="12" class="mt-3">-->
                        <!--                            <router-link tag="button" class="btn btn-custom-orange" :to="'/clients/providers/'+customer.id">Сведения</router-link>-->
                        <!--                        </b-col>-->
                    </b-row>
                </div>
            </b-col>
            <b-col v-if="!customers.length && !loading">
                <empty-request></empty-request>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import OrganizationsApi from '@/services/api/Organizations';
export default {
    name: 'Favorite',
    metaInfo: {
        title: 'Избранное (Поставщики)'
    },
    data() {
        return {
            loading: true,
            customers: []
        };
    },
    created() {
        OrganizationsApi.getFavorite()
            .then((customers) => {
                this.customers = customers.data;
            })
            .catch((error) => console.log(error))
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        removeFavorite(customer) {
            OrganizationsApi.removeFavorite(customer)
                .then(() => {
                    for (let key in this.customers) {
                        if (this.customers[key].id === customer.id) {
                            this.customers.splice(key, 1);
                        }
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.purchase_part {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--orange-color);
    padding: 8px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-body {
    padding: 12px 24px;
}
.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.purchase_part-body span {
    text-transform: none;
}
</style>
