var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create" },
    [
      _c("form-header", { attrs: { title: "Запрос импортозамещения" } }),
      _c(
        "form-content-group",
        { attrs: { title: "Информация о запросе" } },
        [
          _c("form-row-single-select", {
            attrs: {
              options: _vm.purchaseCategories,
              v: _vm.$v.form.purchase_category_id,
              label: "Предмет запроса",
              placeholder: "Выберите предмет запроса",
            },
            model: {
              value: _vm.form.purchase_category_id,
              callback: function ($$v) {
                _vm.$set(_vm.form, "purchase_category_id", $$v)
              },
              expression: "form.purchase_category_id",
            },
          }),
          _c("form-row-edit-date", {
            attrs: {
              minDate: new Date().toJSON().slice(0, 10),
              v: _vm.$v.form.deadline,
              label: "Срок сбора предложений",
              placeholder: "Укажите срок сбора предложений",
            },
            model: {
              value: _vm.form.deadline,
              callback: function ($$v) {
                _vm.$set(_vm.form, "deadline", $$v)
              },
              expression: "form.deadline",
            },
          }),
        ],
        1
      ),
      _c("import-substitution-request-create-ktru-item-block", {
        attrs: { v: _vm.$v.form.ktru_item, title: "ТРУ для импортозамещения" },
        model: {
          value: _vm.form.ktru_item,
          callback: function ($$v) {
            _vm.$set(_vm.form, "ktru_item", $$v)
          },
          expression: "form.ktru_item",
        },
      }),
      _c("form-row-single-document-upload", {
        staticClass: "container",
        attrs: {
          v: _vm.$v.form.stored_document,
          title: "Техническое описание",
        },
        model: {
          value: _vm.form.stored_document,
          callback: function ($$v) {
            _vm.$set(_vm.form, "stored_document", $$v)
          },
          expression: "form.stored_document",
        },
      }),
      _c(
        "form-action-buttons-group",
        [
          _c(
            "b-button",
            {
              staticClass: "mx-2 text-uppercase",
              attrs: { variant: "custom-outline-secondary" },
              on: { click: _vm.onCancel },
            },
            [_vm._v("Отменить")]
          ),
          _c(
            "b-overlay",
            {
              staticClass: "d-inline-block",
              attrs: {
                show: _vm.isDataSending,
                rounded: "",
                opacity: "0.6",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-2 text-uppercase",
                  attrs: {
                    disabled: _vm.$v.form.$invalid,
                    variant: "custom-green",
                  },
                  on: { click: _vm.sendData },
                },
                [_vm._v("Разместить запрос")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }