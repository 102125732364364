var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "var(--greyBg-color)" } },
    [
      _vm.$store.state.user && _vm.$store.state.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _vm.isUserCustomer
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-52 fw-600 white-color mt-2 mb-4 if-tablet",
                              },
                              [_vm._v("Личный кабинет заказчика")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("p", { staticClass: "fs-14 greyBg-color" }, [
                              _vm._v(" Наименование организации: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$store.state.organization.short_name
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("p", { staticClass: "fs-14 greyBg-color" }, [
                              _vm._v(" Пользователь: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$store.state.user.last_name) +
                                    " " +
                                    _vm._s(_vm.$store.state.user.first_name) +
                                    " " +
                                    _vm._s(_vm.$store.state.user.middle_name)
                                ),
                              ]),
                              _c("span", { staticClass: "pl-5" }, [
                                _vm._v(" Логин: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.user.username)
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm.features.telegram_bot
                              ? _c("p", { staticClass: "fs-14 greyBg-color" }, [
                                  _vm._v(" ID Пользователя для Telegram: "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$store.state.user.id)),
                                  ]),
                                ])
                              : _vm._e(),
                            !_vm.$store.state.user.is_chatme_notifiable &&
                            _vm.features.telegram_bot
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-custom-white",
                                    attrs: {
                                      href: _vm.links.telegram_events_bot,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Подключить уведомления о событиях в ИС"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-5 mr-2",
                                  attrs: {
                                    variant: "custom-white",
                                    to: {
                                      name: "purchases.grouped.item.create",
                                      params: { group: "fl44" },
                                    },
                                  },
                                },
                                [_vm._v("СОЗДАТЬ ЗАКУПКУ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-5",
                                  attrs: {
                                    variant: "custom-outline-light",
                                    to: { name: "ExternalPurchaseCreate" },
                                  },
                                },
                                [_vm._v("ДОБАВИТЬ ВНЕСИСТЕМНУЮ ЗАКУПКУ")]
                              ),
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { md: "6" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUserProvider
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "9" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-52 fw-600 white-color mb-3 mt-2 if-tablet",
                              },
                              [_vm._v("Личный кабинет поставщика")]
                            ),
                            _c("p", { staticClass: "fs-14 greyBg-color" }, [
                              _vm._v(" Наименование организации: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$store.state.organization.short_name
                                  )
                                ),
                              ]),
                            ]),
                            _c("p", { staticClass: "fs-14 greyBg-color" }, [
                              _vm._v(" Пользователь: "),
                              _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$store.state.user.last_name) +
                                    " " +
                                    _vm._s(_vm.$store.state.user.first_name) +
                                    " " +
                                    _vm._s(_vm.$store.state.user.middle_name) +
                                    " "
                                ),
                              ]),
                              _c("span", { staticClass: "pl-5" }, [
                                _vm._v(" Логин: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.user.username)
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm.features.telegram_bot
                              ? _c("p", { staticClass: "fs-14 greyBg-color" }, [
                                  _vm._v(" ID Пользователя для Telegram: "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.$store.state.user.id)),
                                  ]),
                                ])
                              : _vm._e(),
                            !_vm.$store.state.user.is_chatme_notifiable &&
                            _vm.features.telegram_bot
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-custom-white",
                                    attrs: {
                                      href: _vm.links.telegram_events_bot,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Подключить уведомления о событиях в ИС и размещении новых извещений"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                !_vm.getOrganizationHasPayments
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn btn-custom-danger text-uppercase",
                                        attrs: {
                                          to: {
                                            name: "ProviderAccountCabinet",
                                          },
                                        },
                                      },
                                      [_vm._v("Пополнить лицевой счет")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("b-col", { attrs: { md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "my-2 mx-2",
                                    attrs: {
                                      variant: "custom-white",
                                      to: {
                                        name: "OrganizationReliabilityReport",
                                      },
                                    },
                                  },
                                  [_vm._v("Проверка контрагентов")]
                                ),
                                _vm.getCertificateNeedToBeProlonged
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "text-uppercase my-2 mx-2",
                                        attrs: {
                                          href: _vm.links.prolong_certificate,
                                          variant: "custom-danger",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("Продлить ЭЦП")]
                                    )
                                  : _vm._e(),
                                _vm.features.marketplace_integration
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "text-uppercase my-2 mx-2",
                                        attrs: { variant: "custom-white" },
                                        on: { click: _vm.goToBashmarket },
                                      },
                                      [_vm._v("ПЕРЕЙТИ НА БАШМАРКЕТ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "py-5" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3", sm: "12" } },
                    [
                      _c(
                        "b-nav",
                        {
                          staticClass: "w-100 provider_menu cabinet_menu mb-3",
                          attrs: { vertical: "" },
                        },
                        [
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("ShowPurchases"),
                                    to: {
                                      name: "ShowPurchases",
                                      params: { cat: "all" },
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Личный кабинет")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "CustomerQuotationRequestsList"
                                    ),
                                    to: {
                                      name: "CustomerQuotationRequestsList",
                                      params: { cat: "all" },
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Запросы КП ")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "CustomerPurchasesCabinet"
                                    ),
                                    to: {
                                      name: "CustomerPurchasesCabinet",
                                      params: { cat: "all" },
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Мои извещения ")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "CustomerContractsCabinet"
                                    ),
                                    to: {
                                      name: "CustomerContractsCabinet",
                                      params: { cat: "all" },
                                    },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Договоры ")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("KtruRequestsList"),
                                    to: { name: "KtruRequestsList" },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Запросы на добавление КТРУ")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("NotificationsList"),
                                    to: { name: "NotificationsList" },
                                  },
                                },
                                [_vm._v("Системные уведомления")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "FavoriteCustomerCabinet"
                                    ),
                                    to: { name: "FavoriteCustomerCabinet" },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Избранное (Поставщики)")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "CustomerAddressesCabinet"
                                    ),
                                    to: { name: "CustomerAddressesCabinet" },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Адресная книга")]
                              )
                            : _vm._e(),
                          _vm.isUserCustomer
                            ? _c(
                                "li",
                                {
                                  staticClass: "nav-item",
                                  on: {
                                    click: function ($event) {
                                      _vm.customerCompanySubmenu =
                                        !_vm.customerCompanySubmenu
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      class: {
                                        "router-link-active":
                                          _vm.checkCompanyInfo,
                                      },
                                      attrs: {
                                        href: "javascript:void(0)",
                                        target: "_self",
                                      },
                                    },
                                    [
                                      _vm._v(" Об организации "),
                                      _c(
                                        "b-collapse",
                                        {
                                          staticClass: "mt-2 cabinet_submenu",
                                          attrs: { id: "provider_company" },
                                          model: {
                                            value: _vm.customerCompanySubmenu,
                                            callback: function ($$v) {
                                              _vm.customerCompanySubmenu = $$v
                                            },
                                            expression:
                                              "customerCompanySubmenu",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive(
                                                    "OrganizationData"
                                                  ),
                                                to: {
                                                  name: "OrganizationData",
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Информация об организации"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive("UserData"),
                                                to: { name: "UserData" },
                                              },
                                            },
                                            [_vm._v("Данные учетной записи")]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive("UsersList"),
                                                to: { name: "UsersList" },
                                              },
                                            },
                                            [_vm._v("Пользователи организации")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "ProviderOffersCabinet"
                                    ),
                                    to: { name: "ProviderOffersCabinet" },
                                  },
                                },
                                [_vm._v("Мои предложения")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "ProviderQuotationsList"
                                    ),
                                    to: {
                                      name: "ProviderQuotationsList",
                                      params: { cat: "all" },
                                    },
                                  },
                                },
                                [_vm._v("Коммерческие предложения ")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "ProviderContractsCabinet"
                                    ),
                                    to: {
                                      name: "ProviderContractsCabinet",
                                      params: { cat: "all" },
                                    },
                                  },
                                },
                                [_vm._v("Договоры")]
                              )
                            : _vm._e(),
                          _vm.isProductionsAllowed && _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "ProviderProductionsList"
                                    ),
                                    to: { name: "ProviderProductionsList" },
                                  },
                                },
                                [_vm._v("Мое производство ")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "ProviderAccountCabinet"
                                    ),
                                    to: { name: "ProviderAccountCabinet" },
                                  },
                                },
                                [_vm._v("Лицевой счет")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("KtruRequestsList"),
                                    to: { name: "KtruRequestsList" },
                                  },
                                },
                                [_vm._v("Запросы на добавление КТРУ")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("NotificationsList"),
                                    to: { name: "NotificationsList" },
                                  },
                                },
                                [_vm._v("Системные уведомления")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "FavoriteProviderCabinet"
                                    ),
                                    to: { name: "FavoriteProviderCabinet" },
                                  },
                                },
                                [_vm._v("Избранное (Заказчики)")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive(
                                      "OrganizationReliabilityReport"
                                    ),
                                    to: {
                                      name: "OrganizationReliabilityReport",
                                    },
                                  },
                                },
                                [_vm._v("Проверка контрагентов")]
                              )
                            : _vm._e(),
                          _vm.isUserProvider
                            ? _c(
                                "li",
                                {
                                  staticClass: "nav-item",
                                  on: {
                                    click: function ($event) {
                                      _vm.providerCompanySubmenu =
                                        !_vm.providerCompanySubmenu
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      class: {
                                        "router-link-active":
                                          _vm.checkCompanyInfo,
                                      },
                                      attrs: {
                                        href: "javascript:void(0)",
                                        target: "_self",
                                      },
                                    },
                                    [
                                      _vm._v(" Об организации "),
                                      _c(
                                        "b-collapse",
                                        {
                                          staticClass: "mt-2 cabinet_submenu",
                                          attrs: { id: "provider_company" },
                                          model: {
                                            value: _vm.providerCompanySubmenu,
                                            callback: function ($$v) {
                                              _vm.providerCompanySubmenu = $$v
                                            },
                                            expression:
                                              "providerCompanySubmenu",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive(
                                                    "OrganizationData"
                                                  ),
                                                to: {
                                                  name: "OrganizationData",
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Информация об организации"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive("UserData"),
                                                to: { name: "UserData" },
                                              },
                                            },
                                            [_vm._v("Данные учетной записи")]
                                          ),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                active:
                                                  _vm.isActive("UsersList"),
                                                to: { name: "UsersList" },
                                              },
                                            },
                                            [_vm._v("Пользователи организации")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "9", sm: "12" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }