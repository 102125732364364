<template>
    <div>
        <b-button variant="custom-green" class="text-uppercase" @click="showExportModal = true" :disabled="exportLoading">Выгрузить реестр договоров в .XLSX</b-button>
        <b-modal v-model="showExportModal" title="Выгрузка реестра договоров в .XLSX" no-close-on-backdrop no-close-on-esc no-close-on-ok hide-footer @cancel="handleCancel" @close="handleCancel" @ok="handleAccept">
            <b-row class="mb-4">
                <b-col md="12">
                    <b-form-group label="Дата заключения договора, С">
                        <date-time-picker
                            id="exportStartDate"
                            v-model="exportRequest.from"
                            label="Дата заключения договора, С"
                            :only-date="true"
                            formatted="DD.MM.YYYY"
                            format="YYYY-MM-DD"
                            buttonNowTranslation="Сегодня"
                            autoClose
                            :no-label="!!exportRequest.from"
                            :max-date="exportRequest.to"></date-time-picker>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Дата заключения договора, По (включительно)">
                        <date-time-picker
                            id="exportEndDate"
                            v-model="exportRequest.to"
                            label="Дата заключения договора, По"
                            :only-date="true"
                            formatted="DD.MM.YYYY"
                            format="YYYY-MM-DD"
                            buttonNowTranslation="Сегодня"
                            autoClose
                            :no-label="!!exportRequest.to"
                            :min-date="exportRequest.from"></date-time-picker>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group label="Типы договоров">
                        <b-form-radio-group id="radio-group-1" v-model="exportRequest.type" :options="radioOptions" name="radio-options" stacked></b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-btn class="mr-2" @click="handleCancel" :disabled="exportLoading">Отмена</b-btn>
                    <b-overlay :show="exportLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-btn variant="primary" @click="handleAccept">Экспортировать</b-btn>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import CabinetCustomerPurchases from '@/services/api/cabinet/customer/purchases';
import FileDownload from 'js-file-download';

export default {
    name: 'export-contracts-button',
    data() {
        return {
            showExportModal: false,
            exportRequest: {
                from: null,
                to: null,
                type: 0
            },
            exportLoading: false,
            radioOptions: [
                { text: 'Договоры', value: 1 },
                { text: 'Внесистемные закупки', value: 2 },
                { text: 'Все', value: 0 }
            ]
        };
    },
    methods: {
        handleAccept() {
            this.exportLoading = true;
            CabinetCustomerPurchases.getContractsXlsExport(this.exportRequest)
                .then((response) => {
                    FileDownload(response.data, 'Договора.xlsx');
                    this.handleCancel();
                })
                .catch(() => {
                    this.handleCancel();
                });
        },
        handleCancel() {
            this.showExportModal = false;
            this.exportRequest.from = null;
            this.exportRequest.to = null;
            this.exportRequest.type = 0;
            this.exportLoading = false;
        }
    }
};
</script>

<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.tabs li + li {
    padding-left: 20px;
}

.tabs li {
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid var(--grey-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.tabs li a {
    color: var(--grey-color);
    text-decoration: none;
    border-bottom: 2px solid transparent;
}
</style>
