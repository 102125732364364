var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-5", attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              ref: "filter",
              staticClass: "w-100",
              attrs: { autocomplete: "off" },
              on: {
                focusin: function ($event) {
                  _vm.alert = false
                },
              },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile",
                                  },
                                  [_vm._v("Реестр извещений")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "filter" },
                            [
                              _c("filter-multiple-select", {
                                attrs: {
                                  label: "Предмет закупки",
                                  options: _vm.forFilters.purchaseCategories,
                                  placeholder: "Выберите предметы закупки",
                                  md: "6",
                                  lg: "6",
                                },
                                model: {
                                  value: _vm.filter.purchaseCategories,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter,
                                      "purchaseCategories",
                                      $$v
                                    )
                                  },
                                  expression: "filter.purchaseCategories",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Наименование ТРУ",
                                  placeholder: "Укажите наименование ТРУ",
                                  md: "6",
                                  lg: "6",
                                },
                                model: {
                                  value: _vm.filter.conditionname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "conditionname", $$v)
                                  },
                                  expression: "filter.conditionname",
                                },
                              }),
                              _vm.showFilters
                                ? [
                                    _c("filter-single-select", {
                                      attrs: {
                                        label: "Тип закупки",
                                        placeholder: "Укажите тип закупки",
                                        options: _vm.forFilters.orderTypes,
                                        "label-field": "text",
                                      },
                                      model: {
                                        value: _vm.filter.orderType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter, "orderType", $$v)
                                        },
                                        expression: "filter.orderType",
                                      },
                                    }),
                                    _c("filter-input-text", {
                                      attrs: {
                                        label: "Заказчик",
                                        placeholder:
                                          "Укажите наименование/ИНН заказчика",
                                      },
                                      model: {
                                        value: _vm.filter.customer,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter, "customer", $$v)
                                        },
                                        expression: "filter.customer",
                                      },
                                    }),
                                    _c("filter-input-text", {
                                      attrs: {
                                        label: "Рег.номер закупки",
                                        placeholder: "Укажите номер закупки",
                                      },
                                      model: {
                                        value: _vm.filter.regNumber,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.filter, "regNumber", $$v)
                                        },
                                        expression: "filter.regNumber",
                                      },
                                    }),
                                    _c("filter-input-currency", {
                                      attrs: {
                                        label: "НМЦК от...",
                                        placeholder: "НМЦК от...",
                                      },
                                      model: {
                                        value: _vm.filter.priceStartFrom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "priceStartFrom",
                                            $$v
                                          )
                                        },
                                        expression: "filter.priceStartFrom",
                                      },
                                    }),
                                    _c("filter-input-currency", {
                                      attrs: {
                                        label: "НМЦК до...",
                                        placeholder: "НМЦК до...",
                                      },
                                      model: {
                                        value: _vm.filter.priceStartTo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "priceStartTo",
                                            $$v
                                          )
                                        },
                                        expression: "filter.priceStartTo",
                                      },
                                    }),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("date-time-picker", {
                                              staticClass: "filter_datepicker",
                                              attrs: {
                                                id: "dtp-order-date-start",
                                                label:
                                                  "Укажите дату проведения с",
                                                "only-date": true,
                                                formatted: "DD.MM.YYYY",
                                                format: "YYYY-MM-DD",
                                                buttonNowTranslation: "Сегодня",
                                                autoClose: "",
                                                "max-date":
                                                  _vm.filter.orderDateFinish,
                                                "no-label":
                                                  !!_vm.filter.orderDateStart,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter.orderDateStart,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "orderDateStart",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.orderDateStart",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs-10 halfWhite-color",
                                              },
                                              [_vm._v("Дата проведения с...")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("date-time-picker", {
                                              staticClass: "filter_datepicker",
                                              attrs: {
                                                id: "dtp-order-date-finish",
                                                label:
                                                  "Укажите дату проведения по",
                                                "only-date": true,
                                                formatted: "DD.MM.YYYY",
                                                format: "YYYY-MM-DD",
                                                buttonNowTranslation: "Сегодня",
                                                autoClose: "",
                                                "min-date":
                                                  _vm.filter.orderDateStart,
                                                "no-label":
                                                  !!_vm.filter.orderDateFinish,
                                              },
                                              model: {
                                                value:
                                                  _vm.filter.orderDateFinish,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "orderDateFinish",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.orderDateFinish",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs-10 halfWhite-color",
                                              },
                                              [_vm._v("Дата проведения по...")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "flex_col-mobile" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: { variant: "custom-white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPurchases(false)
                                        },
                                      },
                                    },
                                    [_vm._v("Найти ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.showOtherFilter },
                                    },
                                    [_vm._v("Все фильтры ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.clearFilter },
                                    },
                                    [_vm._v("Сбросить")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.copyUrlWithFilters },
                                    },
                                    [_vm._v("Скопировать ссылку на фильтр")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    !_vm.mobileMenu
                      ? _c(
                          "ul",
                          { staticClass: "category_menu" },
                          _vm._l(
                            _vm.purchases_categories,
                            function (category, cIndex) {
                              return _c(
                                "li",
                                { key: "category_" + cIndex },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/purchases/" + category.path,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(category.title) + " ")]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.mobileMenu
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "purchase_category",
                                    expression: "'purchase_category'",
                                  },
                                ],
                                staticClass:
                                  "fs-14 fw-600 my-3 green-color d-flex justify-content-between",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.activeTab[0].title) + " "
                                ),
                                _c("i", {
                                  staticClass:
                                    "fa fs-14 fw-600 green-color d-flex align-items-center",
                                  class: {
                                    "fa-chevron-down": !_vm.mobileMenu_show,
                                    "fa-chevron-up": _vm.mobileMenu_show,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "b-collapse",
                              {
                                attrs: { id: "purchase_category" },
                                model: {
                                  value: _vm.mobileMenu_show,
                                  callback: function ($$v) {
                                    _vm.mobileMenu_show = $$v
                                  },
                                  expression: "mobileMenu_show",
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { staticClass: "mobile_category_menu" },
                                  _vm._l(
                                    _vm.purchases_categories,
                                    function (category, cIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: "category_" + cIndex,
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeCategory(
                                                category.path
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(category.title) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "greyBg",
          staticStyle: { "border-top": "2px solid #e8ced3" },
          attrs: { fluid: "", id: "content-block" },
        },
        [
          _vm.purchases.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._l(_vm.purchases, function (purchase) {
            return _c(
              "b-container",
              { key: purchase.id },
              [
                _c(
                  "b-row",
                  [
                    (purchase.status_id === _vm.PURCHASE_STATUS.status_start &&
                      !purchase.is_hold) ||
                    purchase.is_hidden === true
                      ? _c("purchase-bidding", {
                          attrs: {
                            PURCHASE_STATUS: _vm.PURCHASE_STATUS,
                            checkingProvider: _vm.checkingProvider,
                            purchase: purchase,
                          },
                          on: { checkProvider: _vm.checkProvider },
                        })
                      : _vm._e(),
                    purchase.status_id === _vm.PURCHASE_STATUS.status_start &&
                    purchase.is_hold
                      ? _c("purchase-hold", { attrs: { purchase: purchase } })
                      : _vm._e(),
                    purchase.status_id ===
                    _vm.PURCHASE_STATUS.status_protocol_formed
                      ? _c("purchase-summed", { attrs: { purchase: purchase } })
                      : _vm._e(),
                    purchase.status_id === _vm.PURCHASE_STATUS.status_cancel
                      ? _c("purchase-canceled", {
                          attrs: { purchase: purchase },
                        })
                      : _vm._e(),
                    purchase.status_id === _vm.PURCHASE_STATUS.status_fail
                      ? _c("purchase-failed", { attrs: { purchase: purchase } })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.loading && !_vm.purchases.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _vm.purchases.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getPurchases()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "attention-modal",
          attrs: { "hide-footer": "", title: "Внимание!", size: "lg" },
          model: {
            value: _vm.showDepositModal,
            callback: function ($$v) {
              _vm.showDepositModal = $$v
            },
            expression: "showDepositModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("div", { staticClass: "w-100 fs-14 grey-color text-left" }, [
                _vm._v(
                  "Для подачи предложения в закупочной сессии на лицевом счете Вашей организации не хватает " +
                    _vm._s(_vm.needDep) +
                    " рублей."
                ),
              ]),
              _c("br"),
              _c(
                "div",
                { staticClass: "w-100 fs-14 fw-600 grey-color form-group" },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "stop-number-mousewheel",
                        rawName: "v-stop-number-mousewheel",
                      },
                    ],
                    attrs: {
                      type: "number",
                      min: _vm.needDep,
                      state: _vm.checkDepositValid,
                      step: "any",
                    },
                    model: {
                      value: _vm.neededDeposit,
                      callback: function ($$v) {
                        _vm.neededDeposit = $$v
                      },
                      expression: "neededDeposit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-input", {
                        attrs: {
                          type: "email",
                          placeholder: "Введите почту",
                          state: _vm.checkEmailValid,
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-green" },
                          on: { click: _vm.sendInvoiceToEmail },
                        },
                        [_vm._v("ОТПРАВИТЬ НА E-MAIL ")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase ml-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.downloadInvoice },
                        },
                        [_vm._v("СКАЧАТЬ PDF ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c("input", {
        staticClass: "hidden",
        staticStyle: { display: "none" },
        attrs: { type: "text", value: "", id: "myInput" },
      }),
      _c(
        "router-link",
        {
          staticClass: "copy_link hidden",
          staticStyle: { display: "none" },
          attrs: {
            id: "copy_link",
            to: {
              name: "PurchasesList",
              params: { order: _vm.current_route },
              query: _vm.buildQueries(),
            },
            exact: "",
          },
        },
        [_vm._v(" Информация о закупке ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }