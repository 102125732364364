<template>
    <organization-reliability-report-controller v-slot:default="orrc">
        <div class="atmo-page-content atmo-client-space">
            <header>
                <h3>Проверка контрагентов</h3>
                <p>
                    Скачайте пример отчета по <b><a style="text-decoration: underline; color: var(--green-color)" v-bind:href="orrc.report_example_link" target="_blank">ссылке</a></b
                    >.
                </p>
                <p>
                    Стоимость одно запроса по организации составляет
                    <span v-if="orrc.vmdservice.viewables.financials.is_empty" class="atmo-skeleton-placeholder-item">&emsp;</span>
                    <b v-else><span v-text="orrc.vmdservice.viewables.financials.report_price"></span> руб.</b>
                    Эта сумма будет списана с лицевого счета Вашей организации в момент скачивания отчета. <br />Остаток на лицевом счете составляет:
                    <span v-if="orrc.vmdservice.viewables.financials.is_empty" class="atmo-skeleton-placeholder-item">&emsp;&emsp;</span>
                    <span v-else>{{ orrc.vmdservice.viewables.financials.deposit_balance | formatnumber }}</span> руб.
                </p>
            </header>

            <p v-if="!orrc.is_form_visible && !orrc.vmdservice.viewables.is_loading.financials" class="atmo-error">Остатка на лицевом счете недостаточно для оплаты отчета. Пожалуйста, пополните лицевой счет и перезагрузите эту страницу.</p>

            <main v-if="orrc.is_form_visible">
                <section class="atmo-form__field atmo-inverse">
                    <span class="atmo-input-w-spinner" v-bind:key="orrc.vmdservice.viewables.refresh_key">
                        <clearable-input v-slot:default="ci">
                            <span class="atmo-clearable-input">
                                <input v-model="orrc.vmdservice.editable.inn" v-focus v-input-int="true" id="orr-inn" required type="tel" pattern="\d{10}|\d{12}" size="14" minlength="10" maxlength="12" style="width: auto" autocomplete="off" />
                                <button v-if="!ci.isEmpty()" v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
                            </span>
                        </clearable-input>

                        <transition name="fade">
                            <!-- // NB: To eliminate annoying spinner blinking. -->
                            <div v-if="orrc.vmdservice.viewables.is_loading.organization" class="atmo-spinner">
                                <img src="@theme/assets/images/loaders/spinner-sector.svg" alt="Waiting spinner" />
                            </div>
                        </transition>
                    </span>

                    <label for="orr-inn">Введите ИНН. 10-значный (для организаций) или 12-значный (для ИП).</label>
                </section>

                <p v-if="orrc.is_not_found_message_visble" class="atmo-error">Организация с таким ИНН не найдена.</p>
                <p v-if="!orrc.vmdservice.editable.is_inn_valid" class="atmo-error">ИНН должен содержать 10 или 12 цифр.</p>

                <template v-if="orrc.vmdservice.viewables.is_found">
                    <h6>Найдена организация</h6>
                    <OrganizationInfo.view v-bind:organization="orrc.vmdservice.viewables.organization" />

                    <ul class="atmo-inputs-inline">
                        <li class="atmo-form__field atmo-checkbox">
                            <input v-model="orrc.vmdservice.editable.has_to_email_report" type="checkbox" name="orr-email" id="orr-email" class="atmo-icon-checkmark" />
                            <label for="orr-email">Отправить отчет на email:</label>
                        </li>
                        <li class="atmo-form__field atmo-inverse">
                            <input v-model="orrc.vmdservice.editable.email" v-bind:disabled="!orrc.vmdservice.editable.has_to_email_report" required type="email" size="50" />
                            <p class="atmo-error">Укажите правильный email</p>
                        </li>
                    </ul>

                    <p><i class="atmo-warning atmo-icon-warning"></i>&nbsp;Внимание! Повторное скачивание отчета без оплаты недоступно. <br />Рекомендуем отправить отчет на электронную почту. Для этого установите галочку напротив данной опции выше и укажите актуальный адрес.</p>

                    <button v-bind:disabled="!orrc.vmdservice.editable.is_email_valid || !$store.getters.getCanSign" v-on:click.stop="orrc.invokeSignReportDownloadPopup" class="atmo-button mx-2">Подтвердить</button>
                    <span v-if="!$store.getters.getCanSign" class="text-danger pt-1">Скачивание отчёта возможно только при авторизации при помощи ЭЦП</span>
                </template>
            </main>
        </div>
    </organization-reliability-report-controller>
</template>

<script>
import Config from '@core/js/ddriven/application/config/Config';
import OrganizationReliabilityReportController from '@core/js/viewmodels/misc/organization-reliability-report/OrganizationReliabilityReportController.viewmodel.ts';
import OrganizationInfoView from 'themes/views/pages/client-space/supplier/organization-reliability-report/OrganizationInfo.partial.view.vue';
import ClearableInput from '@lib/js/src/vue/components/ClearableInput.viewmodel';
import Focus from '@lib/js/src/vue/directives/Focus.directive';

export default {
    directives: {
        focus: new Focus()
    },
    components: {
        'organization-reliability-report-controller': OrganizationReliabilityReportController,
        'clearable-input': ClearableInput,

        'OrganizationInfo.view': OrganizationInfoView
    },
    computed: {
        reliability_report_example() {
            return Config.get('theme.variables.links.other.reliability_report_example');
        }
    }
};
</script>
