var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "purchases.grouped",
                        params: { group: "fl44" },
                      },
                    },
                  },
                  [_vm._v("Извещения ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "contracts.grouped",
                        params: { group: "fl44" },
                      },
                    },
                  },
                  [_vm._v("Договоры ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/quotation-requests" },
                  },
                  [_vm._v("Запросы КП ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "ImportSubstitutionRequestsList",
                        params: { path: "open" },
                      },
                    },
                  },
                  [_vm._v("Импортозамещение")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/clients" },
                  },
                  [_vm._v("ЗАКАЗЧИКИ И ПОСТАВЩИКИ ")]
                ),
              ],
              1
            ),
            _vm.$links.marketplace_link
              ? _c("p", { staticClass: "mt-3 text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                      attrs: {
                        href: _vm.$links.marketplace_link,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" БАШМАРКЕТ ")]
                  ),
                ])
              : _vm._e(),
            _c("p", { staticClass: "mt-3 text-center" }, [
              _c(
                "a",
                {
                  staticClass:
                    "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                  attrs: { href: _vm.$links.info_materials, target: "_blank" },
                },
                [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                !_vm.$store.state.token
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "text-nowrap text-uppercase mb-2",
                        attrs: { variant: "custom-danger" },
                        on: { click: _vm.showModal },
                      },
                      [_vm._v("Регистрация")]
                    )
                  : _vm._e(),
                !!_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-green",
                        attrs: {
                          tag: "button",
                          exact: "",
                          to: { name: "Cabinet" },
                        },
                      },
                      [_vm._v("Личный кабинет ")]
                    )
                  : _vm._e(),
                !_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-green",
                        attrs: { tag: "button", to: "/auth/login" },
                      },
                      [_vm._v("Вход в ЛК ")]
                    )
                  : _vm._e(),
                !!_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-outline-secondary mt-2",
                        attrs: { tag: "button", to: "/auth/logout" },
                      },
                      [_vm._v(" Выйти ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }