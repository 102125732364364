<template>
    <div v-if="!loading" class="new_quotation">
        <b-container fluid class="main_layer py-5">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mb-4">Подача коммерческого предложения на запрос КП № {{ quotationRequest.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14">&nbsp;</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="greyBg pb-4">
            <b-container>
                <b-row>
                    <b-col class="d-flex justify-content-between align-items-center">
                        <p class="block_head-text">Информация о заказчике</p>
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Наименование:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.organization.full_name }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">ИНН:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.organization.inn }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters v-if="quotationRequest.organization.kpp">
                    <b-col md="3" class="fs-14 grey-color">КПП:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.organization.kpp }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Адрес:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.organization.post_address }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Адрес электронной почты:</b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <a :href="'mailto:' + quotationRequest.organization.email">{{ quotationRequest.organization.email }}</a>
                    </b-col>
                </b-row>
                <b-row class="py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Номер контактного телефона:</b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <a :href="'tel:+' + quotationRequest.organization.phone">{{ quotationRequest.organization.phone }}</a>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="pb-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="block_head-text">Информация о поставщике</p>
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Полное наименование:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.full_name }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Сокращенное наименование:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.short_name }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Адрес</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.post_address }} {{ $store.state.user.organization.fact_address }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">ОГРН</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.ogrn }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">ИНН</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.inn }}</b-col>
                </b-row>
                <b-row class="py-3" no-gutters v-if="$store.state.user.organization.kpp">
                    <b-col md="3" class="fs-14 grey-color">КПП</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ $store.state.user.organization.kpp }}</b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="greyBg pb-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="block_head-text">Информация о запросе КП</p>
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Рег.номер запроса КП:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.reg_number }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Предмет закупки:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ quotationRequest.name }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Срок сбора КП:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ getDateTimeFormat(quotationRequest.deadline) }}</b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color">Документ:</b-col>
                    <b-col md="9" class="fs-14 grey-color"
                        ><a class="system_link-style" :href="fileUrl + quotationRequest.document_path" target="_blank">{{ quotationRequest.document_name }}</a></b-col
                    >
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="pb-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="block_head-text">Информация о коммерческом предложении</p>
                    </b-col>
                </b-row>
                <b-row class="border-bottom py-3" no-gutters>
                    <b-col md="3" class="fs-14 grey-color mt-2">Срок действия коммерческого предложения:</b-col>
                    <b-col md="9" class="fs-14 grey-color">
                        <date-time-picker
                            id="purchase_planned_contract_date"
                            :class="$v.form.deadline.$invalid ? 'is-invalid' : ''"
                            v-model="form.deadline"
                            label="Укажите срок действия КП"
                            :only-date="true"
                            formatted="DD.MM.YYYY"
                            format="YYYY-MM-DD"
                            buttonNowTranslation="Сегодня"
                            autoClose
                            :min-date="new Date().toJSON().slice(0, 10)"></date-time-picker>
                        <b-form-invalid-feedback v-if="$v.form.deadline.$invalid" style="display: block">Поле не заполнено </b-form-invalid-feedback>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="greyBg pb-4">
            <b-container>
                <form-row-specification-table :v="$v.form.specs" v-model="form.specs" no-add-spec no-delete-spec with-price :spec-sum="specSum" :spec-errors="specErrors" :spec-error-message="specErrorMessage" />
            </b-container>
        </b-container>
        <b-container fluid class="pb-4">
            <b-container>
                <b-row>
                    <b-col class="d-flex justify-content-between align-items-center">
                        <p class="fs-32 grey-color my-4">Документы</p>
                        <file-uploader :is-main="true" v-if="!form.document" @set_file="addFile">Добавить документ</file-uploader>
                    </b-col>
                </b-row>
                <b-row class="pb-4">
                    <b-col>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Наименование</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="form.document">
                                <tr>
                                    <td>
                                        <b-button variant="link" size="xs" style="padding-top: 0; padding-bottom: 0; color: var(--green-color) !important" class="fs-14" @click="$commonDocumentDownload(form.document)">{{ form.document.name }}</b-button>
                                    </td>
                                    <td width="1">
                                        <b-button @click="removeFile" variant="light" size="sm" style="background: transparent">
                                            <img src="/images/trash_icon.svg" alt="trash_icon" />
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" align="center">Документ не добавлен.</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="pb-4">
            <b-container>
                <b-row class="mb-4">
                    <b-col>
                        <b-button variant="custom-outline-secondary" class="mr-3" @click="cancelQuotation">ОТМЕНИТЬ</b-button>
                        <b-button variant="custom-green" @click="prepareForm" :disabled="!isValid">ПОДАТЬ ПРЕДЛОЖЕНИЕ</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-modal ref="sign-form-modal" title="Подписать предложение" size="lg" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc @close="cancelSignModal">
            <div class="d-block text-left">
                <b-tabs content-class="mt-3">
                    <b-tab active>
                        <template slot="title">
                            <span class="system_link-style fs-16">Форма</span>
                        </template>
                        <div>
                            <div class="border-bottom py-3 d-flex">
                                <div class="w-50 fs-14 grey-color">Рег. номер запроса КП:</div>
                                <div class="w-50 fs-14 fw-600 grey-color">№ {{ quotationRequest.reg_number }}</div>
                            </div>
                            <div class="border-bottom py-3 d-flex">
                                <div class="w-50 fs-14 grey-color">Общая стоимость предложения:</div>
                                <div class="w-50 fs-14 fw-600 grey-color">{{ $formatPrice(specSum) }} руб.</div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template slot="title">
                            <span class="system_link-style fs-16">Xml представление</span>
                        </template>
                        <b-form-textarea disabled v-model="formXml" rows="20"></b-form-textarea>
                    </b-tab>
                </b-tabs>
                <div class="py-3 d-flex">
                    <div class="w-50 fs-14 grey-color">Сертификат</div>
                    <div class="w-50 fs-14 fw-600 grey-color">
                        <b-input disabled :value="currentCertSubject"></b-input>
                    </div>
                </div>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelSignModal">ОТМЕНИТЬ</b-button>
                <b-button variant="custom-green" class="text-uppercase" v-if="!isSignedXml" :disabled="certIndex == null" @click="signForm">ПОДПИСАТЬ</b-button>
                <b-button variant="custom-green" class="text-uppercase" v-if="isSignedXml" :disabled="certIndex == null" @click="sendData">ОТПРАВИТЬ</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import QuotationRequests from '@/services/api/QuotationRequests';
import CadesMixins from '@/mixins/CadesMixins';
import Quotations from '@/services/api/Quotations';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import DateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import FormRowSpecificationTable from '@/components/common/form-rows/form-row-specification-table';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    name: 'QuotationCreate',
    mixins: [CadesMixins, validationMixin],
    components: {
        FormRowSpecificationTable,
        DateTimePicker
    },
    data() {
        return {
            visible: [],
            formXml: '',
            currentCertSubject: '',
            files: [],
            form: {
                quotation_request_id: null,
                deadline: null,
                document: null,
                specs: [],
                cost: 0
            },
            fileUrl: Config.get('api.config.legacy.url'),
            isSignedXml: '',
            quotationRequest: null,
            loading: true,
            total: 0,
            selected: null,
            formdata: {},
            showSignModal: false,
            showAttentionModal: false,
            showDepositModal: false,
            email: this.$store.state.user.email
        };
    },
    validations: {
        form: {
            quotation_request_id: { required },
            deadline: { required },
            specs: { required }
        }
    },
    mounted() {
        this.fillData().then(() => {
            setTimeout(() => {
                this.loading = false;
            }, 100);
        });
    },
    methods: {
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        format(value) {
            const count = value.toString().includes('.') ? value.toString().split('.').pop().length : 0;
            if (count > 2) {
                return parseFloat(value).toFixed(2);
            } else {
                return value;
            }
        },
        addFile(file) {
            this.form.document = file;
        },
        removeFile() {
            this.form.document = null;
        },
        fillData: async function () {
            let quotationRequest = await QuotationRequests.get(this.$route.params.id);
            this.quotationRequest = quotationRequest.data.data;
            this.form.quotation_request_id = this.quotationRequest.id;
            this.quotationRequest.items.forEach((item) => {
                this.form.specs.push({ ...item, price: 0, total_sum: 0 });
            });
        },
        prepareForm() {
            if (this.hasCertificates() === false) {
                this.loadCertificates();
            } else {
                this.showSignForm();
            }
            this.$on('certificates_loaded', () => {
                this.showSignForm();
            });
        },
        recalcItemsTotal() {
            this.form.specs.forEach((item, index) => {
                this.form.specs[index].total_sum = item.price * item.count;
            });
        },
        showSignForm() {
            this.recalcItemsTotal();
            this.formdata = {};
            this.formdata['items'] = [];
            for (const key in this.form.specs) {
                this.formdata['items'].push(this.form.specs[key]);
            }
            this.formdata['document'] = this.form.document;
            this.formdata['quotation_request_id'] = this.form.quotation_request_id;
            this.formdata['total_cost'] = this.specSum;
            this.formdata['organization_id'] = this.$store.state.user.organization.id;
            this.formdata['user_id'] = this.$store.state.user.id;

            Quotations.getXml(this.formdata).then((resp) => {
                this.formXml = resp.data.xml;
                this.showSignModal = true;
                let certs = this.$store.state.certificates;
                let parsedCerts = this.$store.state.parsedCertificates;
                this.certIndex = this.$store.state.currentCertificateIndex;
                this.currentThumprint = this.$store.state.currentThumbprint;
                let currentParsedCert = parsedCerts[this.certIndex];
                this.currentCertSubject = currentParsedCert.subject;
                this.currentCertThumbprint = currentParsedCert.thumbprint;
                this.currentCert = certs[this.certIndex];
                if (this.currentCertThumbprint !== this.currentThumprint) {
                    this.pushToast({
                        text: 'Ошибка распознавания сертификата, авторизуйтесь заново',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                }
            });
        },
        signForm() {
            if (this.$store.state.canAsync) {
                let sign = this.signXml(this.formXml, this.currentCert);
                sign.then((val) => {
                    this.formXml = val;
                    this.isSignedXml = true;
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                this.formXml = this.signXmlNPAPI(this.formXml, this.currentCert);
                this.isSignedXml = true;
            }
        },
        sendData() {
            // eslint-disable-next-line no-undef
            // this.form.files = this.files;
            this.recalcItemsTotal();
            axios
                .post('/quotations', {
                    quotation: { ...this.form, cost: this.specSum },
                    xml: this.formXml,
                    thumbprint: this.$store.state.currentThumbprint
                })
                .then(() => {
                    this.pushToast({
                        text: 'Предложение КП сформировано',
                        title: 'Успех',
                        variant: 'green'
                    });
                    this.showSignModal = true;
                    this.$router.push('/quotation-requests/new').catch(() => {});
                })
                .catch((error) => {
                    this.pushToast({
                        text: error.response.data.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                });
        },
        sumTotalItem(item, index) {
            if (this.form.specs[index] && Object.prototype.hasOwnProperty.call(this.form.specs[index], 'price')) {
                return this.form.specs[index].price * item.count;
            }
            return 0;
        },
        cancelQuotation() {
            this.$router.push('/quotation-requests/new').catch(() => {});
        },
        //переход непосредственно к подписи
        acceptConfirmation: function () {
            this.showAttentionModal = false;
            this.prepareForm();
        }
    },
    computed: {
        specErrors() {
            return !!this.specErrorMessage;
        },
        specErrorMessage() {
            if (!this.form.specs.every((e) => e.price > 0)) return 'У одной или нескольких позиций в таблице спецификации не заполнено поле "Цена за единицу"';
            return '';
        },
        specSum() {
            return Math.round(this.form.specs.reduce((acc, cv) => acc + Math.round(cv.count * cv.price * 100) / 100, 0) * 100) / 100;
        },
        isValid() {
            return !this.$v.$invalid && !this.specErrors;
        }
    }
};
</script>

<style scoped>
a {
    color: var(--green-color) !important;
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    min-height: 350px;
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>

<style>
.new_quotation .is-valid {
    border-color: rgb(206, 212, 218) !important;
    background: #fff !important;
}
</style>
