import axios from 'axios';

export default {
    getQuotationRequests(param, page) {
        return axios.get('/cabinet/customer/quotation_requests/' + param, {
            params: {
                page: page
            }
        });
    },
    stop(quotationRequestId) {
        return axios.delete('/cabinet/customer/quotation_requests/' + quotationRequestId);
    }
};
