var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            { staticClass: "d-flex flex-column justify-content-between h-100" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-52 fw-600 white-color mt-3 mb-4 main_text",
                      },
                      [_vm._v("Информационные материалы")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "tabs", staticStyle: { "margin-top": "115px" } },
                _vm._l(_vm.informationSections, function (item) {
                  return _c(
                    "router-link",
                    {
                      key: item.id,
                      attrs: {
                        tag: "li",
                        active: _vm.isActive(item.id),
                        to: "/information/" + item.id,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }