var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", [_vm._v("Идентификационные данные пользователя")]),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            rounded: "",
            opacity: "0.6",
            "spinner-variant": "primary",
          },
        },
        [
          _c("form-row-edit-text", {
            attrs: { label: "Фамилия", disabled: "" },
            model: {
              value: _vm.form.user.last_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "last_name", $$v)
              },
              expression: "form.user.last_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { label: "Имя", disabled: "" },
            model: {
              value: _vm.form.user.first_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "first_name", $$v)
              },
              expression: "form.user.first_name",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { label: "Отчество", disabled: "" },
            model: {
              value: _vm.form.user.middle_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "middle_name", $$v)
              },
              expression: "form.user.middle_name",
            },
          }),
          _c("form-row-edit-email", {
            attrs: {
              "email-confirmed": _vm.form.user.email_confirmed,
              v: _vm.$v.form.user.email,
              value: _vm.form.user.email,
              label: "Email",
              "with-validation": "",
              "is-edit": "",
            },
            on: {
              input: function ($event) {
                _vm.form.user.email_confirmed = false
              },
              value: function ($event) {
                _vm.form.user.email = $event
              },
              "email-confirmed": function ($event) {
                _vm.form.user.email_confirmed = $event
              },
            },
          }),
          _c("form-row-edit-phone", {
            attrs: {
              label: "Телефон",
              value: _vm.form.user.phone,
              v: _vm.$v.form.user.phone,
            },
            on: {
              value: function ($event) {
                _vm.form.user.phone = $event
              },
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                label: "Должность",
                "label-cols-sm": "4",
                "label-cols-lg": "3",
                "label-class":
                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                state: _vm.$v.form.user.post.$invalid
                  ? !_vm.$v.form.user.post.$invalid
                  : null,
              },
            },
            [
              _c(
                "b-form-select",
                {
                  staticClass: "fs-14",
                  attrs: {
                    state: !_vm.$v.form.user.post.$invalid,
                    "aria-describedby": "formdata_post_liveFeedback",
                  },
                  model: {
                    value: _vm.form.user.post,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.user, "post", $$v)
                    },
                    expression: "form.user.post",
                  },
                },
                [
                  _c("option", { attrs: { value: "", disabled: "" } }, [
                    _vm._v("Выберите должность"),
                  ]),
                  _c("option", [_vm._v("Руководитель организации")]),
                  _c("option", [_vm._v("Администратор организации")]),
                  _c("option", [
                    _vm._v(
                      "Лицо, уполномоченное на размещение информации и документов"
                    ),
                  ]),
                  _c("option", [
                    _vm._v(
                      "Лицо, имеющее право подписи документов от имени организации"
                    ),
                  ]),
                ]
              ),
              _vm.$v.form.user.post.$invalid
                ? _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: "formdata_post_liveFeedback",
                        state: !_vm.$v.form.user.post.$invalid,
                      },
                    },
                    [_vm._v(" Поле обязательно для заполнения. ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("form-row-edit-text", {
            attrs: {
              label: "Логин",
              description:
                "Запомните или запишите Ваш логин - он будет нужен в случае необходимости сброса пароля",
              disabled: "",
            },
            model: {
              value: _vm.form.user.username,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "username", $$v)
              },
              expression: "form.user.username",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              label: "Новый пароль",
              v: _vm.form.user.password ? _vm.$v.form.user.password : null,
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.form.user.password = $event
              },
            },
            model: {
              value: _vm.form.user.password,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "password", $$v)
              },
              expression: "form.user.password",
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              label: "Подтверждение пароля",
              v: _vm.form.user.password
                ? _vm.$v.form.user.password_confirmation
                : null,
              type: "password",
            },
            on: {
              value: function ($event) {
                _vm.form.user.password_confirmation = $event
              },
            },
            model: {
              value: _vm.form.user.password_confirmation,
              callback: function ($$v) {
                _vm.$set(_vm.form.user, "password_confirmation", $$v)
              },
              expression: "form.user.password_confirmation",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pt-3 pl-3" },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isLoading,
                rounded: "",
                opacity: "0.6",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-3",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.onCancel },
                },
                [_vm._v("Отмена")]
              ),
            ],
            1
          ),
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isLoading,
                rounded: "",
                opacity: "0.6",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    disabled: _vm.$v.form.$invalid,
                    variant: "custom-green",
                  },
                  on: { click: _vm.sendData },
                },
                [_vm._v("Сохранить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }