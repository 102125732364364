var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-between mb-4 column-992",
            },
            [
              _c(
                "p",
                {
                  staticClass: "fs-32 grey-color m-0 main_text",
                  staticStyle: { "line-height": "1" },
                },
                [_vm._v("Редактирование запроса на добавление позиций КТРУ")]
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c(
            "b-card",
            { staticClass: "transparent-card", attrs: { "no-body": "" } },
            [
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.forFilters.ktruGroups,
                  v: _vm.$v.form.ktru_group_id,
                  label: "Группа КТРУ",
                  placeholder: "Выберите группу КТРУ",
                },
                model: {
                  value: _vm.form.ktru_group_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ktru_group_id", $$v)
                  },
                  expression: "form.ktru_group_id",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.forFilters.ktruTypes,
                  v: _vm.$v.form.ktru_type_id,
                  label: "Тип КТРУ",
                  placeholder: "Выберите тип КТРУ",
                },
                model: {
                  value: _vm.form.ktru_type_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ktru_type_id", $$v)
                  },
                  expression: "form.ktru_type_id",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.forFilters.countries,
                  v: _vm.$v.form.country_of_origin_id,
                  label: "Страна происхождения",
                  placeholder: "Выберите страну происхождения",
                },
                model: {
                  value: _vm.form.country_of_origin_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "country_of_origin_id", $$v)
                  },
                  expression: "form.country_of_origin_id",
                },
              }),
              _c("form-row-edit-text", {
                attrs: { v: _vm.$v.form.name, label: "Название" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("form-row-edit-textarea", {
                attrs: { rows: 5, label: "Описание" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.forFilters.okeis,
                  v: _vm.$v.form.okei_id,
                  label: "Единица измерения (ОКЕИ)",
                  "label-field": "breadcrumb",
                  placeholder: "Выберите единицу измерения (ОКЕИ)",
                },
                model: {
                  value: _vm.form.okei_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "okei_id", $$v)
                  },
                  expression: "form.okei_id",
                },
              }),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.forFilters.okpds,
                  v: _vm.$v.form.okpd_id,
                  label: "ОКПД2",
                  "label-field": "breadcrumb",
                  placeholder: "Выберите ОКПД2",
                },
                model: {
                  value: _vm.form.okpd_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "okpd_id", $$v)
                  },
                  expression: "form.okpd_id",
                },
              }),
              _c(
                "b-table-simple",
                { staticClass: "table-atmo" },
                [
                  _c(
                    "b-thead",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-th", { staticStyle: { width: "350px" } }, [
                            _vm._v("Название"),
                          ]),
                          _c("b-th", [_vm._v("Значение")]),
                          _c(
                            "b-th",
                            {
                              staticClass: "align-right",
                              staticStyle: { width: "100px" },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { size: "sm", variant: "primary" },
                                  on: { click: _vm.addSpec },
                                },
                                [_vm._v("Добавить")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tbody",
                    _vm._l(
                      _vm.$v.form.specs.$each.$iter,
                      function (item, index) {
                        return _c(
                          "b-tr",
                          { key: index },
                          [
                            _c(
                              "b-td",
                              { attrs: { valign: "top" } },
                              [
                                _c("b-form-input", {
                                  staticClass: "fs-14",
                                  attrs: {
                                    disabled: index == 0,
                                    state: !item.Name.$invalid,
                                  },
                                  model: {
                                    value: item.Name.$model,
                                    callback: function ($$v) {
                                      _vm.$set(item.Name, "$model", $$v)
                                    },
                                    expression: "item.Name.$model",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              [
                                _c("b-form-textarea", {
                                  staticClass: "fs-14",
                                  attrs: { state: !item.Value.$invalid },
                                  model: {
                                    value: item.Value.$model,
                                    callback: function ($$v) {
                                      _vm.$set(item.Value, "$model", $$v)
                                    },
                                    expression: "item.Value.$model",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticStyle: { background: "transparent" },
                                    attrs: {
                                      disabled: index == 0,
                                      size: "sm",
                                      variant: "light",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeSpec(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: "trash_icon",
                                        src: "/images/trash_icon.svg",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-right mt-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mx-2 text-uppercase",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mx-2",
                      attrs: { variant: "custom-danger" },
                      on: { click: _vm.onSaveDraft },
                    },
                    [_vm._v("Сохранить черновик")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mx-2",
                      attrs: {
                        variant: "custom-green",
                        disabled: _vm.$v.form.$invalid,
                      },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Сохранить и отправить")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }